import { Fragment, useEffect, useState } from "react";
import { get, post } from "../../api";
import companyDefault from "../../images/resource/company-logo/1-1.png";
import JobListItem from "../Protected/Common/JobListItem";

const Recentjobs = ({ isLogin }) => {
  const [recentJobs, setRecentJobs] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState({
    category: "",
    job_title: "",
    postcode: "",
  });
  const [searchList, setSearchList] = useState([]);

  useEffect(() => {
    // const session = getSession(EMPLOYR_LOGIN_SESSION);
    // setUser(session);
    getActiveJobs();
  }, []);
  const getActiveJobs = async () => {
    const url = "/job/jobs/search";
    setLoading(true);
    try {
      const response = await post(url, {
        ...searchData,
        // page: page,
      });
      console.log("search", response);
      console.log(response);
      if (response?.status === 200) {
        setSearchList(response?.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <>
      {/* <!-- Job Section --> */}
      <section className="job-section style-two">
        <div className="auto-container wow fadeInUp">
          <div className="sec-title text-center">
            <h2>Recent Jobs</h2>
            <div className="text">
              Know your worth and find the job that qualify your life
            </div>
          </div>

          <div className="row wow fadeInUp animated animated">
            {searchList?.map((job) => {
              return (
                <Fragment key={job?.jobId}>
                  <JobListItem
                    job={job}
                    isLogin={isLogin}
                    classStyle={"job-block col-lg-4 col-md-4 col-sm-4"}
                  />
                </Fragment>
              );
            })}
          </div>
        </div>
      </section>
      {/* <!-- End Job Section --> */}
    </>
  );
};
export default Recentjobs;
