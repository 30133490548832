import React, { useState } from "react";
import CountDown from "./CountDown";
const Notifications = () => {
  const [isShow, setIsShow] = useState(false); // make it false when need to hide notification
  return (
    isShow && (
      <div class="alert alert-primary" role="alert">
        Thank you for visiting us! Mployr will be launching on 19th Feb with all
        innovative features! Please have a look round our brand new web site in the
        meantime and sign up ready for the launch in <CountDown />.
      </div>
    )
  );
};

export default Notifications;
