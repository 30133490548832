/**
 *
 * @param {string} key
 * @param {string} value
 */
export function createLocalStorage(key, value) {
  localStorage.setItem(key, value);
}
/**
 *
 * @param {string} key
 * @returns {string}
 */
export function getLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}
