import { useEffect, useState } from "react";
import Footer from "../Dashboard/Footer";
import { get, post } from "../../../api";
import Admin from "../Dashboard/Admin";
import { FormProvider, useForm } from "react-hook-form";
import { Input } from "../../Common/FormControl/Input";
import { formatDateString } from "../../../utils/date";

const UserManagement = () => {
  const [userDetail, setUserDetail] = useState();
  const [isLoading, setLoading] = useState(true);
  const [applicants, setApplicants] = useState([]);
  const [filterUser, setFilterUser] = useState();

  const { register } = useForm();
  const initialValues = {
    firstName: "",
    lastName: "",
    addressFirstLine: "",
    addressSecondLine: "",
    city: "",
    postCode: "",
    phone: "",
    email: "",
  };

  const methods = useForm({
    mode: "onTouched",
    defaultValues: { ...initialValues },
  });

  const onSubmitUser = methods.handleSubmit(async (basicDetails) => {
    console.log("Basic Details", basicDetails);
    setLoading(true);
    const payload = {
      firstName: basicDetails?.firstName,
      lastName: basicDetails?.lastName,
      addressFirstLine: basicDetails?.addressFirstLine,
      addressSecondLine: basicDetails?.addressSecondLine,
      city: basicDetails?.city,
      postCode: basicDetails?.postCode,
      phone: basicDetails?.phone,
      email: basicDetails?.email,
      companyName: basicDetails?.carehome,
      role: basicDetails?.role,
      password: "123456",
    };
    try {
      const url = `/user/users/manage/register`;
      const response = await post(url, payload);
      if (response?.status === 202) {
        // setUpdateProfileMsg("success");
        console.log("resource updated", response);
        if (response) {
          //   setFavourites(response?.favourites?.length);
        }
      } else {
        // setUpdateProfileMsg("error");
      }
      setLoading(false);
    } catch (error) {
      //   setUpdateProfileMsg("error");
      setLoading(false);
      console.log("error", error);
    }
  });
  useEffect(() => {
    // const session = getSession(EMPLOYR_LOGIN_SESSION);
    callMethod();
  }, []);
  const callMethod = async () => {
    const url = `/user/users/manage/list`;
    try {
      const response = await get(url);
      setUserDetail(response);
      setFilterUser(response);
      // setApplicants(response)
      console.log("profile response", response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };
  const searchUser = (event) => {
    const value = event?.target?.value;
    if (value) {
      const list = userDetail?.content?.filter((x) =>
        String(x?.firstName).toLowerCase().includes(String(value).toLowerCase())
      );
      console.log("list", list);
      setFilterUser(list);
      setApplicants(list);
      console.log("setFilterUser", applicants);
    } else {
      setFilterUser(userDetail?.content);
    }
  };
  console.log("userDetail", userDetail);
  return (
    <>
      <div className="page-wrapper dashboard ">
        <Admin />
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="upper-title-box">
              <h3>Manage Users</h3>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Ls widget --> */}
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-title"></div>

                    <div className="widget-content">
                      <div className="default-tabs style-two tabs-box">
                        {/* <!--Tabs Box--> */}
                        <ul className="tab-buttons clearfix">
                          <li className="tab-btn active-btn" data-tab="#tab1">
                            <span className="la la-user-shield"></span> User
                            Management
                          </li>
                        </ul>

                        <div className="tabs-content">
                          {/* <!--Inbox--> */}
                          <div
                            className="tab active-tab animated fadeIn"
                            id="tab1"
                            style={{ display: "block" }}
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="notification-widget ls-widget">
                                  <div className="tabs-box">
                                    <div className="widget-title">
                                      <h4>Add/Edit Users</h4>

                                      <div className="filter-block">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            name="listing-search"
                                            placeholder="Search User"
                                            onChange={searchUser}
                                          />
                                          <span className="icon flaticon-search-3"></span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="widget-content">
                                      <div className="table-outer">
                                        <table className="default-table">
                                          <thead>
                                            <tr>
                                              <th>Action</th>
                                              <th>Name</th>
                                              <th>Email</th>
                                              <th>Phone</th>
                                              <th>Role</th>
                                              <th>Creation Date</th>
                                              <th>Status</th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {userDetail?.content?.length ===
                                              0 && (
                                              <div className="job-block col-lg-12 col-md-12 col-sm-12">
                                                <div className="inner-box">
                                                  <div className="content">
                                                    No Result Found.
                                                  </div>
                                                </div>
                                              </div>
                                            )}

                                            {filterUser?.content?.map(
                                              (user) => {
                                                return (
                                                  <tr key={user?.userId}>
                                                    <td
                                                      style={{
                                                        paddingLeft: "0",
                                                      }}
                                                    >
                                                      <div className="option-box">
                                                        <ul className="option-list">
                                                          <li>
                                                            <button data-text="Edit">
                                                              <span className="la la-pencil"></span>
                                                            </button>
                                                          </li>
                                                          <li>
                                                            <button data-text="De-activate">
                                                              <span className="la la-trash text-danger"></span>
                                                            </button>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      {user?.firstName}{" "}
                                                      {user?.lastName}
                                                    </td>
                                                    <td>{user?.email}</td>
                                                    <td>{user?.phone}</td>
                                                    <td>
                                                      {user?.role ===
                                                      "CANDIDATE" ? (
                                                        <span class="badge bg-primary">
                                                          {user?.role}
                                                        </span>
                                                      ) : (
                                                        <span class="badge bg-success">
                                                          {user?.role
                                                            ?.split("_")
                                                            ?.join(" ")}
                                                        </span>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {formatDateString(
                                                        user?.startDate
                                                      )}
                                                    </td>
                                                    <td className="text-primary">
                                                      {user?.status}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                            {applicants?.map((user) => {
                                              return (
                                                <tr key={user?.userId}>
                                                  <td
                                                    style={{
                                                      paddingLeft: "0",
                                                    }}
                                                  >
                                                    <div className="option-box">
                                                      <ul className="option-list">
                                                        <li>
                                                          <button data-text="Edit">
                                                            <span className="la la-pencil"></span>
                                                          </button>
                                                        </li>
                                                        <li>
                                                          <button data-text="De-activate">
                                                            <span className="la la-trash text-danger"></span>
                                                          </button>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </td>
                                                  <td>{user?.firstName}</td>
                                                  <td>{user?.email}</td>
                                                  <td>{user?.phone}</td>
                                                  <td>
                                                    {user?.role ===
                                                    "CANDIDATE" ? (
                                                      <span class="badge bg-primary">
                                                        {user?.role}
                                                      </span>
                                                    ) : (
                                                      <span class="badge bg-success">
                                                        {user?.role
                                                          ?.split("_")
                                                          ?.join(" ")}
                                                      </span>
                                                    )}
                                                  </td>
                                                  <td>
                                                    {formatDateString(
                                                      user?.startDate
                                                    )}
                                                  </td>
                                                  <td>{user?.status}</td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-inner login-form default-form">
                                  <FormProvider {...methods}>
                                    <form
                                      onSubmit={(e) => e.preventDefault()}
                                      noValidate
                                    >
                                      <div className="btn-box row content-elements">
                                        <div className="form-group col-lg-6 col-md-12">
                                          <label>Role</label>
                                          <select
                                            name="role"
                                            className="chosen-select-role"
                                            placeholder="Select role"
                                            {...register("role")}
                                          >
                                            <option value={"MPLOYR_ADMIN"}>
                                              Mployr Admin
                                            </option>
                                            <option value={"CAREHOME_USER"}>
                                              Carehome User
                                            </option>
                                            <option value={"CANDIDATE"}>
                                              Candidate
                                            </option>
                                          </select>
                                        </div>
                                        <div className="form-group col-lg-6 col-md-12">
                                          <Input
                                            label="Care Home"
                                            type="text"
                                            name="carehome"
                                            placeholder="Search or Type New"
                                          />
                                        </div>
                                      </div>

                                      <div className="btn-box row">
                                        <div className="form-group col-lg-6 col-md-12">
                                          <Input
                                            label="First Name"
                                            type="text"
                                            name="firstName"
                                            placeholder="First Name"
                                          />
                                        </div>

                                        <div className="form-group col-lg-6 col-md-12">
                                          <Input
                                            label="Last Name"
                                            type="text"
                                            name="lastName"
                                            placeholder="Last Name"
                                          />
                                        </div>
                                      </div>

                                      <div className="btn-box row">
                                        <div className="form-group col-lg-6 col-md-12">
                                          <Input
                                            label="Address 1"
                                            type="text"
                                            name="addressFirstLine"
                                            placeholder="Address Line 1"
                                          />
                                        </div>

                                        <div className="form-group col-lg-6 col-md-12">
                                          <Input
                                            label="Address 2"
                                            type="text"
                                            name="addressSecondLine"
                                            placeholder="Address Line 2"
                                          />
                                        </div>
                                      </div>

                                      <div className="btn-box row">
                                        <div className="form-group col-lg-6 col-md-12">
                                          <Input
                                            label="City"
                                            type="text"
                                            name="city"
                                            placeholder="City"
                                            required=""
                                          />
                                        </div>

                                        <div className="form-group col-lg-6 col-md-12">
                                          <Input
                                            label="Post Code"
                                            type="text"
                                            name="postCode"
                                            placeholder="Post Code"
                                          />
                                        </div>
                                      </div>

                                      <div className="btn-box row">
                                        <div className="form-group col-lg-6 col-md-12">
                                          <Input
                                            label="Phone"
                                            type="text"
                                            name="phone"
                                            placeholder="Phone Number"
                                            required=""
                                          />
                                        </div>
                                        <div className="form-group col-lg-6 col-md-12">
                                          <Input
                                            label="Email"
                                            type="text"
                                            name="email"
                                            placeholder="Email Address"
                                            required=""
                                          />
                                        </div>
                                      </div>

                                      <div className="form-group col-lg-2 col-md-12">
                                        <button
                                          className="theme-btn btn-style-one"
                                          type="submit"
                                          name="Savebasic"
                                          onClick={onSubmitUser}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </form>
                                  </FormProvider>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default UserManagement;
