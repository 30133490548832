import React from "react";
export default function JobCard(props) {
  return (
    <>
      <div
        className="card"
        style={{
          width: "12rem",
          zIndex: "9999",
        }}
      >
        <div className="text-center">
          <img style={{height: '100px', width: '100px'}} src={props?.companyPhoto} class="card-img-top" />
        </div>
        <div className="card-body pt-0">
          <h5 className="card-title" >{props?.title}</h5>
          <p>{props?.cityName}</p>
          <a
            className="btn btn-primary btn-sm"
            target="_blank"
            style={{ fontSize: '10px'}}
            href={`/#/jobSingle?id=${props?.jobId}`}
          >
            View Details
          </a>
        </div>
      </div>
    </>
  );
}
