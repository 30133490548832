import { height } from "@mui/system";
import "@whereby.com/browser-sdk/embed"

const MyVideoViewCall = ({ roomUrl }) => {
  return (
    <div className="videoGrid">
      <whereby-embed room={roomUrl} style={{height: '800px'}}/>
    </div>
  );
};

export default MyVideoViewCall;
