const Footer = () => {
    return (
        <>
            <div className="copyright-text">
                <p>&copy; {new Date().getFullYear()} mployr. All Right Reserved.</p>
            </div>

        </>
    )
};
export default Footer;