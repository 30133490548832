import { useEffect, useRef, useState } from "react";
import Jobs from "./Jobs";
import MapPoint from "./MapPoint";
import OverlayContainer from "./OverlayContainer";
import MapPointCareHome from "./MapPointCareHome";

function Map({ center, zoom, isJobList = false, isCareHomeList = false, JobList, careHomeList }) {
  const ref = useRef(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (ref.current) {
      let createdMap = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        disableDefaultUI: true,
        clickableIcons: false,
      });
      setMap(createdMap);
    }
  }, [center, zoom]);
  return (
    <div
      ref={ref}
      id="map"
      style={{
        height: "100vh",
      }}
    >
      {isJobList &&
        JobList.map((job, index) => (
          <OverlayContainer
            map={map}
            position={{
              lat: job?.latitude,
              lng: job?.longitude,
            }}
            key={index}
          >
            <MapPoint
              companyPhoto={job?.companyPhoto}
              cityName={job?.cityName}
              jobId={job?.jobId}
              title={job?.title}
            />
          </OverlayContainer>
        ))}
      {isCareHomeList &&
        careHomeList.map((careHome, index) => (
          <OverlayContainer
            map={map}
            position={{
              lat: careHome?.latitude,
              lng: careHome?.longitude,
            }}
            key={index}
          >
            <MapPointCareHome
              companyPhoto={careHome?.companyPhoto}
              cityName={careHome?.city}
              companyName={careHome?.companyName}
              companyId={careHome?.companyId}
              siteId={careHome?.siteId}
              openJobs={careHome?.openJobs}
              regDate={careHome?.regDate}
            />
          </OverlayContainer>
        ))}
    </div>
  );
}

export default Map;
