import Header from "../Protected/Header";
import Footer from "../Common/Footer";
import Scripts from "../Common/Scripts/Scripts";
import { Helmet } from "react-helmet";
import contactMarker from "../../images/icons/contact-map-marker.png";
import placeMarker from "../../images/icons/placeholder.svg";
import smartphoneMarker from "../../images/icons/smartphone.svg";
import { post } from "../../api";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { InputError } from "../Common/FormControl/Input";
import Success from "../Common/Alert/Success";
import Error from "../Common/Alert/Error";
import Loading from "../Common/Loading";

function Contact() {
  const [isLoading, setLoading] = useState(false);
  const [sentEmailFlag, setSentEmailFlag] = useState("");
  const validateField = {
    required: {
      value: true,
      message: "required",
    },
  };
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm();
  console.log("errors", errors);

  const sendContactUsEmail = async (contactDetail) => {
    console.log(contactDetail);
    setLoading(true);
    try {
      setSentEmailFlag("");
      const url = `/message/email/contact-us`;
      const responseData = {
        name: contactDetail?.username,
        email: contactDetail?.email,
        subject: contactDetail?.subject,
        body: contactDetail?.message,
      };

      const response = await post(url, responseData);
      if (response?.status === 201 || response?.status === 200) {
        setSentEmailFlag("success");
        reset({
          username: "",
          email: "",
          subject: "",
          message: "",
        });
      } else {
        setSentEmailFlag("error");
      }
      setLoading(false);
    } catch (err) {
      setSentEmailFlag("error");
      setLoading(false);
      console.log("Something Went Wrong, Email couldn't be sent", err);
    }
  };
  return (
    <div className="page-wrapper">
      <span className="header-span"></span>
      <Scripts />
      <Header />

      {/* <!-- Contact Section --> */}
      <section className="contact-section">
        <div className="auto-container">
          <div className="upper-box">
            <div className="row">
              <div className="contact-block col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <span className="icon">
                    <img src={placeMarker} alt="" />
                  </span>
                  <h4>Address</h4>
                  <p>
                    Mployr
                    <br />
                    B1 Business Centre, Suite 206
                    <br /> Davyfield Road, Blackburn
                    <br />
                    Bb1 2QY
                  </p>
                </div>
              </div>

              <div className="contact-block col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <span className="icon">
                    <img src={smartphoneMarker} alt="" />
                  </span>
                  <h4>Email</h4>
                  <p>
                    <a href="mailto:info@mployr.com">info@mployr.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* /<!-- Contact Form --> */}
          <div className="contact-form default-form">
            <h3>Leave A Message</h3>
            {/* <!--Contact Form--> */}
            {sentEmailFlag === "success" && (
              <>
                <Success msg="Your email has been sent successfully." />
              </>
            )}
            {sentEmailFlag === "error" && (
              <>
                <Error msg="Something went wrong while sending an email. Please try later." />
              </>
            )}
            <form onSubmit={handleSubmit(sendContactUsEmail)} noValidate>
              <div className="row">
                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                  <div className="response"></div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                  <label>Your Name</label>
                  <input
                    type="text"
                    name="username"
                    className="username"
                    {...register("username", validateField)}
                    placeholder="Your Name*"
                    required
                  />
                  {errors?.username && (
                    <InputError
                      message={errors?.username?.message}
                      key={errors?.username?.message}
                    />
                  )}
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                  <label>Your Email</label>
                  <input
                    type="email"
                    name="email"
                    className="email"
                    {...register("email", {
                      required: "required",
                      validate: {
                        maxLength: (v) =>
                          v.length <= 50 ||
                          "The email should have at most 50 characters",
                        matchPattern: (v) =>
                          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                            v
                          ) || "Email address must be a valid address",
                      },
                    })}
                    placeholder="Your Email*"
                    required
                  />
                  {errors?.email && (
                    <InputError
                      message={errors?.email?.message}
                      key={errors?.email?.message}
                    />
                  )}
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                  <label>Subject</label>
                  <input
                    type="text"
                    name="subject"
                    className="subject"
                    {...register("subject", validateField)}
                    placeholder="Subject *"
                    required
                  />
                  {errors?.subject && (
                    <InputError
                      message={errors?.subject?.message}
                      key={errors?.subject?.message}
                    />
                  )}
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                  <label>Your Message</label>
                  <textarea
                    name="message"
                    placeholder="Write your message..."
                    {...register("message", validateField)}
                    required=""
                  ></textarea>
                  {errors?.message && (
                    <InputError
                      message={errors?.message?.message}
                      key={errors?.message?.message}
                    />
                  )}
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                  {isLoading && <Loading />}
                  {!isLoading && (
                    <button
                      className="theme-btn btn-style-one"
                      type="submit"
                      id="submit"
                      name="submit-form"
                    >
                      Send Message
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
          {/* /<!--End Contact Form --> */}
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Contact;
