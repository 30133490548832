const formatDateLabel = (origDate) => {
    let formattedDate;
    const [yy, mm, dd] = origDate ? origDate.split('-') : '';
    switch (mm) {
        case '01':
            formattedDate = `${dd} Jan ${yy}`
            break;
        case '02':
            formattedDate = `${dd} Feb ${yy}`
            break;
        case '03':
            formattedDate = `${dd} Mar ${yy}`
            break;
        case '04':
            formattedDate = `${dd} Apr ${yy}`
            break;
        case '05':
            formattedDate = `${dd} May ${yy}`
            break;
        case '06':
            formattedDate = `${dd} June ${yy}`
            break;
        case '07':
            formattedDate = `${dd} July ${yy}`
            break;
        case '08':
            formattedDate = `${dd} Aug ${yy}`
            break;
        case '09':
            formattedDate = `${dd} Sep ${yy}`
            break;
        case '10':
            formattedDate = `${dd} Oct ${yy}`
            break;
        case '11':
            formattedDate = `${dd} Nov ${yy}`
            break;
        case '12':
            formattedDate = `${dd} Dec ${yy}`
            break;
        default:
            break;
    }
    return formattedDate
}
export default formatDateLabel;