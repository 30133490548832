import React, { useEffect, useState } from "react";
import { get, put } from "../../../api";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import { getSession } from "../../../utils/session";
import Scripts from "../../Common/Scripts/Scripts";
import Admin from "../Dashboard/Admin";
import Footer from "../Dashboard/Footer";
import Header from "../Dashboard/Header";
import { formatDate, formatDateString } from "../../../utils/date";
import { useForm } from "react-hook-form";
import Error from "../../Common/Alert/Error";
import Success from "../../Common/Alert/Success";

function ManageSubscriptions() {
  const [user, setUser] = useState();
  const [reviewList, setReviewList] = useState([]);
  const [subList, setSubList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [subscriptionApiMsg, setSubscriptionApiMsg] = useState("");
  const [subscriptionApiMsgDate, setSubscriptionApiMsgForDate] = useState("");
  const { register, getValues } = useForm();
  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
    getAllSubscriptionList();
  }, []);
  const getAllSubscriptionList = async () => {
    try {
      const url = `/user/company/subscription/list`;
      const response = await get(url);
      setSubList(response);
      getSubscriptionList();
    } catch (e) {
      console.log(e);
    }
  };
  const getSubscriptionList = async () => {
    try {
      const url = `/user/company/subscription?type=Review`;
      const response = await get(url);
      setReviewList(response);
    } catch (e) {
      console.log(e);
    }
  };
  const getFeesValue = (subValue) => {
    console.log("subValue", subValue);
    console.log("subList", subList);
    const list = subList?.filter((itm) => itm?.subscriptionId === subValue);
    console.log("list", list);
    if (list?.length > 0) {
      return list[0].fee;
    } else {
      return 0;
    }
  };
  const updateSubscriptionRequest = async (subscription, status) => {
    const startDate = getValues(`subscription-${subscription?.subscriptionId}`);
    setSubscriptionApiMsgForDate("");
    const payload = {
      companyId: subscription?.companyId,
      subscriptionType: subscription?.subscription?.subscriptionId,
      companySubscriptionId: subscription?.subscriptionId,
      companySubscriptionStatus: status,
      startDate: startDate,
    };
    console.log("status", status);
    console.log("subscription", subscription);
    if (startDate) {
      try {
        setLoading(true);
        setSubscriptionApiMsg("");
        const url = `user/company/subscription`;
        const response = await put(url, payload);
        if (
          response?.status === 201 ||
          response?.status === 200 ||
          response?.status === 202 ||
          response?.status === 204
        ) {
          setSubscriptionApiMsg("success");
          getSubscriptionList();
        } else {
          setSubscriptionApiMsg("error");
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    } else {
      setSubscriptionApiMsgForDate("error");
      return;
    }
  };
  return (
    <div className="page-wrapper dashboard ">
      <Admin />
      {isLoading && <div className="preloader"></div>}
      <section className="user-dashboard">
        <div className="dashboard-outer">
          <div className="upper-title-box">
            <h3>Manage Subscriptions</h3>
          </div>

          <div className="row">
            <div className="col-lg-12">
              {/* <!-- Ls widget --> */}
              <div className="ls-widget">
                <div className="tabs-box">
                  <div className="widget-title"></div>

                  <div className="widget-content">
                    <div className="default-tabs style-two tabs-box">
                      <div className="message-box info">
                        <p>
                          Select start date for subscriotion before approving
                          and marking as paid.
                        </p>
                      </div>

                      <div className="tabs-content">
                        {/* <!--Inbox--> */}
                        <div
                          className="tab active-tab animated fadeIn"
                          id="tab1"
                          style={{ display: "block" }}
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              {subscriptionApiMsg === "success" && (
                                <Success msg={`Success: Subscription saved.`} />
                              )}
                              {subscriptionApiMsgDate === "error" && (
                                <Error
                                  msg={`Error: Please select start date to proceed.`}
                                  setParentState={setSubscriptionApiMsgForDate}
                                />
                              )}
                              {setSubscriptionApiMsgForDate === "error" && (
                                <Error
                                  msg={`Error: Please Select Date to proceed.`}
                                />
                              )}
                              {subscriptionApiMsg === "error" && (
                                <Error
                                  msg={`Error: Something went wrong. Please try again.`}
                                />
                              )}
                              <div className="notification-widget ls-widget">
                                <div className="">
                                  <div className="widget-content">
                                    <div className="table-outer">
                                      <table className="default-table">
                                        <thead>
                                          <tr>
                                            <th>Carehome</th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>Fee</th>
                                            <th>Request Date</th>
                                            <th>Start Date</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          {reviewList?.length === 0 && (
                                            <tr>
                                              <td>No Result Found</td>
                                            </tr>
                                          )}
                                          {reviewList?.map((itm) => (
                                            <tr key={itm?.subscriptionId}>
                                              <td>{itm?.companyName}</td>
                                              <td>{itm?.fromSubscription}</td>
                                              <td>{itm?.toSubscription}</td>
                                              <td>£{getFeesValue(itm?.toSubscription)}</td>
                                              <td>
                                                {formatDateString(
                                                  itm?.subscriptionDate
                                                )}
                                              </td>
                                              <td>
                                                <input
                                                  type="date"
                                                  {...register(
                                                    `subscription-${itm?.subscriptionId}`
                                                  )}
                                                />
                                              </td>
                                              <td
                                                style={{
                                                  paddingLeft: "0",
                                                }}
                                              >
                                                <div className="option-box">
                                                  <ul className="option-list">
                                                    <li>
                                                      <button
                                                        className="text-danger"
                                                        data-text="Cancel Request"
                                                        onClick={() => {
                                                          updateSubscriptionRequest(
                                                            itm,
                                                            "Cancelled"
                                                          );
                                                        }}
                                                      >
                                                        <span className="la la-times-circle"></span>
                                                      </button>
                                                    </li>
                                                    <li>
                                                      <button
                                                        data-text="Mark as paid"
                                                        onClick={() => {
                                                          updateSubscriptionRequest(
                                                            itm,
                                                            "Active"
                                                          );
                                                        }}
                                                      >
                                                        <span className="la la-check-circle"></span>
                                                      </button>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ManageSubscriptions;
