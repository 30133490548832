import { useEffect, useRef, useState } from "react";
import CareHomeCard from "./CareHomeCard";
import CareHomePoint from "./CareHomePoint";

const MapPointCareHome = (props) => {
  const [opened, setIsOpened] = useState(false);
  const handleOnOpen = () => setIsOpened(true);
  const handleOnClose = () => setIsOpened(false);
  const containerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpened(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  return (
    <div ref={containerRef}>
      {opened ? (
        <CareHomeCard
          companyPhoto={props?.companyPhoto}
          cityName={props?.cityName}
          companyName={props?.companyName}
          companyId={props?.companyId}
          siteId={props?.siteId}
          openJobs={props?.openJobs}
          regDate={props?.regDate}
          handleClose={handleOnClose}
        />
      ) : (
        <CareHomePoint onClick={handleOnOpen} />
      )}
    </div>
  );
};

export default MapPointCareHome;
