import { Fragment, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { isFormInvalid } from "../../../utils/isFormInvalid";
import { findInputError } from "../../../utils/findInputError";
import { get } from "../../../api";
export const Input = ({
  label,
  type,
  name,
  id,
  placeholder,
  validation,
  className,
  maxLength,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);
  const classList = `form-group ${className}`;
  const [postCodeList, setPostCodeList] = useState([]);
  const selectedValues = useMemo(
    () => postCodeList.filter((v) => v),
    [postCodeList]
  );
  const handleOnSearch = async (e) => {
    setValue("postcode", e?.target?.value, { shouldValidate: true });
    try {
      const url = `https://api.getaddress.io/autocomplete/${e?.target?.value}?api-key=pdSw7G1TEk6kghR1DNzddQ41182&all=true`;
      const response = await get(url);
      const res = [];
      response?.suggestions?.forEach((itm) => {
        res.push({
          id: itm?.id,
          name: `${itm?.address}`,
          url: `${itm?.url}`,
        });
      });
      setPostCodeList(res);
    } catch (e) {
      setPostCodeList([]);
    }
  };
  const handleOnSelect = async (data) => {
    console.log("data", data);
    try {
      const url = `https://api.getaddress.io${data?.url}?api-key=pdSw7G1TEk6kghR1DNzddQ41182&all=true`;
      const response = await get(url);
      console.log("response", response);
      setValue("postcode", response?.postcode, { shouldValidate: true });
      setValue("addressSecondLine", response?.line_2);
      setValue("addressFirstLine", response?.line_1, { shouldValidate: true });
      setValue("addr1", response?.line_1);
      setValue("addr2", response?.line_2, { shouldValidate: true });
      setValue("city", response?.town_or_city, { shouldValidate: true });
      setValue("latitude", response?.latitude);
      setValue("longitude", response?.longitude);
      setPostCodeList([]);
    } catch (e) {
      console.log("error while loading postcode");
    }
  };
  return (
    <div className={classList}>
      <label htmlFor={id}>{label}</label>
      {name === "postcode" && (
        <div className="autocomplete">
          <input
            id={name}
            type={type}
            name={name}
            onKeyUp={handleOnSearch}
            onFocusOut={(e) => console.log("blur", e)}
            {...register(name, validation)}
            placeholder="Search post code"
          />
          <ul className="postCodeSearchResult">
            {selectedValues?.map((itm) => (
              <li onClick={() => handleOnSelect(itm)} key={itm?.id}>{itm?.name}</li>
            ))}
          </ul>
        </div>
      )}
      {name !== "postcode" && (
        <input
          id={id}
          name={name}
          autoComplete="false"
          type={type}
          placeholder={placeholder}
          {...register(name, validation)}
          maxLength={maxLength ? maxLength: ''}
        />
      )}

      {isInvalid && (
        <InputError
          classList={name === "postcode" ? "margin-top-error" : ""}
          message={inputErrors?.error?.message}
          key={inputErrors?.error?.message}
        />
      )}
    </div>
  );
};

export const InputError = ({ message, key, classList }) => {
  return (
    <Fragment key={key}>
      <div className={`text-danger ${classList}`}>{message}</div>
    </Fragment>
  );
};
