import React, { useEffect } from "react";
import Scripts from "../../Common/Scripts/Scripts";
import CandidateSidebar from "../Dashboard/CandidateSidebar";
import Footer from "../Dashboard/Footer";
import Header from "../Dashboard/Header";
import { getSession } from "../../../utils/session";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import { useState } from "react";
import { ROLE } from "../../../constant/role";
import { get } from "../../../api";
import { formatDate, formatDateString } from "../../../utils/date";

const AppliedJobs = () => {
  const [user, setUser] = useState();
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [filterJobs, setFilterJobs] = useState([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
    getAppliedJobDetails();
  }, []);
  const getAppliedJobDetails = async () => {
    setLoading(true);
    try {
      const url = `/job/jobs/applied`;
      const response = await get(url);
      setAppliedJobs(response);
      setFilterJobs(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };
  const searchJob = (event) => {
    const value = event?.target?.value;
    if (value) {
      const list = appliedJobs?.filter((x) =>
        String(x?.title + x?.companyName + x?.jobApplicationStatus)
          .toLowerCase()
          .includes(String(value).toLowerCase())
      );
      setFilterJobs(list);
    } else {
      setFilterJobs(appliedJobs);
    }
  };
  return (
    <>
      <div className="page-wrapper dashboard">
        <Scripts />
        <Header />
        {isLoading && <div className="preloader"></div>}
        {user?.role === ROLE.CANDIDATE && <CandidateSidebar />}
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="upper-title-box">
              <h3>Applied Jobs</h3>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Ls widget --> */}
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-title">
                      <h4>My Applied Jobs</h4>

                      <div className="filter-block">
                        <div className="form-group">
                          <input
                            type="text"
                            name="listing-search"
                            placeholder="title, company or status"
                            onChange={searchJob}
                          />
                          <span className="icon flaticon-search-3"></span>
                        </div>
                      </div>
                    </div>

                    <div className="widget-content">
                      <div className="table-outer">
                        <table className="default-table manage-job-table">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Job Title</th>
                              <th>Date Applied</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {appliedJobs?.length === 0 && (
                              <tr>
                                <td>
                                  <div className="job-block">
                                    <div className="inner-box">
                                      <div className="content">
                                        No Result Found.
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                            {filterJobs?.map((job) => (
                              <tr key={job?.id}>
                                <td
                                  style={{
                                    paddingLeft: "0",
                                  }}
                                >
                                  <div className="option-box">
                                    <ul className="option-list">
                                      <li>
                                        <a
                                          target="_blank"
                                          href={`/#/jobSingle?id=${job?.id}`}
                                          data-text="View Job"
                                        >
                                          <span className="la la-eye"></span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td>
                                  {/* <!-- Job Block --> */}
                                  <div className="job-block">
                                    <div className="inner-box">
                                      <div className="content">
                                        <span className="company-logo">
                                          <img src={job?.companyPhoto} alt="" />
                                        </span>
                                        <h4>
                                          <a
                                            target="_blank"
                                            href={`/#/jobSingle?id=${job?.id}`}
                                          >
                                            {job?.title}
                                          </a>
                                        </h4>
                                        <ul className="job-info">
                                          <li>
                                            <span className="icon flaticon-briefcase"></span>{" "}
                                            {job?.userType}
                                          </li>
                                          <li>
                                            <span className="icon flaticon-map-locator"></span>{" "}
                                            {job?.cityName}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {job?.appliedJobDate
                                    ? formatDateString(job?.appliedJobDate)
                                    : "Dec 5, 2023"}
                                </td>
                                <td>
                                  <span
                                    className={
                                      job?.jobApplicationStatus === "ACCEPTED"
                                        ? "badge bg-success"
                                        : job?.jobApplicationStatus ===
                                          "REJECTED"
                                        ? "badge bg-danger"
                                        : "badge bg-warning text-dark"
                                    }
                                  >
                                    {job?.jobApplicationStatus === "REJECTED"
                                      ? "Unsuccessful"
                                      : job?.jobApplicationStatus
                                          ?.split("_")
                                          ?.join(" ")}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default AppliedJobs;
