import { Fragment } from "react";
import logo from "./../../images/logo-2.svg";
import logo1 from "./../../images/logo.svg";

function PageNotFound() {
  return (
    <Fragment>
      <div className="page-wrapper">
        <header className="main-header">
          <div className="container-fluid">
            <div className="main-box">
              <div className="nav-outer">
                <div className="logo-box">
                  <div className="logo">
                    <a href="/">
                      <img src={logo} alt="" title="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="outer-box">
                <div className="btn-box">
                  <a href="/" className="theme-btn btn-style-three">
                    <span className="btn-title">Home</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="mobile-header">
            <div className="logo">
              <a href="/">
                <img src={logo1} alt="" title="" />
              </a>
            </div>
            <div className="btn-box">
              <a
                href="/"
                className="theme-btn btn-style-three"
                style={{ float: "right" }}
              >
                <span className="btn-title">Home</span>
              </a>
            </div>
          </div>
          <div id="nav-mobile"></div>
        </header>
        <div className="login-section">
          <div className="image-layer"></div>
          <div className="outer-box"><h1>Page Not Found</h1></div>
        </div>
      </div>
    </Fragment>
  );
}

export default PageNotFound;
