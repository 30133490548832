import Header from "../Protected/Header";
import Footer from "../Common/Footer";
import Scripts from "../Common/Scripts/Scripts";
import img1 from "../../images/resource/about-img-1.jpg";
import img2 from "../../images/resource/about-img-2.jpg";
import img3 from "../../images/resource/about-img-3.jpg";
import img4 from "../../images/resource/about-img-4.jpg";
import img5 from "../../images/resource/about-img-5.jpg";
import img6 from "../../images/resource/about-img-6.jpg";
import one from "../../images/background/1.jpg";


function About() {
  return (
    <div className="page-wrapper">
      <Scripts />
      {/* <!-- Preloader --> */}
      {/* <div className="preloader">Hello</div> */}

      {/* <!-- Header Span --> */}
      <span className="header-span"></span>
      <Header />
      {/* <!--Page Title--> */}
      <section className="page-title">
        <div className="auto-container">
          <div className="title-outer">
            <h1>About Us</h1>
            <ul className="page-breadcrumb">
              <li><a href="/">Home</a></li>
              <li>About Us</li>
            </ul>
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- About Section Three --> */}
      <section className="about-section-three">
        <div className="auto-container">
          <div className="images-box">
            <div className="row">
              <div className="column col-lg-3 col-md-6 col-sm-6">
                <figure className="image"><img src={img1} alt=""/></figure>
              </div>
              <div className="column col-lg-3 col-md-6 col-sm-6 d-none d-md-block">
                <figure className="image"><img src={img2} alt=""/></figure>
                <figure className="image"><img src={img3} alt=""/></figure>
              </div>
              <div className="column col-lg-3 col-md-6 col-sm-6 d-none d-md-block">
                <figure className="image"><img src={img4} alt=""/></figure>
                <figure className="image"><img src={img5} alt=""/></figure>
              </div>
              <div className="column col-lg-3 col-md-6 col-sm-6 d-none d-md-block">
                <figure className="image"><img src={img6} alt=""/></figure>
              </div>
            </div>
          </div>


          {/* <!-- Fun Fact Section --> */}

          <div className="text-box">
            <h4>About Mployr</h4>
            <p>Mployr is an innovative recruitment model aimed at bridging the gap between care worker and care employer. The whole process is streamlined offering a sleek, effective and smooth procedure, cutting down on traditional recruitment time and costs..</p>
            <p>Mployr has a vast network of over 400 carehomes and over 1000 candidates. Built over cloud, it will be accessible 24x7 so one can access on demand.</p>

            <p>Portals are retro fitted to work for carehomes which end up being far too complicated. One often gets lost in setting it up rather than posting jobs. All of this without any clarity of your monthly recruitment spend.</p>

            <p>For the potential carers, it is even more taxing. Once the right job is found, the turnaround time is too high due to the portals acting as a the middle men eating up significant time. </p>

            <p>That is why we have come up with a solution to connect care with ease and speed.</p>
          </div>
        </div>
      </section>
      {/* <!-- End About Section Three --> */}

      <section className="call-to-action-two" style={{backgroundImage: `url(${one})`}}>
        <div className="auto-container">
          <div className="sec-title light text-center">
            <h2>Lets get going!</h2>

          </div>

          <div className="btn-box">
            <a href="/#/jobList" target="_blank" className="theme-btn btn-style-three">Search Job</a>
          </div>
        </div>
      </section>
      {/* <!-- End Call To Action --> */}
      <Footer />
    </div>
  );
}

export default About;
