import "./register.style.css";
import { Fragment, useState } from "react";
import { Input } from "../Common/FormControl/Input";
import { FormProvider, useForm } from "react-hook-form";
import Warning from "../Common/Alert/Warning";
import Error from "../Common/Alert/Error";
import Success from "../Common/Alert/Success";
import { post } from "../../api";
import { formatData } from "../../DataConversion/formatData";
import logo from "./../../images/logo.svg";
import Loading from "../Common/Loading";
import { useNavigate } from "react-router";
import Term from "../../documents/terms.pdf";
import Privacy from "../../documents/privacy_policy.pdf";

function Register() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [candidate, setCandidate] = useState(true);
  const [careHome, setCareHome] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [warningMsg, setWarningMsg] = useState("");
  const [checked, setChecked] = useState(false);
  const handleTermClick = () => {
    setWarningMsg('');
    setChecked(!checked);
  }

  const toggleUserType = (e) => {
    if (e.target.checked) {
      setCareHome(true);
      setCandidate(false);
    } else {
      setCandidate(true);
      setCareHome(false);
    }
  };

  const methods = useForm();
  const {
    formState: { errors },
  } = useForm();
  const { formState, getValues, reset } = useForm();
  console.log("formstate", formState);
  const onSubmit = methods.handleSubmit(async (userData) => {
    setWarningMsg('');
    if (!checked) {
      console.log('show warning for checkbox');
      setWarningMsg('Error: Please agree to the terms and conditions to proceed.');
      return;
    }
    setLoading(true);
    const formattedData = formatData({
      ...userData,
      roleId: candidate ? "Candidate" : "CareHome",
      company: careHome ? getValues("CompanyName") : "",
    });
    if (formattedData?.role === "candidate") {
      delete formattedData.company;
      delete formattedData.companyId;
    }
    const url = "/user/users/register";
    try {
      const response = await post(
        url,
        formatData({
          ...userData,
          roleId: candidate ? "CANDIDATE" : "CARE_HOME",
          company: careHome ? getValues("CompanyName") : "",
        })
      );
      console.log("response", response);
      setLoading(false);
      if (response?.status === 201) {
        navigate("/login?isRegistered=true", { replace: true });
      } else if (response?.status === 500) {
        setSuccessMsg("");
        setWarningMsg(
          "Error: User already exists. Please use a different mail id."
        );
      } else {
        setSuccessMsg("");
        setWarningMsg(response);
        console.log("Register Fail");
        console.error("Error:", response);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      setWarningMsg(
        "Error: User already exists. Please use a different mail id."
      );
      setSuccessMsg("");
    }
  });
  return (
    <Fragment>
      <div className="page-wrapper">
        {/* <div className="preloader"></div> */}
        <header className="main-header">
          <div className="container-fluid">
            <div className="main-box">
              <div className="nav-outer">
                <div className="logo-box">
                  <div className="logo">
                    <a href="/">
                      <img src={logo} alt="" title="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="outer-box">
                <div className="btn-box">
                  <a href="/#/login" className="theme-btn btn-style-three">
                    <span className="btn-title">Back to Login</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="mobile-header">
            <div className="logo">
              <a href="/">
                <img src="images/logo.svg" alt="" title="" />
              </a>
            </div>

            <div className="nav-outer clearfix">
              <div className="outer-box">
                <div className="login-box">
                  <a href="/" className="call-modal">
                    <span className="icon-user"></span>
                  </a>
                </div>

                <a
                  href="#nav-mobile"
                  className="mobile-nav-toggler navbar-trigger"
                >
                  <span className="flaticon-menu-1"></span>
                </a>
              </div>
            </div>
          </div>

          <div id="nav-mobile"></div>
        </header>
        <div className="login-section">
          <div className="image-layer"></div>
          <div className="outer-box">
            <div className="login-form default-form">
              <div className="form-inner">
                <h3>Create a mployr Account</h3>
                {warningMsg && (
                  <Warning msg={warningMsg} setWarningMsg={setWarningMsg} />
                )}
                {successMsg && (
                  <>
                    <Success msg={successMsg} setSuccessMsg={setSuccessMsg} />
                  </>
                )}
                <FormProvider {...methods}>
                  <form onSubmit={(e) => e.preventDefault()} noValidate>
                    <label style={{ padding: "10px" }}>Register as</label>
                    <div style={{ marginBottom: "10px" }}>
                      <label
                        className="toggleSwitch nolabel"
                        onChange={(e) => {
                          toggleUserType(e);
                        }}
                      >
                        <input type="checkbox" />
                        <a></a>
                        <span>
                          <span className="left-span">Candidate</span>
                          <span className="right-span">Care Home</span>
                        </span>
                      </label>
                    </div>
                    <div className="btn-box row">
                      <Input
                        label="First Name"
                        type="text"
                        name="fname"
                        id="fname"
                        placeholder="First Name"
                        className="col-lg-6 col-md-12"
                        validation={{
                          required: {
                            value: true,
                            message: "required",
                          },
                        }}
                      />

                      <Input
                        label="Last Name"
                        type="text"
                        name="lname"
                        id="lname"
                        placeholder="Last Name"
                        className="col-lg-6 col-md-12"
                        validation={{
                          required: {
                            value: true,
                            message: "required",
                          },
                        }}
                      />
                    </div>

                    <div className="btn-box row">
                      <Input
                        label="Address Line 1"
                        type="text"
                        name="addressFirstLine"
                        id="addressFirstLine"
                        placeholder="Address Line 1"
                        className="col-lg-6 col-md-12"
                        validation={{
                          required: {
                            value: true,
                            message: "required",
                          },
                        }}
                      />

                      <Input
                        label="Address 2"
                        type="text"
                        name="addressSecondLine"
                        id="addressSecondLine"
                        placeholder="Address 2"
                        className="col-lg-6 col-md-12"
                      />
                    </div>

                    <div className="btn-box row">
                      <Input
                        label="City"
                        type="text"
                        name="city"
                        id="city"
                        placeholder="City"
                        className="col-lg-6 col-md-12"
                        validation={{
                          required: {
                            value: true,
                            message: "required",
                          },
                        }}
                      />

                      <Input
                        label="Post Code"
                        type="text"
                        name="postcode"
                        id="postcode"
                        placeholder="Post Code"
                        className="col-lg-6 col-md-12"
                        validation={{
                          required: {
                            value: true,
                            message: "required",
                          },
                        }}
                      />
                    </div>

                    <div
                      className="btn-box row"
                      style={{ marginBottom: candidate ? "15px" : "" }}
                    >
                      <Input
                        label="Phone Number"
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="(####) ###-####"
                        className="col-lg-6 col-md-12"
                        validation={{
                          required: {
                            value: true,
                            message: "required",
                          },
                          minLength: {
                            value: 10,
                            message: "10 or 11 characters required",
                          },
                          maxLength: {
                            value: 11,
                            message: "10 or 11 characters required",
                          },
                          pattern: {
                            value: /^(\+\d{1,3}[- ]?)?\d{10}|d{11}$/i,
                            message: "invalid phone number",
                          },
                        }}
                      />
                      {careHome && (
                        <Input
                          label="Company Name"
                          type="text"
                          name="CompanyName"
                          id="CompanyName"
                          placeholder="Company Name"
                          className="col-lg-6 col-md-12"
                          validation={{
                            required: {
                              value: true,
                              message: "required",
                            },
                          }}
                        />
                      )}
                      <Input
                        label="Email Address"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email Address"
                        className="col-lg-6 col-md-12"
                        validation={{
                          required: {
                            value: true,
                            message: "required",
                          },
                        }}
                      />
                      {errors.email && (
                        <Error msg="Error: Invalid values. Please check your input." />
                      )}
                      <Input
                        label="Password"
                        type="password"
                        name="pw"
                        id="pw"
                        placeholder="Password"
                        className="col-lg-6 col-md-12"
                        validation={{
                          required: {
                            value: true,
                            message: "required",
                          },
                          minLength: {
                            value: 6,
                            message: "min 6 characters required",
                          },
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <div className="field-outer">
                        <div className="input-group checkboxes square">
                          <input
                            type="checkbox"
                            name="terms_conditions"
                            value=""
                            id="terms_conditions"
                            onClick={handleTermClick}
                            checked={checked}
                          />
                          <label
                            htmlFor="terms_conditions"
                            className="terms_conditions"
                          >
                            <span className="custom-checkbox">
                              I agree to the&nbsp;
                              <a
                                href={Term}
                                without
                                rel="noopener noreferrer"
                                target="_blank"
                                className="me-3"
                              >
                                Terms & Conditions
                              </a>
                            </span>
                            <div>
                              Your personal data will be used to support your
                              experience throughout this website, to manage
                              access to your account, and for other purposes
                              described in our&nbsp;
                              <a
                                href={Privacy}
                                without
                                rel="noopener noreferrer"
                                target="_blank"
                                className="me-3"
                              >
                                privacy policy.
                              </a>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group text-center">
                      {!isLoading ? (
                        <button
                          className="theme-btn btn-style-one"
                          onClick={onSubmit}
                          name="Register"
                        >
                          Register
                        </button>
                      ) : (
                        <Loading />
                      )}
                    </div>
                  </form>
                </FormProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default Register;
