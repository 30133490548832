import React, { useContext } from "react";
import logo from "../../../images/logo.svg";
import company6 from "../../../images/resource/company-6.png";
import { useLocation, useNavigate } from "react-router-dom";
import { clearSession, getSession } from "../../../utils/session";
import { useState, useEffect } from "react";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import { ROLE } from "../../../constant/role";
import { AuthContext } from "../../../context/auth";
import { get, post } from "../../../api";
import Navbar from "../Common/Navbar";
import Notifications from "../../Common/Notifications";

const Header = () => {
  const [user, setUser] = useState();
  const [isLogin, setIsLogin] = useState(false);
  const location = useLocation();
  const { favourites, profilePic, unreadMessages } = useContext(AuthContext);

  const isActiveNav = (nav) => {
    return location.pathname.indexOf(nav) !== -1 ? "active" : "";
  };

  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
    isUserLogin();
  }, []);
  const navigate = useNavigate();
  const isUserLogin = async () => {
    const url = `/user/users/isLogin`;
    try {
      const response = await get(url);
      setIsLogin(!!(response?.status == "true"));
    } catch (e) {
      if (e?.request?.status === 401) {
        clearSession(EMPLOYR_LOGIN_SESSION);
        const jobList = location.pathname.indexOf("jobList") !== -1;
        const jobSingle = location.pathname.indexOf("jobSingle") !== -1;
        if (!jobList && !jobSingle) {
          navigate("/login");
        }
      }
      setIsLogin(false);
    }
  };
  const logout = async (e) => {
    e?.preventDefault();
    const url = "user/users/logout";
    try {
      const response = await post(url, {});
      if (response?.status === 200) {
        clearSession(EMPLOYR_LOGIN_SESSION);
        navigate("/login");
      }
    } catch (e) {
      if (e?.response?.data?.status === 498) {
        clearSession(EMPLOYR_LOGIN_SESSION);
        navigate("/login");
      }
    }
  };
  const handleClick = () => {
    if (isLogin) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      {/* <!-- Header Span --> */}
      <span className="header-span"></span>

      {/* <!-- Main Header--> */}
      <header className="main-header header-shaddow">
        <div className="container-fluid">
          {/* <!-- Main box --> */}
          <div className="main-box">
            {/* <!--Nav Outer --> */}
            <div className="nav-outer">
              <div className="logo-box">
                <div className="logo">
                  <a href="/">
                    <img src={logo} alt="" title="" />
                  </a>
                </div>
              </div>

              <Navbar isLogin={isLogin} />

              {/* <!-- Main Menu End--> */}
            </div>

            <div
              className="outer-box"
              style={{ display: isLogin ? "flex" : "none" }}
            >
              {user?.role === ROLE.CANDIDATE && (
                <button
                  className="menu-btn"
                  onClick={() => {
                    navigate("/savedjobs");
                  }}
                >
                  <span className="count">{favourites}</span>
                  <span className="icon la la-heart-o"></span>
                </button>
              )}
              <button
                className="menu-btn"
                onClick={() => {
                  navigate("/messages");
                }}
              >
                <span className="count">{unreadMessages}</span>
                <span className="icon la la-envelope"></span>
              </button>

              <button className="menu-btn" onClick={logout}>
                <span className="icon la la-sign-out"></span>
              </button>

              {/* <!-- Dashboard Option --> */}
              <div className="dropdown dashboard-option">
                <a
                  className="dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={profilePic ? profilePic : company6}
                    alt="avatar"
                    className="thumb"
                  />
                  <span className="name">My Account</span>
                </a>
                <ul className="dropdown-menu">
                  <li className={isActiveNav("dashboard")}>
                    <a href="/#/dashboard">
                      {" "}
                      <i className="la la-home"></i> Dashboard
                    </a>
                  </li>
                  <li className={isActiveNav("profile")}>
                    <a href="/#/profile">
                      <i className="la la-user-tie"></i>View Profile
                    </a>
                  </li>
                  {user?.role === ROLE.CARE_HOME && (
                    <li className={isActiveNav("postjob")}>
                      <a href="/#/postjob">
                        <i className="la la-paper-plane"></i>Post a New Job
                      </a>
                    </li>
                  )}
                  {user?.role === ROLE.CARE_HOME && (
                    <li className={isActiveNav("managejobs")}>
                      <a href="/#/managejobs">
                        <i className="la la-briefcase"></i> Manage Jobs{" "}
                      </a>
                    </li>
                  )}
                  {user?.role === ROLE.CARE_HOME && (
                    <li className={isActiveNav("applicants")}>
                      <a href="/#/applicants">
                        <i className="la la-user-friends"></i> All Applicants
                      </a>
                    </li>
                  )}
                  {user?.role === ROLE.CANDIDATE && (
                    <li className={isActiveNav("savedjobs")}>
                      <a href="/#/savedjobs">
                        <i className="la la-tags"></i> Saved Jobs
                      </a>
                    </li>
                  )}
                  {user?.role === ROLE.CANDIDATE && (
                    <li className={isActiveNav("appliedjobs")}>
                      <a href="/#/appliedjobs">
                        <i className="la la-business-time"></i> Applied Jobs
                      </a>
                    </li>
                  )}
                  <li className={isActiveNav("repots")}>
                    <a href="/#/profile">
                      <i className="la la-chart-pie"></i>Reports
                    </a>
                  </li>
                  {user?.role === ROLE.ADMIN && (
                    <li className={isActiveNav("messages")}>
                      <a href="/#/messages">
                        <i className="la la-comment-o"></i>Manage Subscriptions
                      </a>
                    </li>
                  )}
                  <li className={isActiveNav("messages")}>
                    <a href="/#/messages">
                      <i className="la la-envelope"></i>Messages
                    </a>
                  </li>
                  {(user?.role === ROLE.CANDIDATE ||
                    user?.role === ROLE.CARE_HOME) && (
                    <>
                      <li className={isActiveNav("meetings")}>
                        <a href="/#/meetings">
                          <i className="la la-bell"></i>Meetings
                        </a>
                      </li>
                      <li className={isActiveNav("timesheets")}>
                        <a href="/#/profile">
                          <i className="la la-calendar-check"></i>Timesheets
                        </a>
                      </li>
                    </>
                  )}
                  <li className={isActiveNav("feedback")}>
                    <a href="/#/feedback">
                      <i className="la la-sms"></i> Feedback
                    </a>
                  </li>

                  {/* {user?.role === ROLE.ADMIN && (
                    <li className={isActiveNav("appliedjobs")}>
                      <a href="/#/appliedjobs">
                        <i className="la la-business-time"></i> Applied Jobs
                      </a>
                    </li>
                  )} */}
                  {user?.role === ROLE.ADMIN && (
                    <li className={isActiveNav("management")}>
                      <a href="/#/profile">
                        <i className="la la-user-edit"></i> User Management
                      </a>
                    </li>
                  )}
                  <li>
                    <a onClick={logout}>
                      <i className="la la-sign-out"></i>Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="outer-box"
              style={{
                display: isLogin ? "none" : "block",
              }}
            >
              {/* < />!-- Login/Register --> */}
              <div className="btn-box">
                <a href="/#/login" className="theme-btn btn-style-one">
                  <span className="btn-title">Login / Register</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Mobile Header --> */}
        <div className="mobile-header">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="" title="" />
            </a>
          </div>

          {/* <!--Nav Box--> */}
          <div className="nav-outer clearfix">
            <div className="outer-box">
              {/* <!-- Login/Register --> */}
              <div className="login-box">
                {isLogin && (
                  <>
                    &nbsp;&nbsp;
                    <button onClick={logout}>
                      <span className="icon la la-sign-out"></span>
                    </button>
                  </>
                )}
              </div>
              {isLogin && (
                <>
                  <button
                    className="menu-btn"
                    onClick={() => {
                      navigate("/messages");
                    }}
                  >
                    <span className="count">{unreadMessages}</span>
                    <span className="icon la la-envelope"></span>
                  </button>
                </>
              )}
              {isLogin && user?.role === ROLE.CANDIDATE && (
                <button
                  className="menu-btn"
                  onClick={() => {
                    navigate("/savedjobs");
                  }}
                >
                  <span className="count">{favourites}</span>
                  <span className="icon la la-heart-o"></span>
                </button>
              )}

              <button id="toggle-user-sidebar" onClick={handleClick}>
                <img
                  src={profilePic ? profilePic : company6}
                  alt="avatar"
                  className="thumb"
                />
              </button>
              <a
                href="#nav-mobile"
                className="mobile-nav-toggler navbar-trigger"
              >
                <span className="flaticon-menu-1"></span>
              </a>
            </div>
          </div>
        </div>

        {/* <!-- Mobile Nav --> */}
        <div id="nav-mobile"></div>
        {/* <!-- Sidebar Backdrop --> */}
        <div className="sidebar-backdrop"></div>
      </header>
      {/* <!--End Main Header --> */}
      <Notifications />
    </>
  );
};
export default Header;
