import React, { useContext, useEffect } from "react";
import Scripts from "../../Common/Scripts/Scripts";
import CandidateSidebar from "../Dashboard/CandidateSidebar";
import Footer from "../Dashboard/Footer";
import Header from "../Dashboard/Header";
import { getSession } from "../../../utils/session";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import { useState } from "react";
import JobLists from "./JobLists";
import { ROLE } from "../../../constant/role";
import { get, put } from "../../../api";
import { AuthContext } from "../../../context/auth";
import Success from "../../Common/Alert/Success";
import Error from "../../Common/Alert/Error";

const SavedJobs = () => {
  const [user, setUser] = useState();
  const [savedJobsFavourites, setSavedJobsFavourites] = useState([]);
  const [savedJobs, setSavedJobs] = useState([]);
  const [saveJobApiMsg, setSaveJobApiMsg] = useState('');
  const [filterJobs, setFilterJobs] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
    callMethod();
  }, []);
  const {
    setFavourites
  } = useContext(AuthContext);
  const callMethod = async () => {
    setLoading(true);
    try{
      const url = `/user/users/detail`;
      const response = await get(url);
      setSavedJobsFavourites(response?.favourites);
      if(response){
        setFavourites(response?.favourites?.length);
      }
      createSaveGetCall(response?.favourites);
    }catch(e){
      setLoading(false);
    }
  };

  const createSaveGetCall = async (data) => {
    let ids ='';
    if(data?.length > 0) {
      for (const iterator of data) {
        if(ids) {
          ids += `,${iterator?.shiftId}`;
        } else {
          ids += `${iterator?.shiftId}`;
        }
      }
      setTimeout(async () => {
        try {
          const url = `job/jobs/data/${ids}`;
          const response = await get(url);
          setSavedJobs(response);
          setFilterJobs(response);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          console.error(e);
        }
      }, 1000);
    } else {
      setLoading(false);
    }
  }
  const searchJob = (event) => {
    const value = event?.target?.value;
    if (value) {
      const list = savedJobs?.filter((x) =>
        String(x?.title + x?.company).toLowerCase().includes(String(value).toLowerCase())
      );
      setFilterJobs(list);
    } else {
      setFilterJobs(savedJobs);
    }
  };
  const marskAsUnsaved = async (job) => {
    setSaveJobApiMsg('')
    setLoading(true);
    const url = `/job/application/favourite`;
    const payload = {
      shiftId: job?.id,
      saveType: 'UNFAVOURITE',
    };
    try {
      const response = await put(url, payload);
      if (
        response?.status === 200 ||
        response?.status === 202 ||
        response?.status === 204
      ) {
        setSaveJobApiMsg("success");
        callMethod();
      } else {
        setSaveJobApiMsg("error");
      }
      setLoading(false);
    } catch (e) {
      setSaveJobApiMsg("error");
      setLoading(false);
    }
  }
  return (
    <>
      <div className="page-wrapper dashboard">
        <Scripts />
        <Header />
        {isLoading && <div className="preloader"></div>}
        {user?.role === ROLE.CANDIDATE && <CandidateSidebar />}
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="upper-title-box">
              <h3>Saved Jobs</h3>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Ls widget --> */}
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-title">
                      <h4>My Saved Jobs</h4>

                      <div className="filter-block">
                        <div className="form-group">
                          <input
                            type="text"
                            name="listing-search"
                            onChange={searchJob}
                            placeholder="Job title or company"
                          />
                          <span className="icon flaticon-search-3"></span>
                        </div>
                      </div>
                    </div>
                    {saveJobApiMsg === "success" && (
                      <>
                        <Success msg="Job has been successfully unsaved." />
                      </>
                    )}
                    {saveJobApiMsg === "error" && (
                      <>
                        <Error msg="Something went wrong while marking a job as unsaved. Please try again." />
                      </>
                    )}

                    <div className="widget-content">
                      <div className="table-outer">
                        <table className="default-table manage-job-table">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Job Title</th>
                              <th>Vacancies</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {savedJobs?.length === 0 && (
                              <tr>
                                <td>
                                  <div className="job-block">
                                    <div className="inner-box">
                                      <div className="content">
                                        No Result Found.
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                            {filterJobs?.map((job) => (
                              <tr key={job?.id}>
                                <td
                                  style={{
                                    paddingLeft: "0",
                                  }}
                                >
                                  <div className="option-box">
                                    <ul className="option-list">
                                      <li>
                                        <a
                                          target="_blank"
                                          href={`/#/jobSingle?id=${job?.id}`}
                                          data-text="View Job"
                                        >
                                          <span className="la la-eye"></span>
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          data-text="Mark as unsaved"
                                          onClick={(e) => {
                                            e?.preventDefault();
                                            e?.stopPropagation();
                                            marskAsUnsaved(job);
                                          }}
                                        >
                                          <i className="flaticon-bookmark"></i>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td>
                                  {/* <!-- Job Block --> */}
                                  <div className="job-block">
                                    <div className="inner-box">
                                      <div className="content">
                                        <span className="company-logo">
                                          <img src={job?.companyPhoto} alt="" />
                                        </span>
                                        <h4>
                                          <a
                                            target="_blank"
                                            href={`/#/jobSingle?id=${job?.id}`}
                                          >
                                            {job?.title
                                              ? job?.title
                                              : "Test Title"}
                                          </a>
                                        </h4>
                                        <ul className="job-info">
                                          <li>
                                            <span className="icon flaticon-briefcase"></span>{" "}
                                            {job?.userType}
                                          </li>
                                          <li>
                                            <span className="icon flaticon-map-locator"></span>{" "}
                                            {job?.city}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>{job?.vacancies}</td>
                                <td>
                                  {job.status === "ACTIVE" ? (
                                    <span class="badge bg-success">
                                      {job?.status}
                                    </span>
                                  ) : job.status === "INACTIVE" ? (
                                    <span class="badge bg-danger">
                                      {job?.status?.split("_")?.join(" ")}
                                    </span>
                                  ) : (
                                    <span class="badge bg-warning">
                                      {job?.status?.split("_")?.join(" ")}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default SavedJobs;
