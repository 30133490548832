import React, { useEffect, useState } from "react";
import Header from "../Protected/Header";
import Footer from "../Common/Footer";
import { get } from "../../api";
import { useForm } from "react-hook-form";
import companyDefault from "../../images/resource/company-6.png";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Map from "../Protected/Map/Map";
import Scripts from "../Common/Scripts/Scripts";
const render = (status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return <></>;
};

function EmployeeList() {
  const [searchResult, setSearchResult] = useState([]);
  const [filterCareHomes, setFilterCareHomes] = useState();
  const [mapView, setMapView] = useState(false);
  const [searchParam, setSearchParam] = useState({
    rows: 10,
    offset: 0,
    name: "",
    site: "",
  });
  const { register, getValues, setValue } = useForm();
  useEffect(() => {
    searchCareHome();
  }, []);
  const searchCareHome = async () => {
    const url = `/user/users/care-home/search?rows=${searchParam?.rows}&offset=${searchParam?.offset}`;
    try {
      const response = await get(url);
      setSearchResult(response);
      setFilterCareHomes(response);
    } catch (e) {
      console.error(e);
    }
  };
  const searchwithFilter = () => {
    const city = getValues("city");
    const companyName = getValues("companyName");
    console.log("city", city);
    if (city) {
      const list = searchResult?.filter((x) =>
        x?.city?.toLowerCase().includes(city?.toLowerCase())
      );
      setFilterCareHomes(list);
    } else if (companyName) {
      const list = searchResult?.filter((x) =>
        x?.companyName?.toLowerCase().includes(companyName?.toLowerCase())
      );
      setFilterCareHomes(list);
    } else {
      setFilterCareHomes(searchResult);
    }
  };
  return (
    <div className="page-wrapper">
      <span className="header-span"></span>
      <Scripts />
      <Header />
      {/* <!--Page Title--> */}
      <section className="page-title">
        <div className="auto-container">
          <div className="title-outer">
            <h1>Care Homes</h1>
            <ul className="page-breadcrumb">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Care Homes</li>
            </ul>
          </div>
        </div>
      </section>
      {/* <!--End Page Title--> */}

      {/* <!-- Listing Section --> */}
      <section className="ls-section">
        <div className="auto-container">
          <div className="filters-backdrop"></div>

          <div className="row">
            {/* <!-- Filters Column --> */}
            <div className="filters-column col-lg-4 col-md-12 col-sm-12">
              <div className="inner-column pd-right">
                <div className="filters-outer">
                  <button type="button" className="theme-btn close-filters">
                    X
                  </button>

                  {/* <!-- Filter Block --> */}
                  <form>
                    <div className="filter-block">
                      <h4>Search by Keywords</h4>
                      <div className="form-group">
                        <input
                          type="text"
                          name="companyName"
                          placeholder="Company"
                          onKeyUp={() => {
                            searchwithFilter();
                          }}
                          {...register("companyName")}
                        />
                        <span className="icon flaticon-search-3"></span>
                      </div>
                    </div>

                    {/* <!-- Filter Block --> */}
                    <div className="filter-block">
                      <h4>Location</h4>
                      <div className="form-group">
                        <input
                          type="text"
                          name="city"
                          placeholder="City or postcode"
                          onKeyUp={() => {
                            searchwithFilter();
                          }}
                          {...register("city")}
                        />
                        <span className="icon flaticon-map-locator"></span>
                      </div>
                      {/* <p>Radius around selected destination</p>
                    <div className="range-slider-one">
                      <div className="area-range-slider"></div>
                      <div className="input-outer">
                        <div className="amount-outer">
                          <span className="area-amount"></span>km
                        </div>
                      </div>
                    </div> */}
                    </div>

                    {/* <!-- Filter Block --> */}
                    {/* <div className="filter-block">
                      <h4>Category</h4>
                      <div className="form-group">
                        <select
                          onSelect={() => searchwithFilter()}
                          className="chosen-select-cat"
                          {...register("category")}
                        >
                          <option>Choose a category</option>
                          <option>Residential</option>
                          <option>Commercial</option>
                          <option>Industrial</option>
                          <option>Apartments</option>
                        </select>
                        <span className="icon flaticon-briefcase"></span>
                      </div>
                    </div> */}
                  </form>
                </div>

                {/* <!-- End Call To Action --> */}
              </div>
            </div>

            {/* <!-- Content Column --> */}
            <div className="content-column col-lg-8 col-md-12 col-sm-12">
              <div className="ls-outer">
                <button
                  type="button"
                  className="theme-btn btn-style-two toggle-filters"
                >
                  Show Filters
                </button>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <button
                      className="btn btn-primary"
                      onClick={() => setMapView(!mapView)}
                    >
                      Toggle Map View
                    </button>
                  </div>
                </div>
                {mapView && (
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <Wrapper
                        apiKey={process.env.REACT_APP_GOOGLE_KEY}
                        render={render}
                      >
                        <Map
                          isCareHomeList={true}
                          careHomeList={filterCareHomes}
                          center={{ lat: 50.736129, lng: -1.988229 }}
                          zoom={5}
                        />
                      </Wrapper>
                    </div>
                  </div>
                )}
                {/* <!-- ls Switcher --> */}
                <div className="ls-switcher">
                  <div className="showing-result">
                    <div className="text">
                      Showing <strong>{searchResult?.length}</strong> employer
                    </div>
                  </div>
                  <div className="sort-by"></div>
                </div>
                {filterCareHomes?.length === 0 && (
                  <div className="company-block-three">
                    <div className="inner-box">
                      <div className="content"></div>No Result Found
                    </div>
                  </div>
                )}
                {filterCareHomes?.map((itm, index) => (
                  <div
                    className="company-block-three"
                    key={`${itm?.siteId}-${index}`}
                  >
                    <div className="inner-box">
                      <div className="content">
                        <div className="content-inner">
                          <span className="company-logo">
                            <img
                              src={
                                itm?.companyPhoto
                                  ? itm?.companyPhoto
                                  : companyDefault
                              }
                              alt=""
                            />
                          </span>
                          <h4>
                            <a
                              target="_blank"
                              href={`/#/jobList?siteId=${itm?.siteId}&companyId=${itm?.companyId}`}
                            >
                              {itm?.companyName}
                            </a>
                          </h4>
                          <ul className="job-info">
                            <li>
                              <span className="icon flaticon-map-locator"></span>{" "}
                              {itm?.city}
                            </li>
                            {/* <li>
                              <span className="icon flaticon-briefcase"></span>{" "}
                              {itm?.city}
                            </li> */}
                          </ul>
                        </div>

                        <ul className="job-other-info">
                          {/* <li className="privacy">Featured</li> */}
                          <li className="time">Open Jobs – {itm?.openJobs}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
                {/* <!-- Block Block --> */}
                {/* <!-- Listing Show More --> */}
                <div className="ls-show-more">
                  <p>Showing {searchResult?.length} Care Homes</p>
                  <div className="bar">
                    <span className="bar-inner" style={{ width: "40%" }}></span>
                  </div>
                  <button className="show-more">Show More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Listing Page Section --> */}
      <Footer />
    </div>
  );
}

export default EmployeeList;
