import React, { useState } from "react";

const JobCategories = ({ homePageCount, userRoleTypes, jobTypes }) => {
  function getOpenPositions(userType, jobTypes) {
    const openPosition = jobTypes?.find(position => position?.jobTypeId === userType?.userTypeId)
    return openPosition?.typeCount || 2;
  }
  return (
    <>
      <section className="job-categories">
        <div className="auto-container">
          <div className="sec-title text-center">
            <h2>Job Categories</h2>
            <div className="text">
              {homePageCount.openJobs} jobs live - {homePageCount.todayJobCount}{" "}
              added today.
            </div>
          </div>

          <div className="row wow fadeInUp animated">
            {userRoleTypes?.map((userType) => {
              return (
                <div
                  className="category-block col-lg-4 col-md-6 col-sm-12"
                  key={userType?.userTypeId}
                >
                  <div className="inner-box">
                    <div className="content">
                      <span className="icon flaticon-money-1"></span>
                      <h4>
                        <a href="#">{userType.description}</a>
                      </h4>
                      <p>{ `(${getOpenPositions(userType, jobTypes)} open positions)` }</p>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* <!-- Category Block --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Job Categories --> */}
    </>
  );
};
export default JobCategories;
