import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { get, post } from "../../api";
import { Fragment } from "react";
import Login from "../Login";

const ValidateUser = () => {
  // const { token } = useParams();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');
  const navigate = useNavigate();
  const [validationStatus, setValidationStatus] = useState("Validating");

  useEffect(() => {
    const validateUser = async () => {
        const url = `/user/token/email`;
        const tokenData = {
          token: token,
        };
        console.log("validate user");
        const response = await post(url, tokenData);
        console.log('response', response);
        if (response?.status === 200) {
          if(response?.data?.status === 'ACTIVE') {
            setValidationStatus("success");
          }else {
            setValidationStatus("error");
          }
        } else {
          setValidationStatus("error");
        }
    };

    validateUser();
  }, [token]);

  // useEffect(() => {
  //   const redirectTimeout = setTimeout(() => {
  //     navigate("/login");
  //   }, 3000);

  //   return () => clearTimeout(redirectTimeout);
  // }, []);

  return (
    <Fragment>
      <Login validationStatus={validationStatus} setValidationStatus={setValidationStatus}/>

    </Fragment>
  );
};

export default ValidateUser;
