import { useState } from "react";
import { Fragment } from "react";
import logo from "./../../images/logo-2.svg";
import logo1 from "./../../images/logo.svg";
import Scripts from "../Common/Scripts/Scripts";
import { FormProvider, useForm } from "react-hook-form";
import { Input, InputError } from "../Common/FormControl/Input";
import { put } from "../../api";
import { useSearchParams } from "react-router-dom";
import Loading from "../Common/Loading";

function ChangePassword() {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newpass: "",
      reenternewpass: "",
    },
  });
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [isLoading, setLoading] = useState(false);
  const [apiMsg, setApiMsg] = useState("");
  const onChangePasswordSubmit = async (data) => {
    console.log("data", data);
    const payload = {
      token: token || "",
      newPassword: data?.newpass,
    };
    setLoading(true);
    const url = `/user/users/forgot-password`;
    try {
      const response = await put(url, payload);
      console.log("response", response);
      if (
        response?.status === 200 ||
        response?.status === 202 ||
        response?.status === 204
      ) {
        console.log("applied", response);
        setApiMsg("success");
        reset({
          email: "",
        });
      } else {
        setApiMsg("error");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
    console.log("payload", payload);
  };
  return (
    <Fragment>
      <Scripts />
      <div className="page-wrapper">
        <header className="main-header">
          <div className="container-fluid">
            <div className="main-box">
              <div className="nav-outer">
                <div className="logo-box">
                  <div className="logo">
                    <a href="index.html">
                      <img src={logo} alt="" title="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="outer-box">
                <div className="btn-box">
                  <a href="/#/login" className="theme-btn btn-style-three">
                    <span className="btn-title">Login</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="mobile-header">
            <div className="logo">
              <a href="index.html">
                <img src={logo1} alt="" title="" />
              </a>
            </div>
          </div>

          <div id="nav-mobile"></div>
        </header>

        <div className="login-section">
          <div className="image-layer"></div>
          <div className="outer-box">
            {/* <!-- Login Form --> */}
            <div className="login-form default-form">
              <div className="form-inner">
                <h3>Change Password</h3>
                {apiMsg === "success" && (
                  <div className="message-box success">
                    <p>Success: Your password has been successfully changed.</p>
                    <button className="close-btn">
                      <span className="close_icon"></span>
                    </button>
                  </div>
                )}
                {apiMsg === "error" && (
                  <div className="message-box error">
                    <p>
                      Error: Something went wrong while changing your password.
                      Please try again.
                    </p>
                    <button className="close-btn">
                      <span className="close_icon"></span>
                    </button>
                  </div>
                )}

                {/* <div className="message-box info">
                  <p>Change password</p>
                </div> */}
                {/* <!--Login Form--> */}
                <form
                  onSubmit={handleSubmit(onChangePasswordSubmit)}
                  noValidate
                >
                  <div className="form-group">
                    <label>New Password</label>
                    <input
                      type="password"
                      id="newpass"
                      name="newpass"
                      {...register("newpass", {
                        required: {
                          value: true,
                          message: "required",
                        },
                      })}
                    />
                    {errors?.newpass && (
                      <InputError
                        message={errors?.newpass?.message}
                        key={errors?.newpass?.message}
                      />
                    )}
                  </div>

                  <div className="form-group">
                    <label>Re-enter Password</label>
                    <input
                      id="reenternewpass"
                      type="password"
                      name="reenternewpass"
                      {...register("reenternewpass", {
                        required: {
                          value: true,
                          message: "required",
                        },
                        validate: (val) => {
                          if (watch("newpass") != val) {
                            return "Your passwords do no match";
                          }
                        },
                      })}
                    />
                    {errors?.reenternewpass && (
                      <InputError
                        message={errors?.reenternewpass?.message}
                        key={errors?.reenternewpass?.message}
                      />
                    )}
                  </div>

                  <div className="form-group">
                    {isLoading && <Loading />}
                    {!isLoading && (
                      <button
                        className="theme-btn btn-style-one"
                        type="submit"
                        name="log-in"
                      >
                        Change
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
            {/* <!--End Login Form --> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ChangePassword;
