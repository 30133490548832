import React from "react";
import { useLocation } from "react-router-dom";

function Navbar({ isLogin }) {
  const location = useLocation();
  const getActiveClassName = (nav) => {
    return location.pathname.indexOf(nav) !== -1 ? "current" : "";
  }
  return (
    <nav className="nav main-menu">
      <ul className="navigation" id="navbar">
        <li className="dropdown">
          <a href="/">Home</a>
        </li>

        <li className={getActiveClassName("jobList")}>
          <a href="/#/jobList">Jobs</a>
        </li>

        <li className={getActiveClassName("employeeList")}>
          <a href="/#/employeeList">Care Homes</a>
        </li>
        <li className={getActiveClassName("about")}>
          <a href="/#/about">About Us</a>
        </li>
        <li className={getActiveClassName("contact")}>
          <a href="/#/contact">Contact Us</a>
        </li>

        <li className="mm-add-listing">
          <span>
            <span className="contact-info">
              {/* <span className="phone-num">
                <span>Email us</span>
                <a href="mailto: info@mployr.com">info@mployr.com</a>
              </span> */}
              <span className="address">
                B1 Business Centre, Suite 206<br /> Davyfield Road, Blackburn <br />
                Bb1 2QY
              </span>
              <a href="mailto:info@mployr.com" className="email">info@mployr.com</a>
            </span>
            <span className="social-links">
              <a target="_blank" href="https://www.facebook.com/profile.php?id=61555675002922">
                <span className="fab fa-facebook-f"></span>
              </a>
              <a href="#">
                <span className="fab fa-instagram"></span>
              </a>
              <a target="_blank" href="https://youtu.be/It4_-NatoKY">
                <span className="fab fa-youtube"></span>
              </a>
            </span>
          </span>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
