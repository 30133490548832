import React from "react";
export default function CareHomeCard(props) {
  return (
    <>
      <div
        className="card"
        style={{
          width: "12rem",
          zIndex: "9999",
        }}
      >
        <div className="text-center">
          <img style={{height: '100px', width: '100px'}} src={props?.companyPhoto} class="card-img-top" />
        </div>
        <div className="card-body">
          <h5 className="card-title">{props?.companyName}</h5>
          <p><span className="badge bg-primary">Open Jobs - {props?.openJobs}</span></p>
          <a
            className="btn btn-primary btn-sm"
            target="_blank"
            style={{ fontSize: '10px'}}
            href={`/#/jobList?siteId=${props?.siteId}&companyId=${props?.companyId}`}
          >
            View Details
          </a>
        </div>
      </div>
    </>
  );
}
