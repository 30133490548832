/**
 *
 * @param {string} key
 * @param {string} value
 */
export function createSession(key, value) {
  localStorage.setItem(key, value);
}
/**
 *
 * @param {string} key
 * @returns {string}
 */
export function getSession(key) {
  return JSON.parse(localStorage.getItem(key));
}
/**
 *
 * @param {string} key
 */
export function clearSession(key) {
  if (key) {
    localStorage.removeItem(key);
  } else {
    localStorage.clear();
  }
}
