import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Scripts from "../../Common/Scripts/Scripts";
import CareHomeSidebar from "../Dashboard/CareHomeSideBar";
import Footer from "../Dashboard/Footer";
import Header from "../Dashboard/Header";
import { del, get, put } from "../../../api";
import { InputError } from "../../Common/FormControl/Input";
import { AuthContext } from "../../../context/auth";
import { formatJobDate, isPastdate } from "../../../utils/date";
import { scrollTop } from "../../../utils/scroll";
import Select from "react-select";
import { weekOptions } from "../../../utils/weeks";
import { jobCategory } from "../../../utils/jobCategory";

export const sliceArray = (data, startIndex, endIndex) => {
  return data?.slice(startIndex, endIndex);
};

const ManageJobs = () => {
  const { setFavourites } = useContext(AuthContext);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userRoleTypes, setUserTypeRoles] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      jobDescription: "",
      roleType: "",
      hours: "",
      rateph: "",
      experience: "",
      qualification: "",
      nvqLevel: "",
      vacancy: "",
      stdate: "",
      enddate: "",
      site: "",
      shift: "",
      choosedays: "",
    },
  });
  const [isLoading, setLoading] = useState(false);
  const [editIdForJob, setEditIdForJob] = useState("");
  const [jobs, setJobs] = useState();
  const [filterJobs, setFilterJobs] = useState();
  const [userDetail, setUserDetail] = useState();
  const [jobAddedMsg, setJobAddedMsg] = useState("");
  const fetchAllJobs = async (companyId) => {
    setLoading(true);
    const url = `/job/jobs/company?companyId=${companyId}`;
    try {
      let response = await get(url);
      if (response?.length > 0) {
        response = response?.sort(
          (objA, objB) => new Date(objA.startDate) - new Date(objB.startDate)
        );
      }

      setJobs(response);
      if (response?.length > 10) {
        setFilterJobs(sliceArray(response, 0, 10));
      } else {
        setFilterJobs(response);
      }

      console.log("job response", response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const callMethod = async () => {
    try {
      const url = `/user/users/detail`;
      const response = await get(url);
      setUserDetail(response);
      console.log("profile response", response);
      if (response) {
        setFavourites(response?.favourites?.length);
        fetchAllJobs(response?.company?.companyId);
      }
    } catch (e) {
      //
    }
  };
  const getUserRoleType = async () => {
    try {
      const url = `/user/users/type`;
      const response = await get(url);
      if (response) {
        setUserTypeRoles(response);
      }
    } catch (e) {
      //
    }
  };
  useEffect(() => {
    callMethod();
    getUserRoleType();
  }, []);

  const submitPostJob = async (data) => {
    // setLoading(true);
    console.log("job data", data);
    setLoading(true);
    const payload = {
      id: Number(editIdForJob),
      companyId: Number(userDetail?.company?.companyId),
      title: data?.name,
      description: data?.jobDescription,
      siteId: Number(data?.site),
      startDate: data?.stdate,
      jobTypeId: data?.roletype,
      endDate: data?.enddate,
      nvqLevelRequired: Number(data?.nvqLevel) || 0,
      minHoursPerWeek: Number(data?.hours),
      minQualification: data?.qualification,
      vacancies: Number(data?.vacancy),
      minExperience: data?.experience,
      ratePerHour: Number(data?.rateph),
      days: String(data?.choosedays),
      shift: data?.Shift,
      status: "ACTIVE",
    };
    try {
      const url = `/job/jobs/${payload.id}`;
      const response = await put(url, payload);
      if (response?.status === 202 || response?.status === 201) {
        console.log("resource updated", response);
        setJobAddedMsg("success");
        setEditIdForJob("");
        fetchAllJobs(userDetail?.company?.companyId);
        reset({
          name: "",
          jobDescription: "",
          roleType: "",
          hours: "",
          rateph: "",
          experience: "",
          qualification: "",
          nvqLevel: "",
          vacancy: "",
          stdate: "",
          enddate: "",
          site: "",
          shift: "",
          choosedays: "",
        });
      } else {
        setJobAddedMsg("error");
      }
    } catch (e) {
      console.log("e", e);
      setJobAddedMsg("error");
    }

    setLoading(false);
  };

  const editJob = (job) => {
    setEditIdForJob(job?.id);
    console.log("job", job);
    reset({
      name: job?.title,
      jobDescription: job?.description,
      site: job?.siteId,
      stdate: job?.startDate,
      enddate: job?.endDate,
      nvqLevel: job?.nvqLevelRequired,
      hours: job?.minHoursPerWeek,
      qualification: job?.minQualification,
      vacancy: job?.vacancies,
      experience: job?.minExperience,
      rateph: job?.ratePerHour,
      choosedays: job?.days?.split(","),
      Shift: job?.shift,
      roleType: job?.jobTypeId,
      jobCategory: job?.jobCategory,
    });
  };

  const searchJob = (event) => {
    const value = event?.target?.value;
    if (value) {
      const list = jobs?.filter((x) =>
        x?.title?.toLowerCase().includes(value?.toLowerCase())
      );
      setFilterJobs(list);
    } else {
      setFilterJobs(jobs);
    }
  };

  const onDeleteJob = async (itm) => {
    console.log("onDeleteJob", itm);
    setLoading(true);
    const url = `/job/jobs/${itm?.id}`;
    try {
      const response = await del(url);
      if (response?.status === 200 || response?.status === 204) {
        console.log("response", response);
        callMethod();
        fetchAllJobs(userDetail?.company?.companyId);
      } else {
        console.log("something went wrong");
      }
      setLoading(false);
    } catch (e) {
      console.error("e", e);
      setLoading(false);
    }
  };

  const onHoldJob = async (itm) => {
    console.log("onHoldJob", itm);
    setLoading(true);
    const url = `/job/jobs/${itm?.id}`;
    const payload = { ...itm, status: "ON_HOLD" };
    try {
      const response = await put(url, payload);
      if (
        response?.status === 200 ||
        response?.status === 201 ||
        response?.status === 204 ||
        response?.status === 202
      ) {
        console.log("response", response);
        fetchAllJobs(userDetail?.company?.companyId);
      } else {
        console.log("something went wrong");
      }
      setLoading(false);
    } catch (e) {
      console.error("e", e);
      setLoading(false);
    }
  };
  const onActiveJob = async (itm) => {
    setLoading(true);
    const url = `/job/jobs/${itm?.id}`;
    const payload = { ...itm, status: "ACTIVE" };
    try {
      const response = await put(url, payload);
      if (
        response?.status === 200 ||
        response?.status === 201 ||
        response?.status === 204 ||
        response?.status === 202
      ) {
        console.log("response", response);
        fetchAllJobs(userDetail?.company?.companyId);
      } else {
        console.log("something went wrong");
      }
      setLoading(false);
    } catch (e) {
      console.error("e", e);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="page-wrapper dashboard ">
        <Scripts />
        <Header />
        {isLoading && <div className="preloader"></div>}
        <CareHomeSidebar />
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="upper-title-box">
              <h3>Manage Jobs</h3>
              {/* <div className="message-box success">
                <p>Success: Your job has been successfully updated.</p>
                <button className="close-btn">
                  <span className="close_icon"></span>
                </button>
              </div> */}
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Ls widget --> */}
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-title">
                      <h4>My Job Listings</h4>
                      <div className="filter-block">
                        <div className="form-group">
                          <input
                            type="text"
                            name="listing-search"
                            placeholder="Job title or company"
                            onChange={searchJob}
                          />
                          <span className="icon flaticon-search-3"></span>
                        </div>
                      </div>
                    </div>

                    <div className="widget-content">
                      <div className="table-outer">
                        <table className="default-table manage-job-table">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Title</th>
                              <th>Applications</th>
                              <th>Start & Closing Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {filterJobs?.length === 0 && (
                              <tr>
                                <td>No Jobs Found !!</td>
                              </tr>
                            )}
                            {filterJobs?.map((job) => (
                              <tr>
                                <td style={{ padding: "0px" }}>
                                  <div className="option-box">
                                    <ul className="option-list">
                                      <li>
                                        <a
                                          data-text="View"
                                          target="_blank"
                                          href={`/#/jobSingle?id=${job?.id}`}
                                        >
                                          <span className="la la-eye"></span>
                                        </a>
                                      </li>
                                      <li>
                                        <button
                                          data-text="Modify"
                                          onClick={() => {
                                            scrollTop(".update-job-form");
                                            console.log("job ===>", job);
                                            const days = job?.days
                                              ?.split(",")
                                              ?.map((itm) => {
                                                return {
                                                  value: itm,
                                                  label: itm,
                                                };
                                              });
                                            console.log("days", days);
                                            setSelectedOption(days);
                                            editJob(job);
                                          }}
                                        >
                                          <span className="la la-pencil"></span>
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="text-danger"
                                          data-text="Delete"
                                          onClick={() => onDeleteJob(job)}
                                        >
                                          <span className="la la-trash"></span>
                                        </button>
                                      </li>
                                      {job?.status === "ACTIVE" && (
                                        <li>
                                          <button
                                            data-text="Put on Hold"
                                            onClick={() => {
                                              console.log("Put on Hold");
                                              onHoldJob(job);
                                            }}
                                          >
                                            <span className="la la-pause-circle"></span>
                                          </button>
                                        </li>
                                      )}
                                      {job?.status === "ON_HOLD" && (
                                        <li>
                                          <button
                                            data-text="Make Active"
                                            onClick={() => {
                                              onActiveJob(job);
                                            }}
                                          >
                                            <span className="la la-play-circle"></span>
                                          </button>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </td>
                                <td>
                                  <h6>{job?.title}</h6>
                                  <span className="info">
                                    <i className="icon flaticon-map-locator"></i>{" "}
                                    {job?.siteCity}
                                  </span>
                                </td>

                                <td>{job?.applicants} Applied</td>
                                <td>
                                  {formatJobDate(job?.startDate)} <br />
                                  {formatJobDate(job?.endDate)}
                                </td>
                                <td>
                                  {isPastdate(job?.endDate) && (
                                    <span>
                                      <span class="badge bg-danger">
                                        Closed
                                      </span>
                                    </span>
                                  )}
                                  {!isPastdate(job?.endDate) && (job.status === "ACTIVE" ? (
                                    <span class="badge bg-success">
                                      {job?.status}
                                    </span>
                                  ) : job.status === "INACTIVE" ? (
                                    <span class="badge bg-danger">
                                      {job?.status?.split("_")?.join(" ")}
                                    </span>
                                  ) : (
                                    <span class="badge bg-warning">
                                      {job?.status?.split("_")?.join(" ")}
                                    </span>
                                  ))}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <div className="ls-show-more">
                          <p>Showing {filterJobs?.length} Jobs</p>
                          {jobs?.length > 0 &&
                            jobs?.length !== filterJobs?.length && (
                              <>
                                <div className="bar">
                                  <span
                                    className="bar-inner"
                                    style={{ width: "40%" }}
                                  ></span>
                                </div>
                                <button
                                  className="show-more"
                                  onClick={() => {
                                    console.log(
                                      "filterJobs?.length",
                                      filterJobs
                                    );
                                    const data = sliceArray(
                                      jobs,
                                      filterJobs?.length,
                                      filterJobs?.length + 10
                                    );
                                    console.log("data", data);
                                    setFilterJobs([...filterJobs, ...data]);
                                  }}
                                >
                                  Show More
                                </button>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Ls widget --> */}
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-title update-job-form">
                      <h4>Update Job Listing</h4>
                    </div>
                    {jobAddedMsg === "success" && (
                      <div className="message-box success">
                        <p>Success: Your job has been updated.</p>
                        <button className="close-btn">
                          <span className="close_icon"></span>
                        </button>
                      </div>
                    )}
                    {jobAddedMsg === "error" && (
                      <div className="message-box error">
                        <p>
                          Error: The job could not be updated. Please check
                          input and re-submit.
                        </p>
                        <button className="close-btn">
                          <span className="close_icon"></span>
                        </button>
                      </div>
                    )}

                    <div className="widget-content">
                      <form
                        onSubmit={handleSubmit(submitPostJob)}
                        className="default-form"
                      >
                        <div className="row">
                          {/* <!-- Input --> */}

                          <div className="form-group col-lg-12 col-md-12">
                            <label>Job Title</label>
                            <input
                              type="text"
                              name="name"
                              placeholder="Title"
                              {...register("name", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                            />
                            {errors?.name && (
                              <InputError
                                message={errors?.name?.message}
                                key={errors?.name?.message}
                              />
                            )}
                          </div>

                          {/* <!-- About Company --> */}
                          <div className="form-group col-lg-12 col-md-12">
                            <label>Job Description</label>
                            <textarea
                              {...register("jobDescription", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              placeholder=""
                            ></textarea>
                            {errors?.jobDescription && (
                              <InputError
                                message={errors?.jobDescription?.message}
                                key={errors?.jobDescription?.message}
                              />
                            )}
                          </div>

                          {/* <!-- Input --> */}

                          <div className="form-group col-lg-6 col-md-12">
                            <label>Role Type</label>
                            <select
                              {...register("roletype", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              name="roletype"
                              className="chosen-select-role"
                            >
                              <option value={""}>Select</option>
                              {userRoleTypes?.map((itm) => (
                                <option value={itm?.userTypeId}>
                                  {itm?.description}
                                </option>
                              ))}
                            </select>
                            {errors?.roletype && (
                              <InputError
                                message={errors?.roletype?.message}
                                key={errors?.roletype?.message}
                              />
                            )}
                          </div>

                          <div className="form-group col-lg-6 col-md-12">
                            <label>Job Category</label>
                            <select
                              {...register("jobCategory", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              name="jobCategory"
                              className="chosen-select-jobCategory"
                            >
                              <option value={""}>Select</option>
                              {jobCategory?.map((itm) => (
                                <option value={itm?.value}>{itm?.label}</option>
                              ))}
                            </select>
                            {errors?.jobCategory && (
                              <InputError
                                message={errors?.jobCategory?.message}
                                key={errors?.jobCategory?.message}
                              />
                            )}
                          </div>

                          {/* <!-- Input --> */}
                          <div className="form-group col-lg-6 col-md-12">
                            <label>Min. hours per week</label>
                            <input
                              type="text"
                              name="hours"
                              id="hours"
                              {...register("hours", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              placeholder="Hours per week"
                            />
                            {errors?.hours && (
                              <InputError
                                message={errors?.hours?.message}
                                key={errors?.hours?.message}
                              />
                            )}
                          </div>

                          {/* <!-- Input --> */}
                          <div className="form-group col-lg-6 col-md-12">
                            <label>Rate per hour</label>
                            <input
                              type="text"
                              name="rateph"
                              {...register("rateph", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              placeholder="Hourly Rate in GBP"
                            />
                            {errors?.rateph && (
                              <InputError
                                message={errors?.rateph?.message}
                                key={errors?.rateph?.message}
                              />
                            )}
                          </div>

                          <div className="form-group col-lg-6 col-md-12">
                            <label>Minimum Experience (years)</label>
                            <input
                              type="number"
                              min={0}
                              name="experience"
                              {...register("experience", {
                                required: {
                                  value: /^[0-9]*[1-9][0-9]*$/,
                                  message:
                                    "Input must be a number greater than 0.",
                                },
                              })}
                              placeholder="Years of Experience needed"
                            />
                            {errors?.experience && (
                              <InputError
                                message={errors?.experience?.message}
                                key={errors?.experience?.message}
                              />
                            )}
                          </div>

                          <div className="form-group col-lg-6 col-md-12">
                            <label>Minimum Qualification Needed</label>
                            <input
                              type="text"
                              name="qualification"
                              {...register("qualification", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              placeholder="Min. Qualification"
                            />
                            {errors?.qualification && (
                              <InputError
                                message={errors?.qualification?.message}
                                key={errors?.qualification?.message}
                              />
                            )}
                          </div>

                          <div className="form-group col-lg-6 col-md-12">
                            <label>Min. NVQ Level Required</label>
                            <select
                              {...register("nvqLevel", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              className="chosen-select-nvq"
                            >
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              <option>Not Applicable</option>
                            </select>
                            {errors?.nvqLevel && (
                              <InputError
                                message={errors?.nvqLevel?.message}
                                key={errors?.nvqLevel?.message}
                              />
                            )}
                          </div>

                          <div className="form-group col-lg-6 col-md-12">
                            <label>Vacancies</label>
                            <input
                              {...register("vacancy", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              type="text"
                              name="vacancy"
                              placeholder="Number of vacancies"
                            />
                            {errors?.vacancy && (
                              <InputError
                                message={errors?.vacancy?.message}
                                key={errors?.vacancy?.message}
                              />
                            )}
                          </div>

                          {/* <!-- Input --> */}
                          <div className="form-group col-lg-6 col-md-12">
                            <label>Vacancy Start Date</label>
                            <input
                              {...register("stdate", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              type="date"
                              name="stdate"
                              placeholder=""
                            />
                            {errors?.stdate && (
                              <InputError
                                message={errors?.stdate?.message}
                                key={errors?.stdate?.message}
                              />
                            )}
                          </div>

                          {/* <!-- Input --> */}
                          <div className="form-group col-lg-6 col-md-12">
                            <label>Application Deadline Date</label>
                            <input
                              {...register("enddate", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              type="date"
                              name="enddate"
                              placeholder=""
                            />
                            {errors?.enddate && (
                              <InputError
                                message={errors?.enddate?.message}
                                key={errors?.enddate?.message}
                              />
                            )}
                          </div>

                          {/* <!-- Input --> */}
                          <div className="form-group col-lg-6 col-md-12">
                            <label>Select Site</label>
                            <select
                              {...register("site", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              name="site"
                              className="chosen-select-site"
                            >
                              <option value="" disabled selected>Select</option>
                              {userDetail?.company?.sites?.map(function (itm) {
                                return (
                                  <option value={itm?.id} key={itm?.id}>
                                    {itm?.name} - {itm?.city}
                                  </option>
                                );
                              })}
                            </select>
                            {errors?.site && (
                              <InputError
                                message={errors?.site?.message}
                                key={errors?.site?.message}
                              />
                            )}
                          </div>

                          {/* <!-- Input --> */}
                          <div className="form-group col-lg-6 col-md-12">
                            <label>Shift</label>
                            <select
                              {...register("Shift", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              className="chosen-select-shift"
                            >
                              <option>Morning</option>
                              <option>Afternoon</option>
                              <option>Evening</option>
                              <option>Night</option>
                              <option>May vary</option>
                            </select>
                            {errors?.Shift && (
                              <InputError
                                message={errors?.Shift?.message}
                                key={errors?.Shift?.message}
                              />
                            )}
                          </div>

                          <div className="form-group col-lg-6 col-md-12">
                            <label>Applicable Days </label>
                            <ul className="checkboxes square">
                              {weekOptions?.map((itm) => (
                                <li key={itm?.value}>
                                  <input
                                    id={itm?.value}
                                    type="checkbox"
                                    name="choosedays"
                                    value={itm?.value}
                                    {...register("choosedays", {
                                      required: {
                                        value: true,
                                        message: "required",
                                      },
                                    })}
                                  />
                                  <label for={itm?.label}>{itm?.label}</label>
                                </li>
                              ))}
                            </ul>
                            {errors?.choosedays && (
                              <InputError
                                message={errors?.choosedays?.message}
                                key={errors?.choosedays?.message}
                              />
                            )}
                          </div>

                          {/* <!-- Input --> */}
                          <div className="form-group col-lg-12 col-md-12 text-right">
                            <button
                              type="submit"
                              className="theme-btn btn-style-one"
                            >
                              Update Job
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
      {/* <!-- End Dashboard --> */}
    </>
  );
};
export default ManageJobs;
