function JobLists() {
  return (<tr>
    <td>
      {/* <!-- Job Block --> */}
      <div className="job-block">
        <div className="inner-box">
          <div className="content">
            <span className="company-logo"><img src="images/resource/company-logo/1-1.png" alt=""/></span>
            <h4><a href="/#/jobSingle">Senior Full Stack Engineer, Creator Success</a></h4>
            <ul className="job-info">
              <li><span className="icon flaticon-briefcase"></span> Segment</li>
              <li><span className="icon flaticon-map-locator"></span> London, UK</li>
            </ul>
          </div>
        </div>
      </div>
    </td>
    <td>3</td>
    <td className="status">Active</td>
    <td>
      <div className="option-box">
        <ul className="option-list">
          <li><a href="/#/jobSingle" data-text="View Application"><span className="la la-eye"></span></a></li>
        </ul>
      </div>
    </td>
  </tr>)
}

export default JobLists;
