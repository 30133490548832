import { Fragment, useContext, useState } from "react";
import { Input } from "../Common/FormControl/Input";
import { FormProvider, useForm } from "react-hook-form";
import Error from "../Common/Alert/Error";
import Success from "../Common/Alert/Success";
import { post } from "../../api";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/auth";
import Loading from "../Common/Loading";
import logo1 from "./../../images/logo.svg";
import { createSession } from "../../utils/session";
import { CORRELATION_ID, EMPLOYR_LOGIN_SESSION } from "../../constant/session";
import { useSearchParams } from "react-router-dom";
import { createLocalStorage } from "../../utils/storage";
// import Term from "../../documents/terms.pdf";
// import Privacy from "../../documents/privacy_policy.pdf";

function Login({ validationStatus, setValidationStatus }) {
  const [isLoading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const isRegistered = searchParams.get("isRegistered");
  const methods = useForm();
  const navigate = useNavigate();
  const { auth, setAuth } = useContext(AuthContext);
  const [error, setError] = useState("");
  const onSubmit = methods.handleSubmit(async (data) => {
    setLoading(true);
    // const url = "/login-validate"; // Java API Mock
    const url = "/user/users/login"; // Cloud APi Mock
    // const payload = {
    //   email: data.username,
    //   password: data.password,
    // };
    const payload = {
      login: data.username,
      password: data.password,
    };

    console.log("payload", payload);

    try {
      const response = await post(url, payload);
      console.log("response", response);
      setLoading(false);
      if (response?.status === 200) {
        console.log("response", response);
        createSession(EMPLOYR_LOGIN_SESSION, JSON.stringify(response?.data));
        createLocalStorage(
          CORRELATION_ID,
          JSON.stringify(response?.data?.correlationId)
        );
        setAuth({
          ...auth,
          // token: response.data?.token,
          isAuthenticated: response.data?.authenticated,
        });
        console.log("Login Success");
        navigate("/dashboard");
      } else {
        setError("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setError("Please enter valid email and password.");
    }
  });
  return (
    <Fragment>
      <div className="page-wrapper">
        {/* <div className="preloader"></div> */}

        <header className="main-header">
          <div className="container-fluid">
            <div className="main-box">
              <div className="nav-outer">
                <div className="logo-box">
                  <div className="logo">
                    <a href="/">
                      <img src={logo1} alt="" title="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="outer-box">
                <div className="btn-box">
                  <a href="/" className="theme-btn btn-style-three">
                    <span className="btn-title">Home</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="mobile-header">
            <div className="logo">
              <a href="/">
                <img src={logo1} alt="" title="" />
              </a>
            </div>
            <div className="btn-box">
              <a
                href="/"
                className="theme-btn btn-style-three"
                style={{ float: "right" }}
              >
                <span className="btn-title">Home</span>
              </a>
            </div>
          </div>
          <div id="nav-mobile"></div>
        </header>
        <div className="login-section">
          <div className="image-layer"></div>
          <div className="outer-box">
            <div className="login-form default-form">
              <div className="form-inner">
                <h3>Login to mployr</h3>
                {/* <h3>Verifying Email</h3> */}
                <div>
                  {validationStatus === "Validating" && <Loading />}
                  {isRegistered && (
                    <>
                      <Success msg="You have successfully registered yourself. Please check your inbox for verification link." />
                    </>
                  )}
                  {validationStatus === "success" && (
                    <>
                      <Success
                        msg="You have successfully verified yourself. Please login using
                    your credentials."
                      />
                    </>
                  )}
                  {validationStatus === "error" && (
                    <Error
                      msg="Error: Verification failed. Make sure you use the
                    verification link sent to your registered email inbox."
                      setError={setError}
                    />
                  )}
                  {error && <Error msg={error} setError={setError} />}
                </div>
                <FormProvider {...methods}>
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    noValidate
                    className="container"
                  >
                    <Input
                      label="Email"
                      type="email"
                      name="username"
                      id="username"
                      placeholder="Email"
                      validation={{
                        required: {
                          value: true,
                          message: "required",
                        },
                      }}
                    />
                    <Input
                      label="Password"
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Password"
                      validation={{
                        required: {
                          value: true,
                          message: "required",
                        },
                        minLength: {
                          value: 6,
                          message: "min 6 characters required",
                        },
                      }}
                    />
                    {/* <div className="form-group">
                      <div className="field-outer">
                        <div className="input-group checkboxes square">
                          <input
                            type="checkbox"
                            name="terms_conditions"
                            value=""
                            id="terms_conditions"
                            required
                          />
                          <label
                            htmlFor="terms_conditions"
                            className="terms_conditions"
                          >
                            <span className="custom-checkbox">
                              I agree to the&nbsp;
                              <a
                                href={Term}
                                without
                                rel="noopener noreferrer"
                                target="_blank"
                                className="me-3"
                              >
                                Terms & Conditions
                              </a>
                            </span>
                            <div>
                              Your personal data will be used to support your
                              experience throughout this website, to manage
                              access to your account, and for other purposes
                              described in our&nbsp;
                              <a
                                href={Privacy}
                                without
                                rel="noopener noreferrer"
                                target="_blank"
                                className="me-3"
                              >
                                privacy policy
                              </a>
                              .
                            </div>
                          </label>
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group">
                      <div className="field-outer">
                        <div className="input-group checkboxes square">
                          <input
                            type="checkbox"
                            name="remember-me"
                            value=""
                            id="remember"
                          />
                          <label htmlFor="remember" className="remember">
                            <span className="custom-checkbox"></span> Remember
                            me
                          </label>
                        </div>
                        <a
                          href="/#/forgotPassword"
                          className="pwd"
                          style={{ textDecoration: "none" }}
                        >
                          Forgot password?
                        </a>
                      </div>
                    </div>
                    <div className="form-group text-center">
                      {!isLoading ? (
                        <button
                          disabled={isLoading}
                          className="theme-btn btn-style-one"
                          name="log-in"
                          onClick={onSubmit}
                        >
                          Log In
                        </button>
                      ) : (
                        <Loading />
                      )}
                    </div>
                  </form>
                </FormProvider>
                <div className="bottom-box">
                  <div className="text">
                    Don't have an account?{" "}
                    <a href="/#/register" style={{ textDecoration: "none" }}>
                      Signup
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
