import Header from "./Protected/Header";
import Banner from "./Common/Banner";
import About from "./Common/About";
import JobSection from "./Common/JobSection";
import Footer from "./Common/Footer";
import JobCategories from "./Common/JobCategories";
import Recentjobs from "./Common/RecentJobs";
import Scripts from "./Common/Scripts/Scripts";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/auth";
import { get, post } from "../api";

function Home() {
  const [isLogin, setLogin] = useState(false);
  const [userRoleTypes, setUserTypeRoles] = useState([]);
  const [jobTypes, setJobType] = useState([]);
  const { setAuth, auth } = useContext(AuthContext);
  const [homePageCount, setHomePageCount] = useState({
    activeUsers: 0,
    openJobs: 0,
    activeSites: 0,
  });
  const checkLogin = async () => {
    const url = `/user/users/isLogin`;
    try {
      const response = await get(url);
      if (response) {
        setLogin(true);
        setAuth({
          ...auth,
          isAuthenticated: !!(response?.status == "true"),
        });
      }
    } catch (e) {
      setLogin(false);
    }
  };
  const getHomePageCount = async () => {
    try {
      const url = `user/stats/homepage/count`;
      const response = await get(url);
      console.log("getHomePageCount ===>", response);
      if (response) {
        setHomePageCount(response);
      }
    } catch (e) {
      console.error("getHomePageCount failed");
    }
  };
  const getUserRoleType = async () => {
    const url = `/user/users/type`;
    try {
      const response = await get(url);
      if (response) {
        setUserTypeRoles(response);
      }
    } catch (e) {
      console.error(`${url} Failed`);
    }
  };
  const getJobTypeCount = async () => {
    const url = `job/jobs/count/type`;
    try {
      const response = await get(url);
      if (response) {
        setJobType(response);
      }
    } catch (e) {
      console.error(`${url} Failed`);
    }
  };
  useEffect(() => {
    checkLogin();
    getHomePageCount();
    getUserRoleType();
    getJobTypeCount();
  }, []);
  return (
    <div className="page-wrapper">
      <Scripts />
      {/* <!-- Preloader --> */}
      {/* <div className="preloader">Hello</div> */}

      {/* <!-- Header Span --> */}
      <span className="header-span"></span>
      <Header isLogin={isLogin} />
      <Banner homePageCount={homePageCount} userRoleTypes={userRoleTypes} />
      <About homePageCount={homePageCount} />
      <JobSection isLogin={isLogin} />
      {/* <TopCompanies /> */}
      <JobCategories
        homePageCount={homePageCount}
        userRoleTypes={userRoleTypes}
        jobTypes={jobTypes}
      />
      <Recentjobs isLogin={isLogin} />
      <Footer isLogin={isLogin} />
    </div>
  );
}

export default Home;
