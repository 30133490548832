import React, { useEffect, useState } from "react";
import CareHomeSidebar from "../Dashboard/CareHomeSideBar";
import Footer from "../Dashboard/Footer";
import Header from "../Dashboard/Header";
import { ROLE } from "../../../constant/role";
import Admin from "../Dashboard/Admin";
import MyVideoViewCall from "../Meetings/hooks/useRoomConnection";
import { useSearchParams } from "react-router-dom";
import Scripts from "../../Common/Scripts/Scripts";
import CandidateSidebar from "../Dashboard/CandidateSidebar";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import { getSession } from "../../../utils/session";

const VideoMeeting = () => {
  const [user, setUser] = useState();
  const [searchParams] = useSearchParams();
  const meetingHash = searchParams.get("meetingHash");
  console.log("meetingHash", meetingHash);
  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
  }, []);
  return (
    <>
      <div className="page-wrapper dashboard ">
        <Scripts />
        {user?.role === ROLE.CANDIDATE && (
          <>
            <Header />
            <CandidateSidebar />
          </>
        )}
        {user?.role === ROLE.CARE_HOME && (
          <>
            <Header />
            <CareHomeSidebar />
          </>
        )}
        {user?.role === ROLE.ADMIN && <Admin />}
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="upper-title-box">
              <h3>Message Centre</h3>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <MyVideoViewCall roomUrl={meetingHash} />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default VideoMeeting;
