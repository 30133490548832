import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSession } from "../../../utils/session";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import Header from "./Header";
import CandidateSidebar from "./CandidateSidebar";
import { get, post } from "../../../api";
import Scripts from "../../Common/Scripts/Scripts";
import { AuthContext } from "../../../context/auth";
import companyDefault from "../../../images/resource/company-logo/1-1.png";
import { Chart, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  formatDate,
  formatDateString,
  getTimeFromDate,
} from "../../../utils/date";
import PayIn from "../Reports/Candidate/Payin";
Chart.register(ArcElement, Tooltip, Legend, Title);

export const calculateCompliancePercentage = (
  userDetail,
  isQuestionAnswered,
  isDbs,
  isTraining
) => {
  let output = 0;
  const status1 = userDetail?.photoIdProof ? 1 : 0;
  const status2 = isTraining ? 1 : 0;
  const status3 = isDbs ? 1 : 0;
  const status4 = userDetail?.userReferences?.length > 0 ? 1 : 0;
  const status5 = isQuestionAnswered ? 1 : 0;
  const status6 =
    userDetail?.addressFirstLine &&
    userDetail?.firstName &&
    userDetail?.dob &&
    userDetail?.email &&
    userDetail?.photo &&
    userDetail?.status === "ACTIVE"
      ? 1
      : 0;
  output =
    (status1 + status2 + status3 + status4 + status5 + status6) * 16.6666666667;
  return Math.round(output);
};
const Candidate = () => {
  const [user, setUser] = useState();
  const [isLoading, setLoading] = useState(true);
  const [userDetail, setUserDetail] = useState();
  const [isQuestionAnswered, setQuestionAnswered] = useState(false);
  const [profileCompletionData, setProfileCompletionData] = useState();
  const [isDbsCertificateAvailable, setIsDbsCertificateAvailable] =
    useState(false);
  const [isTrainingCertificateAvailable, setIsTrainingCertificateAvailable] =
    useState(false);
  const [messages, setMessages] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [filterJobs, setFilterJobs] = useState([]);
  const [todayMeetings, setTodayMeetings] = useState([]);
  const [profilePercent, setProfilePercent] = useState(0);
  const [stats, setStats] = useState();
  const { setFavourites, setProfilePic, setUnreadMessages } =
    useContext(AuthContext);

  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setProfileCompletionData(null);
    setUser(session);
    const callMethod = async () => {
      const url = `/user/users/detail`;
      try {
        const response = await get(url);
        setUserDetail(response);
        const data = response?.userInterviews;
        if (response) {
          setProfilePic(response?.photo);
          setFavourites(response?.favourites?.length);
          const trainings = response?.userTrainings?.filter(
            (itm) => itm?.trainingTitle !== "DBS_CERTIFICATE"
          );
          setIsTrainingCertificateAvailable(!!(trainings?.length > 0));

          const dbs = response?.userTrainings?.filter(
            (itm) => itm?.trainingTitle === "DBS_CERTIFICATE"
          );
          setIsDbsCertificateAvailable(!!(dbs?.length > 0));
          const res = [];
          if (data?.length > 0) {
            for (const itm of data) {
              if (itm?.userResponse !== null) {
                res.push(true);
              } else {
                res.push(false);
              }
            }
            setQuestionAnswered(!!res?.every((itm) => itm === true));
          }
          const profilePercentage = calculateCompliancePercentage(
            response,
            !!res?.every((itm) => itm === true),
            !!(dbs?.length > 0),
            !!(trainings?.length > 0)
          );
          setProfilePercent(profilePercentage);
          setProfileCompletionData({
            labels: ["Completed", "pending"],
            datasets: [
              {
                data: [profilePercentage, 100 - profilePercentage],
                backgroundColor: ["#f9ca0c", "rgb(204, 223, 243)"],
                borderWidth: 2,
              },
            ],
          });
        }

        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    callMethod();
    const getMessages = async () => {
      const url = `/message/messages?message-section=INBOX`;
      try {
        const response = await get(url);
        setMessages(response.slice(0, 10));
      } catch (e) {
        setLoading(false);
        console.log("error response", e);
      }
    };
    getMessages();
    getAppliedJobDetails();
    getStats();
    getTodaysMeetings();
  }, []);
  const getTodaysMeetings = async () => {
    const date = formatDate(new Date());
    try {
      const url = `/meeting-timesheet/meeting?date=${date}`;
      const response = await get(url);
      setTodayMeetings(response);
      console.log("response", response);
    } catch (e) {
      console.log(e);
    }
  };
  const getAppliedJobDetails = async () => {
    const url = "/job/jobs/search?pageSize=6";
    try {
      const response = await post(url, {
        category: "",
        job_title: "",
        postcode: "",
      });
      if (response?.status === 200) {
        setAppliedJobs(response?.data);
        setFilterJobs(response?.data);
      }
    } catch (e) {
      // console.error(e);
    }
  };
  const sortByDate = messages?.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });

  const getStats = async () => {
    const url = `/user/stats/count`;
    try {
      const response = await get(url);
      setStats(response);
      setUnreadMessages(response?.unreadMsg);
    } catch (e) {
      // console.error(e);
    }
  };

  const navigate = useNavigate();
  return (
    <>
      {!isLoading && <Scripts />}
      <Header />
      <CandidateSidebar />
      {isLoading && <div className="preloader"></div>}
      <section className="user-dashboard">
        <div className="dashboard-outer">
          <div className="upper-title-box">
            <h3 className="capitalize">
              Welcome, {userDetail?.firstName} {userDetail?.lastName}
            </h3>
            <div className="text">Ready to jump back in?</div>
          </div>

          {/* <!--Candidate summary--> */}
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
              <div
                className="ui-item dashboard-box"
                onClick={(e) => {
                  e?.stopPropagation();
                  navigate("/appliedjobs");
                }}
              >
                <div className="left">
                  <i className="icon flaticon-briefcase"></i>
                </div>
                <div className="right">
                  <h4>{stats?.appliedJobs ? stats.appliedJobs : 0}</h4>
                  <p>Applied Jobs</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
              <div
                className="ui-item ui-red dashboard-box"
                onClick={(e) => {
                  e?.stopPropagation();
                  navigate("/reports");
                }}
              >
                <div className="left">
                  <i className="icon la la-wallet"></i>
                </div>
                <div className="right">
                  <h4>£{stats?.payIn ? stats.payIn : 0}</h4>
                  <p>Pay In</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
              <div
                className="ui-item ui-yellow dashboard-box"
                onClick={(e) => {
                  e?.stopPropagation();
                  navigate("/timesheet");
                }}
              >
                <div className="left">
                  <i className="icon la la-user-clock"></i>
                </div>
                <div className="right">
                  <h4>{stats?.hoursBooked ? stats.hoursBooked : 0}</h4>
                  <p>Hours Booked</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
              <div
                className="ui-item ui-green dashboard-box"
                onClick={(e) => {
                  e?.stopPropagation();
                  navigate("/messages");
                }}
              >
                <div className="left">
                  <i className="icon la la-envelope"></i>
                </div>
                <div className="right">
                  <h4>{stats?.messages ? stats.messages : 0}</h4>
                  <p>Messages</p>
                </div>
              </div>
            </div>
          </div>

          {/* <!--Alert for carehome and candidate--> */}
          {profilePercent !== 100 && (
            <div className="row">
              <div className="column col-lg-12 col-md-12 col-sm-12">
                <div className="message-box error">
                  <p>
                    You are not 100% compliant. Please complete the below to get
                    shortlisted & selected for jobs.
                  </p>
                  <button className="close-btn">
                    <span className="close_icon"></span>
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* <!--candidate checklist--> */}
          <div className="row">
            <div className="col-lg-5">
              <div className="notification-widget ls-widget">
                <div className="tabs-box">
                  <div className="widget-title">
                    <h4>Profile Completion {profilePercent}%</h4>
                  </div>
                  {!isLoading && (
                    <div className="widget-content skills-percentage">
                      <div className="pie-graph">
                        <div className="graph-outer">
                          {profileCompletionData && (
                            <Doughnut data={profileCompletionData} />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="notification-widget ls-widget">
                <div className="tabs-box">
                  <div className="widget-title">
                    <h4>Compliance Checklist</h4>
                  </div>
                  <div className="widget-content">
                    <div className="table-outer">
                      <table className="default-table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Item</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            {userDetail?.addressFirstLine &&
                            userDetail?.firstName &&
                            userDetail?.dob &&
                            userDetail?.email &&
                            userDetail?.photo &&
                            userDetail?.status === "ACTIVE" ? (
                              <td className="completed">
                                <span className="la la-check white"></span>
                              </td>
                            ) : (
                              <td className="notcompleted">
                                <span className="la la-times white"></span>
                              </td>
                            )}
                            <td>Profile</td>
                            <td>
                              {userDetail?.addressFirstLine &&
                              userDetail?.firstName &&
                              userDetail?.dob &&
                              userDetail?.email &&
                              userDetail?.photo &&
                              userDetail?.status === "ACTIVE"
                                ? "Completed"
                                : "Not Completed"}
                            </td>
                            <td>
                              <div className="option-box">
                                <ul className="option-list">
                                  <li>
                                    <a href="#/profile" data-text="Update">
                                      <span className="la la-pencil"></span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            {isTrainingCertificateAvailable ? (
                              <td className="completed">
                                <span className="la la-check white"></span>
                              </td>
                            ) : (
                              <td className="notcompleted">
                                <span className="la la-times white"></span>
                              </td>
                            )}
                            <td>Training Certificates</td>
                            <td>
                              {isTrainingCertificateAvailable
                                ? "Uploaded"
                                : "Not Uploaded"}
                            </td>
                            <td>
                              <div className="option-box">
                                <ul className="option-list">
                                  <li>
                                    <a
                                      href="#/profile?tab=certificate"
                                      data-text="Update"
                                    >
                                      <span className="la la-pencil"></span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            {isDbsCertificateAvailable ? (
                              <td className="completed">
                                <span className="la la-check white"></span>
                              </td>
                            ) : (
                              <td className="notcompleted">
                                <span className="la la-times white"></span>
                              </td>
                            )}
                            <td>DBS Certificate</td>
                            <td>
                              {" "}
                              {isDbsCertificateAvailable
                                ? "Uploaded"
                                : "Not Uploaded"}
                            </td>
                            <td>
                              <div className="option-box">
                                <ul className="option-list">
                                  <li>
                                    <a
                                      href="#/profile?tab=certificate"
                                      data-text="Update"
                                    >
                                      <span className="la la-pencil"></span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            {userDetail?.userReferences?.length > 0 ? (
                              <td className="completed">
                                <span className="la la-check white"></span>
                              </td>
                            ) : (
                              <td className="notcompleted">
                                <span className="la la-times white"></span>
                              </td>
                            )}
                            <td>Reference Checks</td>
                            <td>
                              {userDetail?.userReferences?.length > 0
                                ? "Reference Verified"
                                : "Reference Not Verified"}
                            </td>
                            <td>
                              <div className="option-box">
                                <ul className="option-list">
                                  <li>
                                    <a
                                      href="#/profile?tab=references"
                                      data-text="Update"
                                    >
                                      <span className="la la-pencil"></span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            {isQuestionAnswered ? (
                              <td className="completed">
                                <span className="la la-check white"></span>
                              </td>
                            ) : (
                              <td className="notcompleted">
                                <span className="la la-times white"></span>
                              </td>
                            )}
                            <td>Pre-Interview Questions</td>
                            <td>
                              {isQuestionAnswered > 0
                                ? "Answered"
                                : "Not answered"}
                            </td>
                            <td>
                              <div className="option-box">
                                <ul className="option-list">
                                  <li>
                                    <a
                                      href="#/profile?tab=preInterview"
                                      data-text="Update"
                                    >
                                      <span className="la la-pencil"></span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            {userDetail?.photoIdProof ? (
                              <td className="completed">
                                <span className="la la-check white"></span>
                              </td>
                            ) : (
                              <td className="notcompleted">
                                <span className="la la-times white"></span>
                              </td>
                            )}
                            <td>Photo ID Proof</td>
                            <td>
                              {userDetail?.photoIdProof
                                ? "submitted"
                                : "Not submitted"}
                            </td>
                            <td>
                              <div className="option-box">
                                <ul className="option-list">
                                  <li>
                                    <a href="#/profile" data-text="Update">
                                      <span className="la la-pencil"></span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!--Today Meeting --> */}
          <div className="row">
            <div className="col-lg-12">
              {/* <!-- Graph widget --> */}
              <div className="graph-widget ls-widget">
                <div className="tabs-box">
                  <div className="widget-title">
                    <h4>Today's Meetings</h4>
                  </div>

                  <div className="widget-content">
                    <div className="table-outer">
                      <table className="default-table manage-job-table">
                        <thead>
                          <tr>
                            <th>Action</th>
                            <th>Meeting Time</th>
                            <th>Company</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {todayMeetings?.length === 0 && (
                            <tr>
                              <td>No Meetings Found !!</td>
                            </tr>
                          )}
                          {todayMeetings?.map((itm) => (
                            <tr>
                              <td
                                style={{
                                  paddingLeft: "0",
                                }}
                              >
                                <div className="option-box">
                                  <ul className="option-list">
                                    <li>
                                      <a data-text="View" href={"/#/meetings"}>
                                        <span className="la la-eye"></span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                              <td>
                                {/* <h6>{itm?.meetingDate}</h6> */}
                                <span className="info">
                                  <i className="icon flaticon-clock"></i>{" "}
                                  {getTimeFromDate(itm?.meetingDateTime)}
                                </span>
                              </td>
                              <td>{itm?.companyName}</td>
                              <td>{itm?.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!--candidate chart--> */}
          <div className="row">
            <div className="col-lg-12">
              {/* <!-- Graph widget --> */}
              <div className="graph-widget ls-widget">
                <div className="tabs-box">
                  <PayIn />
                </div>
              </div>
            </div>
          </div>

          {/* <!--candidate notification--> */}
          <div className="row">
            <div className="col-lg-5">
              {/* <!-- Notification Widget --> */}
              <div className="notification-widget ls-widget">
                <div className="widget-title">
                  <h4>Notifications</h4>
                  <div className="chosen-outer-date">
                    {/* <!--Tabs Box--> */}
                    <button
                      onClick={(e) => {
                        e?.stopPropagation();
                        navigate("/messages");
                      }}
                      className="theme-btn btn-style-three"
                    >
                      View all
                    </button>
                  </div>
                </div>
                <div className="widget-content">
                  <ul className="notification-list">
                    {sortByDate?.map((item) => {
                      return (
                        item?.toId === userDetail?.userId && (
                          <li key={item?.messageId}>
                            <span className="icon flaticon-briefcase"></span>{" "}
                            <strong>{`${item?.message} from ${item?.fromFullName}`}</strong>{" "}
                            {/* <span className="colored">Web Developer</span> */}
                          </li>
                        )
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-7">
              {/* <!-- applicants Widget --> */}
              <div className="applicants-widget ls-widget">
                <div className="widget-title">
                  <h4>Urgent Jobs</h4>
                  <div className="chosen-outer">
                    {/* <!--Tabs Box--> */}
                    <button
                      onClick={(e) => {
                        e?.stopPropagation();
                        navigate("/appliedjobs");
                      }}
                      className="theme-btn btn-style-three"
                    >
                      View all
                    </button>
                  </div>
                </div>
                <div className="widget-content">
                  <div className="row">
                    {/* <!-- Job Block --> */}
                    {appliedJobs?.length === 0 && (
                      <div className="job-block col-lg-12 col-md-12 col-sm-12">
                        <div className="inner-box">
                          <div className="content">No Result Found.</div>
                        </div>
                      </div>
                    )}
                    {filterJobs?.map((job) => {
                      return (
                        <div
                          className="job-block col-lg-12 col-md-12 col-sm-12"
                          key={job?.id}
                        >
                          <div className="inner-box">
                            <div className="content">
                              <span className="company-logo">
                                <img
                                  src={
                                    job?.companyPhoto
                                      ? job?.companyPhoto
                                      : companyDefault
                                  }
                                  alt=""
                                />
                              </span>
                              <h4>
                                <a
                                  target="_blank"
                                  href={`/#/jobSingle?id=${job?.jobId}`}
                                >
                                  {job?.title}
                                </a>
                              </h4>
                              <ul className="job-info">
                                {/* <li>
                                  <span className="icon flaticon-briefcase"></span>{" "}
                                  Segment
                                </li> */}
                                <li>
                                  <span className="icon flaticon-map-locator"></span>{" "}
                                  {job?.cityName}
                                </li>
                                <li>
                                  <span className="icon flaticon-clock-3"></span>{" "}
                                  Closing Date: {formatDateString(job?.endDate)}
                                </li>
                                <li>
                                  <span className="icon flaticon-money"></span>{" "}
                                  £{job?.rate}
                                </li>
                              </ul>
                              <button className="bookmark-btn">
                                <span className="flaticon-bookmark"></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Candidate;
