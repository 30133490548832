import React from "react";

  const About = ({ homePageCount }) => {
  return (
    <>
      {/* <!-- About Section --> */}
      <section className="about-section style-two">
        {/* <!-- Fun Fact Section --> */}
        <div className="fun-fact-section wow fadeInUp">
          <div className="row">
            {/* <!--Column--> */}
            <div className="counter-column col-lg-4 col-md-4 col-sm-12 wow fadeInUp">
              <div className="count-box">
                <span
                  className="count-text">
                  {homePageCount?.activeUsers}
                </span>
              </div>
              <h4 className="counter-title">
                {homePageCount?.activeUsers} daily active users
              </h4>
            </div>

            {/* <!--Column--> */}
            <div
              className="counter-column col-lg-4 col-md-4 col-sm-12 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="count-box">
                <span
                  className="count-text">
                  {homePageCount?.openJobs}
                </span>
              </div>
              <h4 className="counter-title">
                Over {homePageCount?.openJobs} open job positions
              </h4>
            </div>

            {/* <!--Column--> */}
            <div
              className="counter-column col-lg-4 col-md-4 col-sm-12 wow fadeInUp"
              data-wow-delay="800ms"
            >
              <div className="count-box">
                <span
                  className="count-text">
                  {homePageCount?.activeSites}
                </span>
                +
              </div>
              <h4 className="counter-title">
                Over {homePageCount?.activeSites} care homes registered
              </h4>
            </div>
          </div>
        </div>
        {/* <!-- Fun Fact Section --> */}
      </section>
      {/* // <!-- End About Section -->         */}
    </>
  );
};
export default About;
