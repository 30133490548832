import { useLocation, useNavigate } from "react-router-dom";
import { clearSession, getSession } from "../../../utils/session";
import { useEffect } from "react";
import { post } from "../../../api";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
const CareHomeSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const logout = async (e) => {
    e?.preventDefault();
    try {
      const url = "user/users/logout";
      const response = await post(url, {});
      if (response?.status === 200) {
        clearSession(EMPLOYR_LOGIN_SESSION);
        navigate("/login");
      }
    } catch (e) {
      if (e?.response?.data?.status === 498) {
        clearSession(EMPLOYR_LOGIN_SESSION);
        navigate("/login");
      }
    }
  };
  const isActiveNav = (nav) => {
    return location.pathname.indexOf(nav) !== -1 ? "active" : "";
  };
  return (
    <div className="user-sidebar">
      <div className="sidebar-inner">
        <ul className="navigation">
          <li className={isActiveNav("dashboard")}>
            <a href="/#/dashboard">
              {" "}
              <i className="la la-home"></i> Dashboard
            </a>
          </li>
          <li className={isActiveNav("profile")}>
            <a href="/#/profile">
              <i className="la la-user-tie"></i>View Profile
            </a>
          </li>
          <li className={isActiveNav("postjob")}>
            <a href="/#/postjob">
              <i className="la la-paper-plane"></i>Post a New Job
            </a>
          </li>
          <li className={isActiveNav("managejobs")}>
            <a href="/#/managejobs">
              <i className="la la-briefcase"></i> Manage Jobs{" "}
            </a>
          </li>
          <li className={isActiveNav("applicants")}>
            <a href="/#/applicants">
              <i className="la la-user-friends"></i> All Applicants
            </a>
          </li>
          <li className={isActiveNav("reports")}>
            <a href="/#/reports">
              <i className="la la-chart-pie"></i>Reports
            </a>
          </li>
          <li className={isActiveNav("messages")}>
            <a href="/#/messages">
              <i className="la la-envelope"></i>Messages
            </a>
          </li>
          <li className={isActiveNav("meetings")}>
            <a href="/#/meetings">
              <i className="la la-bell"></i>Meetings
            </a>
          </li>
          <li className={isActiveNav("timesheet")}>
            <a href="/#/timesheet">
              <i className="la la-calendar-check"></i>Timesheets
            </a>
          </li>
          <li className={isActiveNav("feedback")}>
            <a href="/#/feedback">
              <i className="la la-sms"></i> Feedback
            </a>
          </li>
          <li>
            <a onClick={logout}>
              <i className="la la-sign-out"></i>Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default CareHomeSidebar;
