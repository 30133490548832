export const formatDate = (dateStr) => {
  const today = new Date(dateStr);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = yyyy + "-" + mm + "-" + dd;
  return formattedToday;
};
export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export const formatJobDate = (dateStr) => {
  const [yyyy, mm, dd] = dateStr?.split('-');
  
  const formattedToday =  dd + " " + monthNames[mm - 1] + " " + yyyy;
  return formattedToday;
}
export const formatDateHyphen = (dateStr) => {
  const today = new Date(dateStr);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = yyyy + "-" + mm + "-" + dd;
  return formattedToday;
};

export const formatDateString = (dateStr) => {
  if(dateStr) {
    const today = new Date(dateStr);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedToday = monthNames[mm - 1] + ", " + dd + " " + yyyy;
    return formattedToday;
  }
  return '';
};



export const getCurrentDaysInMonth = () => {
  const currentDate = new Date();
  let date = new Date(currentDate?.getFullYear(), currentDate?.getMonth(), 1);
  let days = [];
  while (date.getMonth() === currentDate?.getMonth()) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return {
    days,
    year: currentDate?.getFullYear(),
    month: monthNames[currentDate?.getMonth()],
    monthNumber: Number(currentDate?.getMonth()) + 2,
  };
};

export const getTimeFromDate = (date) => {
  return date?.split("T")[1]?.substr(0, 5);
};

export const getTimeBetweenDates = (startDate, endDate, breakTime) => {
  const seconds = String(Math.floor((endDate - startDate) / 1000));
  let minutes = String(Math.floor(seconds / 60));
  let hours = String(Math.floor(minutes / 60));
  let calculatedHours = String(Number(hours)-breakTime);
  return `${calculatedHours} hours`;
};

export const isPastdate = (matchDate) => {
  let givenDate1 = new Date(matchDate);
  let diff = new Date().getTime() - givenDate1.getTime();
  if (diff > 0) {
    return true;
  } else {
    return false;
  }
};
export const isDateOld = (startDate, matchDate) => {
  let givenDate1 = new Date(matchDate);
  let diff = new Date(startDate).getTime() - givenDate1.getTime();
  if (diff > 0) {
    return true;
  } else {
    return false;
  }
};
