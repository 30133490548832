const JobPoint = (props) => {
  return (
    <div className="mapPointer"
      onClick={props.onClick}
    >
      <i class="fa fa-map-marker-alt"></i>
    </div>
  );
};

export default JobPoint;
