import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducer/index";
const initialState = {
  userLogin: { userInfo: {} },
  userRegister: { userInfo: {} },
  userDetails: {},
};
export default configureStore({
  reducer: reducer,
  preloadedState: initialState,
});
