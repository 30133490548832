import React, { useEffect, useState } from "react";
import { ROLE } from "../../constant/role";
import logo from "./../../images/logo.svg";
import Cookie from '../../documents/cookie_policy.pdf';
import Privacy from '../../documents/privacy_policy.pdf';
import Term from '../../documents/terms.pdf';
import { getSession } from "../../utils/session";
import { EMPLOYR_LOGIN_SESSION } from "../../constant/session";
const Footer = ({ isLogin = false }) => {
  const [user, setUser] = useState();
  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
  }, []);
  return (
    <div className="page-wrapper">
      {/* <!-- Main Footer --> */}
      <footer className="main-footer alternate3">
        <div className="auto-container">
          {/* <!--Widgets Section--> */}
          <div className="widgets-section">
            <div className="row">
              <div className="big-column col-xl-4 col-lg-3 col-md-12">
                <div className="footer-column about-widget">
                  <div className="logo">
                    <a href="#">
                      <img src={logo} alt="" />
                    </a>
                  </div>

                  <p className="address">
                    B1 Business Centre, Suite 206
                    <br /> Davyfield Road, Blackburn
                    <br />
                    Bb1 2QY <br />
                    <a href="mailto:info@mployr.com" className="email">
                      info@mployr.com
                    </a>
                  </p>
                </div>
              </div>

              <div className="big-column col-xl-8 col-lg-9 col-md-12">
                <div className="row">
                  <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h4 className="widget-title">For Candidates</h4>
                      <div className="widget-content">
                        <ul className="list">
                          <li>
                            <a href="/#/jobList" target="_blank">
                              Browse Jobs
                            </a>
                          </li>
                          <li>
                            <a href="/#/employeeList" target="_blank">
                              Browse Carehomes
                            </a>
                          </li>
                          {(user?.role === ROLE.CANDIDATE) && (
                            <>
                              <li>
                                <a href="/#/dashboard" target="_blank">
                                  Candidate Dashboard
                                </a>
                              </li>
                              <li>
                                <a href="/#/appliedjobs" target="_blank">
                                  Applied Jobs
                                </a>
                              </li>
                              <li>
                                <a href="/#/savedjobs" target="_blank">
                                  My Saved Jobs
                                </a>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h4 className="widget-title">For Employers</h4>
                      <div className="widget-content">
                        <ul className="list">
                          {(user?.role === ROLE.CARE_HOME) && (
                            <>
                              <li>
                                <a href="/#/dashboard" target="_blank">
                                  Dashboard
                                </a>
                              </li>
                              <li>
                                <a href="/#/profile" target="_blank">
                                  Subscriptions
                                </a>
                              </li>
                              <li>
                                <a href="/#/postjob" target="_blank">
                                  Add Job
                                </a>
                              </li>
                              <li>
                                <a href="/#/managejobs" target="_blank">
                                  Applicants
                                </a>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h4 className="widget-title">About Us</h4>
                      <div className="widget-content">
                        <ul className="list">
                          <li>
                            <a href="/#/about" target="_blank">
                              About Mployr
                            </a>
                          </li>
                          <li>
                            <a href="/#/contact" target="_blank">
                              Contact
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h4 className="widget-title">Resources</h4>
                      <div className="widget-content">
                        <ul className="list">
                          <li>
                            <a href="sitemap.html">Sitemap</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--Bottom--> */}
        <div className="footer-bottom">
          <div className="auto-container">
            <div className="outer-box">
              <div className="copyright-text">
                &copy; {new Date().getFullYear()} mployr. All Right Reserved.
              </div>
              <div className="copyright-text">
                <a href={Cookie} without rel="noopener noreferrer" target="_blank" className="me-3">
                  Cookie policy
                </a>
                <a href={Privacy} without rel="noopener noreferrer" target="_blank" className="me-3">
                  Privacy policy
                </a>
                <a href={Term} without rel="noopener noreferrer" target="_blank" className="me-3">
                  Terms of use
                </a>
              </div>
              <div className="copyright-text">
                <a target="_blank" href="https://www.tdkglobal.com">
                    Designed and developed by TDK Global
                </a>
              </div>
              <div className="social-links">
                <a target="_blank" href="https://www.facebook.com/profile.php?id=61555675002922">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-instagram"></i>
                </a>
                <a target="_blank" href="https://youtu.be/It4_-NatoKY">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Scroll To Top --> */}
        <div className="scroll-to-top scroll-to-target" data-target="html">
          <span className="fa fa-angle-up"></span>
        </div>
      </footer>
      {/* <!-- End Main Footer --> */}
    </div>
  );
};

export default Footer;
