import React, { useEffect, useState } from "react";
import fileDownload from "js-file-download";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { get, getFile } from "../../../../api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function PayIn() {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
  const [payInType, setPayInType] = useState("month");
  const [payIn, setPayIn] = useState({
    labels: [],
    datasets: [
      {
        label: "Pay In",
        data: [],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  });
  useEffect(() => {
    getPayIn(false);
  }, []);
  const getPayIn = async (isDownload = false, type) => {
    // type = week/month
    try {
      let url = `/meeting-timesheet/report/pay-in?type=${
        type ? type : payInType
      }&download=${isDownload}`;
      let response;
      if (isDownload) {
        response = await getFile(url);
      } else {
        response = await get(url);
      }
      let labels = [];
      let data = [];
      if (isDownload && response?.data) {
        fileDownload(
          response?.data,
          response?.headers?.["content-disposition"]?.split("filename=")[1]
        );
      }
      if (!isDownload && response?.count) {
        for (const [key, value] of Object.entries(response?.count)) {
          labels.push(key);
          data.push(value);
        }
        setPayIn({
          labels: labels,
          datasets: [
            {
              label: "Pay in",
              data: data,
              fill: true,
              backgroundColor: "rgba(75,192,192,0.2)",
              borderColor: "rgba(75,192,192,1)",
            },
          ],
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <div className="widget-title">
        <h4>Pay-in</h4>
        <div className="chosen-outer">
          {/* <!--Tabs Box--> */}
          <select
            className="chosen-select-payin-time"
            onChange={(e) => {
              e?.stopPropagation();
              setPayInType(e?.target?.value);
              getPayIn(false, e?.target?.value);
            }}
          >
            <option value={"month"}>Last 6 Months</option>
            <option value={"week"}>Last 24 Weeks</option>
          </select>
          <div>
            <button
              type="button"
              className="theme-btn btn-sm btn-style-three"
              onClick={(e) => {
                e?.stopPropagation();
                getPayIn(true);
              }}
            >
              Download
            </button>
          </div>
        </div>
      </div>

      <div className="widget-content">
        <Line data={payIn} options={options} />
      </div>
    </>
  );
}

export default PayIn;
