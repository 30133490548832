import { useLocation, useNavigate } from "react-router-dom";
import { clearSession, getSession } from "../../../utils/session";
import { useEffect } from "react";
import { post } from "../../../api";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
const CandidateSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const logout = async (e) => {
    e?.preventDefault();
    try {
      const url = "user/users/logout";
      const response = await post(url, {});
      if (response?.status === 200) {
        clearSession(EMPLOYR_LOGIN_SESSION);
        navigate("/login");
      }
    } catch (e) {
      if (e?.response?.data?.status === 498) {
        clearSession(EMPLOYR_LOGIN_SESSION);
        navigate("/login");
      }
    }
  };
  const handleClickDashboard = (value) => {
    navigate("/dashboard");
  };
  const handleClick = (value) => {
    navigate(`/candidate/${value}`);
  };
  const isActiveNav = (nav) => {
    return location.pathname.indexOf(nav) !== -1 ? "active" : "";
  };
  return (
    <>
      {/* <!-- User Sidebar --> */}

      <div className="user-sidebar">
        <div className="sidebar-inner">
          <ul className="navigation">
            <li className={isActiveNav("dashboard")}>
              <a onClick={() => handleClickDashboard("dashboard")}>
                {" "}
                <i className="la la-home"></i> Dashboard
              </a>
            </li>
            <li className={isActiveNav("profile")}>
              <a href="/#/profile">
                <i className="la la-user-tie"></i>View Profile
              </a>
            </li>
            <li className={isActiveNav("savedjobs")}>
              <a href="/#/savedjobs">
                <i className="la la-tags"></i> Saved Jobs
              </a>
            </li>
            <li className={isActiveNav("appliedjobs")}>
              <a href="/#/appliedjobs">
                <i className="la la-business-time"></i> Applied Jobs
              </a>
            </li>
            <li className={isActiveNav("reports")}>
              <a href="/#/reports">
                <i className="la la-chart-pie"></i>Reports
              </a>
            </li>
            <li className={isActiveNav("messages")}>
              <a href="/#/messages">
                <i className="la la-envelope"></i>Messages
              </a>
            </li>
            <li className={isActiveNav("meetings")}>
              <a href="/#/meetings">
                <i className="la la-bell"></i>Meetings
              </a>
            </li>
            <li className={isActiveNav("timesheet")}>
              <a href="/#/timesheet">
                <i className="la la-calendar-check"></i>Timesheets
              </a>
            </li>
            <li className={isActiveNav("feedback")}>
              <a href="/#/feedback">
                <i className="la la-sms"></i> Feedback
              </a>
            </li>
            <li>
              <a onClick={logout}>
                <i className="la la-sign-out"></i>Logout
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* <!-- End User Sidebar --> */}
    </>
  );
};
export default CandidateSidebar;
