import { useEffect } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";

const Scripts = () => {
  const loadScript = function (src) {
    $(`script[src='${src}']`).remove();
    let tag = document.createElement("script");
    tag.async = true;
    tag.src = src;
    document.getElementsByTagName("body")[0].appendChild(tag);
  };
  useEffect(() => {
    loadScript("./js/jquery.fancybox.js");
    loadScript("./js/jquery.modal.min.js");
    loadScript("./js/popper.min.js");
    loadScript("./js/chosen.min.js");
    loadScript("./js/bootstrap.min.js");
    loadScript("./js/mmenu.polyfills.js");
    loadScript("./js/mmenu.js");
    loadScript("./js/appear.js");
    loadScript("./js/ScrollMagic.min.js");
    loadScript("./js/rellax.min.js");
    loadScript("./js/owl.js");
    loadScript("./js/wow.js");
    loadScript("./js/knob.js");
    loadScript("./js/script.js");
    // loadScript("./js/chart.min.js");
  }, []);
  return (
    <Helmet>
      <div></div>
    </Helmet>
  );
};
export default Scripts;
