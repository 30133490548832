import CandidateSidebar from "../Dashboard/CandidateSidebar";
import CareHomeSideBar from "../Dashboard/CareHomeSideBar";
import Header from "../Dashboard/Header";
import { FormProvider, useForm } from "react-hook-form";
import { useContext, useEffect, useMemo } from "react";
import { getSession } from "../../../utils/session";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import { useState } from "react";
import Footer from "../Dashboard/Footer";
import company6 from "../../../images/resource/company-6.png";
import { Input, InputError } from "../../Common/FormControl/Input";
import { del, get, post, put, uploadPhoto } from "../../../api";
import { ROLE } from "../../../constant/role";
import Success from "../../Common/Alert/Success";
import Error from "../../Common/Alert/Error";
import Warning from "../../Common/Alert/Warning";
import Loading from "../../Common/Loading";
import { useSearchParams } from "react-router-dom";
import $ from "jquery";
import { AuthContext } from "../../../context/auth";
import { formatDateString } from "../../../utils/date";
import formatDateLabel from "../../../utils/formatDateLabel";
import Admin from "../Dashboard/Admin";
import { scrollTop } from "../../../utils/scroll";
import Scripts from "../../Common/Scripts/Scripts";

const CandidateProfile = () => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const certificateForm = useForm();
  const basicDetailsForm = useForm();
  const [searchParams] = useSearchParams();

  const tab = searchParams.get("tab");
  const initialValues = {
    fname: "",
    lame: "",
    addr1: "",
    addr2: "",
    city: "",
    pcode: "",
    phone: "",
  };
  const methods = useForm({
    mode: "onTouched",
    defaultValues: { ...initialValues },
  });
  // const onSubmitShifts = methods.handleSubmit(async (shiftsData) => {
  //   console.log("shiftsData", shiftsData);
  // });

  /**State */
  const [user, setUser] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isLoadingSiteSubmit, setLoadingSiteSubmit] = useState(false);
  const [isUpdateSiteForm, setIsUpdateSiteForm] = useState(false);
  const [userDetail, setUserDetail] = useState();
  const [siteAddedMsg, setSiteAddedMsg] = useState("");
  const [certificateApiMsg, setCertificateApiMsg] = useState("");
  const [updateProfileMsg, setUpdateProfileMsg] = useState("");
  const [isEditModaRefernce, setEditModeReference] = useState(false);
  const [editRefId, setEditRefId] = useState("");
  const [apiMsgReference, setApiMsgReference] = useState("");
  const [cpwdApiMsg, setCpwdApiMsg] = useState("");
  const [skillUpdateApiMsg, setSkillUpdateApiMsg] = useState("");
  const [shiftsApiMsg, setShiftsApiMsg] = useState("");
  const [toaApiMsg, setToaApiMsg] = useState("");
  const [isEditModeSite, setEditModeSite] = useState(false);
  const [editSiteId, setEditSiteId] = useState("");
  const [certificateFile, setCertificateFile] = useState();
  const [preInterviewApiMsg, setpreInterviewApiMsg] = useState("");
  const [contractFile, setContractFile] = useState();
  const [certificateUrl, setCertificateUrl] = useState("");
  const [fileSizeError, setFileSizeError] = useState("");
  const [postCodeList, setPostCodeList] = useState([]);
  const selectedPostCodeList = useMemo(
    () => postCodeList.filter((v) => v),
    [postCodeList]
  );

  /** end */

  const navigateTab = () => {
    if (tab) {
      console.log("tab", tab);
      if (tab === "toa") {
        $("#toaSection").get(0).scrollIntoView();
      } else if (tab === "preInterview") {
        $("[data-tab|='#tab5']").click();
      } else if (tab === "certificate") {
        $("[data-tab|='#tab3']").click();
      } else if (tab === "references") {
        $("[data-tab|='#tab2']").click();
      } else if (tab === "sites") {
        $("[data-tab|='#tab4']").click();
      } else if (tab === "subscription") {
        $("[data-tab|='#tab6']").click();
      }
    }
  };
  const { setFavourites, setProfilePic } = useContext(AuthContext);
  const callMethod = async () => {
    const url = `/user/users/detail`;
    try {
      const response = await get(url);
      setUserDetail(response);
      console.log("profile response", response);
      setValue("chooseskill", response?.userSkill?.languages);
      setValue("summary", response?.userSkill?.summary);
      setValue("skills", response?.userSkill?.skills);
      setValue("toa", response?.company?.toaStatus);
      setValue(
        "selectedDays",
        response?.userShift?.preferredShiftDays?.split(",")
      );
      setValue("selectshift", response?.userShift?.preferredShiftTime);
      setValue("gender", response?.gender);
      basicDetailsForm.setValue("firstName", response?.firstName);
      basicDetailsForm.setValue("lastName", response?.lastName);
      basicDetailsForm.setValue("addressFirstLine", response?.addressFirstLine);
      basicDetailsForm.setValue(
        "addressSecondLine",
        response?.addressSecondLine
      );
      basicDetailsForm.setValue("city", response?.city);
      basicDetailsForm.setValue("postCode", response?.postCode);
      basicDetailsForm.setValue("phone", response?.phone);
      basicDetailsForm.setValue("dob", response?.dob);
      basicDetailsForm.setValue("gender", response?.gender);
      basicDetailsForm.setValue("workingStatus", response?.workingStatus || "");
      basicDetailsForm.setValue("exp", response?.exp);
      if (response) {
        setFavourites(response?.favourites?.length);
        setProfilePic(response?.photo);
      }
      setLoading(false);
      setTimeout(() => {
        navigateTab();
      }, 500);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
    callMethod();
    if (session?.role === ROLE.CARE_HOME) {
      getSubscriptions();
      getActiveSubscriptions();
    }
  }, []);
  const onSubmitBasic = async (basicDetails) => {
    console.log("Basic Details", basicDetails);
    setLoading(true);
    const payload = {
      firstName: basicDetails?.firstName,
      lastName: basicDetails?.lastName,
      addressFirstLine: basicDetails?.addressFirstLine,
      addressSecondLine: basicDetails?.addressSecondLine,
      city: basicDetails?.city,
      postCode: basicDetails?.postcode,
      latitude: basicDetails?.latitude,
      longitude: basicDetails?.longitude,
      phone: basicDetails?.phone,
      dob: basicDetails?.dob,
      gender: basicDetails?.gender,
      yearExp: basicDetails?.exp,
      workingStatus: basicDetails?.workingStatus || "",
    };
    try {
      const url = `/user/users/detail`;
      const response = await put(url, payload);
      if (response?.status === 202) {
        setUpdateProfileMsg("success");
        console.log("resource updated", response);
        if (response) {
          setFavourites(response?.favourites?.length);
        }
      } else {
        setUpdateProfileMsg("error");
      }
      setLoading(false);
    } catch (error) {
      setUpdateProfileMsg("error");
      setLoading(false);
      console.log("error", error);
    }
  };

  const onSubmitRef = methods.handleSubmit(async (refData) => {
    console.log("refData", refData);
    setLoading(true);
    setApiMsgReference("");
    const payload = {
      title: refData.title,
      name: refData.fullname,
      role: refData.refrole,
      company: refData.company,
      email: refData.refemail,
      phone: refData.refphone,
    };
    const url = `/user/users/references`;
    try {
      const response = await post(url, payload);
      if (response?.status === 200) {
        console.log("references updated", response);
        setApiMsgReference("success");
        callMethod();
        methods.reset({
          title: "",
          fullname: "",
          refrole: "",
          company: "",
          refemail: "",
          refphone: "",
        });
      } else {
        setApiMsgReference("error");
      }
      setLoading(false);
    } catch (e) {
      setApiMsgReference("error");
      setLoading(false);
      console.log("error", e);
    }
  });

  const onSaveRef = methods.handleSubmit(async (refData) => {
    console.log("refData", refData);
    setLoading(true);
    const payload = {
      title: refData.title,
      name: refData.fullname,
      role: refData.refrole,
      company: refData.company,
      email: refData.refemail,
      phone: refData.refphone,
    };
    const url = `/user/users/references/${editRefId}`;
    try {
      const response = await put(url, payload);
      if (response?.status === 202) {
        console.log("references updated", response);
        setApiMsgReference("success");
        callMethod();
      } else {
        setApiMsgReference("error");
      }
      setLoading(false);
    } catch (e) {
      setApiMsgReference("error");
      setLoading(false);
      console.log("error", e);
    }
  });

  const onSubmitCertificates = async (certificateData) => {
    console.log("certificateData", certificateData);
    setLoading(true);
    setCertificateApiMsg("");
    // formData.append("type", "USER_PROFILE");
    try {
      const url = `/user/users/upload?type=${certificateData?.typeCertificate}`;
      const { status, data } = await uploadPhoto(url, certificateFile);
      if (status === 202) {
        const payload = {
          certificate: data?.url,
          type: certificateData?.typeCertificate,
          startDate: certificateData?.startDate,
          endDate: certificateData?.endDate || null,
          comment: certificateData?.comments,
        };
        const url = `/user/users/trainings`;
        try {
          const response = await post(url, payload);
          if (response?.status === 200) {
            setCertificateApiMsg("success");
            console.log("resource updated", response);
            setSiteAddedMsg("success");
            callMethod();
            // /setUserRef([...userRef, response?.userReferences]);
          } else {
            setCertificateApiMsg("error");
          }
          setLoading(false);
        } catch (e) {
          setCertificateApiMsg("error");
          setLoading(false);
          setSiteAddedMsg("error");
          console.log("error", e);
        }
      }
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  };

  const onSaveSites = methods.handleSubmit(async (siteData) => {
    console.log("siteData", siteData);
    setLoadingSiteSubmit(true);
    setSiteAddedMsg("");
    const payload = {
      name: siteData?.sitename,
      address1: siteData?.addr1,
      address2: siteData?.addr2,
      city: siteData?.city,
      postCode: siteData?.postcode,
      latitude: siteData?.latitude,
      longitude: siteData?.longitude,
      facilityManager: siteData?.fmname,
    };
    const url = `/user/users/company/${userDetail?.company?.companyId}/sites`;
    try {
      const response = await post(url, payload);
      if (response?.status === 201) {
        console.log("resource updated", response);
        setSiteAddedMsg("success");
        callMethod();
        methods.reset({});
      } else {
        setSiteAddedMsg("error");
      }
      setLoadingSiteSubmit(false);
    } catch (e) {
      setLoadingSiteSubmit(false);
      setSiteAddedMsg("error");
    }
  });

  const onUpdateSites = methods.handleSubmit(async (siteData) => {
    console.log("siteData", siteData);
    setLoading(true);
    setSiteAddedMsg("");
    const payload = {
      name: siteData?.sitename,
      address1: siteData?.addr1,
      address2: siteData?.addr2,
      city: siteData?.city,
      postCode: siteData?.postcode,
      latitude: siteData?.latitude,
      longitude: siteData?.longitude,
      facilityManager: siteData?.fmname,
    };
    const url = `/user/users/company/${userDetail?.company?.companyId}/sites/${editSiteId}`;
    try {
      const response = await put(url, payload);
      if (response?.status === 202) {
        console.log("resource updated", response);
        setSiteAddedMsg("success");
        callMethod();
      } else {
        setSiteAddedMsg("error");
      }
      setLoading(false);
    } catch (e) {
      setSiteAddedMsg("error");
      setLoading(false);
    }
  });

  const onSubmitInterview = async (interviewData) => {
    const payload = [];
    const questions = userDetail?.userInterviews;
    for (const iterator of questions) {
      payload.push({
        ...iterator,
        userResponse: interviewData[iterator?.qid],
      });
    }
    console.log("payload", payload);
    const url = `/user/users/pre-interview`;
    try {
      const response = await put(url, { userInterviews: payload });
      if (
        response?.status === 202 ||
        response?.status === 204 ||
        response?.status === 200
      ) {
        setpreInterviewApiMsg("success");
        console.log("resource updated", response);
      } else {
        setpreInterviewApiMsg("error");
      }
      scrollTop(".widget-title");
    } catch (e) {
      setpreInterviewApiMsg("error");
      scrollTop(".widget-title");
    }
  };

  const handlePasswordSubmit = async (password) => {
    console.log("password", password);
    setCpwdApiMsg("");
    const payload = {
      currentPassword: password?.oldpassword,
      newPassword: password?.newpassword,
    };
    setLoading(true);
    try {
      const url = `/user/users/change-password`;
      const response = await put(url, payload);
      if (response?.status === 202) {
        setCpwdApiMsg("success");
        reset({
          newpassword: "",
          oldpassword: "",
        });
        console.log("password updated", response);
      } else {
        setCpwdApiMsg("error");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setCpwdApiMsg("error");
      console.log("error", error);
    }
  };

  const handleSkillSubmit = async (skill, e) => {
    console.log("skillData", skill);
    e?.preventDefault();
    setLoading(true);
    const payload = {
      languages: skill?.chooseskill,
      summary: skill?.summary,
      skills: skill?.skills,
    };
    const url = `/user/users/skill`;
    try {
      const response = await put(url, payload);
      if (response?.status === 202) {
        setSkillUpdateApiMsg("success");
        console.log("resource updated", response);
      } else {
        setSkillUpdateApiMsg("error");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setSkillUpdateApiMsg("error");
    }
  };

  const onSubmitShifts = async (shifts) => {
    console.log("shiftData", shifts);
    setLoading(true);
    const payload = {
      preferredShiftDays: String(shifts?.selectedDays),
      preferredShiftTime: shifts?.selectshift,
    };
    const url = `/user/users/pref-shift`;
    try {
      const response = await put(url, payload);
      if (response?.status === 202) {
        setShiftsApiMsg("success");
        console.log("resource updated", response);
      } else {
        setShiftsApiMsg("false");
      }
      setLoading(false);
    } catch (e) {
      setShiftsApiMsg("false");
      setLoading(false);
    }
  };

  const handleSubmitToA = async (toaData) => {
    console.log("ToAData", toaData);
    const payload = {
      ...userDetail?.company,
      toaStatus: toaData?.toa,
    };
    setLoading(true);
    const url = `/user/users/company/${userDetail?.company?.companyId}`;
    try {
      const response = await put(url, payload);
      if (response?.status === 202) {
        console.log("resource updated", response);
        setToaApiMsg("success");
      } else {
        setToaApiMsg("error");
      }
      setLoading(false);
    } catch (e) {
      setToaApiMsg("error");
      setLoading(false);
    }
  };

  const deleteReference = async (refData) => {
    console.log("refData", refData);
    setLoading(true);
    const url = `/user/users/references/${Number(refData?.id)}`;
    try {
      const response = await del(url);
      if (response?.status === 200 || response?.status === 204) {
        console.log("response", response);
        callMethod();
      } else {
        console.log("something went wrong");
      }
      setLoading(false);
    } catch (e) {
      console.error("e", e);
      setLoading(false);
    }
  };

  const deleteTraining = async (trainingData) => {
    console.log("trainingData", trainingData);
    setLoading(true);
    const url = `/user/users/trainings/${Number(trainingData?.id)}`;
    try {
      const response = await del(url);
      if (response?.status === 200 || response?.status === 204) {
        console.log("response", response);
        callMethod();
      } else {
        console.log("something went wrong");
      }
      setLoading(false);
    } catch (e) {
      console.error("e", e);
      setLoading(false);
    }
  };

  const onDeleteSite = async (itm) => {
    console.log("onDeleteSite", itm);
    setLoading(true);
    const url = `/user/users/company/${userDetail?.company?.companyId}/sites/${itm?.id}`;
    try {
      const response = await del(url);
      if (response?.status === 200 || response?.status === 204) {
        console.log("response", response);
        callMethod();
      } else {
        console.log("something went wrong");
      }
      setLoading(false);
    } catch (e) {
      console.error("e", e);
      setLoading(false);
    }
  };

  const editReference = async (itm) => {
    setEditModeReference(true);
    setEditRefId(itm?.id);
    console.log("itm", itm);
    methods.reset({
      title: itm?.title,
      fullname: itm?.name,
      refrole: itm?.role,
      company: itm?.organization,
      refemail: itm?.email,
      refphone: itm?.phone,
    });
    document.getElementById("reference-form").scrollIntoView();
  };

  const editSite = async (itm) => {
    setEditModeSite(true);
    setEditSiteId(itm?.id);
    console.log("itm", itm);
    methods.reset({
      sitename: itm?.name,
      addr1: itm?.address1,
      addr2: itm?.address2,
      city: itm?.city,
      postcode: itm?.postCode,
      fmname: itm?.facilityManager,
    });
  };

  const onCancelRefUpdate = async () => {
    setEditModeReference(false);
    setEditRefId("");
    methods.reset({
      title: "",
      fullname: "",
      refrole: "",
      company: "",
      refemail: "",
      refphone: "",
    });
  };

  const onCancelSiteUpdate = async () => {
    setEditModeSite(false);
    setEditSiteId("");
    methods.reset({
      sitename: "",
      addr1: "",
      addr2: "",
      city: "",
      postcode: "",
      fmname: "",
    });
  };

  const handleFileSelect = async (event) => {
    event?.stopPropagation();
    setLoading(true);
    setFileSizeError("");
    const file = event.target.files[0];
    const file_size = event.target.files[0].size;
    if (file_size > 3000000) {
      setFileSizeError("error");
      setValue("photo", "");
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", `${userDetail?.firstName}-profile-photo`);
    // formData.append("type", "USER_PROFILE");
    try {
      const url = "/user/users/upload?type=USER_PROFILE";
      const { status } = await uploadPhoto(url, formData);
      if (status === 202) {
        callMethod();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleCertificateFileChange = async (event) => {
    event?.stopPropagation();
    setFileSizeError("");
    const file = event.target.files[0];
    const file_size = event.target.files[0].size;
    if (file_size > 3000000) {
      setFileSizeError("error");
      // setLoading(true);
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", `${userDetail?.firstName}-certificate-photo`);
    setCertificateFile(formData);
  };

  const handleContractFileChange = async (event) => {
    event?.stopPropagation();
    setFileSizeError("");
    setLoading(true);
    const file = event.target.files[0];
    const file_size = event.target.files[0].size;
    if (file_size > 3000000) {
      setFileSizeError("error");
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", `${userDetail?.firstName}-contract`);
    try {
      const url = "/user/users/upload?type=SIGNATURE";
      const { status } = await uploadPhoto(url, formData);
      if (status === 202) {
        callMethod();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }

    // setContractFile(formData);
  };

  const handlePhotoIdSelect = async (event) => {
    console.log("event", event);
    event?.stopPropagation();
    setFileSizeError("");
    setLoading(true);
    const file = event.target.files[0];
    const file_size = event.target.files[0].size;
    if (file_size > 3000000) {
      setFileSizeError("error");
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", `${userDetail?.firstName}-profile-photo-id`);
    // formData.append("type", "USER_PROFILE");
    try {
      const url = "/user/users/upload?type=USER_PHOTO_PROOF_ID";
      const { status } = await uploadPhoto(url, formData);
      if (status === 202) {
        callMethod();
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const handleResumeSelect = async (event) => {
    console.log("event", event);
    event?.stopPropagation();
    setFileSizeError("");
    setLoading(true);
    const file = event.target.files[0];
    const file_size = event.target.files[0].size;
    if (file_size > 3000000) {
      setFileSizeError("error");
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", `${userDetail?.firstName}-profile-resume`);
    // formData.append("type", "USER_PROFILE");
    try {
      const url = "/user/users/upload?type=USER_RESUME";
      const { status } = await uploadPhoto(url, formData);
      if (status === 202) {
        callMethod();
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const [subscriptionList, setSubscriptionList] = useState([]);
  const [activeSubscriptionList, setActiveSubscriptionList] = useState([]);

  const getSubscriptions = async () => {
    const url = `/user/company/subscription/list`;
    const response = await get(url);
    console.log("response", response);
    if (response) {
      const res1 = response?.filter((itm) => itm?.subscriptionId === "Free");
      const res2 = response?.filter((itm) => itm?.subscriptionId !== "Free");
      setSubscriptionList([...res1, ...res2]);
    }
  };

  const getActiveSubscriptions = async () => {
    const url = `/user/company/subscription?type=Active`;
    const response = await get(url);
    setActiveSubscriptionList(response);
  };

  const subscribeNow = async (data) => {
    const url = `/user/company/subscription`;
    const payload = {
      companyId: userDetail?.company?.companyId,
      subscriptionType: data?.subscriptionId,
      companySubscriptionId: userDetail?.company?.companyId,
      companySubscriptionStatus: "Review",
    };
    setLoading(true);
    try {
      await post(url, payload);
      //const response = activeSubscriptionList
      //   ? await put(url, payload)
      //   : await post(url, payload);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("error", e);
    }
  };

  const handleOnSearch = async (e) => {
    basicDetailsForm.setValue("postcode", e?.target?.value, {
      shouldValidate: true,
    });
    try {
      const url = `https://api.getaddress.io/autocomplete/${e?.target?.value}?api-key=pdSw7G1TEk6kghR1DNzddQ41182&all=true`;
      const response = await get(url);
      const res = [];
      response?.suggestions?.forEach((itm) => {
        res.push({
          id: itm?.id,
          name: `${itm?.address}`,
          url: `${itm?.url}`,
        });
      });
      setPostCodeList(res);
    } catch (e) {
      setPostCodeList([]);
    }
  };
  const handleOnSelect = async (data) => {
    console.log("data", data);
    try {
      const url = `https://api.getaddress.io${data?.url}?api-key=pdSw7G1TEk6kghR1DNzddQ41182&all=true`;
      const response = await get(url);
      basicDetailsForm.setValue("postCode", response?.postcode);
      basicDetailsForm.setValue("addressFirstLine", response?.line_1);
      basicDetailsForm.setValue("addressSecondLine", response?.line_2);
      basicDetailsForm.setValue("city", response?.town_or_city);
      basicDetailsForm.setValue("latitude", response?.latitude);
      basicDetailsForm.setValue("longitude", response?.longitude);
      setPostCodeList([]);
    } catch (e) {
      console.log("error while loading postcode");
    }
  };

  return (
    <>
      <div className="page-wrapper dashboard">
        <Scripts />

        {isLoading && <div className="preloader"></div>}
        {user?.role === ROLE.CANDIDATE && (
          <>
            <Header />
            <CandidateSidebar />
          </>
        )}
        {user?.role === ROLE.CARE_HOME && (
          <>
            <Header />
            <CareHomeSideBar />
          </>
        )}
        {user?.role === ROLE.ADMIN && <Admin />}
        {/* <!-- Dashboard --> */}
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="upper-title-box">
              <h3>Profile</h3>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Ls widget --> */}
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-title">
                      <h4>My Profile</h4>
                    </div>

                    <div className="widget-content">
                      <div className="default-tabs style-two tabs-box">
                        {/* <!--Tabs Box--> */}
                        <ul className="tab-buttons clearfix">
                          <li className="tab-btn active-btn" data-tab="#tab1">
                            Basic details
                          </li>
                          <li
                            className="tab-btn"
                            data-tab="#tab2"
                            style={{
                              display:
                                user?.role === ROLE.CANDIDATE
                                  ? "block"
                                  : "none",
                            }}
                          >
                            References
                          </li>
                          <li
                            className="tab-btn"
                            data-tab="#tab3"
                            style={{
                              display:
                                user?.role === ROLE.CANDIDATE
                                  ? "block"
                                  : "none",
                            }}
                          >
                            Certificates
                          </li>
                          <li
                            className="tab-btn"
                            data-tab="#tab5"
                            style={{
                              display:
                                user?.role === ROLE.CANDIDATE
                                  ? "block"
                                  : "none",
                            }}
                          >
                            Pre-Interview Questions
                          </li>
                          <li
                            className="tab-btn"
                            data-tab="#tab4"
                            style={{
                              display:
                                user?.role === ROLE.CARE_HOME
                                  ? "block"
                                  : "none",
                            }}
                          >
                            Manage Sites
                          </li>
                          <li
                            className="tab-btn"
                            data-tab="#tab6"
                            style={{
                              display:
                                user?.role === ROLE.CARE_HOME
                                  ? "block"
                                  : "none",
                            }}
                          >
                            Subscription
                          </li>
                        </ul>

                        <div className="tabs-content">
                          {/* <!--Basic Details--> */}
                          <div
                            className="tab active-tab animated fadeIn"
                            id="tab1"
                            style={{ display: "block" }}
                          >
                            {/* <div className="message-box success">
                              Details successfully saved.
                              <button className="close-btn">
                                <span className="close_icon"></span>
                              </button>
                            </div> */}

                            <div className="form-inner login-form default-form">
                              <div>
                                {fileSizeError === "error" && (
                                  <Error
                                    msg={
                                      "You are trying to upload more than 3 MB size file. Please try again with below 3MB."
                                    }
                                    setParentState={setFileSizeError}
                                  />
                                )}
                              </div>
                              <div className="uploading-outer">
                                <div
                                  className={
                                    userDetail?.photo
                                      ? "uploadPhotoButton"
                                      : "uploadButton"
                                  }
                                >
                                  <input
                                    {...register("photo")}
                                    className="uploadButton-input"
                                    type="file"
                                    name="photo"
                                    accept="image/*, application/pdf"
                                    id="photo"
                                    onChange={handleFileSelect}
                                  />
                                  {user?.role === ROLE.CANDIDATE &&
                                    (userDetail?.photo ? (
                                      <>
                                        <label
                                          className="uploadButton-button ripple-effect"
                                          htmlFor="photo"
                                        >
                                          <div className="pic-container pic-medium pic-circle">
                                            <img
                                              className="pic"
                                              src={
                                                userDetail?.photo
                                                  ? userDetail?.photo
                                                  : company6
                                              }
                                              alt=""
                                            />
                                            <div className="pic-overlay">
                                              <a>
                                                <i className="las la-pencil-alt"></i>
                                              </a>
                                            </div>
                                          </div>
                                        </label>
                                      </>
                                    ) : (
                                      <label
                                        className="uploadButton-button ripple-effect"
                                        htmlFor="photo"
                                      >
                                        Upload Profile Pic
                                      </label>
                                    ))}
                                  {user?.role === ROLE.CARE_HOME &&
                                    (userDetail?.photo ? (
                                      <>
                                        <label
                                          className="uploadButton-button ripple-effect"
                                          htmlFor="photo"
                                        >
                                          <div className="pic-container pic-medium pic-circle">
                                            <img
                                              className="pic"
                                              src={
                                                userDetail?.photo
                                                  ? userDetail?.photo
                                                  : company6
                                              }
                                              alt=""
                                            />
                                            <div className="pic-overlay">
                                              <a>
                                                <i className="las la-pencil-alt"></i>
                                              </a>
                                            </div>
                                          </div>
                                        </label>
                                      </>
                                    ) : (
                                      <label
                                        className="uploadButton-button ripple-effect"
                                        htmlFor="photo"
                                      >
                                        Upload Company Logo
                                      </label>
                                    ))}
                                  {user?.role === ROLE.ADMIN &&
                                    (userDetail?.photo ? (
                                      <>
                                        <label
                                          className="uploadButton-button ripple-effect"
                                          htmlFor="photo"
                                        >
                                          <div className="pic-container pic-medium pic-circle">
                                            <img
                                              className="pic"
                                              src={
                                                userDetail?.photo
                                                  ? userDetail?.photo
                                                  : company6
                                              }
                                              alt=""
                                            />
                                            <div className="pic-overlay">
                                              <a>
                                                <i className="las la-pencil-alt"></i>
                                              </a>
                                            </div>
                                          </div>
                                        </label>
                                      </>
                                    ) : (
                                      <label
                                        className="uploadButton-button ripple-effect"
                                        htmlFor="photo"
                                      >
                                        Upload Admin Photo
                                      </label>
                                    ))}
                                </div>
                                <div className="text">
                                  Max file size is 3MB, Minimum dimension:
                                  300x300 And Suitable files are .jpg &amp; .png
                                </div>
                                <figure className="profilepic">&nbsp;</figure>
                              </div>

                              <div className="uploading-outer">
                                <div className="uploadButton">
                                  <input
                                    {...register("photoIdProof")}
                                    className="uploadButton-input"
                                    type="file"
                                    name="photoIdProof"
                                    accept="image/*, application/pdf"
                                    id="photoIdProof"
                                    onChange={handlePhotoIdSelect}
                                  />
                                  <label
                                    className="uploadButton-button ripple-effect"
                                    htmlFor="photoIdProof"
                                  >
                                    Upload ID Proof
                                  </label>
                                  {userDetail?.photoIdProof && (
                                    <div
                                      style={{
                                        marginTop: "1rem",
                                      }}
                                    >
                                      <a
                                        href={userDetail?.photoIdProof}
                                        download
                                      >
                                        Uploaded ID Proof
                                      </a>
                                    </div>
                                  )}
                                </div>
                                <div className="text">
                                  Max file size is 3MB, Suitable files are .jpg
                                  &amp; .png &amp; .pdf. Make sure the document
                                  is clear and without any glare or shadow.
                                </div>
                              </div>
                              {user?.role === ROLE.CANDIDATE && (
                                <div className="uploading-outer">
                                  <div className="uploadButton">
                                    <input
                                      {...register("resume")}
                                      className="uploadButton-input"
                                      type="file"
                                      name="resume"
                                      accept="image/*, application/pdf"
                                      id="resume"
                                      onChange={handleResumeSelect}
                                    />
                                    <label
                                      className="uploadButton-button ripple-effect"
                                      htmlFor="resume"
                                    >
                                      Upload Resume
                                    </label>
                                    {userDetail?.resume && (
                                      <div
                                        style={{
                                          marginTop: "1rem",
                                        }}
                                      >
                                        <a href={userDetail?.resume} download>
                                          Uploaded CV
                                        </a>
                                      </div>
                                    )}
                                    {!userDetail?.resume && (
                                      <div
                                        style={{
                                          marginTop: "1rem",
                                        }}
                                        className="text-danger"
                                      >
                                        Please upload your updated CV.
                                      </div>
                                    )}
                                  </div>
                                  <div className="text">
                                    Max file size is 3MB, Suitable files are
                                    .pdf.
                                  </div>
                                  <figure className="profilepic">&nbsp;</figure>
                                </div>
                              )}

                              {/* <!--Basic Details Form--> */}
                              {/* <FormProvider {...methods}> */}
                              <form
                                onSubmit={basicDetailsForm.handleSubmit(
                                  onSubmitBasic
                                )}
                                // onSubmit={(e) => e.preventDefault()}
                                noValidate
                              >
                                <div className="btn-box row content-elements">
                                  <h4 className="title">
                                    Update Basic Details
                                  </h4>
                                  {updateProfileMsg === "success" && (
                                    <>
                                      <Success msg="You have successfully updated your basic details." />
                                    </>
                                  )}
                                  {updateProfileMsg === "error" && (
                                    <>
                                      <Warning msg="Something went wrong while updating your basic details. Please try again." />
                                    </>
                                  )}
                                  <div className="form-group col-lg-6 col-md-12">
                                    <label htmlFor="firstName">
                                      First Name
                                    </label>
                                    <input
                                      id="firstName"
                                      name="firstName"
                                      type="text"
                                      placeholder="First Name"
                                      {...basicDetailsForm.register(
                                        "firstName"
                                      )}
                                    />
                                  </div>

                                  <div className="form-group col-lg-6 col-md-12">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input
                                      id="lastName"
                                      name="lastName"
                                      type="text"
                                      placeholder="Last Name"
                                      {...basicDetailsForm.register("lastName")}
                                    />
                                  </div>
                                </div>

                                <div className="btn-box row">
                                  <div className="form-group col-lg-6 col-md-12">
                                    <label htmlFor="addressFirstLine">
                                      Address 1
                                    </label>
                                    <input
                                      id="addressFirstLine"
                                      name="addressFirstLine"
                                      type="text"
                                      placeholder="Address 1"
                                      {...basicDetailsForm.register(
                                        "addressFirstLine"
                                      )}
                                    />
                                  </div>

                                  <div className="form-group col-lg-6 col-md-12">
                                    <label htmlFor="addressSecondLine">
                                      Address 2
                                    </label>
                                    <input
                                      id="addressSecondLine"
                                      name="addressSecondLine"
                                      type="text"
                                      placeholder="Address 2"
                                      {...basicDetailsForm.register(
                                        "addressSecondLine"
                                      )}
                                    />
                                  </div>
                                </div>

                                <div className="btn-box row">
                                  <div className="form-group col-lg-6 col-md-12">
                                    <label htmlFor="city">City</label>
                                    <input
                                      id="city"
                                      name="city"
                                      type="text"
                                      placeholder="City"
                                      {...basicDetailsForm.register("city")}
                                    />
                                  </div>

                                  <div className="form-group col-lg-6 col-md-12">
                                    <label htmlFor="postCode">Post Code</label>
                                    <div className="autocomplete">
                                      <input
                                        id="postCode"
                                        type="text"
                                        name="postCode"
                                        onKeyUp={handleOnSearch}
                                        onFocusOut={(e) =>
                                          console.log("blur", e)
                                        }
                                        {...basicDetailsForm.register(
                                          "postCode"
                                        )}
                                        placeholder="Search post code"
                                      />
                                      <ul className="postCodeSearchResult postCodeSearchResultSite">
                                        {selectedPostCodeList?.map((itm) => (
                                          <li
                                            onClick={() => handleOnSelect(itm)}
                                            key={itm?.id}
                                          >
                                            {itm?.name}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>

                                <div className="btn-box row">
                                  <div className="form-group col-lg-6 col-md-12">
                                    <label htmlFor="phone">Phone Number</label>
                                    <input
                                      id="phone"
                                      name="phone"
                                      type="text"
                                      placeholder="Phone Number"
                                      {...basicDetailsForm.register("phone")}
                                    />
                                  </div>
                                  <div className="form-group col-lg-6 col-md-12">
                                    <label htmlFor="dob">Date of Birth</label>
                                    <input
                                      id="dob"
                                      name="dob"
                                      type="date"
                                      placeholder="dd/mm/yyyy"
                                      {...basicDetailsForm.register("dob")}
                                    />
                                  </div>
                                </div>
                                {user?.role === ROLE.CANDIDATE && (
                                  <div className="btn-box row">
                                    <div className="form-group col-lg-6 col-md-12">
                                      <label>Gender</label>
                                      <select
                                        name="gender"
                                        className="chosen-select-gender"
                                        {...basicDetailsForm.register("gender")}
                                      >
                                        <option value={""}>Select</option>
                                        <option value={"Male"}>Male</option>
                                        <option value={"Female"}>Female</option>
                                        <option
                                          value={"Do not wish to specify"}
                                          selected
                                        >
                                          Do not wish to specify
                                        </option>
                                      </select>
                                    </div>

                                    <div className="form-group col-lg-6 col-md-12">
                                      <label htmlFor="exp">
                                        Years of Experience
                                      </label>
                                      <input
                                        id="exp"
                                        name="exp"
                                        type="number"
                                        placeholder=""
                                        {...basicDetailsForm.register("exp")}
                                      />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-12">
                                      <label>
                                        Select your working status in the UK
                                      </label>
                                      <select
                                        name="workingStatus"
                                        className="chosen-select-working-status"
                                        {...basicDetailsForm.register(
                                          "workingStatus"
                                        )}
                                      >
                                        <option value={""} selected>
                                          Select
                                        </option>
                                        <option value={"UK_CITIZEN"}>
                                          UK Citizen
                                        </option>
                                        <option
                                          value={"INDEFINITE_LEAVE_TO_REMAIN"}
                                        >
                                          Indefinite Leave to Remain
                                        </option>
                                        <option value={"EU_CITIZEN"}>
                                          EU Citizen
                                        </option>
                                        <option
                                          value={
                                            "WORK_PERMIT_HOLDER_NO_SPONSOR_REQUIRED"
                                          }
                                        >
                                          Work Permit Holder (No Sponsor
                                          Required)
                                        </option>
                                        <option
                                          value={
                                            "WORK_PERMIT_HOLDER_NEW_SPONSOR_REQUIRED"
                                          }
                                        >
                                          Work Permit Holder (New Sponsor
                                          Required)
                                        </option>
                                        <option value={"SPONSORSHIP_REQUIRED"}>
                                          Sponsorship Required (No Work Permit)
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                )}
                                <div className="form-group col-lg-2 col-md-12">
                                  <button
                                    className="theme-btn btn-style-one"
                                    type="submit"
                                    name="Savebasic"
                                    // onClick={onSubmitBasic}
                                  >
                                    Save
                                  </button>
                                </div>
                              </form>
                              {/* </FormProvider> */}
                              {/* <!--Password Change Form--> */}
                              {/* <FormProvider {...methods}> */}
                              <form
                                name="passwordchange"
                                onSubmit={handleSubmit(handlePasswordSubmit)}
                              >
                                <div className="btn-box row content-elements">
                                  <h4 className="title">Change Password</h4>
                                  {cpwdApiMsg === "success" && (
                                    <>
                                      <Success msg="You have successfully updated your password." />
                                    </>
                                  )}
                                  {cpwdApiMsg === "error" && (
                                    <>
                                      <Error msg="Something went wrong while changing your password. Please try again." />
                                    </>
                                  )}
                                  <div className="form-group col-lg-6 col-md-12">
                                    <label>Current Password</label>
                                    <input
                                      type="password"
                                      name="oldpassword"
                                      placeholder="Current Password"
                                      required=""
                                      {...register("oldpassword")}
                                    />
                                  </div>

                                  <div className="form-group col-lg-6 col-md-12">
                                    <label>New Password</label>
                                    <input
                                      type="password"
                                      name="newpassword"
                                      placeholder="New Password"
                                      required=""
                                      {...register("newpassword")}
                                    />
                                  </div>
                                </div>
                                <div className="form-group col-lg-2 col-md-12">
                                  <button
                                    className="theme-btn btn-style-one"
                                    type="submit"
                                    name="Savepassword"
                                  >
                                    Save
                                  </button>
                                </div>
                              </form>
                              {/* </FormProvider> */}
                              {/* <!-- Skills Form--> */}
                              {user?.role === ROLE.CANDIDATE && (
                                <form
                                  name="skills"
                                  onSubmit={handleSubmit(handleSkillSubmit)}
                                >
                                  <div className="btn-box row content-elements">
                                    <h4 className="title">Update Skills</h4>
                                    {skillUpdateApiMsg === "success" && (
                                      <>
                                        <Success msg="You have successfully updated your skills." />
                                      </>
                                    )}
                                    {skillUpdateApiMsg === "error" && (
                                      <>
                                        <Warning msg="Something went wrong while updating your skills. Please try again." />
                                      </>
                                    )}
                                    <div className="form-group col-lg-6 col-md-12">
                                      <label>Known Languages</label>
                                      <select
                                        className="chosen-select-language"
                                        name="chooseskill"
                                        {...register("chooseskill")}
                                      >
                                        <option value={""}>Select</option>
                                        <option value={"en"}>English</option>
                                        <option value={"fr"}>French</option>
                                        <option value={"es"}>Spanish</option>
                                        <option value={"nl"}>Dutch</option>
                                      </select>
                                    </div>

                                    <div className="form-group col-lg-6 col-md-12">
                                      <label>Skills</label>
                                      <input
                                        {...register("skills")}
                                        type="text"
                                        name="skills"
                                        maxLength={200}
                                        placeholder="Separate by commas"
                                      />
                                    </div>

                                    <div className="form-group col-lg-12 col-md-12">
                                      <label>Experience Summary</label>
                                      <textarea
                                        {...register("summary")}
                                        placeholder="Provide a short summary"
                                        name="summary"
                                      ></textarea>
                                    </div>
                                  </div>

                                  <div className="form-group col-lg-2 col-md-12">
                                    <button
                                      className="theme-btn btn-style-one"
                                      type="submit"
                                      name="Savepassword"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </form>
                              )}

                              {/* <!--Preferred Shifts--> */}
                              {user?.role === ROLE.CANDIDATE && (
                                <form
                                  name="shift"
                                  onSubmit={handleSubmit(onSubmitShifts)}
                                >
                                  <div className="btn-box row content-elements">
                                    <h4 className="title">Preferred Shift</h4>
                                    {shiftsApiMsg === "success" && (
                                      <>
                                        <Success msg="You have successfully updated preferred shift." />
                                      </>
                                    )}
                                    {shiftsApiMsg === "error" && (
                                      <>
                                        <Warning msg="Something went wrong while updating your preferred shift. Please try again." />
                                      </>
                                    )}
                                    <div className="form-group row col-lg-12 col-md-12">
                                      <div className="form-group col-lg-6 col-md-12 col-sm-12">
                                        {/* <!-- Checkboxes Ouer --> */}
                                        <div className="checkbox-outer">
                                          <label>Select days </label>
                                          <ul className="checkboxes square">
                                            <li>
                                              <input
                                                id="check-sun"
                                                type="checkbox"
                                                name="selectedDays"
                                                value={"Sunday"}
                                                {...register("selectedDays")}
                                              />
                                              <label htmlFor="check-sun">
                                                Sunday
                                              </label>
                                            </li>
                                            <li>
                                              <input
                                                id="check-mon"
                                                type="checkbox"
                                                name="selectedDays"
                                                value={"Monday"}
                                                {...register("selectedDays")}
                                              />
                                              <label htmlFor="check-mon">
                                                Monday
                                              </label>
                                            </li>
                                            <li>
                                              <input
                                                id="check-tues"
                                                type="checkbox"
                                                value={"Tuesday"}
                                                name="selectedDays"
                                                {...register("selectedDays")}
                                              />
                                              <label htmlFor="check-tues">
                                                Tuesday
                                              </label>
                                            </li>
                                            <li>
                                              <input
                                                id="check-wed"
                                                type="checkbox"
                                                value={"Wednesday"}
                                                name="selectedDays"
                                                {...register("selectedDays")}
                                              />
                                              <label htmlFor="check-wed">
                                                Wednesday
                                              </label>
                                            </li>
                                            <li>
                                              <input
                                                id="check-thurs"
                                                type="checkbox"
                                                value={"Thursday"}
                                                name="selectedDays"
                                                {...register("selectedDays")}
                                              />
                                              <label htmlFor="check-thurs">
                                                Thursday
                                              </label>
                                            </li>
                                            <li>
                                              <input
                                                id="check-fri"
                                                type="checkbox"
                                                name="selectedDays"
                                                value={"Friday"}
                                                {...register("selectedDays")}
                                              />
                                              <label htmlFor="check-fri">
                                                Friday
                                              </label>
                                            </li>
                                            <li>
                                              <input
                                                id="check-sat"
                                                type="checkbox"
                                                value={"Saturday"}
                                                name="selectedDays"
                                                {...register("selectedDays")}
                                              />
                                              <label htmlFor="check-sat">
                                                Saturday
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>

                                      <div className="form-group col-lg-6 col-md-12">
                                        <label>Select shift </label>
                                        <select
                                          className="chosen-select-shift"
                                          name="selectshift"
                                          {...register("selectshift")}
                                        >
                                          <option value={""}>Select</option>
                                          <option value={"All Shifts"}>
                                            All Shifts
                                          </option>
                                          <option value={"Morning"}>
                                            Morning
                                          </option>
                                          <option value={"Afternoon"}>
                                            Afternoon
                                          </option>
                                          <option value={"Evening"}>
                                            Evening
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group col-lg-2 col-md-12">
                                    <button
                                      className="theme-btn btn-style-one"
                                      // onClick={onSubmitShifts}
                                      name="Saveshift"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </form>
                              )}

                              {/* <!--ToA Acceptance Form--> */}
                              {user?.role === ROLE.CARE_HOME && (
                                <div id="toaSection">
                                  <form
                                    name="toa"
                                    onSubmit={handleSubmit(handleSubmitToA)}
                                  >
                                    <div className="btn-box row content-elements">
                                      <h4 className="title">
                                        Accept Terms of Agreement
                                      </h4>
                                      {toaApiMsg === "success" && (
                                        <>
                                          <Success msg="You have successfully updated terms of agreement." />
                                        </>
                                      )}
                                      {toaApiMsg === "error" && (
                                        <>
                                          <Warning msg="Something went wrong while updating your terms of agreement. Please try again." />
                                        </>
                                      )}
                                      <div className="form-group col-lg-12 col-md-12">
                                        <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                          <div className="message-box info">
                                            <a>
                                              Download the agreement and upload
                                              signed contract.
                                            </a>
                                          </div>
                                          <div className="">
                                            <div className="uploadButton">
                                              <input
                                                {...register("contract")}
                                                className="uploadButton-input"
                                                type="file"
                                                name="contract"
                                                accept="image/*, application/pdf"
                                                id="contract"
                                                onChange={
                                                  handleContractFileChange
                                                }
                                              />
                                              <label
                                                className="uploadButton-button ripple-effect"
                                                htmlFor="contract"
                                              >
                                                Upload Signed Contract
                                              </label>
                                              {userDetail?.company
                                                ?.mployrContract && (
                                                <figure className="mt-4">
                                                  <a
                                                    href={
                                                      userDetail?.company
                                                        ?.mployrContract
                                                    }
                                                    download
                                                  >
                                                    Download Uploaded Mployr
                                                    Contract
                                                  </a>
                                                </figure>
                                              )}
                                            </div>
                                          </div>
                                          {/* <!-- Checkboxes Ouer --> */}
                                          <div className="checkbox-outer margin-top-10">
                                            <ul className="checkboxes square">
                                              <li>
                                                <input
                                                  id="check-toa"
                                                  type="checkbox"
                                                  name="toa"
                                                  {...register("toa")}
                                                />
                                                <label htmlFor="check-toa">
                                                  I agree with the terms
                                                </label>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="form-group col-lg-2 col-md-12">
                                      <button
                                        className="theme-btn btn-style-one"
                                        type="submit"
                                        name="Savepassword"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              )}
                            </div>
                          </div>

                          {/* <!--References--> */}
                          <div
                            className="tab"
                            id="tab2"
                            style={{ display: "none" }}
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="notification-widget ls-widget">
                                  <div className="tabs-box">
                                    {userDetail?.userReferences?.length < 2 && (
                                      <div className="message-box info">
                                        Upload at least two reference
                                      </div>
                                    )}
                                    <div className="widget-content">
                                      <div className="table-outer">
                                        <table className="default-table">
                                          <thead>
                                            <tr>
                                              <th>Action</th>
                                              <th>Name</th>
                                              <th>Role</th>
                                              <th>Company</th>
                                              <th>Phone</th>
                                              <th>Email</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {userDetail?.userReferences
                                              ?.length === 0 && (
                                              <tr>
                                                <td>No References Available</td>
                                              </tr>
                                            )}
                                            {userDetail?.userReferences?.map(
                                              function (itm) {
                                                return (
                                                  <tr key={itm?.id}>
                                                    <td>
                                                      <div className="option-box">
                                                        <ul className="option-list">
                                                          <li>
                                                            <button data-text="Update">
                                                              <span
                                                                className="la la-pencil"
                                                                onClick={() =>
                                                                  editReference(
                                                                    itm
                                                                  )
                                                                }
                                                              ></span>
                                                            </button>
                                                          </li>
                                                          <li>
                                                            <button
                                                              data-text="Delete"
                                                              onClick={() =>
                                                                deleteReference(
                                                                  itm
                                                                )
                                                              }
                                                            >
                                                              <span className="la la-trash"></span>
                                                            </button>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </td>
                                                    <td>{itm?.name}</td>
                                                    <td>{itm?.role}</td>
                                                    <td>{itm?.organization}</td>
                                                    <td>{itm?.phone}</td>
                                                    <td>{itm?.email}</td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="row login-form default-form"
                              id="reference-form"
                            >
                              <div className="form-inner">
                                {!isEditModaRefernce && (
                                  <h5>Add a reference</h5>
                                )}
                                {isEditModaRefernce && (
                                  <h5>Update reference</h5>
                                )}
                                <br />
                                {apiMsgReference === "success" && (
                                  <>
                                    <Success msg="You have successfully added reference detail." />
                                  </>
                                )}
                                {apiMsgReference === "error" && (
                                  <>
                                    <Error msg="Something went wrong while adding reference details. Please try again." />
                                  </>
                                )}
                                <FormProvider {...methods}>
                                  <form
                                    onSubmit={(e) => e.preventDefault()}
                                    noValidate
                                  >
                                    <div className="btn-box row">
                                      <div className="form-group col-lg-6 col-md-12">
                                        <Input
                                          label="Title"
                                          type="text"
                                          name="title"
                                          placeholder="Title"
                                        />
                                      </div>
                                      <div className="form-group col-lg-6 col-md-12">
                                        <Input
                                          label="Full Name"
                                          type="text"
                                          placeholder="Full name"
                                          name="fullname"
                                        />
                                      </div>
                                    </div>

                                    <div className="btn-box row">
                                      <div className="form-group col-lg-6 col-md-12">
                                        <Input
                                          label="Role"
                                          type="text"
                                          placeholder="Role"
                                          name="refrole"
                                        />
                                      </div>

                                      <div className="form-group col-lg-6 col-md-12">
                                        <Input
                                          label="Company"
                                          type="text"
                                          name="company"
                                          placeholder="Company"
                                        />
                                      </div>
                                    </div>

                                    <div className="btn-box row">
                                      <div className="form-group col-lg-6 col-md-12">
                                        <Input
                                          label="Email Address"
                                          type="email"
                                          name="refemail"
                                          placeholder="Email"
                                        />
                                      </div>

                                      <div className="form-group col-lg-6 col-md-12">
                                        <Input
                                          label="Phone Number"
                                          type="text"
                                          name="refphone"
                                          placeholder="Phone Number"
                                          maxLength={14}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{ display: "flex", gap: "3rem" }}
                                    >
                                      {isEditModaRefernce && (
                                        <div className="form-group col-lg-2 col-md-6">
                                          <button
                                            className="theme-btn btn-style-three "
                                            onClick={onCancelRefUpdate}
                                            name="Savereference"
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      )}
                                      {isEditModaRefernce && (
                                        <div className="form-group col-lg-2 col-md-6">
                                          <button
                                            className="theme-btn btn-style-one "
                                            onClick={onSaveRef}
                                            name="Savereference"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                    {!isEditModaRefernce && (
                                      <div className="form-group col-lg-2 col-md-6">
                                        <button
                                          className="theme-btn btn-style-one "
                                          onClick={onSubmitRef}
                                          name="Savereference"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    )}
                                  </form>
                                </FormProvider>
                              </div>
                            </div>
                          </div>

                          {/* <!--Certificates--> */}
                          <div
                            className="tab"
                            id="tab3"
                            style={{ display: "none" }}
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="notification-widget ls-widget">
                                  <div className="tabs-box">
                                    <div className="message-box info">
                                      NVQ certificate and DBS certificate
                                      improves your chance of getting
                                      shortlisted.
                                    </div>

                                    <div className="widget-content">
                                      <div className="table-outer">
                                        <table className="default-table">
                                          <thead>
                                            <tr>
                                              <th>Action</th>
                                              <th>Certificate</th>
                                              <th>Comment</th>
                                              <th>Start Date</th>
                                              <th>Closing Date</th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {userDetail?.userTrainings
                                              ?.length === 0 && (
                                              <tr>
                                                <td>
                                                  No Certificates Available
                                                </td>
                                              </tr>
                                            )}
                                            {userDetail?.userTrainings?.map(
                                              function (user) {
                                                return (
                                                  <tr key={user.id}>
                                                    <td>
                                                      <div className="option-box">
                                                        <ul className="option-list">
                                                          <li>
                                                            <button
                                                              data-text="View"
                                                              onClick={() => {
                                                                setCertificateUrl(
                                                                  user?.certificate
                                                                );
                                                              }}
                                                            >
                                                              <span className="la la-eye"></span>
                                                            </button>
                                                          </li>
                                                          <li>
                                                            <button
                                                              data-text="Delete"
                                                              onClick={() => {
                                                                deleteTraining(
                                                                  user
                                                                );
                                                              }}
                                                            >
                                                              <span className="la la-trash"></span>
                                                            </button>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      {user?.trainingTitle}
                                                    </td>
                                                    <td>{user?.comment}</td>
                                                    <td>
                                                      {formatDateLabel(
                                                        user?.startDate
                                                      )}
                                                    </td>
                                                    <td>
                                                      {formatDateLabel(
                                                        user?.endDate
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row login-form default-form">
                              {certificateUrl && (
                                <>
                                  <div className="form-inner">
                                    <h5>
                                      Certificate View{" "}
                                      <button
                                        style={{ float: "right" }}
                                        onClick={() => {
                                          setCertificateUrl("");
                                        }}
                                      >
                                        Close
                                      </button>
                                    </h5>

                                    <object
                                      id="fgh"
                                      data={certificateUrl}
                                      width="400"
                                      height="400"
                                    ></object>
                                  </div>
                                </>
                              )}

                              <div className="form-inner">
                                <h5>Add a certificate</h5>
                                <br />
                                {/* <FormProvider {...methods}> */}
                                {certificateApiMsg === "success" && (
                                  <>
                                    <Success msg="You have successfully added certificate detail." />
                                  </>
                                )}
                                {certificateApiMsg === "error" && (
                                  <>
                                    <Error msg="Something went wrong while adding certificate details. Please try again." />
                                  </>
                                )}
                                <form
                                  onSubmit={certificateForm.handleSubmit(
                                    onSubmitCertificates
                                  )}
                                  noValidate
                                >
                                  <div className="btn-box row">
                                    <div className="form-group col-lg-6 col-md-12">
                                      <label>Upload certificate</label>
                                      <input
                                        type="file"
                                        name="certificate"
                                        placeholder="Upload Certificate"
                                        required=""
                                        {...certificateForm.register(
                                          "certificate",
                                          {
                                            required: {
                                              value: true,
                                              message: "required",
                                            },
                                          }
                                        )}
                                        onChange={handleCertificateFileChange}
                                      />
                                      {certificateForm?.formState?.errors
                                        ?.certificate && (
                                        <InputError
                                          message={
                                            certificateForm?.formState?.errors
                                              ?.certificate?.message
                                          }
                                          key={
                                            certificateForm?.formState?.errors
                                              ?.certificate?.message
                                          }
                                        />
                                      )}
                                    </div>
                                    <div className="form-group col-lg-6 col-md-12">
                                      <label>Type of certificate</label>
                                      <select
                                        className="chosen-select-certificate"
                                        name="typeCertificate"
                                        {...certificateForm.register(
                                          "typeCertificate",
                                          {
                                            required: {
                                              value: true,
                                              message: "required",
                                            },
                                          }
                                        )}
                                        // id="type"
                                      >
                                        <option value={""}>Select</option>
                                        <option value={"NVQ_CERTIFICATE"}>
                                          NVQ Certificate
                                        </option>
                                        <option value={"DBS_CERTIFICATE"}>
                                          DBS Certificate
                                        </option>
                                        <option value={"OTHER_CERTIFICATE"}>
                                          Other
                                        </option>
                                      </select>
                                      {certificateForm?.formState?.errors
                                        ?.typeCertificate && (
                                        <InputError
                                          message={
                                            certificateForm?.formState?.errors
                                              ?.typeCertificate?.message
                                          }
                                          key={
                                            certificateForm?.formState?.errors
                                              ?.typeCertificate?.message
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>

                                  <div className="btn-box row">
                                    <div className="form-group col-lg-6 col-md-12">
                                      <label>Start Date</label>
                                      <input
                                        type="date"
                                        placeholder="Start Date"
                                        name="startDate"
                                        required=""
                                        {...certificateForm?.register(
                                          "startDate",
                                          {
                                            required: {
                                              value: true,
                                              message: "required",
                                            },
                                          }
                                        )}
                                      />
                                      {certificateForm?.formState?.errors
                                        ?.startDate && (
                                        <InputError
                                          message={
                                            certificateForm?.formState?.errors
                                              ?.startDate?.message
                                          }
                                          key={
                                            certificateForm?.formState?.errors
                                              ?.startDate?.message
                                          }
                                        />
                                      )}
                                    </div>

                                    <div className="form-group col-lg-6 col-md-12">
                                      <label>Closing Date</label>
                                      <input
                                        type="date"
                                        name="endDate"
                                        placeholder="Closing Date"
                                        required=""
                                        {...certificateForm.register(
                                          "endDate",
                                          {
                                            required: {
                                              value: true,
                                              message: "required",
                                            },
                                          }
                                        )}
                                      />
                                      {certificateForm?.formState?.errors
                                        ?.endDate && (
                                        <InputError
                                          message={
                                            certificateForm?.formState?.errors
                                              ?.endDate?.message
                                          }
                                          key={
                                            certificateForm?.formState?.errors
                                              ?.endDate?.message
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>

                                  <div className="btn-box row">
                                    <div className="form-group col-lg-12 col-md-12">
                                      <label>Comment, if any</label>
                                      <input
                                        type="text"
                                        name="comments"
                                        placeholder="Comment"
                                        required=""
                                        {...certificateForm.register(
                                          "comments"
                                        )}
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group col-lg-2 col-md-12">
                                    <button
                                      className="theme-btn btn-style-one "
                                      // onClick={onSubmitCertificates}
                                      name="Savesite"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </form>
                                {/* </FormProvider> */}
                              </div>
                            </div>
                          </div>

                          {/* <!--Sites--> */}
                          <div
                            className="tab"
                            id="tab4"
                            style={{ display: "none" }}
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="notification-widget ls-widget">
                                  <div className="tabs-box">
                                    {userDetail?.company?.sites?.length ===
                                      0 && (
                                      <div className="message-box info">
                                        Maintain at least one site
                                      </div>
                                    )}
                                    <div className="widget-content">
                                      <div className="table-outer">
                                        <table className="default-table">
                                          <thead>
                                            <tr>
                                              <th>Action</th>
                                              <th>Site Name</th>
                                              <th>Address Line 1</th>
                                              <th>Address Line 2</th>
                                              <th>City</th>
                                              <th>Post Code</th>
                                              <th>Facility Manager</th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {userDetail?.company?.sites
                                              ?.length === 0 && (
                                              <tr>
                                                <td>No Site Available</td>
                                              </tr>
                                            )}
                                            {userDetail?.company?.sites?.map(
                                              function (itm) {
                                                return (
                                                  <tr key={itm?.id}>
                                                    <td
                                                      style={{
                                                        paddingLeft: "0px",
                                                      }}
                                                    >
                                                      <div className="option-box">
                                                        <ul className="option-list">
                                                          <li>
                                                            <button data-text="Update">
                                                              <span
                                                                className="la la-pencil"
                                                                onClick={() => {
                                                                  scrollTop(
                                                                    "#updateSiteSection"
                                                                  );
                                                                  editSite(itm);
                                                                }}
                                                              ></span>
                                                            </button>
                                                          </li>
                                                          <li>
                                                            <button
                                                              data-text="Delete"
                                                              onClick={() =>
                                                                onDeleteSite(
                                                                  itm
                                                                )
                                                              }
                                                            >
                                                              <span className="la la-trash"></span>
                                                            </button>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </td>
                                                    <td>{itm?.name}</td>
                                                    <td>{itm?.address1}</td>
                                                    <td>{itm?.address2}</td>
                                                    <td>{itm?.city}</td>
                                                    <td>{itm?.postCode}</td>
                                                    <td>
                                                      {itm?.facilityManager}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row login-form default-form">
                              <div
                                className="form-inner"
                                id="updateSiteSection"
                              >
                                {!isEditModeSite && <h5>Add a site</h5>}
                                {isEditModeSite && <h5>Update a site</h5>}

                                <br />
                                {siteAddedMsg === "success" && (
                                  <>
                                    <Success msg="You have successfully added site." />
                                  </>
                                )}
                                {siteAddedMsg === "error" && (
                                  <>
                                    <Error msg="Something went wrong while adding site. Please try again." />
                                  </>
                                )}
                                <FormProvider {...methods}>
                                  <form
                                    onSubmit={(e) => e.preventDefault()}
                                    noValidate
                                  >
                                    <div className="btn-box row">
                                      <div className="form-group col-lg-6 col-md-12">
                                        <Input
                                          label="Site name"
                                          type="text"
                                          name="sitename"
                                          placeholder="Site Name"
                                          required=""
                                        />
                                      </div>
                                      <div className="form-group col-lg-6 col-md-12">
                                        <Input
                                          label="Facility Manager"
                                          type="text"
                                          placeholder="Facility Manager Name"
                                          name="fmname"
                                          required=""
                                        />
                                      </div>
                                    </div>

                                    <div className="btn-box row">
                                      <div className="form-group col-lg-6 col-md-12">
                                        <Input
                                          label="Address Line 1"
                                          type="text"
                                          placeholder="Address Line 1"
                                          name="addr1"
                                          required=""
                                        />
                                      </div>

                                      <div className="form-group col-lg-6 col-md-12">
                                        <Input
                                          label="Address Line 2"
                                          type="text"
                                          name="addr2"
                                          placeholder="Address Line 2"
                                          required=""
                                        />
                                      </div>
                                    </div>

                                    <div className="btn-box row">
                                      <div className="form-group col-lg-6 col-md-12">
                                        <Input
                                          label="City"
                                          type="text"
                                          name="city"
                                          placeholder="City"
                                          required=""
                                        />
                                      </div>

                                      <div className="form-group col-lg-6 col-md-12">
                                        <Input
                                          label="Post Code"
                                          type="text"
                                          name="postcode"
                                          placeholder="Post Code"
                                          required=""
                                        />
                                      </div>

                                      {isEditModeSite && (
                                        <div className="form-group col-lg-2 col-md-6">
                                          <button
                                            disabled={isLoadingSiteSubmit}
                                            className="theme-btn btn-style-three "
                                            type="submit"
                                            onClick={onCancelSiteUpdate}
                                            name="cancelSite"
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      )}
                                      {isEditModeSite && (
                                        <div className="form-group col-lg-2 col-md-6">
                                          <button
                                            disabled={isLoadingSiteSubmit}
                                            className="theme-btn btn-style-one "
                                            type="submit"
                                            onClick={onUpdateSites}
                                            name="Savesite"
                                          >
                                            Update
                                          </button>
                                        </div>
                                      )}
                                      {!isEditModeSite && (
                                        <div className="form-group col-lg-2 col-md-6">
                                          {isLoadingSiteSubmit && <Loading />}
                                          {!isLoadingSiteSubmit && (
                                            <button
                                              className="theme-btn btn-style-one "
                                              type="submit"
                                              onClick={onSaveSites}
                                              name="Submitsite"
                                            >
                                              Submit
                                            </button>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </form>
                                </FormProvider>
                              </div>
                            </div>
                          </div>

                          {/* <!--Preinterview Questions--> */}

                          <div
                            className="tab"
                            id="tab5"
                            style={{ display: "none" }}
                          >
                            <div className="column col-lg-12 col-md-12 tab-5-content">
                              {preInterviewApiMsg === "success" && (
                                <div className="message-box success">
                                  Response successfully saved.
                                  <button className="close-btn">
                                    <span className="close_icon"></span>
                                  </button>
                                </div>
                              )}
                              {preInterviewApiMsg === "error" && (
                                <div className="message-box error">
                                  Response is not successfully saved. Please try
                                  again
                                  <button className="close-btn">
                                    <span className="close_icon"></span>
                                  </button>
                                </div>
                              )}
                              <form
                                className="default-form"
                                name="preinterview"
                                onSubmit={handleSubmit(onSubmitInterview)}
                              >
                                {/* <!-- Textarea --> */}
                                {userDetail?.userInterviews?.map((question) => (
                                  <div
                                    className="form-group chosen-search"
                                    key={question?.qid}
                                  >
                                    <label>
                                      {question?.questionDescription}
                                    </label>
                                    <textarea
                                      placeholder="Response"
                                      name="Answer1"
                                      defaultValue={question?.userResponse}
                                      {...register(`${question?.qid}`)}
                                    ></textarea>
                                  </div>
                                ))}

                                <div className="form-group col-lg-2 col-md-12">
                                  <button
                                    className="theme-btn btn-style-one"
                                    type="submit"
                                    name="Savepreinterview"
                                  >
                                    Save
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>

                          {/* <!--Subscription--> */}
                          <div
                            className="tab"
                            id="tab6"
                            style={{ display: "none" }}
                          >
                            <div className="widget-content">
                              <div className="table-outer">
                                <table className="default-table">
                                  <thead>
                                    <tr>
                                      <th>Plan</th>
                                      <th>Number of Job post</th>
                                      <th>Number of recruits</th>
                                      <th>Number of meetings</th>
                                      <th>Start Date</th>
                                      <th>Closing Date</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {userDetail?.company?.subscription
                                      ?.length === 0 && (
                                      <tr>
                                        <td>
                                          No active subscription record found!!
                                        </td>
                                      </tr>
                                    )}
                                    {userDetail?.company?.subscription?.map(
                                      (itm) => (
                                        <tr>
                                          <td>{itm?.subscription}</td>
                                          <td>
                                            {String(
                                              itm?.subscription
                                            ).toLowerCase() === "free"
                                              ? "Unlimited"
                                              : itm?.currentSubscriptionValue
                                                  ?.jobPost}
                                          </td>
                                          <td>
                                            {String(
                                              itm?.subscription
                                            ).toLowerCase() === "free"
                                              ? "Unlimited"
                                              : itm?.currentSubscriptionValue
                                                  ?.carer}
                                          </td>
                                          <td>
                                            {String(
                                              itm?.subscription
                                            ).toLowerCase() === "free"
                                              ? "Unlimited"
                                              : itm?.currentSubscriptionValue
                                                  ?.meetings}
                                          </td>
                                          <td>
                                            {formatDateString(
                                              itm?.subscriptionDate
                                            )}
                                          </td>
                                          <td>
                                            {formatDateString(itm?.endDate)}
                                          </td>
                                          <td className="text-primary">
                                            {itm?.status}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="column col-lg-12 col-md-12">
                              <section className="pricing-section pt-0">
                                <div className="auto-container">
                                  <div className="sec-title text-center">
                                    <h2>Pricing Packages</h2>
                                  </div>
                                  {/* <!--Pricing Tabs--> */}
                                  <div id="generic_price_table">
                                    <section>
                                      <div className="container">
                                        <div className="row">
                                          <div className="col-md-12">
                                            {/* <!--PRICE HEADING START--> */}
                                            <div className="price-heading clearfix"></div>
                                            {/* <!--//PRICE HEADING END--> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="container">
                                        {/* <!--BLOCK ROW START--> */}
                                        <div className="row">
                                          {subscriptionList?.map((itm) => (
                                            <div className="col-md-4">
                                              {/* <!--PRICE CONTENT START--> */}
                                              <div
                                                className={`generic_content ${
                                                  userDetail?.company &&
                                                  userDetail?.company
                                                    .subscription[0].status ===
                                                    "Active" &&
                                                  itm?.subscriptionId ===
                                                    userDetail?.company
                                                      .subscription[0]
                                                      .subscription
                                                    ? "active"
                                                    : ""
                                                } clearfix`}
                                              >
                                                {/* <!--HEAD PRICE DETAIL START--> */}
                                                <div className="generic_head_price clearfix">
                                                  {/* <!--HEAD CONTENT START--> */}
                                                  <div className="generic_head_content clearfix">
                                                    {/* <!--HEAD START--> */}
                                                    <div className="head_bg"></div>
                                                    <div className="head">
                                                      <span>
                                                        {String(
                                                          itm?.subscriptionId
                                                        ).toLowerCase() ===
                                                        "free"
                                                          ? "30 Days"
                                                          : itm?.subscriptionId}
                                                      </span>
                                                    </div>
                                                    {/* <!--//HEAD END--> */}
                                                  </div>
                                                  {/* <!--//HEAD CONTENT END--> */}

                                                  {/* <!--PRICE START--> */}
                                                  <div className="generic_price_tag clearfix">
                                                    <span className="price">
                                                      {String(
                                                        itm?.subscriptionId
                                                      ).toLowerCase() === "free"
                                                        ? "Free"
                                                        : "Tailored price"}

                                                      {/* <span className="sign">
                                                        £
                                                      </span>
                                                      <span className="currency">
                                                        {itm?.fee}
                                                      </span>
                                                      <span className="cent">
                                                        .00
                                                      </span>
                                                      <span className="month">
                                                        /MTH
                                                      </span> */}
                                                    </span>
                                                  </div>
                                                  {/* <!--//PRICE END--> */}
                                                </div>
                                                {/* <!--//HEAD PRICE DETAIL END--> */}

                                                {/* <!--FEATURE LIST START--> */}
                                                <div className="generic_feature_list">
                                                  <ul>
                                                    <li>
                                                      <span>
                                                        {String(
                                                          itm?.subscriptionId
                                                        ).toLowerCase() ===
                                                        "free"
                                                          ? "Unlimited"
                                                          : itm
                                                              ?.subscriptionModel
                                                              ?.jobPost}
                                                      </span>{" "}
                                                      Job Posts
                                                    </li>
                                                    <li>
                                                      <span>
                                                        {String(
                                                          itm?.subscriptionId
                                                        ).toLowerCase() ===
                                                        "free"
                                                          ? "Unlimited"
                                                          : itm
                                                              ?.subscriptionModel
                                                              ?.carer}
                                                      </span>{" "}
                                                      Recruits
                                                    </li>
                                                    <li>
                                                      <span>Access</span> client
                                                      data
                                                    </li>
                                                    <li>
                                                      <span>
                                                        {String(
                                                          itm?.subscriptionId
                                                        ).toLowerCase() ===
                                                        "free"
                                                          ? "Unlimited"
                                                          : itm
                                                              ?.subscriptionModel
                                                              ?.meetings}
                                                      </span>{" "}
                                                      Meetings
                                                    </li>
                                                    <li>
                                                      <span>
                                                        {itm?.support === "No"
                                                          ? "Email"
                                                          : `${itm?.support}`}
                                                      </span>{" "}
                                                      Support
                                                    </li>
                                                  </ul>
                                                </div>
                                                {/* <!--//FEATURE LIST END--> */}

                                                {/* <!--BUTTON START--> */}
                                                <div className="generic_price_btn clearfix">
                                                  <button
                                                    onClick={() => {
                                                      subscribeNow(itm);
                                                    }}
                                                    disabled={
                                                      userDetail?.company &&
                                                      userDetail?.company
                                                        .subscription[0]
                                                        .status === "Active" &&
                                                      itm?.subscriptionId ===
                                                        userDetail?.company
                                                          .subscription[0]
                                                          .subscription
                                                        ? true
                                                        : false
                                                    }
                                                    className={`${
                                                      userDetail?.company &&
                                                      userDetail?.company
                                                        .subscription[0]
                                                        .status === "Active" &&
                                                      itm?.subscriptionId ===
                                                        userDetail?.company
                                                          .subscription[0]
                                                          .subscription
                                                        ? "disabled"
                                                        : ""
                                                    }`}
                                                  >
                                                    Sign up
                                                  </button>
                                                </div>
                                                {/* <!--//BUTTON END--> */}
                                              </div>
                                              {/* <!--//PRICE CONTENT END--> */}
                                            </div>
                                          ))}
                                        </div>
                                        {/* <!--//BLOCK ROW END--> */}
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Dashboard --> */}

        <Footer />
      </div>
      {/* <!-- End Page Wrapper -->  */}
    </>
  );
};
export default CandidateProfile;
