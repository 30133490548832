import React, { useEffect, useState } from "react";
import fileDownload from "js-file-download";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { get, getFile } from "../../../api";
import PayIn from "./Candidate/Payin";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

function CandidateReports() {
  const [timeBooked, setTimeBooked] = useState({
    labels: [],
    datasets: [
      {
        label: "Time Booked",
        data: [],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  });
  const [timeBookedType, setTimeBookedType] = useState("month");
  useEffect(() => {
    getTimeBooked(false);
  }, []);
  const getTimeBooked = async (isDownload = false, type) => {
    // type = week/month
    try {
      let url = `/meeting-timesheet/report/time-booked?type=${
        type ? type : timeBookedType
      }&download=${isDownload}`;
      let response;
      if (isDownload) {
        response = await getFile(url);
      } else {
        response = await get(url);
      }
      let labels = [];
      let data = [];
      if (isDownload && response?.data) {
        fileDownload(
          response?.data,
          response?.headers?.["content-disposition"]?.split("filename=")[1]
        );
      }
      if (!isDownload && response?.count) {
        for (const [key, value] of Object.entries(response?.count)) {
          labels.push(key);
          data.push(value);
        }
        setTimeBooked({
          labels: labels,
          datasets: [
            {
              label: "Time Booked",
              data: data,
              fill: true,
              backgroundColor: "rgba(75,192,192,0.2)",
              borderColor: "rgba(75,192,192,1)",
            },
          ],
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="row">
      <div className="col-lg-12">
        {/* <!-- Graph widget --> */}
        <div className="graph-widget ls-widget">
          <div className="tabs-box">
            <PayIn />
          </div>

          <div className="tabs-box">
            <div className="widget-title">
              <h4>Time Booked</h4>
              <div className="chosen-outer">
                {/* <!--Tabs Box--> */}
                <select
                  className="chosen-select-time-booked"
                  onChange={(e) => {
                    e?.stopPropagation();
                    setTimeBookedType(e?.target?.value);
                    getTimeBooked(false, e?.target?.value);
                  }}
                >
                  <option value={"month"}>Last 6 Months</option>
                  <option value={"week"}>Last 24 Weeks</option>
                </select>
                <div>
                  <button
                    type="button"
                    className="theme-btn btn-sm btn-style-three"
                    onClick={(e) => {
                      e?.stopPropagation();
                      getTimeBooked(true);
                    }}
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>

            <div className="widget-content">
              <Line data={timeBooked} options={options} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CandidateReports;
