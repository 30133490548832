import React, { useEffect, useState } from "react";
import Scripts from "../../Common/Scripts/Scripts";
import CareHomeSidebar from "../Dashboard/CareHomeSideBar";
import Footer from "../Dashboard/Footer";
import Header from "../Dashboard/Header";
import { getSession } from "../../../utils/session";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import { get, put } from "../../../api";
import Loading from "../../Common/Loading";
import { scrollTop } from "../../../utils/scroll";
import candidateDefault from "../../../images/resource/company-6.png";
import { isPastdate } from "../../../utils/date";

const Applicants = () => {
  const [userDetail, setUserDetail] = useState();
  const [isLoading, setLoading] = useState(true);
  const [applicantDetail, setApplicantDetail] = useState();
  const [applicants, setApplicants] = useState([]);
  const [jobs, setJobs] = useState();
  const [filterJobs, setFilterJobs] = useState([]);

  const callMethod = async () => {
    try {
      const url = `/user/users/detail`;
      const response = await get(url);
      getActiveJobs(response?.company?.companyId);
      setUserDetail(response);
      console.log("profile response", response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const jobTypes = [
    "",
    "Senior Care Assistant",
    "Domestic",
    "Deputy Manager",
    "Manager",
    "Care Assistant",
    "Others",
  ];

  const getActiveJobs = async (companyId) => {
    const url = `/job/jobs/company?companyId=${companyId}`;
    const response = await get(url);
    const res = [];
    for (const iterator of response) {
      if (isPastdate(iterator?.endDate)) {
        // skip the past date jobs
      } else {
        res.push(iterator);
      }
    }
    setJobs(res);
    setFilterJobs(res);
    setLoading(false);
  };

  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    callMethod();
  }, []);

  const searchJob = (event) => {
    const value = event?.target?.value;
    if (value) {
      const list = jobs?.filter((x) =>
        String(x?.title).toLowerCase().includes(String(value).toLowerCase())
      );
      setFilterJobs(list);
    } else {
      setFilterJobs(jobs);
    }
  };

  const setApplicantDetails = async (itm) => {
    setApplicantDetail(itm);
    setLoading(true);
    try {
      const url = `/job/application/jobs/${itm?.id}`;
      const response = await get(url);
      console.log("response", response);
      if (response?.length > 0) {
        setApplicants(response);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const rejecCandidate = async (user) => {
    console.log("user", user);
    console.log("applicantDetail", applicantDetail);
    const payload = {
      jobId: applicantDetail?.id,
      userId: user?.userId,
      companyId: applicantDetail?.companyId,
      status: "REJECTED",
    };
    const url = "job/jobs/application/user";
    try {
      const response = await put(url, payload);
      if (response?.status === 202 || response?.status === 201) {
        console.log("response", response);
        setApplicantDetails(applicantDetail);
      } else {
        console.log("err", response);
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <div className="page-wrapper dashboard ">
        <Scripts />
        <Header />
        <CareHomeSidebar />
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="upper-title-box">
              <h3>All Applicants</h3>
              <div className="text">Select a job to view applicants</div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Ls widget --> */}
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-title">
                      <h4>Active Jobs</h4>

                      <div className="filter-block">
                        <div className="form-group">
                          <input
                            type="text"
                            name="listing-search"
                            placeholder="Job title or company"
                            onChange={searchJob}
                          />
                          <span className="icon flaticon-search-3"></span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="message-box info"
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <p>
                        <span class="la la-info-circle"></span> Click on the
                        action button to view all applicants.
                      </p>
                    </div>
                    <div className="widget-content">
                      <div className="table-outer">
                        <table className="default-table manage-job-table">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Job Title</th>
                              <th>Vacancies</th>
                              <th>Applicants</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {filterJobs?.length === 0 && (
                              <tr>
                                <td>No Applicants Found!!</td>
                              </tr>
                            )}
                            {filterJobs?.map((item) => (
                              <tr>
                                <td
                                  style={{
                                    paddingLeft: "0",
                                  }}
                                >
                                  <div className="option-box">
                                    <ul className="option-list">
                                      <li>
                                        <button
                                          onClick={() => {
                                            setApplicantDetails(item);
                                            setTimeout(
                                              () =>
                                                scrollTop(".applicantSection"),
                                              1000
                                            );
                                          }}
                                          data-text="View Applicants"
                                        >
                                          <span className="la la-eye"></span>
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    paddingLeft: "0",
                                  }}
                                >
                                  {/* <!-- Job Block --> */}
                                  <div className="job-block">
                                    {/* <div className="inner-box"> */}
                                    <div className="content">
                                      <span className="company-logo">
                                        <img
                                          src="images/resource/company-logo/1-1.png"
                                          alt=""
                                        />
                                      </span>
                                      <h4>
                                        <a>{item?.title}</a>
                                      </h4>
                                      <ul className="job-info">
                                        <li>
                                          <span className="icon flaticon-briefcase"></span>{" "}
                                          {jobTypes[item?.jobTypeId]}
                                        </li>
                                        <li>
                                          <span className="icon flaticon-map-locator"></span>{" "}
                                          {item?.siteCity}
                                        </li>
                                      </ul>
                                    </div>
                                    {/* </div> */}
                                  </div>
                                </td>
                                <td>{item?.vacancies}</td>
                                <td>{item?.applicants}</td>
                                <td>
                                  {item.status === "ACTIVE" ? (
                                    <span class="badge bg-success">
                                      {item?.status}
                                    </span>
                                  ) : item.status === "INACTIVE" ? (
                                    <span class="badge bg-danger">
                                      {item?.status?.split("_")?.join(" ")}
                                    </span>
                                  ) : (
                                    <span class="badge bg-warning">
                                      {item?.status?.split("_")?.join(" ")}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {applicantDetail && (
              <div className="row">
                <div className="col-lg-12">
                  {/* <!-- Ls widget --> */}
                  <div className="ls-widget">
                    <div className="tabs-box">
                      <div className="widget-title applicantSection">
                        <h4>Applicants</h4>
                      </div>

                      <div className="widget-content">
                        <div className="tabs-box">
                          <div className="aplicants-upper-bar">
                            <h6>{applicantDetail?.title}</h6>
                            <ul className="aplicantion-status tab-buttons clearfix">
                              <li
                                className="tab-btn active-btn totals"
                                data-tab="#totals"
                              >
                                Total(s): {applicantDetail?.applicants}
                              </li>
                              <li
                                className="tab-btn approved"
                                data-tab="#approved"
                              >
                                Approved:{" "}
                                {
                                  applicants?.filter(
                                    (itm) => itm?.applicantStatus === "ACCEPTED"
                                  )?.length
                                }
                              </li>
                              <li
                                className="tab-btn rejected"
                                data-tab="#rejected"
                              >
                                Rejected(s):{" "}
                                {
                                  applicants?.filter(
                                    (itm) => itm?.applicantStatus === "REJECTED"
                                  )?.length
                                }
                              </li>
                            </ul>
                          </div>

                          <div className="tabs-content">
                            {/* <!--Tab--> */}
                            <div className="tab active-tab" id="totals">
                              <div className="row">
                                {/* <!-- Candidate block three --> */}
                                {isLoading && <Loading />}
                                {applicants?.map((itm) => (
                                  <div
                                    className="candidate-block-three col-lg-6 col-md-12 col-sm-12"
                                    key={itm?.userId}
                                  >
                                    <div className="inner-box">
                                      <div className="content">
                                        <figure className="image">
                                          <img
                                            src={
                                              itm?.photo
                                                ? itm?.photo
                                                : candidateDefault
                                            }
                                            alt=""
                                          />
                                        </figure>
                                        <h4 className="name">
                                          <a
                                            target="_blank"
                                            href={`/#/candidate/?id=${itm?.userId}`}
                                          >
                                            {itm?.firstName} {itm?.lastName}
                                          </a>
                                        </h4>
                                        <ul className="candidate-info">
                                          <li>
                                            <span className="icon flaticon-map-locator"></span>{" "}
                                            {itm?.city}
                                          </li>
                                          <li>
                                            <span
                                              className={
                                                itm?.applicantStatus ===
                                                "ACCEPTED"
                                                  ? "badge bg-success"
                                                  : itm?.applicantStatus ===
                                                    "REJECTED"
                                                  ? "badge bg-danger"
                                                  : "badge bg-warning text-dark"
                                              }
                                            >
                                              {itm?.applicantStatus
                                                ?.split("_")
                                                ?.join(" ")}
                                            </span>
                                          </li>
                                        </ul>
                                        {/* <ul className="post-tags">
                                          <li>
                                            <a href="#">App</a>
                                          </li>
                                          <li>
                                            <a href="#">Design</a>
                                          </li>
                                          <li>
                                            <a href="#">Digital</a>
                                          </li>
                                        </ul> */}
                                      </div>
                                      <div className="option-box">
                                        <ul className="option-list">
                                          <li>
                                            <a
                                              target="_blank"
                                              href={`/#/candidate/?id=${itm?.userId}`}
                                              data-text="View Applicant"
                                            >
                                              <span className="la la-eye"></span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              data-text="Setup Meeting"
                                              href={`/#/meetings?id=${itm?.userId}&firstName=${itm?.firstName}&lastName=${itm?.lastName}&title=${applicantDetail?.title}&jobId=${applicantDetail?.id}`}
                                            >
                                              <span className="la la-calendar-plus"></span>
                                            </a>
                                          </li>
                                          <li>
                                            <button
                                              data-text="Reject"
                                              onClick={() =>
                                                rejecCandidate(itm)
                                              }
                                            >
                                              <span className="la la-times-circle"></span>
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ))}

                                {/** end */}
                              </div>
                            </div>

                            {/* <!--Tab--> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default Applicants;
