import { useEffect, useRef, useState } from "react"
import JobCard from "./JobCard"
import JobPoint from "./JobPoint"

const MapPoint = (props) => {
  const [opened, setIsOpened] = useState(false)
  const handleOnOpen = () => setIsOpened(true)
  const handleOnClose = () => setIsOpened(false)
  const containerRef = useRef(null)
  
  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpened(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  return (<div ref={containerRef}>
    {opened ?
      <JobCard
        companyPhoto={props?.companyPhoto}
        cityName={props?.cityName}
        jobId={props?.jobId}
        title={props?.title}
        handleClose={handleOnClose}
      /> :
      <JobPoint
        onClick={handleOnOpen}
      />}
  </div>)
}

export default MapPoint