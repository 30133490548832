import { useContext, useEffect } from "react";
import Scripts from "../../Common/Scripts/Scripts";
import CandidateSidebar from "../Dashboard/CandidateSidebar";
import Footer from "../../Common/Footer";
import Header from "../Dashboard/Header";
import candidateDefault from "../../../images/resource/company-6.png";
import {
  clearSession,
  createSession,
  getSession,
} from "../../../utils/session";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import { useState } from "react";
import { ROLE } from "../../../constant/role";
import CareHomeSidebar from "../Dashboard/CareHomeSideBar";
import { useNavigate, useSearchParams } from "react-router-dom";
import formatDateLabel from "../../../utils/formatDateLabel";
import { get } from "../../../api";
import { AuthContext } from "../../../context/auth";
import { formatDateString } from "../../../utils/date";

const CandidateFullProfile = () => {
  const [searchParams] = useSearchParams();
  const candidateId = searchParams.get("id");
  const [user, setUser] = useState();
  const [candidateDetails, setCandidateDetails] = useState();
  const [appreciationNotes, setAppreciationNotes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isDbsCertificateAvailable, setIsDbsCertificateAvailable] =
    useState(false);
  const { setFavourites, setProfilePic, setAuth, auth, setUnreadMessages } =
    useContext(AuthContext);

  const getUserDetails = async (jobDetails) => {
    const url = `/user/users/detail`;
    const response = await get(url);
    if (response) {
      createSession(
        EMPLOYR_LOGIN_SESSION,
        JSON.stringify({
          authenticated: true,
          role: response?.role,
        })
      );
      setFavourites(response?.favourites?.length);
      setProfilePic(response?.photo);
    }
  };

  const getCandidateDetails = async () => {
    setLoading(true);
    const url = `/user/users/${candidateId}/detail`;
    const response = await get(url);
    console.log("job response", response);
    if (response) {
      setCandidateDetails(response);
      const appreciate =
        response?.userData?.feedback?.filter(
          (itm) => itm?.type === "Appreciation"
        ) || [];
      setAppreciationNotes(appreciate);
      const dbs = response?.userData?.userTrainings?.filter(
        (itm) => itm?.trainingTitle === "DBS_CERTIFICATE"
      );
      setIsDbsCertificateAvailable(!!(dbs?.length > 0));
    }
    setLoading(false);
  };
  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
    const isUserLogin = async () => {
      const url = `/user/users/isLogin`;
      try {
        const response = await get(url);
        if (response) {
          setAuth({
            ...auth,
            isAuthenticated: !!(response?.status == "true"),
          });
        }
      } catch (e) {
        // clearSession();
        console.log("===>", e);
        // if(e?.code === "ECONNABORTED")
        // navigate("/login");
      }
    };
    isUserLogin();
    getUserDetails();
    getCandidateDetails();
    getMessageCount();
  }, []);
  const getMessageCount = async () => {
    const url = `/user/stats/count`;
    try {
      const response = await get(url);
      setUnreadMessages(response?.unreadMsg);
    } catch (e) {
      // console.error(e);
    }
  };
  return (
    <>
      <div className="page-wrapper">
        <Scripts />
        <Header />
        <section className="candidate-detail-section">
          {/* <!-- Upper Box --> */}
          <div className="upper-box">
            <div className="auto-container">
              {/* <!-- Candidate block Five --> */}
              <div className="candidate-block-five">
                <div className="inner-box">
                  <div className="content">
                    <figure className="image">
                      <img
                        src={
                          candidateDetails?.userData?.photo
                            ? candidateDetails?.userData?.photo
                            : candidateDefault
                        }
                        alt=""
                      />
                    </figure>
                    <h4 className="name">
                      <a href="#">
                        {candidateDetails?.userData?.firstName}{" "}
                        {candidateDetails?.userData?.lastName}
                      </a>
                    </h4>
                    <ul className="candidate-info">
                      <li className="designation">
                        {[
                          ...Array(
                            Math.round(
                              candidateDetails?.userData?.ratingTotal /
                                candidateDetails?.userData?.ratingCount
                            ) || 1
                          ),
                        ].map((e, i) => (
                          <i className="la la-star" key={i}></i>
                        ))}
                      </li>
                      <li>
                        <span className="icon flaticon-map-locator"></span>{" "}
                        {candidateDetails?.userData?.city},{" "}
                        {candidateDetails?.userData?.country}
                      </li>
                      <li>
                        <span className="icon flaticon-clock"></span> Member
                        Since,{" "}
                        {formatDateString(
                          candidateDetails?.userData?.startDate
                        )}
                      </li>
                    </ul>
                    <ul className="post-tags">
                      <li>
                        <a>{candidateDetails?.userData?.role}</a>
                      </li>
                    </ul>
                  </div>

                  <div className="btn-box">
                    <a
                      href={candidateDetails?.userData?.resume}
                      download
                      className="theme-btn btn-style-one"
                    >
                      Download CV
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="candidate-detail-outer">
            <div className="auto-container">
              <div className="row">
                <div className="content-column col-lg-8 col-md-12 col-sm-12">
                  <div className="job-detail">
                    <h4>Summary</h4>
                    <p>{candidateDetails?.userData?.userSkill?.summary}</p>

                    {/* <!-- Resume / Education -->/ */}
                    <div className="resume-outer">
                      <div className="upper-title">
                        <h4>Pre-Interview Question Responses</h4>
                      </div>
                      {/* <!-- Resume BLock --> */}
                      <div className="resume-block">
                        {candidateDetails?.userData?.userInterviews
                          ?.sort((a, b) => (a.qid > b.qid ? 1 : -1))
                          .map((item) => (
                            <div className="inner">
                              <span className="name">{item?.qid}</span>
                              <div className="title-box">
                                <div className="info-box">
                                  <h3>{item.questionDescription}</h3>
                                </div>
                              </div>
                              <div className="text">{item?.userResponse}</div>
                            </div>
                          ))}
                      </div>
                    </div>

                    {/* <!-- Resume / Work & Experience --> */}
                    <div className="resume-outer theme-blue">
                      <div className="upper-title">
                        <h4>Previous Jobs through Mployr</h4>
                      </div>
                      {/* <!-- Resume BLock --> */}
                      <div className="resume-block">
                        {candidateDetails?.jobs
                          ?.filter((item) => item?.status.includes("Approved"))
                          .map((item) => (
                            <div className="inner">
                              <span className="name">S</span>
                              <div className="title-box">
                                <div className="info-box">
                                  <h3>{item?.title}</h3>
                                  {/* <span>{item?.description}</span> */}
                                </div>
                                <div className="edit-box">
                                  <span className="year">
                                    {formatDateLabel(item?.startDate)} -{" "}
                                    {formatDateLabel(item?.endDate)}
                                  </span>
                                </div>
                              </div>
                              <div className="text">{item?.description}</div>
                            </div>
                          ))}
                      </div>
                    </div>

                    {/* <!-- Resume / Awards --> */}
                    <div className="resume-outer theme-yellow">
                      <div className="upper-title">
                        <h4>Appreciation Notes</h4>
                      </div>
                      {/* <!-- Resume BLock --> */}
                      {appreciationNotes?.map((feedback, index) => (
                        <div className="resume-block" key={feedback?.id}>
                          <div className="inner">
                            <span className="name">{Number(index) + 1}</span>
                            <div className="title-box">
                              <div className="info-box">
                                <h3>{feedback?.type}</h3>
                                <span>{feedback?.fromUserName}</span>
                              </div>
                              <div className="edit-box">
                                <span className="year">
                                  {formatDateString(feedback?.createdAt)}
                                </span>
                              </div>
                            </div>
                            <div className="text">{feedback?.description}</div>
                          </div>
                        </div>
                      ))}

                      {/** */}
                    </div>
                  </div>
                </div>

                <div className="sidebar-column col-lg-4 col-md-12 col-sm-12">
                  <aside className="sidebar">
                    <div className="sidebar-widget">
                      <div className="widget-content">
                        <ul className="job-overview">
                          <li>
                            <i className="icon icon-expiry"></i>
                            <h5>Experience:</h5>
                            <span>{candidateDetails?.userData?.exp} Years</span>
                          </li>

                          <li>
                            <i className="icon icon-clock"></i>
                            <h5>Preferred Shift:</h5>
                            <span style={{ wordWrap: "break-word" }}>
                              {
                                candidateDetails?.userData?.userShift
                                  ?.preferredShiftTime
                              }
                            </span>
                          </li>

                          <li>
                            <i className="icon icon-calendar"></i>
                            <h5>Preferred Days:</h5>
                            <span style={{ wordWrap: "break-word" }}>
                              {candidateDetails?.userData?.userShift?.preferredShiftDays?.split(
                                ","
                              )?.length === 7
                                ? "All"
                                : candidateDetails?.userData?.userShift
                                    ?.preferredShiftDays}
                            </span>
                          </li>

                          <li>
                            <i className="icon icon-user-2"></i>
                            <h5>Gender:</h5>
                            <span>{candidateDetails?.userData?.gender}</span>
                          </li>

                          <li>
                            <i className="icon icon-language"></i>
                            <h5>Language:</h5>
                            <span>
                              {candidateDetails?.userData?.userSkill?.languages}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="sidebar-widget">
                      {/* <!-- Compliance --> */}
                      <h4 className="widget-title">Compliance</h4>
                      <div className="widget-content">
                        <div className="table-outer">
                          <table className="default-table">
                            <tbody>
                              <tr>
                                <td>Training Certificates</td>
                                {candidateDetails?.userData?.userTrainings.filter(
                                  (itm) =>
                                    itm?.trainingTitle !== "DBS_CERTIFICATE"
                                )?.length > 0 ? (
                                  <td className="completed">
                                    <span className="la la-check white"></span>
                                  </td>
                                ) : (
                                  <td className="notcompleted">
                                    <span className="la la-times white"></span>
                                  </td>
                                )}
                              </tr>
                              <tr>
                                <td>DBS Certificate</td>
                                {isDbsCertificateAvailable ? (
                                  <td className="completed">
                                    <span className="la la-check white"></span>
                                  </td>
                                ) : (
                                  <td className="notcompleted">
                                    <span className="la la-times white"></span>
                                  </td>
                                )}
                              </tr>
                              <tr>
                                <td>Reference Checks</td>
                                {candidateDetails?.userData?.userReferences
                                  ?.length > 0 ? (
                                  <td className="completed">
                                    <span className="la la-check white"></span>
                                  </td>
                                ) : (
                                  <td className="notcompleted">
                                    <span className="la la-times white"></span>
                                  </td>
                                )}
                              </tr>
                              <tr>
                                <td>Photo ID Verification</td>
                                {candidateDetails?.userData?.photoIdProof ? (
                                  <td className="completed">
                                    <span className="la la-check white"></span>
                                  </td>
                                ) : (
                                  <td className="notcompleted">
                                    <span className="la la-times white"></span>
                                  </td>
                                )}
                              </tr>
                              <tr>
                                <td>Pre-interview questions</td>
                                {candidateDetails?.userData?.userInterviews
                                  ?.length > 4 ? (
                                  <td className="completed">
                                    <span className="la la-check white"></span>
                                  </td>
                                ) : (
                                  <td className="notcompleted">
                                    <span className="la la-times white"></span>
                                  </td>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="sidebar-widget">
                      {/* <!-- Job Skills --> */}
                      <h4 className="widget-title">Skills</h4>
                      <div className="widget-content">
                        <ul className="job-skills">
                          {candidateDetails?.userSkill?.skills}
                        </ul>
                      </div>
                    </div>

                    {/* <div className="sidebar-widget contact-widget">
                      <h4 className="widget-title">Contact Candidate</h4>
                      <div className="widget-content">
                        <div className="default-form">
                          <form>
                            <div className="row clearfix">
                              <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                <input
                                  type="text"
                                  name="username"
                                  placeholder="Your Name"
                                  required
                                />
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                <input
                                  type="email"
                                  name="email"
                                  placeholder="Email Address"
                                  required
                                />
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                <textarea
                                  className="darma"
                                  name="message"
                                  placeholder="Message"
                                ></textarea>
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                <button
                                  className="theme-btn btn-style-one"
                                  type="submit"
                                  name="submit-form"
                                >
                                  Send Message
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div> */}
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End candidate Detail Section --> */}
        <Footer />
      </div>
    </>
  );
};
export default CandidateFullProfile;
