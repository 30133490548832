import companyDefault from "../../../images/resource/company-6.png";
import { formatDateString, formatJobDate } from "../../../utils/date";
import JobTags from "./JobTags";

function JobListItem({ job, isLogin, classStyle }) {
  return (
    <div
      className={
        classStyle ? classStyle : "job-block col-lg-6 col-md-12 col-sm-12"
      }
    >
      <div className="inner-box">
        <div className="content">
          <span className="company-logo">
            <img
              src={job?.companyPhoto ? job?.companyPhoto : companyDefault}
              alt=""
            />
          </span>
          <h4>
            <a
              target="_blank"
              href={`/#/jobSingle?id=${job?.jobId}`}
              rel="noreferrer"
            >
              {job?.title}
            </a>
          </h4>
          <ul className="job-info">
            {/* <li><span className="icon flaticon-briefcase"></span> Segment</li> */}
            <li>
              <span className="icon flaticon-map-locator"></span>{" "}
              {job?.cityName}
            </li>
            <li>
              <span className="icon flaticon-clock-3"></span> Closing Date: {formatDateString(job?.endDate)}
            </li>
            <li>
              <span className="icon flaticon-money"></span> £{job?.rate} per
              hour
            </li>
          </ul>
          <JobTags endDate={job?.endDate} job={job}/>
          {isLogin && (
            <button className="bookmark-btn">
              <span className="flaticon-bookmark"></span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default JobListItem;
