import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Scripts from "../../Common/Scripts/Scripts";
import CandidateSidebar from "../Dashboard/CandidateSidebar";
import CareHomeSidebar from "../Dashboard/CareHomeSideBar";
import Footer from "../Dashboard/Footer";
import Header from "../Dashboard/Header";
import { getSession } from "../../../utils/session";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import { ROLE } from "../../../constant/role";
import { AuthContext } from "../../../context/auth";
import { del, get, put } from "../../../api";
import Error from "../../Common/Alert/Error";
import Success from "../../Common/Alert/Success";
import { formatDateString } from "../../../utils/date";
import Admin from "../Dashboard/Admin";

const Messages = () => {
  const [user, setUser] = useState();
  const [isLoading, setLoading] = useState(true);
  const [inboxMessages, setInboxMessages] = useState([]);
  const [myActionMessages, setMyActionMessages] = useState([]);
  const [sentMessages, setSentMessages] = useState([]);
  const [userDetail, setUserDetail] = useState();
  const [inboxApiStatus, setInboxApiStatus] = useState("");
  const [sentApiStatus, setSentApiStatus] = useState("");
  const [myActionApiStatus, setMyActionApiStatus] = useState("");
  const navigate = useNavigate();
  const { setUnreadMessages } = useContext(AuthContext);

  const navigateToLink = (link) => {
    console.log("Inside navigate" + link);
    navigate(link);
  };
  const callMethod = async () => {
    try {
      const url = `/user/users/detail`;
      const response = await get(url);
      setUserDetail(response);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    callMethod();
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
    getInBoxMessages();
    getSentMessages();
    getMyActionMessages();
  }, []);

  const getInBoxMessages = async () => {
    setLoading(true);
    const url = `/message/messages?message-section=INBOX`;
    try {
      const response = await get(url);
      if(response?.length > 0){
        setInboxMessages(response.sort((a,b) => new Date(b?.entryDate) - new Date(a?.entryDate)));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("error response", e);
    }
  };
  const getSentMessages = async () => {
    setLoading(true);
    const url = `/message/messages?message-section=SENT`;
    try {
      const response = await get(url);
      if(response?.length > 0){
        setSentMessages(response.sort((a,b) => new Date(b?.entryDate) - new Date(a?.entryDate)));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("error response", e);
    }
  };
  const getMyActionMessages = async () => {
    setLoading(true);
    const url = `/message/messages?message-section=ACTION_ITEMS`;
    try {
      const response = await get(url);
      if(response?.length > 0){
        setMyActionMessages(response.sort((a,b) => new Date(b?.entryDate) - new Date(a?.entryDate)));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("error response", e);
    }
  };
  const deleteMessage = async (id, messageType) => {
    setLoading(true);
    const url = `/message/messages?messageId=${id}`;
    setInboxApiStatus("");
    setSentApiStatus("");
    setMyActionApiStatus("");
    try {
      const response = await del(url);
      if (response?.status === 200 || response?.status === 204) {
        if (messageType === "inbox") {
          getInBoxMessages();
          setInboxApiStatus("success");
        } else if (messageType === "sent") {
          getSentMessages();
          setSentApiStatus("success");
        } else if (messageType === "myAction") {
          getMyActionMessages();
          setMyActionApiStatus("success");
        }
        getStats();
      } else {
        if (messageType === "inbox") {
          setInboxApiStatus("error");
        } else if (messageType === "sent") {
          setSentApiStatus("error");
        } else if (messageType === "myAction") {
          setMyActionApiStatus("error");
        }
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      if (messageType === "inbox") {
        setInboxApiStatus("error");
      } else if (messageType === "sent") {
        setSentApiStatus("error");
      } else if (messageType === "myAction") {
        setMyActionApiStatus("error");
      }
      console.log("error response", e);
    }
  };
  const unreadMessage = async (messagedId) => {
    try {
      const url = `/message/messages/read?isRead=true&messageIds=${messagedId}`;
      const response = await put(url);
      console.log(response?.status);
      getStats();
    } catch (e) {
      console.log(e);
    }
  };
  const getStats = async () => {
    const url = `/user/stats/count`;
    try {
      const response = await get(url);
      setUnreadMessages(response?.unreadMsg);
    } catch (e) {
      // console.error(e);
    }
  };
  const getReadClassForCandidate = (itm) => {
    let output = "messageRead";
    if (itm?.candidateIsRead === true) {
      output = "";
    }
    return output;
  };
  const getReadClassForCareHome = (itm) => {
    let output = "messageRead";
    if (itm?.isRead === true) {
      output = "";
    }
    return output;
  };
  return (
    <>
      <div className="page-wrapper dashboard ">
        <Scripts />

        {isLoading && <div className="preloader"></div>}
        {user?.role === ROLE.CANDIDATE && (
          <>
            <Header />
            <CandidateSidebar />
          </>
        )}
        {user?.role === ROLE.CARE_HOME && (
          <>
            <Header />
            <CareHomeSidebar />
          </>
        )}
        {user?.role === ROLE.ADMIN && <Admin />}
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="upper-title-box">
              <h3>Message Centre</h3>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Ls widget --> */}
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-title"></div>

                    <div className="widget-content">
                      <div className="default-tabs style-two tabs-box">
                        {/* <!--Tabs Box--> */}
                        <ul className="tab-buttons clearfix">
                          <li className="tab-btn active-btn" data-tab="#tab1">
                            <span className="la la-inbox"></span> Inbox
                          </li>
                          <li className="tab-btn" data-tab="#tab2">
                            <span className="la la-share"></span> Sent
                          </li>
                          <li className="tab-btn" data-tab="#tab3">
                            <span className="la la-tasks"></span> My Action List
                          </li>
                        </ul>

                        <div className="tabs-content">
                          {/* <!--Inbox--> */}
                          <div
                            className="tab active-tab animated fadeIn"
                            id="tab1"
                            style={{ display: "block" }}
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="notification-widget ls-widget">
                                  <div className="tabs-box">
                                    <div className="widget-title">
                                      <b>
                                        <span className="la la-inbox"></span>{" "}
                                        Received Messages
                                      </b>
                                    </div>
                                    {inboxApiStatus === "success" && (
                                      <>
                                        <Success msg="Your message has been successfully deleted.." />
                                      </>
                                    )}
                                    {inboxApiStatus === "error" && (
                                      <>
                                        <Error msg="Something went wrong while deleting message. Please try again" />
                                      </>
                                    )}

                                    <div className="widget-content">
                                      <div className="table-outer">
                                        <table className="default-table">
                                          <thead>
                                            <tr>
                                              <th>Action</th>
                                              <th>Message</th>
                                              <th>From</th>
                                              <th>Date</th>
                                              <th>For</th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {inboxMessages?.length === 0 && (
                                              <tr>
                                                <td>No message available</td>
                                              </tr>
                                            )}
                                            {inboxMessages?.map((item) => {
                                              return (
                                                <tr
                                                  key={item?.messageId}
                                                  className={
                                                    user?.role ===
                                                    ROLE.CANDIDATE
                                                      ? getReadClassForCandidate(
                                                          item
                                                        )
                                                      : getReadClassForCareHome(
                                                          item
                                                        )
                                                  }
                                                >
                                                  <td
                                                    style={{
                                                      paddingLeft: "0",
                                                    }}
                                                  >
                                                    <div className="option-box">
                                                      <ul className="option-list">
                                                        <li>
                                                          <a
                                                            data-text="View"
                                                            onClick={() => {
                                                              unreadMessage(
                                                                item?.messageId
                                                              );
                                                            }}
                                                            href={
                                                              "#" +
                                                              `${item.link}`
                                                            }
                                                          >
                                                            <span className="la la-eye"></span>
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            data-text="Delete"
                                                            className="text-danger"
                                                            onClick={(e) => {
                                                              e?.preventDefault();
                                                              e?.stopPropagation();
                                                              deleteMessage(
                                                                item?.messageId,
                                                                "inbox"
                                                              );
                                                            }}
                                                          >
                                                            <span className="la la-trash"></span>
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </td>
                                                  <td>{item?.message}</td>
                                                  <td>
                                                    {user?.role ===
                                                    ROLE.CANDIDATE
                                                      ? item?.companyName
                                                      : item?.fromFullName}
                                                  </td>
                                                  <td>
                                                    {formatDateString(
                                                      item?.entryDate
                                                    )}
                                                  </td>
                                                  <td>{item?.type}</td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <!--Sent--> */}
                          <div
                            className="tab"
                            id="tab2"
                            style={{ display: "none" }}
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="notification-widget ls-widget">
                                  <div className="tabs-box">
                                    <div className="widget-title">
                                      <b>
                                        <span className="la la-share"></span>{" "}
                                        Sent Messages
                                      </b>
                                    </div>
                                    {sentApiStatus === "success" && (
                                      <>
                                        <Success msg="Your message has been successfully deleted.." />
                                      </>
                                    )}
                                    {sentApiStatus === "error" && (
                                      <>
                                        <Error msg="Something went wrong while deleting message. Please try again" />
                                      </>
                                    )}

                                    <div className="widget-content">
                                      <div className="table-outer">
                                        <table className="default-table">
                                          <thead>
                                            <tr>
                                              <th>Action</th>
                                              <th>Message</th>
                                              <th>To</th>
                                              <th>Date</th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {sentMessages?.length === 0 && (
                                              <tr>
                                                <td>No message available</td>
                                              </tr>
                                            )}
                                            {sentMessages?.map((item) => {
                                              return (
                                                <tr
                                                  key={item?.messageId}
                                                  className={
                                                    user?.role ===
                                                    ROLE.CANDIDATE
                                                      ? getReadClassForCandidate(
                                                          item
                                                        )
                                                      : getReadClassForCareHome(
                                                          item
                                                        )
                                                  }
                                                >
                                                  <td
                                                    style={{
                                                      paddingLeft: "0",
                                                    }}
                                                  >
                                                    <div className="option-box">
                                                      <ul className="option-list">
                                                        <li>
                                                          <a
                                                            data-text="View"
                                                            href={
                                                              "#" +
                                                              `${item.link}`
                                                            }
                                                            onClick={() => {
                                                              unreadMessage(
                                                                item?.messageId
                                                              );
                                                            }}
                                                          >
                                                            <span className="la la-eye"></span>
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            data-text="Delete"
                                                            className="text-danger"
                                                            onClick={(e) => {
                                                              e?.preventDefault();
                                                              e?.stopPropagation();
                                                              deleteMessage(
                                                                item?.messageId,
                                                                "sent"
                                                              );
                                                            }}
                                                          >
                                                            <span className="la la-trash"></span>
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </td>
                                                  <td>{item?.message}</td>
                                                  <td>{item?.toFullName}</td>
                                                  <td>
                                                    {formatDateString(
                                                      item?.entryDate
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <!--Action List--> */}
                          <div
                            className="tab"
                            id="tab3"
                            style={{ display: "none" }}
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="notification-widget ls-widget">
                                  <div className="tabs-box">
                                    <div className="widget-title">
                                      <b>
                                        <span className="la la-tasks"></span>{" "}
                                        For My Action
                                      </b>
                                    </div>
                                    {myActionApiStatus === "success" && (
                                      <>
                                        <Success msg="Your message has been successfully deleted.." />
                                      </>
                                    )}
                                    {myActionApiStatus === "error" && (
                                      <>
                                        <Error msg="Something went wrong while deleting message. Please try again" />
                                      </>
                                    )}

                                    <div className="widget-content">
                                      <div className="table-outer">
                                        <table className="default-table">
                                          <thead>
                                            <tr>
                                              <th>Action</th>
                                              <th>Message</th>
                                              <th>From</th>
                                              <th>Date</th>
                                              <th>For</th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {myActionMessages?.length === 0 && (
                                              <tr>
                                                <td>No message available</td>
                                              </tr>
                                            )}
                                            {myActionMessages?.map((item) => {
                                              return (
                                                <tr
                                                  key={item?.messageId}
                                                  className={
                                                    user?.role ===
                                                    ROLE.CANDIDATE
                                                      ? getReadClassForCandidate(
                                                          item
                                                        )
                                                      : getReadClassForCareHome(
                                                          item
                                                        )
                                                  }
                                                >
                                                  <td
                                                    style={{
                                                      paddingLeft: "0",
                                                    }}
                                                  >
                                                    <div className="option-box">
                                                      <ul className="option-list">
                                                        <li>
                                                          <a
                                                            data-text="View"
                                                            href={
                                                              "#" +
                                                              `${item.link}`
                                                            }
                                                            onClick={() => {
                                                              unreadMessage(
                                                                item?.messageId
                                                              );
                                                            }}
                                                          >
                                                            <span className="la la-eye"></span>
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            data-text="Delete"
                                                            className="text-danger"
                                                            onClick={(e) => {
                                                              e?.preventDefault();
                                                              e?.stopPropagation();
                                                              deleteMessage(
                                                                item?.messageId,
                                                                "myAction"
                                                              );
                                                            }}
                                                          >
                                                            <span className="la la-trash"></span>
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </td>
                                                  <td>{item?.message}</td>
                                                  <td>
                                                    {user?.role ===
                                                    ROLE.CANDIDATE
                                                      ? item?.companyName
                                                      : item?.fromFullName}
                                                  </td>
                                                  <td>
                                                    {formatDateString(
                                                      item?.entryDate
                                                    )}
                                                  </td>
                                                  <td>{item?.type}</td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default Messages;
