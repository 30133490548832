/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import bannerImage from "./../../images/resource/banner-img-8.webp";
import { get, post } from "../../api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import CountDown from "./CountDown";

  const Banner = ({ homePageCount, userRoleTypes }) => {
  const [isLoading, setLoading] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [result, setShowResult] = useState(false);
  const [searchData, setSearchData] = useState({
    category: '',
    job_title: '',
    postcode: '',
  });
  const [searchParams] = useSearchParams();
  // const siteName = searchParams.get("siteName");
  // const isCareHome = searchParams.get("isCareHome");

  const { register, handleSubmit, reset, setValue } = useForm();
  const navigate = useNavigate();

  const handleSearchSubmit = async (data) => {
    console.log("data", data);
    navigate(`/jobList?name=${data.job_title}`);
    // setSearchData(data);
    // setCurrentPage(0);
    // setLoading(true);
    // const url = "/job/jobs/search";
    // let payload = {
    //   page: currentPage,
    // };
    // if (siteName && isCareHome) {
    //   payload.site = siteName;
    //   payload.careHome = isCareHome;
    // } else {
    //   payload.name = data?.job_title;
    //   payload.site = data?.postcode;
    // }
    // try {
    //   const response = await post(url, payload);
    //   console.log(response);
    //   if (response?.status === 200) {
    //     setSearchList(response?.data);
    //     console.log('search res', searchList)
    //   }
    //   setLoading(false);
    // } catch (e) {
    //   setLoading(false);
    // }
  };

  const getJobs = async () => {
    const url = "/job/jobs/search";
    let payload = {
      name: '',
    };
    const response = await post(url,payload);
    if (response) {
      console.log('job list', response);
      setSearchList(response)    }
  };
  useEffect(() => {
    // handleSearchSubmit(
    //   {
    //     job_title: "",
    //   },
    //   true
    // );
    getJobs();
  }, []);
  return (
    // <!-- Banner Section-->
    <section className="banner-section-seven">
      <div className="image-outer">
        <figure className="image">
          <img src={bannerImage} alt="" />
        </figure>
      </div>
      <div className="auto-container">
        <div className="row">
          <div className="content-column col-lg-7 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="title-box wow fadeInUp" data-wow-delay="500ms">
                <h3>
                  There Are <span className="colored">{homePageCount?.totalJobs}</span> <br />
                  Postings Here For you!
                </h3>
                <div className="text">
                  Find Jobs, Employment & Career Opportunities
                </div>
              </div>

              {/* <!-- Job Search Form --> */}
              <div
                className="job-search-form wow fadeInUp animated"
                data-wow-delay="1000ms"
              >
                <form onSubmit={handleSubmit(handleSearchSubmit)}>
                  <div className="row">
                    <div className="form-group col-lg-5 col-md-12 col-sm-12">
                      <span className="icon flaticon-search-1"></span>
                      <input
                        type="text"
                        name="job_title"
                        placeholder="Job title or company"
                        {...register("job_title")}
                      />
                    </div>
                    {/* <!-- Form Group --> */}
                    <div className="form-group col-lg-4 col-md-12 col-sm-12 location">
                      <span className="icon flaticon-map-locator"></span>
                      <input
                        type="text"
                        name="postcode"
                        placeholder="City or postcode"
                        {...register("postcode")}
                      />
                    </div>
                    {/* <!-- Form Group --> */}
                    <div className="form-group col-lg-3 col-md-12 col-sm-12 btn-box">
                      <button type="submit" className="theme-btn btn-style-one" onClick={() => setShowResult(true)}>
                        <span className="btn-title">Find Jobs</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {/* <!-- Job Search Form --> */}

              {/* <!-- Popular Search --> */}
              <div
                className="popular-searches wow fadeInUp"
                data-wow-delay="1500ms"
              >
                <span className="title">Popular Searches : </span>
                {userRoleTypes?.map((userType) => {
                  return (
                    <a href="#" key={userType?.userTypeId}>{userType.description}, </a>
                  )
                })                
                }</div>
              {/* <!-- End Popular Search --> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
