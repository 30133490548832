import Scripts from "../../Common/Scripts/Scripts";
import { Fragment, useContext, useEffect, useState } from "react";
import Header from "../Dashboard/Header";
import Footer from "../../Common/Footer";
import { FormProvider, useForm } from "react-hook-form";
import { get, post, put } from "../../../api";
import { useSearchParams } from "react-router-dom";
import { AuthContext } from "../../../context/auth";
import Success from "../../Common/Alert/Success";
import Error from "../../Common/Alert/Error";
import companyDefault from "../../../images/resource/company-6.png";
import $ from "jquery";
import JobTags from "../Common/JobTags";
import { formatDateString } from "../../../utils/date";
import Map from "../Map/Map";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Loading from "../../Common/Loading";

const render = (status) => {
  if (status === Status.LOADING)
    return (
      <h3>
        <Loading />
      </h3>
    );
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return <></>;
};

const JobList = () => {
  const { register, handleSubmit, reset, setValue, getValues } = useForm();
  const [currentPage, setCurrentPage] = useState(0);
  const [mapView, setMapView] = useState(false);
  const [role, setRole] = useState([]);
  const [saveJobApiMsg, setSaveJobApiMsg] = useState("");
  const [searchData, setSearchData] = useState({
    category: "",
    job_title: "",
    postcode: "",
  });
  const [searchParams] = useSearchParams();
  const siteId = searchParams.get("siteId");
  const companyId = searchParams.get("companyId");
  const name = searchParams.get("name") || "";
  const [isLoading, setLoading] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const { setFavourites, setProfilePic } = useContext(AuthContext);
  const getUserDetails = async () => {
    const url = `/user/users/detail`;
    const response = await get(url);
    if (response) {
      setFavourites(response?.favourites?.length);
      setProfilePic(response?.photo);
    }
  };
  const searchWithPagination = async (page) => {
    let url = "/job/jobs/search";
    const pageSize = getValues("pageSize") ? Number(getValues("pageSize")) : "";
    if (pageSize) {
      url = `/job/jobs/search?pageSize=${pageSize}&page=${
        page ? page : currentPage
      }`;
    }
    setLoading(true);
    try {
      const response = await post(
        url,
        refactorPayload({
          ...searchData,
        })
      );
      if (response?.status === 200) {
        setSearchList(response?.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const handleSearchSubmit = async (data) => {
    console.log("category", data?.category);
    setSearchData(data);
    setCurrentPage(0);
    setLoading(true);
    let url = "/job/jobs/search";
    const pageSize = getValues("pageSize") ? Number(getValues("pageSize")) : "";
    if (pageSize) {
      url = `/job/jobs/search?pageSize=${pageSize}&page=${currentPage}`;
    }
    const roleFilter = data?.roleFilter ? data?.roleFilter : [];
    let jobTypeFilter = data?.jobTypeFilter ? data?.jobTypeFilter : [];
    if (jobTypeFilter?.length === 0 && data?.category?.length > 0) {
      jobTypeFilter = [data?.category];
    }
    const datePostedFilter = data?.datePostedFilter
      ? data?.datePostedFilter
      : 0;
    const experienceLevelFilter = data?.experienceLevelFilter
      ? data?.experienceLevelFilter?.map((itm) => Number(itm))
      : [];
    let payload = refactorPayload({
      page: currentPage,
      nvqLevel: experienceLevelFilter,
      datePosted: Number(datePostedFilter),
      jobType: jobTypeFilter,
      jobTypeId: roleFilter,
      salaryStart: $(".min").text() ? Number($(".min").text()) : 0,
      salaryEnd: $(".max").text() ? Number($(".max").text()) : 0,
    });
    if (siteId && companyId) {
      payload.siteId = Number(siteId);
      payload.companyId = Number(companyId);
    } else {
      payload.name = data?.job_title || name;
      payload.site = data?.postcode;
    }
    try {
      const response = await post(url, payload);
      if (response?.status === 200) {
        setSearchList(response?.data);
      }
      setLoading(false);
    } catch (e) {
      setSearchList([]);
      setLoading(false);
    }
  };
  const refactorPayload = (payload) => {
    let data = payload;
    if (data?.datePosted === 0) {
      delete data?.datePosted;
    }
    if (data?.jobType?.length === 0) {
      delete data?.jobType;
    }
    if (data?.jobTypeId === false || data?.jobTypeId?.length === 0) {
      delete data?.jobTypeId;
    }
    if (data?.nvqLevel?.length === 0) {
      delete data?.nvqLevel;
    }
    if (data?.salaryEnd === 0) {
      delete data?.salaryEnd;
      delete data?.salaryStart;
    }
    if (data?.experienceLevelFilter) {
      delete data?.experienceLevelFilter;
    }
    if (data?.jobTypeFilter) {
      delete data?.jobTypeFilter;
    }
    return data;
  };
  useEffect(() => {
    getRoleTypes();
    handleSearchSubmit(
      {
        job_title: "",
      },
      true
    );
  }, []);
  const saveJob = async (job) => {
    const url = `/job/application/favourite`;
    setLoading(true);
    setSaveJobApiMsg("");
    const payload = {
      shiftId: job?.jobId,
    };
    try {
      const response = await put(url, payload);
      if (
        response?.status === 200 ||
        response?.status === 202 ||
        response?.status === 204
      ) {
        getUserDetails();
        setSaveJobApiMsg("success");
      } else {
        setSaveJobApiMsg("error");
        console.error("something went wrong while saving favourite");
      }
      setLoading(false);
    } catch (e) {
      setSaveJobApiMsg("error");
      setLoading(false);
    }
  };
  const getRoleTypes = async () => {
    const url = `/user/users/type`;
    try {
      const response = await get(url);
      if (response) {
        setRole(response);
      }
    } catch (e) {
      console.error("===>", e);
    }
  };
  const searchFilters = async (param) => {
    setLoading(true);
    setTimeout(async () => {
      const roleFilter = getValues("roleFilter");
      const jobTypeFilter = getValues("jobTypeFilter");
      const datePostedFilter = getValues("datePostedFilter");
      const experienceLevelFilter = getValues("experienceLevelFilter")
        ? getValues("experienceLevelFilter")?.map((itm) => Number(itm))
        : [];
      let url = "/job/jobs/search";
      /* page size */
      let pageSize = getValues("pageSize")
        ? Number(getValues("pageSize"))
        : "";
      if (param?.page) {
        pageSize = Number(param?.page);
      }
      /* page size end */
      if (pageSize) {
        url = `/job/jobs/search?pageSize=${pageSize}&page=${currentPage}`;
      }
      let payload = {};
      if (siteId && companyId) {
        payload.siteId = Number(siteId);
        payload.companyId = Number(companyId);
      }
      payload = {
        ...searchData,
        page: currentPage,
        nvqLevel: experienceLevelFilter,
        datePosted: Number(datePostedFilter),
        jobType: jobTypeFilter ? jobTypeFilter : [],
        jobTypeId: roleFilter,
        salaryStart: $(".min").text() ? Number($(".min").text()) : 0,
        salaryEnd: $(".max").text() ? Number($(".max").text()) : 0,
      };
      setSearchData(payload); // updating searchdtaa state for pagination capture
      try {
        const response = await post(url, refactorPayload(payload));
        if (response?.status === 200) {
          setSearchList(response?.data);
        }
        setLoading(false);
      } catch (e) {
        setSearchList([]);
        setLoading(false);
      }
    }, 1000);
  };
  return (
    <Fragment>
      <div className="page-wrapper">
        <Scripts />
        <Header />
        {isLoading && <div className="preloader"></div>}

        {/* <!--Page Title--> */}
        <section className="page-title style-two">
          <div className="auto-container">
            {/* <!-- <div className="title-outer">
                <h1>Find Jobs</h1>
                <ul className="page-breadcrumb">
                    <li><a href="/">Home</a></li>
                    <li>Jobs</li>
                </ul> 
            </div> --> */}

            {/* <!-- Job Search Form --> */}
            <div className="job-search-form">
              {/* <FormProvider {...methods}> */}
              <form onSubmit={handleSubmit(handleSearchSubmit)}>
                <div className="row">
                  {/* <!-- Form Group --> */}
                  <div className="form-group col-lg-4 col-md-12 col-sm-12">
                    <span className="icon flaticon-search-1"></span>
                    <input
                      type="text"
                      name="job_title"
                      placeholder="Job title or company"
                      {...register("job_title")}
                    />
                  </div>

                  {/* <!-- Form Group --> */}
                  <div className="form-group col-lg-3 col-md-12 col-sm-12 location">
                    <span className="icon flaticon-map-locator"></span>
                    <input
                      type="text"
                      name="postcode"
                      placeholder="City or postcode"
                      {...register("postcode")}
                    />
                  </div>

                  {/* <!-- Form Group --> */}
                  <div className="form-group col-lg-3 col-md-12 col-sm-12 location">
                    <span className="icon flaticon-briefcase"></span>
                    <select
                      className="chosen-select-cat"
                      {...register("category")}
                    >
                      <option value="" selected>
                        All Categories
                      </option>

                      {role?.map((itm) => {
                        return (
                          <option value={itm?.userTypeId} key={itm?.userTypeId}>
                            {itm?.description}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {/* <!-- Form Group --> */}
                  <div className="form-group col-lg-2 col-md-12 col-sm-12 text-right">
                    <button type="submit" className="theme-btn btn-style-one">
                      Find Jobs
                    </button>
                  </div>
                </div>
              </form>
              {/* </FormProvider> */}
            </div>
            {/* <!-- Job Search Form --> */}
          </div>
        </section>
        {/* <!--End Page Title--> */}

        {/* <!-- Listing Section --> */}
        <section className="ls-section">
          <div className="auto-container">
            <div className="filters-backdrop"></div>

            <div className="row">
              {/* <!-- Filters Column --> */}
              <div className="filters-column col-lg-4 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="filters-outer">
                    <button type="button" className="theme-btn close-filters">
                      X
                    </button>

                    {/* <!-- Switchbox Outer --> */}
                    <div className="switchbox-outer">
                      <h4>Role</h4>
                      <ul className="switchbox">
                        {role?.map((itm) => (
                          <li key={itm?.userTypeId}>
                            <label className="switch">
                              <input
                                type="checkbox"
                                value={itm?.userTypeId}
                                name="roleFilter"
                                {...register("roleFilter")}
                                onClick={(e) => {
                                  e?.stopPropagation();
                                  searchFilters();
                                }}
                              />
                              <span className="slider round"></span>
                              <span className="title">{itm?.description}</span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* <!-- Switchbox Outer --> */}
                    <div className="switchbox-outer">
                      <h4>Job type</h4>
                      <ul className="switchbox">
                        <li>
                          <label className="switch">
                            <input
                              type="checkbox"
                              value={"Full_Time"}
                              name="jobTypeFilter"
                              {...register("jobTypeFilter")}
                              onClick={(e) => {
                                e?.stopPropagation();
                                searchFilters();
                              }}
                            />
                            <span className="slider round"></span>
                            <span className="title">Full Time</span>
                          </label>
                        </li>
                        <li>
                          <label className="switch">
                            <input
                              type="checkbox"
                              value={"Internship"}
                              name="jobTypeFilter"
                              {...register("jobTypeFilter")}
                              onClick={(e) => {
                                e?.stopPropagation();
                                searchFilters();
                              }}
                            />
                            <span className="slider round"></span>
                            <span className="title">Internship</span>
                          </label>
                        </li>
                        <li>
                          <label className="switch">
                            <input
                              type="checkbox"
                              value={"Part_Time"}
                              name="jobTypeFilter"
                              {...register("jobTypeFilter")}
                              onClick={(e) => {
                                e?.stopPropagation();
                                searchFilters();
                              }}
                            />
                            <span className="slider round"></span>
                            <span className="title">Part Time</span>
                          </label>
                        </li>
                        <li>
                          <label className="switch">
                            <input
                              type="checkbox"
                              value={"Apprenticeship"}
                              name="jobTypeFilter"
                              {...register("jobTypeFilter")}
                              onClick={(e) => {
                                e?.stopPropagation();
                                searchFilters();
                              }}
                            />
                            <span className="slider round"></span>
                            <span className="title">Apprenticeship</span>
                          </label>
                        </li>
                      </ul>
                    </div>

                    {/* <div className="filter-block">
                  <h4>Location</h4>
                  <div className="form-group">
                    <input type="text" name="listing-search" placeholder="City or postcode"/>
                    <span className="icon flaticon-map-locator"></span>
                  </div>
                  <p>Radius around selected destination</p>
                  <div className="range-slider-one">
                    <div className="area-range-slider ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content"><div className="ui-slider-range ui-corner-all ui-widget-header" style={{left: '0%', width: '50%'}}></div><span tabindex="0" className="ui-slider-handle ui-corner-all ui-state-default" style={{left: '0%'}}></span><span tabindex="0" className="ui-slider-handle ui-corner-all ui-state-default" style={{left:'50%'}}></span></div>
                    <div className="input-outer">
                      <div className="amount-outer"><span className="area-amount">50</span>km</div>
                    </div>
                  </div>
                </div> */}

                    {/* <!-- Checkboxes Ouer --> */}
                    <div className="checkbox-outer">
                      <h4>Date Posted</h4>
                      <ul className="checkboxes">
                        <li>
                          <input
                            id="check-f"
                            type="radio"
                            value={0}
                            name="datePostedFilter"
                            {...register("datePostedFilter")}
                            onClick={(e) => {
                              e?.stopPropagation();
                              searchFilters();
                            }}
                          />
                          <label for="check-f">All</label>
                        </li>
                        <li>
                          <input
                            id="check-a"
                            type="radio"
                            value={60}
                            name="datePostedFilter"
                            {...register("datePostedFilter")}
                            onClick={(e) => {
                              e?.stopPropagation();
                              searchFilters();
                            }}
                          />
                          <label for="check-a">Last Hour</label>
                        </li>
                        <li>
                          <input
                            id="check-b"
                            type="radio"
                            value={1440}
                            name="datePostedFilter"
                            {...register("datePostedFilter")}
                            onClick={(e) => {
                              e?.stopPropagation();
                              searchFilters();
                            }}
                          />
                          <label for="check-b">Last 24 Hours</label>
                        </li>
                        <li>
                          <input
                            id="check-c"
                            type="radio"
                            value={10080}
                            name="datePostedFilter"
                            {...register("datePostedFilter")}
                            onClick={(e) => {
                              e?.stopPropagation();
                              searchFilters();
                            }}
                          />
                          <label for="check-c">Last 7 Days</label>
                        </li>
                        <li>
                          <input
                            id="check-d"
                            type="radio"
                            value={20160}
                            name="datePostedFilter"
                            {...register("datePostedFilter")}
                            onClick={(e) => {
                              e?.stopPropagation();
                              searchFilters();
                            }}
                          />
                          <label for="check-d">Last 14 Days</label>
                        </li>
                        <li>
                          <input
                            id="check-e"
                            type="radio"
                            value={43200}
                            name="datePostedFilter"
                            {...register("datePostedFilter")}
                            onClick={(e) => {
                              e?.stopPropagation();
                              searchFilters();
                            }}
                          />
                          <label for="check-e">Last 30 Days</label>
                        </li>
                      </ul>
                    </div>

                    {/* <!-- Checkboxes Ouer --> */}
                    <div className="checkbox-outer">
                      <h4>NVQ Level</h4>
                      <ul className="checkboxes square">
                        <li>
                          <input
                            id="check-ba"
                            type="checkbox"
                            value={1}
                            name="experienceLevelFilter"
                            {...register("experienceLevelFilter")}
                            onClick={(e) => {
                              e?.stopPropagation();
                              searchFilters();
                            }}
                          />
                          <label for="check-ba">NVQ Level 1</label>
                        </li>
                        <li>
                          <input
                            id="check-bb"
                            type="checkbox"
                            value={2}
                            name="experienceLevelFilter"
                            {...register("experienceLevelFilter")}
                            onClick={(e) => {
                              e?.stopPropagation();
                              searchFilters();
                            }}
                          />
                          <label for="check-bb">NVQ Level 2</label>
                        </li>
                        <li>
                          <input
                            id="check-bc"
                            type="checkbox"
                            value={3}
                            name="experienceLevelFilter"
                            {...register("experienceLevelFilter")}
                            onClick={(e) => {
                              e?.stopPropagation();
                              searchFilters();
                            }}
                          />
                          <label for="check-bc">NVQ Level 3</label>
                        </li>
                        <li>
                          <input
                            id="check-bd"
                            type="checkbox"
                            value={4}
                            name="experienceLevelFilter"
                            {...register("experienceLevelFilter")}
                            onClick={(e) => {
                              e?.stopPropagation();
                              searchFilters();
                            }}
                          />
                          <label for="check-bd">NVQ Level 4</label>
                        </li>
                        <li>
                          <input
                            id="check-be"
                            type="checkbox"
                            value={5}
                            name="experienceLevelFilter"
                            {...register("experienceLevelFilter")}
                            onClick={(e) => {
                              e?.stopPropagation();
                              searchFilters();
                            }}
                          />
                          <label for="check-be">NVQ Level 5</label>
                        </li>
                      </ul>
                    </div>

                    {/* <!-- Filter Block --> */}
                    <div className="filter-block">
                      <h4>Salary per hour</h4>

                      <div className="range-slider-one salary-range">
                        <div className="salary-range-slider"></div>
                        <div className="input-outer">
                          <div className="amount-outer">
                            <span className="amount salary-amount">
                              £<span className="min">0</span> - £
                              <span className="max">0</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- Filter Block --> */}
                    {/* <div className="filter-block">
                      <h4>Tags</h4>
                      <ul className="tags-style-one">
                        <li>
                          <a href="#">app</a>
                        </li>
                        <li>
                          <a href="#">administrative</a>
                        </li>
                        <li>
                          <a href="#">android</a>
                        </li>
                        <li>
                          <a href="#">wordpress</a>
                        </li>
                        <li>
                          <a href="#">design</a>
                        </li>
                        <li>
                          <a href="#">react</a>
                        </li>
                      </ul>
                    </div> */}
                  </div>

                  {/* <!-- End Call To Action --> */}
                </div>
              </div>

              {/* <!-- Content Column --> */}
              <div className="content-column col-lg-8 col-md-12 col-sm-12">
                <div className="ls-outer">
                  <button
                    type="button"
                    className="theme-btn btn-style-two toggle-filters"
                  >
                    Show Filters
                  </button>

                  {/* <!-- ls Switcher --> */}
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <button
                        className="btn btn-primary"
                        onClick={() => setMapView(!mapView)}
                      >
                        Toggle Map View
                      </button>
                    </div>
                  </div>
                  {mapView && (
                    <div className="row mb-4">
                      <div className="col-md-12">
                        <Wrapper
                          apiKey={process.env.REACT_APP_GOOGLE_KEY}
                          render={render}
                        >
                          <Map
                            isJobList={true}
                            JobList={searchList}
                            center={{ lat: 50.736129, lng: -1.988229 }}
                            zoom={5}
                          />
                        </Wrapper>
                      </div>
                    </div>
                  )}
                  <div className="ls-switcher">
                    <div className="showing-result">
                      <div className="text">
                        Showing <strong>{searchList?.length}</strong> jobs
                      </div>
                    </div>
                    <div className="sort-by">
                      <select
                        className="chosen-select-dropdown"
                        {...register("pageSize")}
                        onChange={(e) => {
                          searchFilters({page: e?.target?.value});
                        }}
                      >
                        <option selected value={10}>
                          Show 10
                        </option>
                        <option value={20}>Show 20</option>
                        <option value={30}>Show 30</option>
                        <option value={40}>Show 40</option>
                        <option value={50}>Show 50</option>
                        <option value={60}>Show 60</option>
                      </select>
                    </div>
                  </div>

                  {/* <!-- Job Block --> */}
                  {searchList?.length === 0 && (
                    <div className="job-block">
                      <div className="inner-box">
                        <div className="content">Please find job.</div>
                      </div>
                    </div>
                  )}
                  {saveJobApiMsg === "success" && (
                    <>
                      <Success msg="You have successfully saved job." />
                    </>
                  )}
                  {saveJobApiMsg === "error" && (
                    <>
                      <Error msg="Something went wrong while saving job. please try again." />
                    </>
                  )}
                  {searchList?.map((job) => (
                    <div className="job-block" key={job?.jobId}>
                      <div className="inner-box">
                        <div className="content">
                          <span className="company-logo">
                            <img
                              src={
                                job?.companyPhoto
                                  ? job?.companyPhoto
                                  : companyDefault
                              }
                              alt=""
                            />
                          </span>
                          <h4>
                            <a
                              target="_blank"
                              href={`/#/jobSingle?id=${job?.jobId}`}
                            >
                              {job?.title}
                            </a>
                          </h4>
                          <ul className="job-info">
                            <li>
                              <span className="icon flaticon-home"></span>{" "}
                              {job?.companyName}
                            </li>
                            {/* <li>
                              <span className="icon flaticon-briefcase"></span>{" "}
                              Segment
                            </li> */}
                            <li>
                              <span className="icon flaticon-map-locator"></span>{" "}
                              {job?.cityName}
                            </li>
                            <li>
                              <span className="icon flaticon-clock-3"></span>{" "}
                              Closing Date: {formatDateString(job?.endDate)}
                            </li>
                            <li>
                              <span className="icon flaticon-money"></span> £
                              {job?.rate}/hr
                            </li>
                          </ul>
                          <JobTags endDate={job?.endDate} job={job} />
                          <button
                            className="bookmark-btn"
                            onClick={(e) => {
                              e?.stopPropagation();
                              saveJob(job);
                            }}
                          >
                            <span className="flaticon-bookmark"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* <!-- Pagination --> */}
                  <nav
                    className="ls-pagination"
                    style={{ display: searchList?.length === 0 ? "none" : "" }}
                  >
                    <ul>
                      <li className="prev">
                        <button
                          disabled={currentPage === 0}
                          onClick={async (e) => {
                            e?.stopPropagation();
                            searchWithPagination(currentPage - 1);
                            setCurrentPage(currentPage - 1);
                          }}
                        >
                          <i className="fa fa-arrow-left"></i>
                        </button>
                      </li>
                      <li>
                        <button href="#" className="current-page">
                          {currentPage + 1}
                        </button>
                      </li>
                      <li className="next">
                        <button
                          onClick={async (e) => {
                            e?.stopPropagation();
                            searchWithPagination(currentPage + 1);
                            setCurrentPage(currentPage + 1);
                          }}
                        >
                          <i className="fa fa-arrow-right"></i>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--End Listing Page Section --> */}

        {/* <!-- Main Footer --> */}
        <Footer />
        {/* <!-- End Main Footer --> */}
      </div>
    </Fragment>
  );
};
export default JobList;
