import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import logo from "./../../images/logo-2.svg";
import logo1 from "./../../images/logo.svg";
import Scripts from "../Common/Scripts/Scripts";
import { InputError } from "../Common/FormControl/Input";
import Loading from "../Common/Loading";
import { put } from "../../api";

function ForgotPassword() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });
  const [isLoading, setLoading] = useState(false);
  const [apiMsg, setApiMsg] = useState("");
  const onSubmitForgotPassword = async (data) => {
    console.log("data", data);
    setLoading(true);
    const payload = {
      emailSend: true,
      email: data?.email,
    };
    const url = `/user/users/forgot-password`;
    try {
      const response = await put(url, payload);
      console.log("response", response);
      if (
        response?.status === 200 ||
        response?.status === 202 ||
        response?.status === 204
      ) {
        console.log("applied", response);
        setApiMsg("success");
        reset({
          email: "",
        });
      } else {
        setApiMsg("error");
      }
      setLoading(false);
    } catch (e) {
      setApiMsg("error");
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <Scripts />
      <div className="page-wrapper">
        <header className="main-header">
          <div className="container-fluid">
            <div className="main-box">
              <div className="nav-outer">
                <div className="logo-box">
                  <div className="logo">
                    <a href="index.html">
                      <img src={logo1} alt="" title="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="outer-box">
                <div className="btn-box">
                  <a href="/#/login" className="theme-btn btn-style-three">
                    <span className="btn-title">Login</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="mobile-header">
            <div className="logo">
              <a href="index.html">
                <img src={logo1} alt="" title="" />
              </a>
            </div>
          </div>

          <div id="nav-mobile"></div>
        </header>

        <div className="login-section">
          <div className="image-layer"></div>
          <div className="outer-box">
            <div className="login-form default-form">
              <div className="form-inner">
                <h3>Forgot Password?</h3>
                {apiMsg === "success" && (
                  <div className="message-box success">
                    <p>
                      We have sent you a verification link so you can change
                      password. Please check your inbox.
                    </p>
                    <button className="close-btn">
                      <span className="close_icon"></span>
                    </button>
                  </div>
                )}
                {apiMsg === "error" && (
                  <div className="message-box error">
                    <p>If the user exists in our portal, a reset password link would be sent to the registered email address. Please check your inbox.</p>
                    <button className="close-btn">
                      <span className="close_icon"></span>
                    </button>
                  </div>
                )}

                <form
                  onSubmit={handleSubmit(onSubmitForgotPassword)}
                  noValidate
                >
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      {...register("email", {
                        required: {
                          value: true,
                          message: "required",
                        },
                      })}
                    />
                    {errors?.email && (
                      <InputError
                        message={errors?.email?.message}
                        key={errors?.email?.message}
                      />
                    )}
                  </div>

                  <div className="form-group">
                    {isLoading && <Loading />}
                    {!isLoading && (
                      <button
                        className="theme-btn btn-style-one"
                        type="submit"
                        name="submit"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ForgotPassword;
