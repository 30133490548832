import { Fragment, useEffect, useState } from "react";
import Header from "../Dashboard/Header";
import Scripts from "../../Common/Scripts/Scripts";
import CareHomeSidebar from "../Dashboard/CareHomeSideBar";
import CandidateSidebar from "../Dashboard/CandidateSidebar";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import { ROLE } from "../../../constant/role";
import { getSession } from "../../../utils/session";

import CareHomeReports from "./CareHomeReports";
import CandidateReports from "./CandidateReports";
import AdminReportCharts from "./AdminReports";
import Admin from "../Dashboard/Admin";

const Reports = () => {
  const [user, setUser] = useState();
  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
  }, []);
  return (
    <Fragment>
      <div className="page-wrapper dashboard">
        {/* <Scripts /> */}

        {user?.role === ROLE.CANDIDATE && (
          <>
            <Header />
            <CandidateSidebar />
          </>
        )}
        {user?.role === ROLE.CARE_HOME && (
          <>
            <Header />
            <CareHomeSidebar />
          </>
        )}
        {user?.role === ROLE.ADMIN && <Admin />}
        {/* <!-- Dashboard --> */}
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="upper-title-box">
              <h3>Reports</h3>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Ls widget --> */}
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-title"></div>

                    <div className="widget-content">
                      <div className="default-tabs style-two tabs-box">
                        {/* <!--Tabs Box--> */}
                        <ul className="tab-buttons clearfix">
                          {user?.role === ROLE.ADMIN && (
                            <li className="tab-btn active-btn" data-tab="#tab1">
                              <span className="la la-user-shield"></span> Admin
                            </li>
                          )}
                          {user?.role === ROLE.CARE_HOME && (
                            <li className="tab-btn active-btn" data-tab="#tab2">
                              <span className="la la-building"></span> Carehome
                            </li>
                          )}
                          {user?.role === ROLE.CANDIDATE && (
                            <li className="tab-btn active-btn" data-tab="#tab3">
                              <span className="la la-user"></span> Candidate
                            </li>
                          )}
                        </ul>

                        <div className="tabs-content">
                          {/* <!--Admin--> */}
                          <div
                            className="tab active-tab animated fadeIn"
                            id="tab1"
                            style={{
                              display:
                                user?.role === ROLE.ADMIN ? "block" : "none",
                            }}
                          >
                            <AdminReportCharts />
                          </div>

                          {/* <!--Carehome--> */}
                          <div
                            className="tab active-tab animated fadeIn"
                            id="tab2"
                            style={{
                              display:
                                user?.role === ROLE.CARE_HOME
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <CareHomeReports />
                          </div>

                          {/* <!--Candidate--> */}
                          <div
                            className="tab active-tab animated fadeIn"
                            id="tab3"
                            style={{
                              display:
                                user?.role === ROLE.CANDIDATE
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <CandidateReports />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};
export default Reports;
