import { useLocation, useNavigate } from "react-router-dom";
import logo from "./../../../images/logo-2.svg";
import company from "./../../../images/resource/company-6.png";
import company6 from "../../../images/resource/company-6.png";
import { AuthContext } from "../../../context/auth";
import { useContext, useEffect, useState } from "react";
import { get, post } from "../../../api";
import Navbar from "../Common/Navbar";
import { clearSession, getSession } from "../../../utils/session";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import { ROLE } from "../../../constant/role";
import Notifications from "../../Common/Notifications";

function Header() {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [isLogin, setIsLogin] = useState(false);
  const location = useLocation();

  const isActiveNav = (nav) => {
    return location.pathname.indexOf(nav) !== -1 ? "active" : "";
  };

  const logout = async (e) => {
    e?.preventDefault();
    try {
      const url = "user/users/logout";
      const response = await post(url, {});
      if (response?.status === 200) {
        // clearSession();
        navigate("/login");
      }
    } catch (e) {
      if (e?.response?.data?.status === 498) {
        clearSession();
        navigate("/login");
      }
    }
  };
  const isUserLogin = async () => {
    const url = `/user/users/isLogin`;
    try {
      const response = await get(url);
      console.log("response", response);
      if (response) {
        setIsLogin(!!(response?.status == "true"));
      }
    } catch (e) {
      setIsLogin(false);
      // clearSession();
      // navigate("/login");
    }
  };
  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
    isUserLogin();
    callMethod();
  }, []);
  const {
    favourites,
    profilePic,
    setProfilePic,
    setFavourites,
    unreadMessages,
  } = useContext(AuthContext);
  const callMethod = async () => {
    try {
      const url = `/user/users/detail`;
      const response = await get(url);
      setProfilePic(response?.photo);
      setFavourites(response?.favourites?.length);
    } catch (e) {
      console.log("e", e);
    }
  };
  const handleClick = () => {
    if (isLogin) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <header className="main-header header-shaddow">
        <div className="container-fluid">
          <div className="main-box">
            <div className="nav-outer">
              <div className="logo-box">
                <div className="logo">
                  <a href="/">
                    <img src={logo} alt="" title="" />
                  </a>
                </div>
              </div>

              <Navbar isLogin={isLogin} />
            </div>

            <div
              className="outer-box"
              style={{ display: isLogin ? "flex" : "none" }}
            >
              {user?.role === ROLE.CANDIDATE && (
                <button
                  className="menu-btn"
                  onClick={() => {
                    navigate("/savedjobs");
                  }}
                >
                  <span className="count">{favourites}</span>
                  <span className="icon la la-heart-o"></span>
                </button>
              )}
              <button
                className="menu-btn"
                onClick={() => {
                  navigate("/messages");
                }}
              >
                <span className="count">{unreadMessages}</span>
                <span className="icon la la-envelope"></span>
              </button>

              <button className="menu-btn" onClick={logout}>
                <span className="icon la la-sign-out"></span>
              </button>

              {/* <!-- Dashboard Option --> */}
              <div className="dropdown dashboard-option">
                <a
                  className="dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={profilePic ? profilePic : company6}
                    alt="avatar"
                    className="thumb"
                  />
                  <span className="name">My Account</span>
                </a>
                <ul className="dropdown-menu">
                  <li className={isActiveNav("dashboard")}>
                    <a href="/#/dashboard">
                      {" "}
                      <i className="la la-home"></i> Dashboard
                    </a>
                  </li>
                  <li className={isActiveNav("profile")}>
                    <a href="/#/profile">
                      <i className="la la-user-tie"></i>View Profile
                    </a>
                  </li>
                  {user?.role === ROLE.CARE_HOME && (
                    <li className={isActiveNav("postjob")}>
                      <a href="/#/postjob">
                        <i className="la la-paper-plane"></i>Post a New Job
                      </a>
                    </li>
                  )}
                  {user?.role === ROLE.CARE_HOME && (
                    <li className={isActiveNav("managejobs")}>
                      <a href="/#/managejobs">
                        <i className="la la-briefcase"></i> Manage Jobs{" "}
                      </a>
                    </li>
                  )}
                  {user?.role === ROLE.CARE_HOME && (
                    <li className={isActiveNav("applicants")}>
                      <a href="/#/applicants">
                        <i className="la la-user-friends"></i> All Applicants
                      </a>
                    </li>
                  )}
                  {user?.role === ROLE.CANDIDATE && (
                    <li className={isActiveNav("savedjobs")}>
                      <a href="/#/savedjobs">
                        <i className="la la-tags"></i> Saved Jobs
                      </a>
                    </li>
                  )}
                  {user?.role === ROLE.CANDIDATE && (
                    <li className={isActiveNav("appliedjobs")}>
                      <a href="/#/appliedjobs">
                        <i className="la la-business-time"></i> Applied Jobs
                      </a>
                    </li>
                  )}
                  <li className={isActiveNav("repots")}>
                    <a href="/#/profile">
                      <i className="la la-chart-pie"></i>Reports
                    </a>
                  </li>
                  {user?.role === ROLE.ADMIN && (
                    <li className={isActiveNav("messages")}>
                      <a href="/#/messages">
                        <i className="la la-comment-o"></i>Manage Subscriptions
                      </a>
                    </li>
                  )}
                  <li className={isActiveNav("messages")}>
                    <a href="/#/messages">
                      <i className="la la-envelope"></i>Messages
                    </a>
                  </li>
                  {(user?.role === ROLE.CANDIDATE ||
                    user?.role === ROLE.CARE_HOME) && (
                    <>
                      <li className={isActiveNav("meetings")}>
                        <a href="/#/meetings">
                          <i className="la la-bell"></i>Meetings
                        </a>
                      </li>
                      <li className={isActiveNav("timesheets")}>
                        <a href="/#/profile">
                          <i className="la la-calendar-check"></i>Timesheets
                        </a>
                      </li>
                    </>
                  )}
                  <li className={isActiveNav("feedback")}>
                    <a href="/#/feedback">
                      <i className="la la-sms"></i> Feedback
                    </a>
                  </li>

                  {/* {user?.role === ROLE.ADMIN && (
                    <li className={isActiveNav("appliedjobs")}>
                      <a href="/#/appliedjobs">
                        <i className="la la-business-time"></i> Applied Jobs
                      </a>
                    </li>
                  )} */}
                  {user?.role === ROLE.ADMIN && (
                    <li className={isActiveNav("management")}>
                      <a href="/#/profile">
                        <i className="la la-user-edit"></i> User Management
                      </a>
                    </li>
                  )}
                  <li>
                    <a onClick={logout}>
                      <i className="la la-sign-out"></i>Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="outer-box"
              style={{
                display: isLogin ? "none" : "block",
              }}
            >
              {/* < />!-- Login/Register --> */}
              <div className="btn-box">
                <a href="/#/login" className="theme-btn btn-style-one">
                  <span className="btn-title">Login / Register</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-header">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="" title="" />
            </a>
          </div>
          <div className="nav-outer clearfix">
            <div className="outer-box">
              <div className="login-box">
                <a href="/#/login" className="call-modal">
                  <span></span>
                </a>
              </div>
              <button id="toggle-user-sidebar" onClick={handleClick}>
                <img
                  src={profilePic ? profilePic : company}
                  alt="avatar"
                  className="thumb"
                />
              </button>
              <a
                href="#nav-mobile"
                className="mobile-nav-toggler navbar-trigger"
              >
                <span className="flaticon-menu-1"></span>
              </a>
            </div>
          </div>
        </div>

        <div id="nav-mobile"></div>
      </header>
      <Notifications />
    </>
  );
}

export default Header;
