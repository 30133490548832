import React, { useEffect, useState } from "react";
import fileDownload from "js-file-download";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { get, getFile } from "../../../api";
import Payout from "./Carehome/Payout";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

function CareHomeReports() {
  const [carersRecruitedType, setCarersRecruitedType] = useState("month");
  const [jobPostedClosedType, setJobPostedClosedType] = useState("month");
  const [carersRecruited, setCarersRecruited] = useState({
    labels: [],
    datasets: [
      {
        label: "Carers Recruited",
        data: [],
        backgroundColor: "#55bbaf",
      },
    ],
  });
  const [jobPostedClosed, setJobPostedClosed] = useState({
    labels: [],
    datasets: [
      {
        label: "Jobs Posted",
        data: [],
        backgroundColor: "#ffde75",
      },
      {
        label: "Closed",
        data: [],
        backgroundColor: "#b4d978",
      },
    ],
  });

  useEffect(() => {
    getCarersRecruited(false);
    getJobPostedClosed(false);
  }, []);
  const getCarersRecruited = async (isDownload = false, type) => {
    // type = week/month
    try {
      let url = `/job/report/carer-recruiter?type=${
        type ? type : carersRecruitedType
      }&download=${isDownload}`;
      let response;
      if (isDownload) {
        response = await getFile(url);
      } else {
        response = await get(url);
      }
      console.log("carer recruited ===>", response);
      let labels = [];
      let data = [];
      if (isDownload && response?.data) {
        fileDownload(
          response?.data,
          response?.headers?.["content-disposition"]?.split("filename=")[1]
        );
      }
      if (!isDownload && response?.count) {
        for (const [key, value] of Object.entries(response?.count)) {
          labels.push(key);
          data.push(value);
        }
        setCarersRecruited({
          labels: labels,
          datasets: [
            {
              label: "Carers Recruited",
              data: data,
              backgroundColor: "#55bbaf",
            },
          ],
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getJobPostedClosed = async (isDownload = false, type) => {
    // type = week/month
    try {
      let url = `/job/report/job-status?type=${
        type ? type : jobPostedClosedType
      }&download=${isDownload}`;
      let response;
      if (isDownload) {
        response = await getFile(url);
      } else {
        response = await get(url);
      }
      let labels = [];
      let JobClosed = [];
      let JobOpened = [];
      if (isDownload && response?.data) {
        fileDownload(
          response?.data,
          response?.headers?.["content-disposition"]?.split("filename=")[1]
        );
      }
      if (!isDownload && response?.countStatus) {
        for (const [key, value] of Object.entries(response?.countStatus)) {
          labels.push(key);
          JobClosed.push(value?.JobClosed);
          JobOpened.push(value?.JobOpened);
        }
        setJobPostedClosed({
          labels: labels,
          datasets: [
            {
              label: "Jobs Posted",
              data: JobOpened,
              backgroundColor: "#ffde75",
            },
            {
              label: "Closed",
              data: JobClosed,
              backgroundColor: "#b4d978",
            },
          ],
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        {/* <!-- Graph widget --> */}
        <div className="graph-widget ls-widget">
          <div className="tabs-box">
            <Payout />
          </div>

          <div className="tabs-box">
            <div className="widget-title">
              <h4>Carers Recruited</h4>
              <div className="chosen-outer">
                {/* <!--Tabs Box--> */}
                <select className="chosen-select-carer-site">
                  <option>All sites</option>
                  <option>Site A</option>
                  <option>Site B</option>
                </select>
                <select
                  className="chosen-select-carer-month"
                  onChange={(e) => {
                    e?.stopPropagation();
                    setCarersRecruitedType(e?.target?.value);
                    getCarersRecruited(false, e?.target?.value);
                  }}
                >
                  <option value={"month"}>Last 6 Months</option>
                  <option value={"week"}>Last 24 Weeks</option>
                </select>
                <div>
                  <button
                    type="button"
                    className="theme-btn btn-sm btn-style-three"
                    onClick={(e) => {
                      e?.stopPropagation();
                      getCarersRecruited(true);
                    }}
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>

            <div className="widget-content">
              <Bar options={options} data={carersRecruited} />
            </div>
          </div>

          <div className="tabs-box">
            <div className="widget-title">
              <h4>Jobs Posted vs Closed</h4>
              <div className="chosen-outer">
                {/* <!--Tabs Box--> */}
                <select className="chosen-select-jobposted">
                  <option>All sites</option>
                  <option>Site A</option>
                  <option>Site B</option>
                </select>
                <select
                  className="chosen-select-jobposted-month"
                  onChange={(e) => {
                    e?.stopPropagation();
                    setJobPostedClosedType(e?.target?.value);
                    getJobPostedClosed(false, e?.target?.value);
                  }}
                >
                  <option value={"month"}>Last 6 Months</option>
                  <option value={"week"}>Last 24 Weeks</option>
                </select>
                <div>
                  <button
                    type="button"
                    className="theme-btn btn-sm btn-style-three"
                    onClick={(e) => {
                      e?.stopPropagation();
                      getJobPostedClosed(true);
                    }}
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>

            <div className="widget-content">
              <Bar options={options} data={jobPostedClosed} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareHomeReports;
