import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import { clearSession } from "../../../utils/session";
import { get, post } from "../../../api";
import { useEffect, useState } from "react";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import Revenue from "../Reports/Admin/Revenue";

const Admin = () => {
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState();
  const location = useLocation();
  const [stats, setStats] = useState();
  const logout = async (e) => {
    e?.preventDefault();
    const url = "user/users/logout";
    try {
      const response = await post(url, {});
      if (response?.status === 200) {
        clearSession(EMPLOYR_LOGIN_SESSION);
        navigate("/login");
      }
    } catch (e) {
      if (e?.response?.data?.status === 498) {
        clearSession(EMPLOYR_LOGIN_SESSION);
        navigate("/login");
      }
    }
  };
  const isActiveNav = (nav) => {
    return location.pathname.indexOf(nav) !== -1 ? "active" : "";
  };
  const getStats = async () => {
    const url = `/user/stats/count`;
    try {
      const response = await get(url);
      setStats(response);
    } catch (e) {
      console.error(e);
    }
  };

  const getUserDetails = async () => {
    const url = `/user/users/detail`;
    try {
      const response = await get(url);
      setUserDetail(response);
      console.log("profile response", response);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    getStats();
    getUserDetails();
  }, []);
  return (
    <>
      <Header />
      {/* <!-- Admin Sidebar --> */}

      <div className="user-sidebar">
        <div className="sidebar-inner">
          <ul className="navigation">
            <li className={isActiveNav("dashboard")}>
              <a href="/#/dashboard">
                {" "}
                <i className="la la-home"></i> Dashboard
              </a>
            </li>
            <li className={isActiveNav("profile")}>
              <a href="/#/profile">
                <i className="la la-user-tie"></i>View Profile
              </a>
            </li>
            <li className={isActiveNav("reports")}>
              <a href="/#/reports">
                <i className="la la-chart-pie"></i>Reports
              </a>
            </li>
            <li className={isActiveNav("managesubscriptions")}>
              <a href="/#/managesubscriptions">
                <i className="la la-user-edit"></i> Manage Subscriptions
              </a>
            </li>
            <li className={isActiveNav("messages")}>
              <a href="/#/messages">
                <i className="la la-envelope"></i>Messages
              </a>
            </li>
            <li className={isActiveNav("feedback")}>
              <a href="/#/feedback">
                <i className="la la-sms"></i> Feedback
              </a>
            </li>
            <li className={isActiveNav("user-management")}>
              <a href="/#/user-management">
                <i className="la la-user-edit"></i> User Management
              </a>
            </li>
            <li>
              <a onClick={logout}>
                <i className="la la-sign-out"></i>Logout
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* <!-- End Admin Sidebar --> */}
      {isActiveNav("dashboard") && (
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="upper-title-box">
              <h3 className="capitalize">
                Welcome, {userDetail?.firstName} {userDetail?.lastName}
              </h3>
              <div className="text">Ready to jump back in?</div>
            </div>

            {/* <!--Admin summary--> */}
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div
                  className="ui-item dashboard-box"
                  onClick={(e) => {
                    e?.stopPropagation();
                    navigate("/reports");
                  }}
                >
                  <div className="left">
                    <i className="icon flaticon-briefcase"></i>
                  </div>
                  <div className="right">
                    <h4>{stats?.activeJobs ? stats?.activeJobs : 0}</h4>
                    <p>Active Jobs</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div
                  className="ui-item ui-red dashboard-box"
                  onClick={(e) => {
                    e?.stopPropagation();
                    navigate("/reports");
                  }}
                >
                  <div className="left">
                    <i className="icon la la-hospital-alt"></i>
                  </div>
                  <div className="right">
                    <h4>{stats?.careHomes ? stats?.careHomes : 0}</h4>
                    <p>Care Homes</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div
                  className="ui-item ui-yellow dashboard-box"
                  onClick={(e) => {
                    e?.stopPropagation();
                    navigate("/user-management");
                  }}
                >
                  <div className="left">
                    <i className="icon la la-user"></i>
                  </div>
                  <div className="right">
                    <h4>{stats?.candidates ? stats?.candidates : 0}</h4>
                    <p>Candidates</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div
                  className="ui-item ui-green dashboard-box"
                  onClick={(e) => {
                    e?.stopPropagation();
                    navigate("/messages");
                  }}
                >
                  <div className="left">
                    <i className="icon la la-envelope"></i>
                  </div>
                  <div className="right">
                    <h4>{stats?.messages ? stats?.messages : 0}</h4>
                    <p>Messages</p>
                  </div>
                </div>
              </div>
            </div>

            {/* <!--admin chart--> */}
            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Graph widget --> */}
                <div className="graph-widget ls-widget">
                  <div className="tabs-box">
                    <Revenue />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default Admin;
