import React, { useEffect } from "react";

const CountDown = () => {
  useEffect(() => {
    const timeInMinutes = 14400;
    const currentTime = Date.parse("2024-02-09T00:00:00.000-03:00")
    const deadline = new Date(currentTime + timeInMinutes*60*1000);
    initializeClock('clockdiv', deadline);
  }, []);
  const initializeClock = (id, endtime) => {
    const clock = document.getElementById(id);
    const timeinterval = setInterval(() => {
      const t = getTimeRemaining(endtime);
      clock.innerHTML = `Days: ${t.days}, hours: ${t.hours}, minutes: ${t.minutes}, seconds: ${t.seconds}`;
      if (t.total <= 0) {
        clearInterval(timeinterval);
      }
    }, 1000);
  };
  const getTimeRemaining = (endtime) => {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor( (total/1000) % 60 );
    const minutes = Math.floor( (total/1000/60) % 60 );
    const hours = Math.floor( (total/(1000*60*60)) % 24 );
    const days = Math.floor( total/(1000*60*60*24) );
  
    return {
      total,
      days,
      hours,
      minutes,
      seconds
    };
  }
  return <span id="clockdiv"></span>;
};
export default CountDown;
