import React, { useContext, useEffect, useState } from "react";
import Header from "../Protected/Dashboard/Header";
import Footer from "../Common/Footer";
import { useSearchParams } from "react-router-dom";
import { get, post, put } from "../../api";
import Success from "../Common/Alert/Success";
import Error from "../Common/Alert/Error";
import { createSession, getSession } from "../../utils/session";
import { EMPLOYR_LOGIN_SESSION } from "../../constant/session";
import Scripts from "../Common/Scripts/Scripts";
import { AuthContext } from "../../context/auth";
import { formatDate, formatDateString } from "../../utils/date";
import JobTags from "../Protected/Common/JobTags";
import companyDefault from "../../images/resource/company-6.png";
import { ROLE } from "../../constant/role";

function JobSingle() {
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState();
  const [userDetails, setUserDetails] = useState();
  const [isLogin, setLogin] = useState(false);
  const [isDisableApply, setIsDisableApply] = useState(false);
  const jobId = searchParams.get("id");
  const [jobDetail, setJobDetail] = useState();
  const [relatedJobDetails, setRelatedJobDetails] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [apiMsg, setApiMsg] = useState("");
  const [saveApiMsg, setSaveApiMsg] = useState("");
  const { setFavourites, setProfilePic, setAuth, auth, setUnreadMessages } =
    useContext(AuthContext);

  const getUserRoleType = async () => {
    const url = `/user/users/type`;
    const response = await get(url);
    if (response) {
      setUserTypeRoles(response);
    }
  };
  const [jobTypes, setUserTypeRoles] = useState([]);

  const getJobDetails = async (isLogin) => {
    setLoading(true);
    try {
      if (jobId) {
        let url = "";
        if (isLogin) {
          url = `/job/jobs/${jobId}?user-data=true`;
        } else {
          url = `/job/jobs/${jobId}`;
        }
        const response = await get(url);
        setJobDetail(response);
        getUserDetails(response);
        if (response) {
          getRelatedJobs(response);
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const getRelatedJobs = async (job) => {
    try {
      const url = `/job/jobs/search`;
      const response = await post(url, { name: job?.title });
      if (response?.status === 200) {
        setRelatedJobDetails(response?.data);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const applyJob = async () => {
    const url = `/job/application/apply`;
    setApiMsg("");
    setSaveApiMsg("");
    setLoading(true);
    const payload = {
      shiftId: jobDetail?.id,
      applicationDate: new Date(),
      jobContract: "FULL_TIME",
      status: "APPLIED",
    };

    try {
      const response = await put(url, payload);
      if (response?.status === 200 || response?.status === 202) {
        console.log("applied", response);
        setApiMsg("success");
        getJobDetails(true);
      } else {
        setApiMsg("error");
      }
      setLoading(false);
    } catch (e) {
      setApiMsg("error");
      setLoading(false);
    }
  };
  const saveJob = async () => {
    const url = `/job/application/favourite`;
    setLoading(true);
    setApiMsg("");
    setSaveApiMsg("");
    const payload = {
      shiftId: jobDetail?.id,
    };

    try {
      const response = await put(url, payload);
      if (
        response?.status === 200 ||
        response?.status === 202 ||
        response?.status === 204
      ) {
        setSaveApiMsg("success");
        getUserDetails();
        getJobDetails(true);
      } else {
        setSaveApiMsg("error");
      }
      setLoading(false);
    } catch (e) {
      setSaveApiMsg("error");
      setLoading(false);
    }
  };
  const getUserDetails = async (jobDetails) => {
    const url = `/user/users/detail`;
    try {
      const response = await get(url);
      if (response) {
        createSession(
          EMPLOYR_LOGIN_SESSION,
          JSON.stringify({
            authenticated: true,
            role: response?.role,
          })
        );
        setUserDetails(response);
        setFavourites(response?.favourites?.length);
        setProfilePic(response?.photo);
        checkApplyJobDisabled(response, jobDetails);
      }
    } catch (e) {
      // console.log(e);
    }
  };
  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
    const isUserLogin = async () => {
      const url = `/user/users/isLogin`;
      try {
        const response = await get(url);
        if (response) {
          setLogin(true);
          setAuth({
            ...auth,
            isAuthenticated: !!(response?.status == "true"),
          });
        }
        getJobDetails(true);
        getMessageCount();
        getUserRoleType();
      } catch (e) {
        getJobDetails(false);
        setLogin(false);
        // clearSession();
      }
    };
    isUserLogin();
  }, []);

  const checkApplyJobDisabled = (userDetails, jobDetails) => {
    console.log("jobDetails", jobDetails);
    const isUserAvailable = jobDetails?.jobApplicationApplicants?.filter(
      (itm) => itm?.userId === userDetails?.userId
    );
    console.log("isUserAvailable ===>", isUserAvailable);
    setIsDisableApply(!!(isUserAvailable?.length > 0));
  };
  const getMessageCount = async () => {
    const url = `/user/stats/count`;
    try {
      const response = await get(url);
      setUnreadMessages(response?.unreadMsg);
    } catch (e) {
      // console.error(e);
    }
  };
  const getSiteName = () => {
    const sites = jobDetail?.company?.sites || [];
    const currentActiveSite = sites.filter(
      (itm) => itm.id === jobDetail?.siteId
    );
    return currentActiveSite[0].city || "";
  };
  return (
    <div className="page-wrapper">
      <span className="header-span"></span>
      <Scripts />
      <Header />
      {isLoading && <div className="preloader"></div>}
      {/* <!-- Job Detail Section --> */}
      <section className="job-detail-section">
        <div className="job-detail-outer">
          <div className="auto-container">
            <div className="row">
              <div className="content-column col-lg-8 col-md-12 col-sm-12">
                <div className="job-block-outer">
                  <div className="row">
                    <div className="col-md-12">
                      {apiMsg === "success" && (
                        <>
                          <Success msg="You have successfully applied for this job." />
                        </>
                      )}
                      {apiMsg === "error" && (
                        <>
                          <Error msg="Something went wrong while applying for job. Please try again." />
                        </>
                      )}
                      {saveApiMsg === "success" && (
                        <>
                          <Success msg="You have successfully saved this job." />
                        </>
                      )}
                      {saveApiMsg === "error" && (
                        <>
                          <Error msg="Something went wrong while saving job. Please try again." />
                        </>
                      )}
                    </div>
                  </div>
                  {/* <!-- Job Block --> */}
                  <div className="job-block-seven style-two">
                    <div className="inner-box">
                      <div className="content">
                        <h4>{jobDetail?.title}</h4>
                        <ul className="job-info">
                          {/* <li>
                            <span className="icon flaticon-briefcase"></span>{" "}
                            Segment
                          </li> */}
                          <li>
                            <span className="icon flaticon-map-locator"></span>{" "}
                            {jobDetail?.company?.sites?.length > 0
                              ? getSiteName()
                              : ""}
                          </li>
                          <li>
                            <span className="icon flaticon-clock-3"></span>{" "}
                            {formatDateString(jobDetail?.creationDate)}
                          </li>
                          <li>
                            <span className="icon flaticon-money"></span> £
                            {jobDetail?.ratePerHour}/ hour
                          </li>
                        </ul>
                        <JobTags endDate={jobDetail?.endDate} job={jobDetail} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="job-overview-two">
                  <h4>Job Description</h4>
                  <ul>
                    <li>
                      <i className="icon icon-calendar"></i>
                      <h5>Start date:</h5>
                      <span>{formatDateString(jobDetail?.startDate)}</span>
                    </li>
                    <li>
                      <i className="icon icon-expiry"></i>
                      <h5>Closing date:</h5>
                      <span>{formatDateString(jobDetail?.endDate)}</span>
                    </li>
                    <li>
                      <i className="icon icon-location"></i>
                      <h5>Location:</h5>
                      <span>
                        {jobDetail?.company?.sites?.length > 0
                          ? jobDetail?.company?.sites[0]?.name
                          : ""}
                      </span>
                    </li>
                    <li>
                      <i className="icon icon-user-2"></i>
                      <h5>Role Type:</h5>
                      <span>
                        {
                          jobTypes?.filter(
                            (itm) => itm?.userTypeId === jobDetail?.jobTypeId
                          )?.[0]?.description
                        }
                      </span>
                    </li>
                    <li>
                      <i className="icon icon-user-2"></i>
                      <h5>Vacancies:</h5>
                      <span>{jobDetail?.vacancies}</span>
                    </li>
                    <li>
                      <i className="icon icon-clock"></i>
                      <h5>Hours:</h5>
                      <span>{jobDetail?.minHoursPerWeek}h / week</span>
                    </li>
                    <li>
                      <i className="icon icon-rate"></i>
                      <h5>Rate:</h5>
                      <span>£{jobDetail?.ratePerHour} / hour</span>
                    </li>
                    <li>
                      <i className="icon icon-calendar"></i>
                      <h5>Preferred Days:</h5>
                      <span>
                        {jobDetail?.days?.split(",")?.length === 7
                          ? "All"
                          : jobDetail?.days}
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="job-detail">
                  <h4>Job Description</h4>
                  <p>{jobDetail?.description}</p>
                </div>
              </div>

              <div className="sidebar-column col-lg-4 col-md-12 col-sm-12">
                <aside className="sidebar">
                  <div
                    className="btn-box"
                    style={{
                      display: user?.role === ROLE.CARE_HOME ? "none" : "",
                    }}
                  >
                    <button
                      disabled={
                        isDisableApply || !isLogin || jobDetail?.vacancies < 1
                      }
                      className={
                        isDisableApply
                          ? "theme-btn btn-style-disabled"
                          : "theme-btn btn-style-one"
                      }
                      onClick={applyJob}
                    >
                      Apply For Job
                    </button>
                    <button
                      disabled={!isLogin || jobDetail?.userInfo?.isFavourite}
                      className={
                        jobDetail?.userInfo?.isFavourite
                          ? "bookmark-btn fill"
                          : "bookmark-btn"
                      }
                      onClick={saveJob}
                    >
                      <i className="flaticon-bookmark"></i>
                    </button>
                  </div>

                  <div className="sidebar-widget company-widget">
                    <div className="widget-content">
                      <div className="company-title">
                        <div className="company-logo">
                          <img
                            src={
                              jobDetail?.company?.companyUserPhoto
                                ? jobDetail?.company?.companyUserPhoto
                                : companyDefault
                            }
                            alt=""
                          />
                        </div>
                        <h5 className="company-name">
                          {jobDetail?.company?.companyName}
                        </h5>
                        {/* <a href="#" className="profile-link">
                          View company profile
                        </a> */}
                      </div>

                      <ul className="company-info">
                        {/* <li>
                          Patients: <span>501-1,000</span>
                        </li> */}
                        <li>
                          Phone:{" "}
                          <span>
                            {jobDetail?.company?.companyContactNumber}
                          </span>
                        </li>
                        <li>
                          Email: <span>{jobDetail?.company?.companyName}</span>
                        </li>
                        <li>
                          Site:{" "}
                          <span>
                            {jobDetail?.company?.sites?.length > 0
                              ? getSiteName()
                              : ""}
                          </span>
                        </li>
                      </ul>

                      <div className="btn-box">
                        <a
                          target="_blank"
                          href={`/#/jobList?siteId=${jobDetail?.siteId}&companyId=${jobDetail?.companyId}`}
                          className="theme-btn btn-style-three"
                        >
                          More jobs at {jobDetail?.company?.companyName}
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* <div className="sidebar-widget contact-widget">
                    <h4 className="widget-title">Contact Us</h4>
                    <div className="widget-content">
                      <div className="default-form">
                        <form>
                          <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                              <input
                                type="text"
                                name="username"
                                placeholder="Your Name"
                                required
                              />
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                              <input
                                type="email"
                                name="email"
                                placeholder="Email Address"
                                required
                              />
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                              <textarea
                                className="darma"
                                name="message"
                                placeholder="Message"
                              ></textarea>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                              <button
                                className="theme-btn btn-style-one"
                                type="submit"
                                name="submit-form"
                              >
                                Send Message
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div> */}
                </aside>
              </div>
            </div>

            {/* <!-- Related Jobs --> */}
            <div className="related-jobs">
              <div className="title-box">
                <h3>Related Jobs</h3>
                <div className="text">
                  {relatedJobDetails?.length} jobs live.
                </div>
              </div>
              <div className="row">
                {/* <!-- Job Block --> */}
                {relatedJobDetails?.slice(0, 4).map((job) => (
                  <div
                    className="job-block-four col-xl-3 col-lg-4 col-md-6 col-sm-12"
                    key={job?.id}
                  >
                    <div className="inner-box">
                      <ul className="job-other-info">
                        <li className="time">{jobDetail?.jobCategory}</li>
                      </ul>
                      <span className="company-logo">
                        <img
                          src={
                            jobDetail?.company?.companyUserPhoto
                              ? jobDetail?.company?.companyUserPhoto
                              : companyDefault
                          }
                          alt=""
                        />
                      </span>
                      <span className="company-name">{job?.companyName}</span>
                      <h4>
                        <a target="_blank" href={`/#/jobSingle?id=${job?.id}`}>
                          {job?.title}
                        </a>
                      </h4>
                      <div className="location">
                        <span className="icon flaticon-map-locator"></span>{" "}
                        {job?.cityName}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Job Detail Section --> */}
      <Footer />
    </div>
  );
}

export default JobSingle;
