import { Fragment, useEffect, useState } from "react";
import { get, post } from "../../api";
import JobListItem from "../Protected/Common/JobListItem";

const JobSection = ({ isLogin }) => {
  const [recentJobs, setRecentJobs] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [role, setRole] = useState([]);
  const [searchData, setSearchData] = useState({
    category: "",
    job_title: "",
    postcode: "",
  });
  const [searchList, setSearchList] = useState([]);

  useEffect(() => {
    // const session = getSession(EMPLOYR_LOGIN_SESSION);
    // setUser(session);
    getActiveJobs();
    getRoleTypes();
  }, []);
  const getRoleTypes = async () => {
    const url = `/user/users/type`;
    try {
      const response = await get(url);
      if (response) {
        setRole(response);
      }
    } catch (e) {
      console.error("===>", e);
    }
  };
  const getActiveJobs = async () => {
    const url = "/job/jobs/search?pageSize=6";
    setLoading(true);
    try {
      const response = await post(url, {
        ...searchData,
        // page: page,
      });
      console.log("search", response);
      console.log(response);
      if (response?.status === 200) {
        setSearchList(response?.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <>
      {/* <!-- Job Section --> */}
      <section className="job-section pt-0">
        <div className="auto-container">
          <div className="sec-title-outer">
            <div className="sec-title">
              <h2>Featured Jobs</h2>
              <div className="text">
                Know your worth and find the job that qualify your life
              </div>
            </div>
          </div>

          <div className="row wow fadeInUp">
            {/* <!-- Job Block --> */}
            {searchList.map((job) => {
              return (
                <Fragment key={job?.jobId}>
                  <JobListItem job={job} isLogin={isLogin} />
                </Fragment>
              );
            })}
          </div>

          <div className="btn-box">
            <a
              href="/#/jobList"
              target="_blank"
              className="theme-btn btn-style-one bg-blue"
            >
              <span className="btn-title">All Listings</span>
            </a>
          </div>
        </div>
      </section>
      {/* <!-- End Job Section --> */}
    </>
  );
};
export default JobSection;
