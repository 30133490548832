import { useContext, useEffect } from "react";
// import fetch from "cross-fetch";
import "./calendar.css";
import Scripts from "../../Common/Scripts/Scripts";
import CandidateSidebar from "../Dashboard/CandidateSidebar";
import Footer from "../Dashboard/Footer";
import Header from "../Dashboard/Header";
import { getSession } from "../../../utils/session";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import { useState } from "react";
import { ROLE } from "../../../constant/role";
import CareHomeSidebar from "../Dashboard/CareHomeSideBar";
import { del, get, post, put } from "../../../api";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Loading from "../../Common/Loading";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import Loader from "../../Common/Loading";
import Success from "../../Common/Alert/Success";
import Error from "../../Common/Alert/Error";
import { useForm } from "react-hook-form";
import { InputError } from "../../Common/FormControl/Input";
import { formatDate, formatDateHyphen, getTimeFromDate, isPastdate } from "../../../utils/date";
import { scrollTop } from "../../../utils/scroll";
import $ from "jquery";
import { AuthContext } from "../../../context/auth";
import { useSearchParams } from "react-router-dom";

const Meetings = () => {
  const [user, setUser] = useState();
  const [userDetail, setUserDetail] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [meetingURL, setMeetingURL] = useState("");
  const [jobApplicationStatus, setJobApplicationStatus] = useState("");
  const [jobs, setJobs] = useState([]);
  const [users, setUsers] = useState([]);
  const [jobVal, setJobval] = useState({ title: "" });
  const [userVal, setUserval] = useState({ name: "" });
  const [descriptionVal, setDescriptionVal] = useState("");
  const [meetingCountDetail, setMeetingCountDetail] = useState([]);
  const [clickedDate, setClickedDate] = useState(undefined);
  const [meetingDetailPerDate, setMeetingDetailPerDate] = useState([]);
  const [meetingDetailPerUser, setMeetingDetailPerUser] = useState([]);
  const [timeValue, setTimeValue] = useState("");
  const [meetingNotes, setMeetingNotes] = useState("");
  const [meetingUserStatus, setMeetingUserStatus] = useState(false);
  const [sentInviteFlag, setSentInviteFlag] = useState("");
  const [acceptRejectStatus, setAcceptRejectStatus] = useState("");
  const [deleteMeetingStatus, setDeleteMeetingStatus] = useState("");
  const [fromUserName, setFromUserName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [calendarMonth, setCalendarMonthsDates] = useState();
  const { register, getValues, setValue, formState } = useForm();
  const addMeetingNoteError = formState.errors;
  const setupMeetingForm = useForm();
  const candidateMeetignForm = useForm();
  const candidateError = candidateMeetignForm.formState.errors;
  const { setUnreadMessages } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const meetingIdParam = searchParams.get("meetingId");
  const dateParam = searchParams.get("date");
  const {
    formState: { errors },
  } = setupMeetingForm;

  useEffect(() => {
    window.setTimeout(async () => {
      await window.dispatchEvent(new Event("resize"));
    }, 1000);
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
    callMethod();
    getMeetingDetailsInit();
    /** call onload meeting counts */
    const updatedTIme = new Date();
    const month = Number(updatedTIme?.getMonth()) + 1;
    const year = updatedTIme.getFullYear();
    const payload = {
      monthNumber: month,
      year: year,
    };
    getMeetingCounts(payload);
  }, []);
  const getMessageCount = async () => {
    const url = `/user/stats/count`;
    try {
      const response = await get(url);
      setUnreadMessages(response?.unreadMsg);
    } catch (e) {
      // console.error(e);
    }
  };
  const getMeetingDetailsInit = () => {
    if (meetingIdParam && dateParam) {
      const [yyyy, mm, dd] = dateParam?.split("-");
      console.log("dd", dd);
      console.log("mm", mm);
      console.log("yyyy", yyyy);
      const paramDate = `${mm}-${dd}-${yyyy}`;
      getMeetingByDate(formatDate(paramDate));
      getMeetingById({ id: meetingIdParam });
    } else {
      getMeetingByDate(formatDate(new Date()));
    }
  };
  const resetData = () => {
    setClickedDate(undefined);
    setJobval({ title: "" });
    setUserval({ name: "" });
    setTimeValue("");
    setDescriptionVal("");
    setMeetingNotes("");
    setMeetingUserStatus("");
    setDeleteMeetingStatus("");
  };

  const getMeetingCounts = async (dates) => {
    const { monthNumber, year } = dates;
    const type =
      user?.role ||
      JSON.parse(localStorage.getItem(EMPLOYR_LOGIN_SESSION))?.role;
    try {
      if (type) {
        const url = `/meeting-timesheet/meeting/count?month=${monthNumber}&year=${year}&type=${type}`;
        const response1 = await get(url);
        var calanderData = [];
        for (let [key, value] of Object.entries(response1?.meetings)) {
          // console.log("value", value);
          // console.log("key", key);
          if(!isPastdate(key)){
            calanderData.push({
              title: JSON.stringify({
                count: value?.count,
                timings: value?.timings,
              }),
              date: key,
              getDate: key,
            });
          }
        }
        setMeetingCountDetail(calanderData);
      }
    } catch (err) {
      console.log("Error is", err);
    }
  };

  const getMeetingById = async (meetingId) => {
    setMeetingURL("");
    const url = `/meeting-timesheet/meeting?meetingId=${meetingId?.id}`;
    setJobApplicationStatus("");
    const response = await get(url);
    setMeetingDetailPerUser(response);
    setMeetingNotes(response[0]?.meetingNotes);
    setClickedDate(response[0]?.meetingDate);
    setCompanyName(response[0]?.companyName);
    setMeetingUserStatus(response[0]?.status);
    const tVal = getTime(response[0]?.meetingDateTime);
    setTimeValue(tVal);
    const getJobDetailUrl = `/job/jobs/${response?.[0]?.shiftId}?user-data=false`;
    const jobData = await get(getJobDetailUrl);

    if (
      user?.role === ROLE.CANDIDATE ||
      JSON.parse(localStorage.getItem(EMPLOYR_LOGIN_SESSION))?.role ===
        ROLE.CANDIDATE
    ) {
      candidateMeetignForm.setValue("candidate", response[0]?.companyName, {
        shouldValidate: true,
      });
      candidateMeetignForm.setValue("job", jobData?.title, {
        shouldValidate: true,
      });
      candidateMeetignForm.setValue("meetingdate", response[0]?.meetingDate, {
        shouldValidate: true,
      });
      candidateMeetignForm.setValue(
        "meetingDescription",
        response[0]?.description || ""
      );
      if(response[0]?.status === 'Accepted') {
        setMeetingURL(response[0]?.meetingURL || "");
      }
      candidateMeetignForm.setValue(
        "meetingDetail",
        response[0]?.meetingDetail || ""
      );
      candidateMeetignForm.setValue(
        "meetingType",
        response[0]?.meetingType || ""
      );
      candidateMeetignForm.setValue("meetingtime", getTimeFromDate(response[0]?.meetingDateTime), {
        shouldValidate: true,
      });
      scrollTop(".view-schedule-candidate-section");
    }
    if (
      user?.role === ROLE.CARE_HOME ||
      JSON.parse(localStorage.getItem(EMPLOYR_LOGIN_SESSION))?.role ===
        ROLE.CARE_HOME
    ) {
      setMeetingURL(response[0]?.meetingURL);
      setupMeetingForm.setValue("searchCandidate", response[0]?.fromUserName, {
        shouldValidate: true,
      });
      setupMeetingForm.setValue("meetingDescription", response[0]?.description);
      setupMeetingForm.setValue("meetingDetail", response[0]?.meetingDetail);
      setupMeetingForm.setValue("meetingType", response[0]?.meetingType);
      setupMeetingForm.setValue("searchActiveJobs", jobData?.title, {
        shouldValidate: true,
      });
      setupMeetingForm.setValue("meetingdate", response[0]?.meetingDate, {
        shouldValidate: true,
      });
      setupMeetingForm.setValue("meetingtime", getTimeFromDate(response[0]?.meetingDateTime), {
        shouldValidate: true,
      });
      const meetingDate = new Date(response[0]?.meetingDate);
      const currentdate = new Date();
      const Difference_In_Time = meetingDate.getTime() - currentdate.getTime();
      const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days < 0) {
        $(".addMeetingNoteSection").trigger("click");
        scrollTop(".addMeetingNoteSection");
      } else {
        $(".setupMeetingTab").trigger("click");
        scrollTop(".setupMeetingTab");
      }
    }
    setJobval({
      id: jobData?.id,
      title: jobData?.title,
      city: jobData?.company ? jobData?.company?.sites[0].city : "",
    });
    setDescriptionVal(jobData?.description);
    setUserval({ name: response[0]?.toUserName });
  };

  const sendMeetingInvite = async (data) => {
    setLoading(true);
    setSentInviteFlag("");
    setJobApplicationStatus("");
    setDeleteMeetingStatus("");
    try {
      const url = `/meeting-timesheet/meeting`;
      const cDate = new Date().toISOString();
      const responseData = {
        toUserId: userVal?.id,
        fromUserId: userDetail?.userId,
        companyId: userDetail?.company?.companyId,
        shiftId: data?.searchActiveJobs?.id,
        createdDate: cDate,
        meetingDate: `${data?.meetingdate}`,
        description: data?.meetingDescription,
        meetingType: data?.meetingType,
        meetingDetail: data?.meetingDetail,
        meetingDateTime: `${data?.meetingdate}T${data?.meetingtime}:00.785Z`,
        location: data?.searchActiveJobs?.siteCity,
        meetingNotes: "Meeting notes",
        status: "Scheduled",
        userStatus: userDetail?.status,
        meetingAction: "SEND_INVITE",
      };

      const response = await post(url, responseData);
      if (response?.status === 201 || response?.status === 200) {
        setSentInviteFlag("success");
        getMeetingCounts(calendarMonth);
        setMeetingDetailPerDate([]);
        resetData();
        getMessageCount();
      } else {
        setSentInviteFlag("error");
      }
      setLoading(false);
    } catch (err) {
      setSentInviteFlag("error");
      setLoading(false);
      console.log("Something Went Wrong", err);
    }
  };
  const deleteMeeting = async () => {
    console.log(meetingDetailPerUser[0]);
    try {
      setLoading(true);
      setAcceptRejectStatus("");
      setSentInviteFlag("");
      setJobApplicationStatus("");
      setDeleteMeetingStatus("");
      const url = `/meeting-timesheet/meeting?meetingId=${meetingDetailPerUser[0]?.id}`;
      const response = await del(url);
      if (response?.status === 200 || response?.status === 204) {
        console.log("response", response);
        getMeetingCounts(calendarMonth);
        setDeleteMeetingStatus("success");
        setMeetingDetailPerDate([]);
        setMeetingDetailPerUser([]);
        setLoading(false);
        getMessageCount();
      } else {
        setLoading(false);
        setDeleteMeetingStatus("error");
      }
    } catch (e) {
      setDeleteMeetingStatus("error");
      setLoading(true);
      console.error(e);
    }
  };
  const UpdateMeetingInvite = async (formValue) => {
    // e?.preventDefault();
    console.log("formValue", formValue);
    setLoading(true);
    setAcceptRejectStatus("");
    setSentInviteFlag("");
    setJobApplicationStatus("");
    setDeleteMeetingStatus("");
    try {
      const url = `/meeting-timesheet/meeting`;
      const time = getValues("meetingtime");
      console.log("hello" + time, "hello" + timeValue);
      var updateData = meetingDetailPerUser[0];
      updateData.meetingAction = formValue?.submitValue?.action;
      updateData.meetingNotes = getValues("meetingNotes");
      updateData.description = formValue?.meetingDescription;
      updateData.meetingDetail = formValue?.meetingDetail;
      updateData.meetingType = formValue?.meetingType;
      updateData.meetingDate = formValue?.meetingdate;
      updateData.meetingDateTime = `${formValue?.meetingdate}T${formValue?.meetingtime}:00.785Z`;
      if (formValue?.submitValue?.status) {
        updateData.status = formValue?.submitValue?.status;
        if (formValue?.submitValue?.action === "SELECT_CANDIDATE") {
          updateData.joiningDate = formValue?.joiningDate
            ? new Date(formValue?.joiningDate)
            : "";
          updateData.reportTime = formValue?.reportingTime
            ? `${formatDate(formValue?.joiningDate)}T${
                formValue?.reportingTime
              }:00.785Z`
            : "";
          if (!updateData.joiningDate && !updateData.reportTime) {
            setJobApplicationStatus("validationError");
            setLoading(false);
            return;
          }
        }
      }
      const response = await put(url, updateData);
      if (response?.status === 202 || response?.status === 201) {
        console.log("response", response);
        setAcceptRejectStatus("success");
        getMeetingCounts(calendarMonth);
        setMeetingDetailPerDate([]);
        resetData();
        getMessageCount();
      } else {
        setAcceptRejectStatus("error");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setAcceptRejectStatus("error");
      console.log("Something Went Wrong", err);
    }
  };

  // const updateJobApplication = async (data) => {
  //   const url = `/job/application/update`;
  //   const payload = {
  //     shiftId: data?.shiftId,
  //     userId: data?.toUserId,
  //     status: "ACCEPTED",
  //     joiningDate: data?.joiningDate,
  //     reportTime: data?.reportTime,
  //   };
  //   setLoading(true);
  //   try {
  //     const response = await post(url, payload);
  //     if (
  //       response?.status === 200 ||
  //       response?.status === 202 ||
  //       response?.status === 204
  //     ) {
  //       setJobApplicationStatus("success");
  //     } else {
  //       setJobApplicationStatus("error");
  //     }
  //     setLoading(false);
  //   } catch (e) {
  //     setJobApplicationStatus("error");
  //     setLoading(false);
  //   }
  // };

  const callMethod = async () => {
    try {
      console.log("user det");
      const url = `/user/users/detail`;
      const response = await get(url);
      setUserDetail(response);
      if (response?.role === ROLE.CARE_HOME) {
        getActiveJobs(response?.company?.companyId);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const getActiveJobs = async (companyId) => {
    setLoading(true);
    const url = `/job/jobs/company?companyId=${companyId}`;
    try {
      const response = await get(url);
      const filteredJob = response?.filter((itm) => !isPastdate(itm?.endDate));
      setJobs(filteredJob);
      setLoading(false);
    } catch (err) {
      console.log("Something Went Wrong", err);
    }
  };

  const handleDateClick = (e) => {
    console.log("dateClick", e);
    resetData();
    setMeetingDetailPerDate([]);
    setClickedDate(e.dateStr);
    if (user?.role === ROLE.CANDIDATE) {
      candidateMeetignForm.setValue("meetingdate", e.dateStr, {
        shouldValidate: true,
      });
    }
    if (user?.role === ROLE.CARE_HOME) {
      setupMeetingForm.setValue("meetingdate", e.dateStr, {
        shouldValidate: true,
      });
    }
  };

  const msg = async (date) => {
    setClickedDate(date?._def?.extendedProps?.getDate);
    getMeetingByDate(date?._def?.extendedProps?.getDate);
  };

  const getMeetingByDate = async (date) => {
    try {
      const url = `/meeting-timesheet/meeting?date=${date}`;
      const response = await get(url);
      if (response) {
        setMeetingDetailPerDate(response?.reverse());
      }
    } catch (e) {
      console.error(e);
    }
  };

  function renderEventContent(eventInfo) {
    console.log("event", eventInfo);
    const { count, timings } = JSON.parse(eventInfo.event.title);
    return (
      <>
        {count > 3 ? (
          <p
            id="demo"
            style={{ color: "#ffffff" }}
            onClick={() => msg(eventInfo.event)}
          >
            {count}
          </p>
        ) : (
          <p
            id="demo"
            style={{ color: "#ffffff", lineHeight: "14px" }}
            onClick={() => msg(eventInfo.event)}
          >
            {timings?.map((itm, index) => (
              <small>
                {itm}
                <br />
              </small>
            ))}
          </p>
        )}
      </>
    );
  }

  const getCandidateByUser = async (jobId) => {
    try {
      if (jobId) {
        const url = `/job/jobs/${jobId}?user-data=true`;
        const response = await get(url);
        const res = [];
        if (response) {
          response?.jobApplicationApplicants?.forEach((itm) => {
            res.push({
              id: itm?.userId,
              name: `${itm?.firstName} ${itm?.lastName}`,
            });
          });
        }
        setUsers(res);
        setDescriptionVal(response?.description);
        console.log("jobData", response);
        setJobval({
          id: response?.id,
          title: response?.title,
          city: response?.company?.sites[0].city,
        });
      }
      setLoading(false);
    } catch (err) {
      console.log("Something Went Wrong", err);
    }
  };

  const getWeekDay = (date) => {
    const language = "en-us";
    const options = { weekday: "long" };
    if (date !== undefined) {
      return new Date(date).toLocaleString(language, options);
    } else {
      return new Date().toLocaleString(language, options);
    }
  };

  const getMonthFull = (date) => {
    const language = "en-us";
    const options = { month: "short" };
    if (date !== undefined) {
      return new Date(date).toLocaleString(language, options);
    } else {
      return new Date().toLocaleString(language, options);
    }
  };

  const getDate = (date) => {
    let d = "";
    if (date !== undefined) {
      d = new Date(date);
    } else {
      d = new Date();
    }

    return d.getDate();
  };

  const getTime = (dateValue) => {
    let date = new Date(dateValue);
    let splitted = date.toLocaleString().split(" ")[1].split(":").splice(0, 2);
    var newTime = [];
    splitted.map((x) => newTime.push(("0" + x).slice(-2)));

    let result = newTime.join(":");

    return result;
  };

  const onChangeDate = (date) => {
    // setMeetingCountDetail([]);
    setClickedDate(date.target.value);
    if (user?.role === ROLE.CANDIDATE) {
      candidateMeetignForm.setValue("meetingdate", date.target.value, {
        shouldValidate: true,
      });
    }
    if (user?.role === ROLE.CARE_HOME) {
      setupMeetingForm.setValue("meetingdate", date.target.value, {
        shouldValidate: true,
      });
    }
  };

  return (
    <>
      <div className="page-wrapper dashboard">
        <Scripts />
        <Header />
        {user?.role === ROLE.CANDIDATE && <CandidateSidebar />}
        {user?.role === ROLE.CARE_HOME && <CareHomeSidebar />}
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="upper-title-box">
              <h3>Meetings</h3>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Ls widget --> */}
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-content">
                      <div className="default-tabs style-two tabs-box">
                        {/* <!--Tabs Box--> */}
                        <ul className="tab-buttons clearfix">
                          {user?.role === ROLE.CARE_HOME && (
                            <li className="tab-btn active-btn" data-tab="#tab1">
                              Carehome
                            </li>
                          )}
                          {user?.role === ROLE.CANDIDATE && (
                            <li className="tab-btn active-btn" data-tab="#tab2">
                              Candidate
                            </li>
                          )}
                        </ul>

                        <div
                          className="tabs-content"
                          style={{ padding: "0 px !important" }}
                        >
                          {/* <!--Inbox--> */}
                          <div
                            className="tab active-tab animated fadeIn"
                            id="tab1"
                            style={{
                              display:
                                user?.role === ROLE.CARE_HOME
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                {/* <!-- Ls widget --> */}
                                <div className="ls-widget">
                                  <div>
                                    <div className="widget-content-1">
                                      <div className="table-outer">
                                        <main className="calendar-contain">
                                          <div className="message-box info">
                                            <p style={{ width: "70%" }}>
                                              <span class="la la-info-circle"></span>{" "}
                                              Click on calendar date to view or
                                              setup an invite.
                                            </p>
                                          </div>

                                          <aside className="calendar__sidebar">
                                            <div className="sidebar__nav">
                                              <div className="option-box">
                                                <ul className="option-list">
                                                  {/* <li>
                                                    <button data-text="New Meeting">
                                                      <span className="la la-plus"></span>
                                                    </button>
                                                  </li> */}
                                                  {/* <li>
                                                    <button data-text="Previous Month">
                                                      <span className="la la-angle-left"></span>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button data-text="Next Month">
                                                      <span className="la la-angle-right"></span>
                                                    </button>
                                                  </li> */}
                                                </ul>
                                              </div>
                                            </div>
                                            <h2 className="sidebar__heading">
                                              {/* {date.format(clickedDate, 'DDDD') } */}
                                              {getWeekDay(clickedDate)}
                                              <br />
                                              {getMonthFull(clickedDate)}{" "}
                                              {getDate(clickedDate)}
                                            </h2>
                                            <ul className="sidebar__list">
                                              {/* {meetingDetailPerDate?.length ===
                                                0 && (
                                                <>
                                                  <li className="text-center pb-2">
                                                    No meetings scheduled
                                                  </li>
                                                </>
                                              )} */}
                                              {meetingDetailPerDate?.length >
                                                0 && (
                                                <li className="text-danger">
                                                  <span class="la la-info-circle"></span>{" "}
                                                  Click on an event to display
                                                  details.
                                                </li>
                                              )}
                                              {meetingDetailPerDate?.map(
                                                (listItem) => {
                                                  console.log(
                                                    "listItem",
                                                    listItem
                                                  );
                                                  return (
                                                    <li className="sidebar__list-item">
                                                      <span className="list-item__time">
                                                        {getTimeFromDate(
                                                          listItem.meetingDateTime
                                                        )}
                                                      </span>{" "}
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            cursor: "pointer",
                                                            color: "#0d6efd",
                                                          }}
                                                          onClick={() => {
                                                            getMeetingById(
                                                              listItem
                                                            );
                                                          }}
                                                        >
                                                          {listItem.toUserName}{" "}
                                                        </div>
                                                        <span
                                                          className={
                                                            listItem?.status ===
                                                            "Reschedule_Requested"
                                                              ? "scheduled"
                                                              : listItem?.status ===
                                                                "Completed"
                                                              ? "accepted"
                                                              : listItem.status.toLowerCase()
                                                          }
                                                        >
                                                          {listItem?.status
                                                            ?.split("_")
                                                            ?.join(" ")}
                                                        </span>
                                                      </div>
                                                    </li>
                                                  );
                                                }
                                              )}
                                              <li>
                                                <button
                                                  className="theme-btn btn-sm btn-style-one large"
                                                  onClick={() => {
                                                    scrollTop(
                                                      ".setupMeetingTab"
                                                    );
                                                    $(
                                                      ".setupMeetingTab"
                                                    ).trigger("click");
                                                  }}
                                                >
                                                  Add New Meeting
                                                </button>
                                              </li>
                                              {/* <li className="sidebar__list-item sidebar__list-item--complete">
                                                <span className="list-item__time">
                                                  8.30
                                                </span>{" "}
                                                <a href="#">Candidate A</a>{" "}
                                                <span className="accepted">
                                                  Accepted
                                                </span>
                                              </li>
                                              <li className="sidebar__list-item sidebar__list-item--complete">
                                                <span className="list-item__time">
                                                  10.00
                                                </span>{" "}
                                                <a href="#">Candidate B</a>{" "}
                                                <span className="accepted">
                                                  Accepted
                                                </span>
                                              </li>
                                              <li className="sidebar__list-item">
                                                <span className="list-item__time">
                                                  2.30
                                                </span>{" "}
                                                <a href="#">Candidate C</a>{" "}
                                                <span className="rescheduled">
                                                  Reschedule
                                                </span>
                                              </li>
                                              <li className="sidebar__list-item">
                                                <span className="list-item__time">
                                                  4.00
                                                </span>{" "}
                                                <a href="#">Candidate D</a>
                                                <span className="rejected">
                                                  Declined
                                                </span>
                                              </li> */}
                                            </ul>
                                          </aside>
                                          <section className="calendar__days">
                                            <FullCalendar
                                              plugins={[
                                                dayGridPlugin,
                                                interactionPlugin,
                                              ]}
                                              initialView="dayGridMonth"
                                              // weekends={false}
                                              dateClick={(e) =>
                                                handleDateClick(e)
                                              }
                                              events={meetingCountDetail}
                                              eventContent={renderEventContent}
                                              datesSet={(e) => {
                                                console.log("===>", e);
                                                const date = new Date(
                                                  e.startStr
                                                );
                                                let currentTime = new Date(
                                                  date
                                                ).getTime();
                                                let updatedTIme = new Date(
                                                  currentTime +
                                                    10 * 24 * 60 * 60 * 1000
                                                );
                                                const month =
                                                  Number(
                                                    updatedTIme?.getMonth()
                                                  ) + 1;
                                                const year =
                                                  updatedTIme.getFullYear();
                                                const payload = {
                                                  monthNumber: month,
                                                  year: year,
                                                };
                                                setCalendarMonthsDates(payload);
                                                console.log("payload", payload);
                                                getMeetingCounts(payload);
                                              }}
                                            />
                                          </section>
                                        </main>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-12">
                                {/* <!-- Ls widget --> */}
                                <div className="ls-widget">
                                  <div className="tabs-box">
                                    <ul className="accordion-box">
                                      {/* <!--Block--> */}
                                      <li className="accordion block active-block">
                                        <div className="acc-btn setupMeetingTab">
                                          Setup/Update/Join Meeting{" "}
                                          <span className="icon flaticon-add"></span>
                                        </div>
                                        <div
                                          className="acc-content"
                                          style={{ display: "none" }}
                                        >
                                          <div className="content">
                                            <div className="widget-content-1">
                                              <form
                                                className="default-form"
                                                onSubmit={setupMeetingForm.handleSubmit(
                                                  meetingDetailPerUser?.length >
                                                    0
                                                    ? UpdateMeetingInvite
                                                    : sendMeetingInvite
                                                )}
                                                noValidate
                                              >
                                                <div className="row">
                                                  {meetingDetailPerUser?.length >
                                                    0 && (
                                                    <ul className="sidebar__list">
                                                      <li className="pb-2">
                                                        <span
                                                          className={
                                                            meetingDetailPerUser[0]
                                                              ?.status ===
                                                            "Reschedule_Requested"
                                                              ? "scheduled"
                                                              : meetingDetailPerUser[0]?.status?.toLowerCase()
                                                          }
                                                        >
                                                          {meetingDetailPerUser[0]?.status
                                                            ?.split("_")
                                                            ?.join(" ")}
                                                        </span>
                                                      </li>
                                                    </ul>
                                                  )}
                                                  <div className="form-group col-lg-6 col-md-12">
                                                    <label>Job Title</label>
                                                    <Autocomplete
                                                      disablePortal
                                                      id="combo-box-demo"
                                                      {...setupMeetingForm.register(
                                                        "searchActiveJobs",
                                                        {
                                                          required: {
                                                            value: true,
                                                            message: "required",
                                                          },
                                                        }
                                                      )}
                                                      value={jobVal}
                                                      // defaultValue={jobVal}
                                                      options={jobs ? jobs : []}
                                                      // sx={{ width: 500 }}
                                                      getOptionLabel={(
                                                        option
                                                      ) => option.title}
                                                      onChange={(
                                                        _,
                                                        newValue
                                                      ) => {
                                                        setupMeetingForm.setValue(
                                                          "searchActiveJobs",
                                                          newValue,
                                                          {
                                                            shouldValidate: true,
                                                          }
                                                        );
                                                        setJobval(newValue);
                                                        getCandidateByUser(
                                                          newValue?.id
                                                        );
                                                      }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          label="Search Active Job"
                                                        />
                                                      )}
                                                    />
                                                    {errors?.searchActiveJobs && (
                                                      <InputError
                                                        message={
                                                          errors
                                                            ?.searchActiveJobs
                                                            ?.message
                                                        }
                                                        key={
                                                          errors
                                                            ?.searchActiveJobs
                                                            ?.message
                                                        }
                                                      />
                                                    )}
                                                    {/* <input type="text" name="job" value = {'hi'}placeholder="Search Active Job" onChange={searchJob} required/>
                                                                                                            <span className="icon flaticon-search-3"></span> */}
                                                  </div>
                                                  <div className="form-group col-lg-6 col-md-12">
                                                    <label>
                                                      Candidate Name
                                                    </label>
                                                    <Autocomplete
                                                      disablePortal
                                                      id="combo-box-demo"
                                                      {...setupMeetingForm.register(
                                                        "searchCandidate",
                                                        {
                                                          required: {
                                                            value: true,
                                                            message: "required",
                                                          },
                                                        }
                                                      )}
                                                      options={users}
                                                      defaultValue={userVal}
                                                      value={userVal}
                                                      // sx={{ width: 500 }}
                                                      getOptionLabel={(
                                                        option
                                                      ) => option.name}
                                                      onChange={(
                                                        _,
                                                        newValue
                                                      ) => {
                                                        setupMeetingForm.setValue(
                                                          "searchCandidate",
                                                          newValue,
                                                          {
                                                            shouldValidate: true,
                                                          }
                                                        );

                                                        setUserval(newValue);
                                                      }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          label="Search Candidates"
                                                        />
                                                      )}
                                                    />
                                                    {errors?.searchCandidate && (
                                                      <InputError
                                                        message={
                                                          errors
                                                            ?.searchCandidate
                                                            ?.message
                                                        }
                                                        key={
                                                          errors
                                                            ?.searchCandidate
                                                            ?.message
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                  <div className="form-group col-lg-12 col-md-12">
                                                    <label>
                                                      Add notes for applicant
                                                    </label>
                                                    <textarea
                                                      style={{
                                                        minHeight: "160px",
                                                      }}
                                                      name={
                                                        "meetingDescription"
                                                      }
                                                      {...setupMeetingForm.register(
                                                        "meetingDescription"
                                                      )}
                                                      placeholder="Add information for the potential candidate such as any pre-requisites, address, documents to bring, etc."
                                                    ></textarea>
                                                  </div>

                                                  {/* <!-- JD --> */}
                                                  {descriptionVal?.length >
                                                    0 && (
                                                    <div className="form-group job-detail col-lg-12 col-md-12">
                                                      <label>
                                                        Job Description
                                                      </label>
                                                      <p>{descriptionVal}</p>
                                                    </div>
                                                  )}

                                                  <div className="message-box info">
                                                    <p>
                                                      Please note: All meetings
                                                      will be set up for 30
                                                      minutes only.
                                                    </p>
                                                  </div>

                                                  {/* <!-- Input -->/ */}
                                                  <div className="form-group col-lg-6 col-md-12">
                                                    <label>Meeting Date</label>
                                                    <input
                                                      type="date"
                                                      name="meetingdate"
                                                      placeholder=""
                                                      required
                                                      min={formatDateHyphen(
                                                        new Date()
                                                      )}
                                                      onChange={(e) =>
                                                        onChangeDate(e)
                                                      }
                                                      {...setupMeetingForm.register(
                                                        "meetingdate",
                                                        {
                                                          required: {
                                                            value: true,
                                                            message: "required",
                                                          },
                                                        }
                                                      )}
                                                    />
                                                    {errors?.meetingdate && (
                                                      <InputError
                                                        message={
                                                          errors?.meetingdate
                                                            ?.message
                                                        }
                                                        key={
                                                          errors?.meetingdate
                                                            ?.message
                                                        }
                                                      />
                                                    )}
                                                  </div>

                                                  {/* <!-- Input --> */}
                                                  <div className="form-group col-lg-6 col-md-12">
                                                    <label>
                                                      Meeting Start Time
                                                    </label>
                                                    <input
                                                      type="time"
                                                      name="meetingtime"
                                                      step="1800"
                                                      placeholder=""
                                                      required
                                                      onChange={(e) => {
                                                        console.log("e", e);
                                                        setupMeetingForm.setValue(
                                                          "meetingtime",
                                                          e.target.value,
                                                          {
                                                            shouldValidate: true,
                                                          }
                                                        );
                                                        setTimeValue(
                                                          e.target.value
                                                        );
                                                      }}
                                                      {...setupMeetingForm.register(
                                                        "meetingtime",
                                                        {
                                                          required: {
                                                            value: true,
                                                            message: "required",
                                                          },
                                                        }
                                                      )}
                                                    />
                                                    {errors?.meetingtime && (
                                                      <InputError
                                                        message={
                                                          errors?.meetingtime
                                                            ?.message
                                                        }
                                                        key={
                                                          errors?.meetingtime
                                                            ?.message
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                  <div className="form-group col-lg-6 col-md-12">
                                                    <label>Meeting Type</label>
                                                    <select
                                                      {...setupMeetingForm.register(
                                                        "meetingType"
                                                      )}
                                                      name="meetingType"
                                                      className="chosen-select-meetingType"
                                                    >
                                                      <option value={""}>
                                                        Select
                                                      </option>
                                                      <option value={"TELECON"}>
                                                        Telephone
                                                      </option>
                                                      <option
                                                        value={"IN_PERSON"}
                                                      >
                                                        In Person
                                                      </option>
                                                      <option
                                                        value={"VIDEO_CALL"}
                                                      >
                                                        Video Call
                                                      </option>
                                                    </select>
                                                  </div>
                                                  {meetingURL && meetingURL !== 'NA' && (
                                                    <div className="form-group col-lg-6 col-md-12 pt-5">
                                                      <a className="btn btn-primary" href={`/#/video-meeting?meetingHash=${meetingURL}`}>Join Meeting</a>
                                                    </div>
                                                  )}
                                                  <div className="form-group col-lg-12 col-md-12">
                                                    <div className="row">
                                                      <div className="col-md-12">
                                                        {sentInviteFlag ===
                                                          "success" && (
                                                          <Success
                                                            msg={`You have successfully set up a meeting.`}
                                                          />
                                                        )}
                                                        {acceptRejectStatus ===
                                                          "success" && (
                                                          <Success
                                                            msg={`You have successfully updated your meeting.`}
                                                          />
                                                        )}
                                                        {acceptRejectStatus ===
                                                          "error" && (
                                                          <Error
                                                            msg={`Something went wrong while set up a meeting. Please try again.`}
                                                          />
                                                        )}
                                                        {sentInviteFlag ===
                                                          "error" && (
                                                          <Error
                                                            msg={`Something went wrong while set up a meeting. Please try again.`}
                                                          />
                                                        )}
                                                        {deleteMeetingStatus ===
                                                          "success" && (
                                                          <Success
                                                            msg={`Your meeting has been successfully deleted.`}
                                                          />
                                                        )}
                                                        {deleteMeetingStatus ===
                                                          "error" && (
                                                          <Error
                                                            msg={`Something went wrong while deleting a meeting. Please try again.`}
                                                          />
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="buttons">
                                                      {isLoading && <Loader />}
                                                      {!isLoading && (
                                                        <div className="meeting buttons">
                                                          <button
                                                            type="submit"
                                                            disabled={
                                                              meetingUserStatus ===
                                                              "Completed"
                                                            }
                                                            className="theme-btn btn-style-one large"
                                                            onClick={() => {
                                                              setupMeetingForm.setValue(
                                                                "submitValue",
                                                                {
                                                                  action:
                                                                    "SEND_INVITE",
                                                                  status:
                                                                    "Reschedule_Requested",
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            Send Invite
                                                          </button>
                                                          {meetingDetailPerUser?.length >
                                                          0
                                                            ? (meetingDetailPerUser[0]
                                                                ?.status ===
                                                                "Scheduled" ||
                                                                meetingDetailPerUser[0]
                                                                  ?.status ===
                                                                  "Reschedule_Requested") && (
                                                                <button
                                                                  type="button"
                                                                  className="theme-btn btn-style-nine large"
                                                                  onClick={() => {
                                                                    deleteMeeting();
                                                                  }}
                                                                >
                                                                  Delete Meeting
                                                                </button>
                                                              )
                                                            : ""}
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </li>

                                      {/* <!--Block--> */}
                                      <li className="accordion block">
                                        <div className="acc-btn addMeetingNoteSection">
                                          {" "}
                                          Add Meeting Minutes{" "}
                                          <span className="icon flaticon-add"></span>
                                        </div>
                                        <div
                                          className="acc-content current"
                                          style={{ display: "none" }}
                                        >
                                          <div className="content">
                                            <form
                                              className="default-form"
                                              onSubmit={setupMeetingForm.handleSubmit(
                                                UpdateMeetingInvite
                                              )}
                                              noValidate
                                            >
                                              <div className="row">
                                                <div className="message-box warning">
                                                  <p>
                                                    Make sure the meeting has
                                                    concluded before adding
                                                    comments and taking action.
                                                  </p>
                                                </div>
                                                {/* <!-- Input --> */}

                                                <div className="form-group col-lg-6 col-md-12">
                                                  <label>Candidate Name</label>
                                                  {meetingDetailPerUser?.length >
                                                    0 && (
                                                    <p>
                                                      {
                                                        meetingDetailPerUser[0]
                                                          ?.toUserName
                                                      }
                                                    </p>
                                                  )}
                                                </div>

                                                <div className="form-group col-lg-6 col-md-12">
                                                  <label>Job Title</label>
                                                  <p>{jobVal?.title}</p>
                                                </div>

                                                <div className="form-group col-lg-12 col-md-12">
                                                  <label>Meeting Minutes</label>
                                                  <textarea
                                                    style={{
                                                      minHeight: "160px",
                                                    }}
                                                    value={meetingNotes}
                                                    name={"meetingNotes"}
                                                    {...register(
                                                      "meetingNotes"
                                                    )}
                                                    onChange={(e) =>
                                                      setMeetingNotes(
                                                        e.target.value
                                                      )
                                                    }
                                                    placeholder="Enter Meeting Minutes"
                                                  ></textarea>
                                                </div>
                                                <div className="form-group col-lg-6 col-md-12">
                                                  <label>Joining Date</label>
                                                  <input
                                                    type="date"
                                                    name="joiningDate"
                                                    placeholder=""
                                                    required
                                                    {...setupMeetingForm.register(
                                                      "joiningDate"
                                                    )}
                                                  />
                                                </div>
                                                <div className="form-group col-lg-6 col-md-12">
                                                  <label>Reporting Time</label>
                                                  <input
                                                    type="time"
                                                    name="reportingTime"
                                                    step="1800"
                                                    placeholder=""
                                                    required
                                                    {...setupMeetingForm.register(
                                                      "reportingTime"
                                                    )}
                                                  />
                                                </div>

                                                {/* <!-- Input --> */}
                                                <div className="form-group col-lg-12 col-md-12">
                                                  {acceptRejectStatus ===
                                                    "success" && (
                                                    <Success
                                                      msg={`Successfully updated the response.`}
                                                    />
                                                  )}
                                                  {acceptRejectStatus ===
                                                    "error" && (
                                                    <Error
                                                      msg={`Something went wrong while updating response. please try again.`}
                                                    />
                                                  )}
                                                  {jobApplicationStatus ===
                                                    "validationError" && (
                                                    <Error
                                                      msg={`Please fill all the required fields.`}
                                                    />
                                                  )}
                                                  {jobApplicationStatus ===
                                                    "error" && (
                                                    <Error
                                                      msg={`Something went wrong while updating job application. Please try again.`}
                                                    />
                                                  )}
                                                  {jobApplicationStatus ===
                                                    "success" && (
                                                    <Success
                                                      msg={`Successfully selected the employee.`}
                                                    />
                                                  )}
                                                  {isLoading && <Loading />}
                                                  {!isLoading && (
                                                    <div className="meeting buttons">
                                                      <button
                                                        type="submit"
                                                        disabled={
                                                          meetingUserStatus ===
                                                          "Completed"
                                                        }
                                                        name="submit-result"
                                                        className="theme-btn btn-style-seven large"
                                                        onClick={() => {
                                                          setupMeetingForm.setValue(
                                                            "submitValue",
                                                            {
                                                              action:
                                                                "SELECT_CANDIDATE",
                                                              status:
                                                                "Completed",
                                                            }
                                                          );
                                                        }}
                                                      >
                                                        Select Candidate
                                                      </button>
                                                      <button
                                                        type="submit"
                                                        name="submit-result"
                                                        disabled={
                                                          meetingUserStatus ===
                                                          "Completed"
                                                        }
                                                        className="theme-btn btn-style-nine large"
                                                        onClick={() => {
                                                          setupMeetingForm.setValue(
                                                            "submitValue",
                                                            {
                                                              action:
                                                                "REJECT_CANDIDATE",
                                                              status:
                                                                "Completed",
                                                            }
                                                          );
                                                        }}
                                                      >
                                                        Reject Candidate
                                                      </button>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab"
                            id="tab2"
                            style={{
                              display:
                                user?.role === ROLE.CANDIDATE
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                {/* <!-- Ls widget --> */}
                                <div className="ls-widget">
                                  <div>
                                    <div className="widget-content-1">
                                      <div className="table-outer">
                                        <main className="calendar-contain">
                                          <div className="message-box info">
                                            <p style={{ width: "70%" }}>
                                              <span class="la la-info-circle"></span>{" "}
                                              Click on calendar date to view or
                                              update an invite.
                                            </p>
                                          </div>
                                          {/* <section className="title-bar">
                                            <h1 className="title-bar__year">
                                              April 2023
                                            </h1>
                                          </section> */}

                                          <aside className="calendar__sidebar">
                                            <div className="sidebar__nav">
                                              <div className="option-box"></div>
                                            </div>
                                            <h2 className="sidebar__heading">
                                              {/* {date.format(clickedDate, 'DDDD') } */}
                                              {getWeekDay(clickedDate)}
                                              <br />
                                              {getMonthFull(clickedDate)}{" "}
                                              {getDate(clickedDate)}
                                            </h2>
                                            <ul className="sidebar__list">
                                              {/* {meetingDetailPerDate?.length ===
                                                0 && (
                                                <li>No meetings scheduled</li>
                                              )} */}
                                              {meetingDetailPerDate?.length >
                                                0 && (
                                                <li className="text-danger">
                                                  <span class="la la-info-circle"></span>{" "}
                                                  Click on an event to display
                                                  details.
                                                </li>
                                              )}
                                              {meetingDetailPerDate?.map(
                                                (listItem, index) => {
                                                  return (
                                                    <li className="sidebar__list-item">
                                                      <span className="list-item__time">
                                                        {getTimeFromDate(
                                                          listItem.meetingDateTime
                                                        )}
                                                      </span>
                                                      {"   "}
                                                      <span
                                                        style={{
                                                          cursor: "pointer",
                                                          color: "#0d6efd",
                                                        }}
                                                        onClick={() =>
                                                          getMeetingById(
                                                            listItem
                                                          )
                                                        }
                                                      >
                                                        {listItem.companyName
                                                          ? listItem.companyName
                                                          : listItem.fromUserName}{" "}
                                                      </span>
                                                      <span
                                                        className={
                                                          listItem?.status ===
                                                          "Reschedule_Requested"
                                                            ? "scheduled"
                                                            : listItem?.status ===
                                                              "Completed"
                                                            ? "accepted"
                                                            : listItem.status.toLowerCase()
                                                        }
                                                      >
                                                        {listItem?.status.replace(
                                                          "_",
                                                          " "
                                                        )}
                                                      </span>
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </aside>
                                          <section className="calendar__days">
                                            <FullCalendar
                                              plugins={[
                                                dayGridPlugin,
                                                interactionPlugin,
                                              ]}
                                              initialView="dayGridMonth"
                                              // weekends={false}
                                              dateClick={(e) =>
                                                handleDateClick(e)
                                              }
                                              events={meetingCountDetail}
                                              eventContent={renderEventContent}
                                              datesSet={(e) => {
                                                console.log("===>", e);
                                                const date = new Date(
                                                  e.startStr
                                                );
                                                let currentTime = new Date(
                                                  date
                                                ).getTime();
                                                let updatedTIme = new Date(
                                                  currentTime +
                                                    10 * 24 * 60 * 60 * 1000
                                                );
                                                const month =
                                                  Number(
                                                    updatedTIme?.getMonth()
                                                  ) + 1;
                                                const year =
                                                  updatedTIme.getFullYear();
                                                const payload = {
                                                  monthNumber: month,
                                                  year: year,
                                                };
                                                setCalendarMonthsDates(payload);
                                                console.log("payload", payload);
                                                getMeetingCounts(payload);
                                              }}
                                            />
                                          </section>
                                        </main>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-12">
                                {/* <!-- Ls widget --> */}
                                <div className="ls-widget view-schedule-candidate-section">
                                  <div className="tabs-box">
                                    <div className="widget-meeting-title">
                                      <h4>View/Reschedule/Join Meeting</h4>
                                    </div>

                                    <div className="widget-content-1">
                                      <form
                                        className="default-form"
                                        onSubmit={candidateMeetignForm.handleSubmit(
                                          UpdateMeetingInvite
                                        )}
                                        noValidate
                                      >
                                        <div className="row">
                                          {/* <!-- Input --> */}

                                          <div className="form-group col-lg-6 col-md-12">
                                            <label>Carehome Name</label>
                                            <input
                                              type="text"
                                              name="candidate"
                                              placeholder=""
                                              disabled
                                              // value={companyName}
                                              readonly
                                              {...candidateMeetignForm.register(
                                                "candidate",
                                                {
                                                  required: {
                                                    value: true,
                                                    message: "required",
                                                  },
                                                }
                                              )}
                                            />
                                            {candidateError?.candidate && (
                                              <InputError
                                                message={
                                                  candidateError?.candidate
                                                    ?.message
                                                }
                                                key={
                                                  candidateError?.candidate
                                                    ?.message
                                                }
                                              />
                                            )}
                                          </div>

                                          <div className="form-group col-lg-6 col-md-12">
                                            <label>Job Title</label>
                                            <input
                                              type="text"
                                              name="job"
                                              // value={jobVal?.title}
                                              placeholder=""
                                              disabled
                                              readonly
                                              {...candidateMeetignForm.register(
                                                "job",
                                                {
                                                  required: {
                                                    value: true,
                                                    message: "required",
                                                  },
                                                }
                                              )}
                                            />
                                            {candidateError?.job && (
                                              <InputError
                                                message={
                                                  candidateError?.job?.message
                                                }
                                                key={
                                                  candidateError?.job?.message
                                                }
                                              />
                                            )}
                                          </div>
                                          <div className="form-group col-lg-6 col-md-12">
                                            <label>Meeting Type</label>
                                            <input
                                              type="text"
                                              name="meetingType"
                                              placeholder=""
                                              disabled
                                              readonly
                                              {...candidateMeetignForm.register(
                                                "meetingType"
                                              )}
                                            />
                                          </div>
                                          {meetingURL && meetingURL !== 'NA' && (
                                            <div className="form-group col-lg-6 col-md-12 pt-5">
                                              <a className="btn btn-primary" href={`/#/video-meeting?meetingHash=${meetingURL}`}>Join Meeting</a>
                                            </div>
                                          )}
                                          <div className="form-group col-lg-12 col-md-12">
                                            <label>Notes to Applicant</label>
                                            <textarea
                                              style={{
                                                minHeight: "160px",
                                              }}
                                              placeholder=""
                                              disabled
                                              readonly
                                              name={"meetingDescription"}
                                              {...candidateMeetignForm.register(
                                                "meetingDescription"
                                              )}
                                            ></textarea>
                                          </div>

                                          {/* <!-- JD --> */}
                                          <div className="form-group job-detail col-lg-12 col-md-12">
                                            <label>Job Description</label>
                                            <p>{descriptionVal}</p>
                                          </div>

                                          <div
                                            className="message-box info"
                                            style={{ paddingLeft: "2%" }}
                                          >
                                            <p>
                                              Please note: All meetings are for
                                              30 minutes only.
                                            </p>
                                          </div>

                                          {/* <!-- Input --> */}
                                          <div className="form-group col-lg-6 col-md-12">
                                            <label>Meeting Date</label>
                                            <input
                                              type="date"
                                              name="meetingdate"
                                              placeholder=""
                                              required
                                              onChange={(e) => onChangeDate(e)}
                                              // value={clickedDate}
                                              {...candidateMeetignForm.register(
                                                "meetingdate",
                                                {
                                                  required: {
                                                    value: true,
                                                    message: "required",
                                                  },
                                                }
                                              )}
                                            />
                                            {candidateError?.meetingdate && (
                                              <InputError
                                                message={
                                                  candidateError?.meetingdate
                                                    ?.message
                                                }
                                                key={
                                                  candidateError?.meetingdate
                                                    ?.message
                                                }
                                              />
                                            )}
                                          </div>

                                          {/* <!-- Input --> */}
                                          <div className="form-group col-lg-6 col-md-12">
                                            <label>Meeting Start Time</label>
                                            <input
                                              type="time"
                                              name="meetingtime"
                                              step="1800"
                                              placeholder=""
                                              required
                                              onChange={(e) => {
                                                candidateMeetignForm.setValue(
                                                  "meetingtime",
                                                  e.target.value,
                                                  {
                                                    shouldValidate: true,
                                                  }
                                                );
                                                setTimeValue(e.target.value);
                                              }}
                                              {...candidateMeetignForm.register(
                                                "meetingtime",
                                                {
                                                  required: {
                                                    value: true,
                                                    message: "required",
                                                  },
                                                }
                                              )}
                                              // value={timeValue}
                                            />
                                            {candidateError?.meetingtime && (
                                              <InputError
                                                message={
                                                  candidateError?.meetingtime
                                                    ?.message
                                                }
                                                key={
                                                  candidateError?.meetingtime
                                                    ?.message
                                                }
                                              />
                                            )}
                                          </div>
                                          <div>
                                            <div className="checkbox-outer">
                                              <ul className="checkboxes square">
                                                <li>
                                                  <input
                                                    id="check-candidate-confirm"
                                                    type="checkbox"
                                                    name="candidateConfirmCheck"
                                                    value={"Yes"}
                                                    {...candidateMeetignForm.register(
                                                      "candidateConfirmCheck",
                                                      {
                                                        required: {
                                                          value: true,
                                                          message: "required",
                                                        },
                                                      }
                                                    )}
                                                  />
                                                  <label htmlFor="check-candidate-confirm">
                                                    Have you checked the date
                                                    and time before taking the
                                                    action ?
                                                  </label>
                                                  {candidateError?.candidateConfirmCheck && (
                                                    <InputError
                                                      message={
                                                        candidateError
                                                          ?.candidateConfirmCheck
                                                          ?.message
                                                      }
                                                      key={
                                                        candidateError
                                                          ?.candidateConfirmCheck
                                                          ?.message
                                                      }
                                                    />
                                                  )}
                                                </li>
                                              </ul>
                                            </div>
                                          </div>

                                          {/* <!-- Input --> */}
                                          <div className="form-group col-lg-12 col-md-12 text-right">
                                            <div className="form-group col-lg-12 col-md-12">
                                              {acceptRejectStatus ===
                                                "success" && (
                                                <Success
                                                  msg={`Successfully updated the response.`}
                                                />
                                              )}
                                              {acceptRejectStatus ===
                                                "error" && (
                                                <Error
                                                  msg={`Something went wrong while updating response. please try again.`}
                                                />
                                              )}
                                              {isLoading && <Loading />}
                                              {!isLoading && (
                                                <div className="meeting buttons">
                                                  <button
                                                    type="submit"
                                                    disabled={
                                                      meetingUserStatus ===
                                                      "Completed"
                                                    }
                                                    onClick={() => {
                                                      candidateMeetignForm.setValue(
                                                        "submitValue",
                                                        {
                                                          action:
                                                            "ACCEPT_INVITE",
                                                          status: "Accepted",
                                                        }
                                                      );
                                                    }}
                                                    className="theme-btn btn-style-seven large"
                                                  >
                                                    Accept Invite
                                                  </button>
                                                  <button
                                                    type="submit"
                                                    disabled={
                                                      meetingUserStatus ===
                                                      "Completed"
                                                    }
                                                    onClick={() => {
                                                      candidateMeetignForm.setValue(
                                                        "submitValue",
                                                        {
                                                          action:
                                                            "REQUEST_RESCHEDULE",
                                                          status:
                                                            "Reschedule_Requested",
                                                        }
                                                      );
                                                    }}
                                                    className="theme-btn btn-style-two large"
                                                  >
                                                    Request Reschedule
                                                  </button>
                                                  <button
                                                    type="submit"
                                                    disabled={
                                                      meetingUserStatus ===
                                                      "Completed"
                                                    }
                                                    onClick={() => {
                                                      candidateMeetignForm.setValue(
                                                        "submitValue",
                                                        {
                                                          action:
                                                            "REJECT_INVITE",
                                                          status: "Declined",
                                                        }
                                                      );
                                                    }}
                                                    className="theme-btn btn-style-nine large"
                                                  >
                                                    Reject Invite
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default Meetings;
