import React, { Fragment, useState } from "react";

const Warning = ({ msg, setSuccessMsg }) => {
  const [isVisible, setSetIsVisible] = useState(true);
  return (
    <Fragment>
      {isVisible ? (
        <div className="message-box warning pb-0 pt-o">
          <p>{msg}</p>
          <button
            className="close-btn"
            onClick={() => {
              setSetIsVisible(false);
              if (setSuccessMsg) {
                setSuccessMsg("");
              }
            }}
          >
            <span className="close_icon"></span>
          </button>
        </div>
      ) : null}
    </Fragment>
  );
};
export default Warning;
