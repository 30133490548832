import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/auth";
import Candidate from "./Candidate";
import CareHome from "./CareHome";
import { getSession } from "../../../utils/session";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";

import Footer from "./Footer";
import { ROLE } from "../../../constant/role";
import Admin from "./Admin";
const Dashboard = () => {
  const {
    auth: { isAuthenticated },
  } = useContext(AuthContext);
  console.log("isAuthenticated", isAuthenticated);
  const [user, setUser] = useState();
  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
  }, []);
  return (
    <React.Fragment>
      <div className="page-wrapper dashboard ">
        {user?.role === ROLE.CANDIDATE && (
          <Candidate />
        )}
        {user?.role === ROLE.CARE_HOME && (
          <CareHome />
        )}
        {user?.role === ROLE.ADMIN && (
          <Admin />
        )}
        <Footer />
      </div>
    </React.Fragment>
  );
};
export default Dashboard;
