export const formatData = (data) => {
    console.log("DATA", data);  
    return {
    "role": data?.roleId,
    "companyId": data?.companyId,
    "firstName": data?.fname,
    "lastName": data?.lname,
    "password": data?.pw,
    "email": data?.email,
    "phone": data?.phone,
    "userTypeId": 786,
    "photo": null,
    "photoIdProof": null,
    "addressFirstLine":data?.addressFirstLine,
    "addressSecondLine": data?.addressSecondLine,
    "city": data?.city,
    "country": data?.untry,
    "postCode": data?.postcode,
    "dbs": null,
    "startDate": null,
    "companyName": data?.CompanyName,
    "verified": 0,
    "latitude": data?.latitude,
    "longitude": data?.longitude,
    }}