import { useEffect, useState } from "react";

function JobTags({ endDate, job }) {
  const [showUrgentDate, setShowUrgentDate] = useState(false);
  useEffect(() => {
    initShowUrgent();
  }, []);
  const initShowUrgent = () => {
    const jobEndDate = new Date(endDate);
    const currentDate = new Date();
    const Difference_In_Time = jobEndDate.getTime() - currentDate.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    if (Difference_In_Days >= 0 && Difference_In_Days < 8) {
      setShowUrgentDate(true);
    }
  };
  return (
    <ul className="job-other-info">
      <li className="time">{job?.jobCategory?.split("_")?.join(" ")}</li>
      {showUrgentDate && <li className="required">Urgent</li>}
    </ul>
  );
}

export default JobTags;
