import React, { useEffect, useState } from "react";
import fileDownload from "js-file-download";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import { get, getFile } from "../../../api";
import Revenue from "./Admin/Revenue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

function AdminReportCharts() {
  const [careHomeRegisteredType, setCareHomeRegisteredType] = useState("month");
  const [careHomeRegistered, setCareHomeRegistered] = useState({
    labels: [],
    datasets: [
      {
        label: "Carehomes Registered",
        data: [],
        backgroundColor: "#1967d2",
      },
    ],
  });
  const [candidateRegisteredType, setCandidateRegisteredType] =
    useState("month");
  const [candidateRegistered, setCandidateRegistered] = useState({
    labels: [],
    datasets: [
      {
        label: "Candidate Registered",
        data: [],
        backgroundColor: "#55bbaf",
      },
    ],
  });
  useEffect(() => {
    getCareHomeRegistered(false);
    getCandidateRegistered(false);
  }, []);

  const getCareHomeRegistered = async (isDownload, type) => {
    try {
      let url = `/user/report/carehome-registered?type=${
        type ? type : careHomeRegisteredType
      }&download=${isDownload}`;
      let response;
      if (isDownload) {
        response = await getFile(url);
      } else {
        response = await get(url);
      }
      let labels = [];
      let data = [];
      if (isDownload && response?.data) {
        fileDownload(
          response?.data,
          response?.headers?.["content-disposition"]?.split("filename=")[1]
        );
      }
      if (!isDownload && response?.count) {
        for (const [key, value] of Object.entries(response?.count)) {
          labels.push(key);
          data.push(value);
        }
        setCareHomeRegistered({
          labels: labels,
          datasets: [
            {
              label: "Carehomes Registered",
              data: data,
              backgroundColor: "#1967d2",
            },
          ],
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getCandidateRegistered = async (isDownload, type) => {
    try {
      let url = `/user/report/candidate-registered?type=${
        type ? type : candidateRegisteredType
      }&download=${isDownload}`;
      let response;
      if (isDownload) {
        response = await getFile(url);
      } else {
        response = await get(url);
      }
      let labels = [];
      let data = [];
      if (isDownload && response?.data) {
        fileDownload(
          response?.data,
          response?.headers?.["content-disposition"]?.split("filename=")[1]
        );
      }
      if (!isDownload && response?.count) {
        for (const [key, value] of Object.entries(response?.count)) {
          labels.push(key);
          data.push(value);
        }
        setCandidateRegistered({
          labels: labels,
          datasets: [
            {
              label: "Candidate Registered",
              data: data,
              backgroundColor: "#55bbaf",
            },
          ],
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="graph-widget ls-widget">
          <div className="tabs-box">
            <Revenue />
          </div>

          <div className="tabs-box">
            <div className="widget-title">
              <h4>Carehomes Registered</h4>
              <div className="chosen-outer">
                {/* <!--Tabs Box--> */}
                <select
                  className="chosen-select-carehome-registered"
                  onChange={(e) => {
                    e?.stopPropagation();
                    setCareHomeRegisteredType(e?.target?.value);
                    getCareHomeRegistered(false, e?.target?.value);
                  }}
                >
                  <option value={"month"}>Last 6 Months</option>
                  <option value={"week"}>Last 24 Weeks</option>
                </select>
                <div>
                  <button
                    type="button"
                    className="theme-btn btn-sm btn-style-three"
                    onClick={(e) => {
                      e?.stopPropagation();
                      getCareHomeRegistered(true);
                    }}
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>

            <div className="widget-content">
              <Bar options={options} data={careHomeRegistered} />
            </div>
          </div>

          <div className="tabs-box">
            <div className="widget-title">
              <h4>Candidates Registered</h4>
              <div className="chosen-outer">
                {/* <!--Tabs Box--> */}
                <select
                  className="chosen-select-candidate-registered"
                  onChange={(e) => {
                    e?.stopPropagation();
                    setCandidateRegisteredType(e?.target?.value);
                    getCandidateRegistered(false, e?.target?.value);
                  }}
                >
                  <option value={"month"}>Last 6 Months</option>
                  <option value={"week"}>Last 24 Weeks</option>
                </select>
                <div>
                  <button
                    type="button"
                    className="theme-btn btn-sm btn-style-three"
                    onClick={(e) => {
                      e?.stopPropagation();
                      getCandidateRegistered(true);
                    }}
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>

            <div className="widget-content">
              <Bar options={options} data={candidateRegistered} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminReportCharts;
