import { useEffect, useState } from "react";
import Scripts from "../../Common/Scripts/Scripts";
import CandidateSidebar from "../Dashboard/CandidateSidebar";
import CareHomeSidebar from "../Dashboard/CareHomeSideBar";
import Footer from "../Dashboard/Footer";
import Header from "../Dashboard/Header";
import { getSession } from "../../../utils/session";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import { ROLE } from "../../../constant/role";
import { FormProvider, useForm } from "react-hook-form";
import Loading from "./../../Common/Loading";
import { get, post, put } from "../../../api";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import $ from "jquery";
import { formatDate, formatDateString } from "../../../utils/date";
import Admin from "../Dashboard/Admin";
import { scrollTop } from "../../../utils/scroll";
import { InputError } from "../../Common/FormControl/Input";
import { sliceArray } from "../ManageJobs/ManageJobs";

const Feedback = () => {
  const [user, setUser] = useState();
  const [feedbackUser, setFeedbackUser] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  const [jobApiMsg, setJobApiMsg] = useState("");
  const [userDetail, setUserDetail] = useState();
  const [feedback, setFeedback] = useState([]);
  const [filterFeedback, setFilterFeedback] = useState([]);
  

  const callMethod = async () => {
    try {
      setLoading(true);
      const url = `/user/users/detail`;
      const response = await get(url);
      setUserDetail(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };
  const getFeedback = async (session) => {
    setPageLoading(true);
    try {
      const url = `/user/feedback?userType=${session?.role}`;
      const response = await get(url);
      if (response) {
        setFeedback(sliceArray(response, 0, 10));
        setFilterFeedback(response);
      }
      setPageLoading(false);
    } catch (e) {
      setPageLoading(false);
      console.error(e);
    }
  };
  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
    callMethod();
    getFeedback(session);
  }, []);
  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const adminform = useForm();

  const onSubmitAdminForm = async (data) => {
    setLoading(true);
    try {
      let payload = {
        ...candidateViewFeedback,
        status: "RESOLVED",
        adminNotes: `${data?.discussionNotes}`,
      };
      console.log("3" + payload);
      const url = `/user/feedback`;
      const response = await put(url, payload);
      if (
        response?.status === 201 ||
        response?.status === 200 ||
        response?.status === 202 ||
        response?.status === 204
      ) {
        setJobApiMsg("success");
        getFeedback(getSession(EMPLOYR_LOGIN_SESSION));
      } else {
        setJobApiMsg("error");
      }
      setLoading(false);
    } catch (e) {
      setJobApiMsg("error");
      setLoading(false);
    }
  };
  const onSubmitCareHome = async (data) => {
    setLoading(true);
    console.log("hey" + feedbackUser?.name);
    try {
      let payload = {
        toUserId: feedbackUser?.id,
        jobId: feedbackUser?.jobId,
        fromUserId: userDetail?.userId,
        companyId: userDetail?.company?.companyId,
        companyName: userDetail?.company?.companyName,
        toUserName: feedbackUser?.name,
        createdAt: new Date(),
        type: data?.feedbackType,
        rating: Number($('input[name="rating3"]:checked').val()),
        description: data?.feedback,
        status:
          data?.feedbackType === "Appreciation" ? "RESOLVED" : "NOT_RESOLVED",
        adminNotes: "",
        closureDate: new Date(),
      };
      const url = `/user/feedback`;
      if (editMode) {
        payload.id = editModeData?.id;
      }

      const response = editMode
        ? await put(url, payload)
        : await post(url, payload);
      if (
        response?.status === 201 ||
        response?.status === 200 ||
        response?.status === 202 ||
        response?.status === 204
      ) {
        setJobApiMsg("success");
        getFeedback(getSession(EMPLOYR_LOGIN_SESSION));
      } else {
        setJobApiMsg("error");
      }
      setLoading(false);
    } catch (e) {
      setJobApiMsg("error");
      setLoading(false);
    }
  };

  const [editMode, setEditMode] = useState(false);
  const [editModeData, setEditModeData] = useState();

  const editFeedback = (feedback) => {
    setEditMode(true);
    setEditModeData(feedback);
    console.log("feedback", feedback);
    reset({
      feedbackType: feedback?.type,
      feedback: feedback?.description,
    });
    setTimeout(() => {
      $(`input:radio[name=rating3][value=${Number(feedback?.rating)}]`).trigger(
        "click"
      );
    }, 100);
    // $('input[name="rating3"]').val(feedback?.rating);
  };

  const cancelupdateFeedback = () => {
    setEditMode(false);
    setCandidateViewFeedback(null);
    reset({
      feedbackType: "",
      feedback: "",
    });
  };

  const [users, setUsers] = useState([]);
  const handleOnSearch = async (data) => {
    try {
      const url = `/user/company/selected-user/list`;
      const response = await get(url);
      const res = [];
      if (response) {
        response?.forEach((itm) => {
          res.push({
            id: itm?.userId,
            name: `${itm?.firstName} ${itm?.lastName}`,
            jobId: itm?.jobId,
            jobTitle: itm?.jobTitle,
          });
        });
      }
      setUsers(res);
    } catch (e) {
      console.error(e);
    }
  };
  const handleOnSelect = (data) => {
    setFeedbackUser(data);
  };

  const [candidateViewFeedback, setCandidateViewFeedback] = useState();

  const viewCandidateFeedback = (data) => {
    setCandidateViewFeedback(data);
  };
  return (
    <>
      <div className="page-wrapper dashboard ">
        <Scripts />
        <Header />
        {isPageLoading && <div className="preloader"></div>}
        {user?.role === ROLE.CANDIDATE && <CandidateSidebar />}
        {user?.role === ROLE.CARE_HOME && <CareHomeSidebar />}
        {user?.role === ROLE.ADMIN && <Admin />}
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="upper-title-box">
              <h3>Feedback</h3>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Ls widget --> */}
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-title"></div>

                    <div className="widget-content">
                      <div className="default-tabs style-two tabs-box">
                        {/* <!--Tabs Box--> */}
                        <ul className="tab-buttons clearfix">
                          {user?.role === ROLE.ADMIN && (
                            <li className="tab-btn active-btn" data-tab="#tab1">
                              <span className="la la-user-shield"></span> Admin
                            </li>
                          )}
                          {user?.role === ROLE.CANDIDATE && (
                            <li className="tab-btn active-btn" data-tab="#tab2">
                              <span className="la la-user"></span> Candidate
                            </li>
                          )}
                          {user?.role === ROLE.CARE_HOME && (
                            <li className="tab-btn active-btn" data-tab="#tab3">
                              <span className="la la-building"></span> Carehome
                            </li>
                          )}
                        </ul>

                        <div className="tabs-content">
                          {/* <!--Inbox--> */}
                          <div
                            className="tab active-tab animated fadeIn"
                            id="tab1"
                            style={{
                              display:
                                user?.role === ROLE.ADMIN ? "block" : "none",
                            }}
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="notification-widget ls-widget">
                                  <div className="tabs-box">
                                    <div className="widget-content">
                                      <div className="table-outer">
                                        <table className="default-table">
                                          <thead>
                                            <tr>
                                              <th>Action</th>
                                              <th>Feedback</th>
                                              <th>From</th>
                                              <th>Date</th>
                                              <th>For</th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {feedback?.length === 0 && (
                                              <tr>
                                                <td>No Result Found</td>
                                              </tr>
                                            )}
                                            {feedback?.map((itm) => {
                                              return (
                                                <tr key={itm?.id}>
                                                  <td
                                                    style={{
                                                      paddingLeft: "0",
                                                    }}
                                                  >
                                                    <div className="option-box">
                                                      <ul className="option-list">
                                                        <li>
                                                          <button
                                                            data-text="View"
                                                            onClick={() => {
                                                              setCandidateViewFeedback(
                                                                itm
                                                              );
                                                              setTimeout(() => {
                                                                scrollTop(
                                                                  ".admin-job-overview"
                                                                );
                                                              }, 50);
                                                            }}
                                                          >
                                                            <span className="la la-eye"></span>
                                                          </button>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {itm?.type}:{" "}
                                                    {itm?.description}
                                                  </td>
                                                  <td>{itm?.companyName}</td>
                                                  <td>
                                                    {formatDateString(
                                                      itm?.createdAt
                                                    )}
                                                  </td>
                                                  <td>{itm?.toUserName}</td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                        <div className="ls-show-more">
                                          <p>Showing {feedback?.length} out of {filterFeedback?.length} feedbacks</p>
                                          {feedback?.length > 0 &&
                                            filterFeedback?.length !==
                                              feedback?.length && (
                                              <>
                                                <div className="bar">
                                                  <span
                                                    className="bar-inner"
                                                    style={{ width: "40%" }}
                                                  ></span>
                                                </div>
                                                <button
                                                  className="show-more"
                                                  onClick={() => {
                                                    const data = sliceArray(
                                                      filterFeedback,
                                                      feedback?.length,
                                                      filterFeedback?.length +
                                                        10
                                                    );
                                                    setFeedback([
                                                      ...feedback,
                                                      ...data,
                                                    ]);
                                                  }}
                                                >
                                                  Show More
                                                </button>
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {candidateViewFeedback && (
                              <div className="row admin-job-overview">
                                <div className="col-lg-12">
                                  <div className="job-detail">
                                    <div className="job-overview-two">
                                      <h4>
                                        From:{" "}
                                        {candidateViewFeedback?.companyName}
                                      </h4>
                                      <ul>
                                        <li>
                                          <i className="icon icon-calendar"></i>
                                          <h5>Date Posted:</h5>
                                          <span>
                                            {formatDate(
                                              candidateViewFeedback?.createdAt
                                            )}
                                          </span>
                                        </li>

                                        <li>
                                          <i className="icon icon-location"></i>
                                          <h5>Job Title:</h5>
                                          <span>
                                            {candidateViewFeedback?.jobTitle}
                                          </span>
                                        </li>
                                        <li>
                                          <i className="icon icon-user-2"></i>
                                          <h5>For:</h5>
                                          <span>
                                            {candidateViewFeedback?.toUserName}
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                    Feedback
                                    <ul style={{ padding: "0" }}>
                                      {[
                                        ...Array(
                                          candidateViewFeedback?.rating || 1
                                        ),
                                      ].map((e, i) => (
                                        <i className="la la-star" key={i}></i>
                                      ))}
                                    </ul>
                                    <p>{candidateViewFeedback?.description}</p>
                                    Discussion Notes, if any
                                    <p>
                                      {candidateViewFeedback?.adminNotes
                                        ? candidateViewFeedback?.adminNotes
                                        : "Not Available"}
                                    </p>
                                    {/* <FormProvider {...methods}> */}
                                    <form
                                      onSubmit={adminform.handleSubmit(
                                        onSubmitAdminForm
                                      )}
                                      className="default-form"
                                    >
                                      <div className="form-group col-lg-12 col-md-12">
                                        <div className="message-box warning">
                                          Please ensure you have had a
                                          discussion with the carehome and
                                          candidate before closing any
                                          complaint.
                                        </div>

                                        <label>Add discussion notes</label>

                                        <textarea
                                          placeholder="Notes"
                                          {...adminform.register(
                                            "discussionNotes",
                                            {
                                              required: {
                                                value: true,
                                                message: "required",
                                              },
                                            }
                                          )}
                                        ></textarea>
                                      </div>

                                      {/* <!-- Input --> */}
                                      <div className="form-group col-lg-12 col-md-12 text-right">
                                        <button
                                          className="theme-btn btn-style-one"
                                          type="submit"
                                        >
                                          Submit & Close
                                        </button>
                                      </div>
                                    </form>
                                    {/* </FormProvider> */}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          {/* <!--Sent--> */}
                          <div
                            className="tab active-tab animated fadeIn"
                            id="tab2"
                            style={{
                              display:
                                user?.role === ROLE.CANDIDATE
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="notification-widget ls-widget">
                                  <div className="tabs-box">
                                    <div className="widget-content">
                                      <div className="table-outer">
                                        <table className="default-table">
                                          <thead>
                                            <tr>
                                              <th>Action</th>
                                              <th>Feedback</th>
                                              <th>From</th>
                                              <th>Date</th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {feedback?.length === 0 && (
                                              <tr>
                                                <td>No Result Found</td>
                                              </tr>
                                            )}
                                            {feedback
                                              ?.sort((a, b) =>
                                                a.id < b.id ? 1 : -1
                                              )
                                              .filter(
                                                (itm) =>
                                                  itm.status === "RESOLVED"
                                              )
                                              .map((itm) => (
                                                <tr key={itm?.id}>
                                                  <td
                                                    style={{
                                                      paddingLeft: "0",
                                                    }}
                                                  >
                                                    <div className="option-box">
                                                      <ul className="option-list">
                                                        <li>
                                                          <button
                                                            data-text="View"
                                                            onClick={() => {
                                                              viewCandidateFeedback(
                                                                itm
                                                              );
                                                              setTimeout(() => {
                                                                scrollTop(
                                                                  ".candidate-job-overview"
                                                                );
                                                              }, 50);
                                                            }}
                                                          >
                                                            <span className="la la-eye"></span>
                                                          </button>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {itm?.type}:{" "}
                                                    {itm?.description}
                                                  </td>
                                                  <td>{itm?.companyName}</td>
                                                  <td>
                                                    {formatDateString(
                                                      itm?.createdAt
                                                    )}
                                                  </td>
                                                </tr>
                                              ))}
                                          </tbody>
                                        </table>
                                        <div className="ls-show-more">
                                          <p>
                                            Showing{" "}
                                            {
                                              feedback
                                                ?.sort((a, b) =>
                                                  a.id < b.id ? 1 : -1
                                                )
                                                .filter(
                                                  (itm) =>
                                                    itm.status === "RESOLVED"
                                                )?.length
                                            }{" "}
                                            out of {filterFeedback?.filter(
                                                  (itm) =>
                                                    itm.status === "RESOLVED"
                                                )?.length} feedbacks
                                          </p>
                                          {feedback?.length > 0 &&
                                            filterFeedback?.length !==
                                              feedback?.length && (
                                              <>
                                                <div className="bar">
                                                  <span
                                                    className="bar-inner"
                                                    style={{ width: "40%" }}
                                                  ></span>
                                                </div>
                                                <button
                                                  className="show-more"
                                                  onClick={() => {
                                                    const data = sliceArray(
                                                      filterFeedback,
                                                      feedback?.length,
                                                      filterFeedback?.length +
                                                        10
                                                    );
                                                    setFeedback([
                                                      ...feedback,
                                                      ...data,
                                                    ]);
                                                  }}
                                                >
                                                  Show More
                                                </button>
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {candidateViewFeedback && (
                              <div className="row candidate-job-overview">
                                <div className="col-lg-12">
                                  <div className="job-detail">
                                    <div className="job-overview-two">
                                      <h4>
                                        From: Carehome{" "}
                                        {candidateViewFeedback?.companyName}
                                      </h4>
                                      <ul>
                                        <li>
                                          <i className="icon icon-calendar"></i>
                                          <h5>Date Posted:</h5>
                                          <span>
                                            {formatDate(
                                              candidateViewFeedback?.createdAt
                                            )}
                                          </span>
                                        </li>

                                        <li>
                                          <i className="icon icon-location"></i>
                                          <h5>Job title:</h5>
                                          <span>
                                            {candidateViewFeedback?.jobTitle}
                                          </span>
                                        </li>
                                        <li>
                                          <i className="icon icon-user-2"></i>
                                          <h5>For:</h5>
                                          <span>
                                            {candidateViewFeedback?.toUserName}
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                    Feedback
                                    <ul style={{ padding: "0" }}>
                                      {[
                                        ...Array(
                                          candidateViewFeedback?.rating || 1
                                        ),
                                      ].map((e, i) => (
                                        <i className="la la-star" key={i}></i>
                                      ))}
                                    </ul>
                                    <p>{candidateViewFeedback?.description}</p>
                                    Discussion Notes, if any
                                    <p>
                                      {candidateViewFeedback?.adminNotes
                                        ? candidateViewFeedback?.adminNotes
                                        : "Not Available"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          {/* <!--Action List--> */}
                          <div
                            className="tab active-tab animated fadeIn"
                            id="tab3"
                            style={{
                              display:
                                user?.role === ROLE.CARE_HOME
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="notification-widget ls-widget">
                                  <div className="tabs-box">
                                    <div className="widget-content">
                                      <div className="table-outer">
                                        <table className="default-table">
                                          <thead>
                                            <tr>
                                              <th>Action</th>
                                              <th>Feedback</th>
                                              <th>For</th>
                                              <th>Date</th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {feedback?.length === 0 && (
                                              <tr>
                                                <td>No Result Found</td>
                                              </tr>
                                            )}
                                            {feedback
                                              ?.sort((a, b) =>
                                                a.id < b.id ? 1 : -1
                                              )
                                              .map((itm) => (
                                                <tr key={itm?.id}>
                                                  <td
                                                    style={{
                                                      paddingLeft: "0",
                                                    }}
                                                  >
                                                    <div className="option-box">
                                                      <ul className="option-list">
                                                        <li>
                                                          <button
                                                            data-text="View"
                                                            onClick={() => {
                                                              setCandidateViewFeedback(
                                                                itm
                                                              );
                                                              setTimeout(() => {
                                                                scrollTop(
                                                                  ".job-detail-view-section"
                                                                );
                                                              }, 50);
                                                            }}
                                                          >
                                                            <span className="la la-eye"></span>
                                                          </button>
                                                        </li>
                                                        <li>
                                                          <button
                                                            data-text="Edit"
                                                            onClick={() => {
                                                              setCandidateViewFeedback(
                                                                null
                                                              );
                                                              editFeedback(itm);
                                                              setTimeout(() => {
                                                                scrollTop(
                                                                  ".feedback-edit-section"
                                                                );
                                                              }, 50);
                                                            }}
                                                          >
                                                            <span className="la la-pencil"></span>
                                                          </button>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {itm?.type}:{" "}
                                                    {itm?.description}
                                                  </td>
                                                  <td>{itm?.toUserName}</td>
                                                  <td>
                                                    {formatDateString(
                                                      itm?.createdAt
                                                    )}
                                                  </td>
                                                </tr>
                                              ))}
                                          </tbody>
                                        </table>
                                        <div className="ls-show-more">
                                          <p>
                                            Showing{" "}
                                            {
                                              feedback?.sort((a, b) =>
                                                a.id < b.id ? 1 : -1
                                              )?.length
                                            }{" "}
                                            out of {filterFeedback?.length} feedbacks
                                          </p>
                                          {feedback?.length > 0 &&
                                            filterFeedback?.length !==
                                              feedback?.length && (
                                              <>
                                                <div className="bar">
                                                  <span
                                                    className="bar-inner"
                                                    style={{ width: "40%" }}
                                                  ></span>
                                                </div>
                                                <button
                                                  className="show-more"
                                                  onClick={() => {
                                                    const data = sliceArray(
                                                      filterFeedback,
                                                      feedback?.length,
                                                      filterFeedback?.length +
                                                        10
                                                    );
                                                    setFeedback([
                                                      ...feedback,
                                                      ...data,
                                                    ]);
                                                  }}
                                                >
                                                  Show More
                                                </button>
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {candidateViewFeedback && (
                              <div className="row job-detail-view-section">
                                <div className="col-lg-12">
                                  <div className="job-detail">
                                    <div className="job-overview-two">
                                      <h4>
                                        From: Carehome{" "}
                                        {candidateViewFeedback?.fromUserName}
                                      </h4>
                                      <ul>
                                        <li>
                                          <i className="icon icon-calendar"></i>
                                          <h5>Date Posted:</h5>
                                          <span>
                                            {formatDate(
                                              candidateViewFeedback?.createdAt
                                            )}
                                          </span>
                                        </li>

                                        <li>
                                          <i className="icon icon-location"></i>
                                          <h5>Job:</h5>
                                          <span>
                                            {candidateViewFeedback?.jobTitle}
                                          </span>
                                        </li>
                                        <li>
                                          <i className="icon icon-user-2"></i>
                                          <h5>For:</h5>
                                          <span>
                                            {candidateViewFeedback?.toUserName}
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                    Feedback
                                    <ul style={{ padding: "0" }}>
                                      {[
                                        ...Array(
                                          candidateViewFeedback?.rating || 0
                                        ),
                                      ].map((e, i) => (
                                        <i className="la la-star" key={i}></i>
                                      ))}
                                    </ul>
                                    <p>{candidateViewFeedback?.description}</p>
                                    Discussion Notes, if any
                                    <p>
                                      {candidateViewFeedback?.adminNotes
                                        ? candidateViewFeedback?.adminNotes
                                        : "Not Available"}
                                    </p>
                                    <button
                                      className="theme-btn btn-style-three"
                                      onClick={() => {
                                        cancelupdateFeedback();
                                      }}
                                    >
                                      Close
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {!candidateViewFeedback && (
                              <form
                                onSubmit={handleSubmit(onSubmitCareHome)}
                                className="default-form"
                              >
                                {jobApiMsg === "success" && (
                                  <div className="message-box success">
                                    <p>Success: Your feedback has been sent.</p>
                                    <button className="close-btn">
                                      <span className="close_icon"></span>
                                    </button>
                                  </div>
                                )}
                                {jobApiMsg === "error" && (
                                  <div className="message-box error">
                                    <p>
                                      Error: Something went wrong while sending
                                      feebback. Please try again.
                                    </p>
                                    <button className="close-btn">
                                      <span className="close_icon"></span>
                                    </button>
                                  </div>
                                )}
                                <div className="row feedback-edit-section">
                                  {/* <!-- Input --> */}

                                  <div className="form-group col-lg-6 col-md-12">
                                    <label>Feedback Type</label>
                                    <select
                                      className="chosen-select-feedback"
                                      {...register("feedbackType", {
                                        required: {
                                          value: true,
                                          message: "required",
                                        },
                                      })}
                                    >
                                      <option value={""}>Select</option>
                                      <option>Appreciation</option>
                                      <option>Complaint</option>
                                    </select>
                                    {errors?.feedbackType && (
                                      <InputError
                                        message={errors?.feedbackType?.message}
                                        key={errors?.feedbackType?.message}
                                      />
                                    )}
                                  </div>
                                  <div className="form-group col-lg-6 col-md-12">
                                    {editMode && (
                                      <>
                                        <label>For User:&nbsp;</label>
                                        <input
                                          disabled
                                          name="userName"
                                          id="user-name"
                                          value={editModeData?.toUserName}
                                          type="text"
                                        />
                                        {editModeData?.toUtoUserIdserId}
                                      </>
                                    )}
                                    {!editMode && (
                                      <>
                                        <label>Select User</label>
                                        {/*{users?.map((user) => {*/}
                                        {/*  return (*/}
                                        {/*    <select>*/}
                                        {/*      <option id={user.userId}>{user.name}</option>*/}
                                        {/*    </select>*/}
                                        {/*  )*/}
                                        {/*})}*/}
                                        <ReactSearchAutocomplete
                                          items={users}
                                          onSearch={handleOnSearch}
                                          onSelect={handleOnSelect}
                                          // {...register("candidateUser", {
                                          //   required: {
                                          //     value: true,
                                          //     message: "required",
                                          //   },
                                          // })}
                                        />
                                        {/* {errors?.candidateUser && (
                                        <InputError
                                          message={
                                           errors?.candidateUser?.message
                                          }
                                          key={errors?.candidateUser?.message}
                                        />
                                    )} */}
                                      </>
                                    )}
                                  </div>
                                  <div className="form-group col-lg-12 col-md-12">
                                    <label>Feedback</label>
                                    <div
                                      id="full-stars-example-two"
                                      className="mb-2"
                                    >
                                      <div className="rating-group">
                                        <label
                                          aria-label="1 star"
                                          className="rating__label"
                                          htmlFor="rating3-1"
                                        >
                                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                                        </label>
                                        <input
                                          {...register("rating3")}
                                          className="rating__input"
                                          name="rating3"
                                          id="rating3-1"
                                          value="1"
                                          checked
                                          type="radio"
                                        />
                                        <label
                                          aria-label="2 stars"
                                          className="rating__label"
                                          htmlFor="rating3-2"
                                        >
                                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                                        </label>
                                        <input
                                          {...register("rating3")}
                                          className="rating__input"
                                          name="rating3"
                                          id="rating3-2"
                                          value="2"
                                          type="radio"
                                        />
                                        <label
                                          aria-label="3 stars"
                                          className="rating__label"
                                          htmlFor="rating3-3"
                                        >
                                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                                        </label>
                                        <input
                                          {...register("rating3")}
                                          className="rating__input"
                                          name="rating3"
                                          id="rating3-3"
                                          value="3"
                                          type="radio"
                                        />
                                        <label
                                          aria-label="4 stars"
                                          className="rating__label"
                                          htmlFor="rating3-4"
                                        >
                                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                                        </label>
                                        <input
                                          {...register("rating3")}
                                          className="rating__input"
                                          name="rating3"
                                          id="rating3-4"
                                          value="4"
                                          type="radio"
                                        />
                                        <label
                                          aria-label="5 stars"
                                          className="rating__label"
                                          htmlFor="rating3-5"
                                        >
                                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                                        </label>
                                        <input
                                          {...register("rating3")}
                                          className="rating__input"
                                          name="rating3"
                                          id="rating3-5"
                                          value="5"
                                          type="radio"
                                        />
                                      </div>
                                    </div>

                                    <textarea
                                      {...register("feedback", {
                                        required: {
                                          value: true,
                                          message: "required",
                                        },
                                      })}
                                      placeholder="Please provide feedback..."
                                    ></textarea>
                                    {errors?.feedback && (
                                      <InputError
                                        message={errors?.feedback?.message}
                                        key={errors?.feedback?.message}
                                      />
                                    )}
                                  </div>
                                  <div className="job-detail">
                                    {editMode && (
                                      <div className="job-overview-two pb-4">
                                        Discussion Notes (provided by admin), if
                                        any
                                        <p>{editModeData?.adminNotes}</p>
                                      </div>
                                    )}

                                    <div className="form-group col-lg-12 col-md-12 text-right">
                                      {isLoading && <Loading />}
                                      {!isLoading && !editMode && (
                                        <>
                                          <div className="checkbox-outer">
                                            <ul className="checkboxes square">
                                              <li>
                                                <input
                                                  id="check-candidate-confirm"
                                                  type="checkbox"
                                                  name="candidateConfirmCheck"
                                                  value={"Yes"}
                                                  {...register(
                                                    "candidateConfirmCheck",
                                                    {
                                                      required: {
                                                        value: true,
                                                        message: "required",
                                                      },
                                                    }
                                                  )}
                                                />
                                                <label htmlFor="check-candidate-confirm">
                                                  Have you provided the rating ?
                                                </label>
                                                {errors?.candidateConfirmCheck && (
                                                  <InputError
                                                    message={
                                                      errors
                                                        ?.candidateConfirmCheck
                                                        ?.message
                                                    }
                                                    key={
                                                      errors
                                                        ?.candidateConfirmCheck
                                                        ?.message
                                                    }
                                                  />
                                                )}
                                              </li>
                                            </ul>
                                          </div>
                                          <button
                                            className="theme-btn btn-style-one"
                                            type="submit"
                                          >
                                            Post
                                          </button>
                                        </>
                                      )}
                                      {!isLoading && editMode && (
                                        <button
                                          className="theme-btn btn-style-three"
                                          onClick={() => {
                                            cancelupdateFeedback();
                                          }}
                                        >
                                          Cancel
                                        </button>
                                      )}
                                      {!isLoading && editMode && (
                                        <button
                                          className="theme-btn btn-style-one m-2"
                                          type="submit"
                                        >
                                          Update Feedback
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </form>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default Feedback;
