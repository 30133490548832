import { Fragment, useEffect, useState } from "react";
import "../Meetings/calendar.css";
import Scripts from "../../Common/Scripts/Scripts";
import Footer from "../Dashboard/Footer";
import Header from "../Dashboard/Header";
import { getSession } from "../../../utils/session";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import { ROLE } from "../../../constant/role";
import CandidateSidebar from "../Dashboard/CandidateSidebar";
import CareHomeSidebar from "../Dashboard/CareHomeSideBar";
import { get, post, put } from "../../../api";
import { useForm } from "react-hook-form";
import Loading from "../../Common/Loading";
import Error from "../../Common/Alert/Error";
import Success from "../../Common/Alert/Success";
import {
  formatDate,
  formatDateString,
  getCurrentDaysInMonth,
  getTimeBetweenDates,
  getTimeFromDate,
} from "../../../utils/date";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

export const getTimeSheetStatusBadge = (status) => {
  if (status === "APPROVED") {
    return (
      <span className="badge bg-success approved">
        {status?.split("_")?.join(" ")}
      </span>
    );
  } else if (status === "REJECTED") {
    return (
      <span className="badge bg-danger">{status?.split("_")?.join(" ")}</span>
    );
  } else if (status === "PENDING_APPROVAL") {
    return (
      <span className="badge bg-warning text-dark">
        {status?.split("_")?.join(" ")}
      </span>
    );
  }
};

function Timesheet() {
  const [user, setUser] = useState();
  const [isLoading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState();
  const [timeSheetApiMsg, setTimeSheetApiMsg] = useState("");
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [count, setCount] = useState([]);
  const [calendarMonth, setCalendarMonthsDates] = useState();
  const [selectedTimeSheet, setSelectedTimeSheet] = useState({});
  const [clickedDate, setClickedDate] = useState(undefined);
  const [careHomeSelectedDate, setCareHomeSelectedDate] = useState();

  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
    callMethod();
    setCalendarMonthsDates(getCurrentDaysInMonth());
  }, []);

  const callMethod = async () => {
    setLoading(true);
    const url = `/user/users/detail`;
    const response = await get(url);
    setUserDetail(response);
    setLoading(false);
    getAppliedJobs(response);
  };
  const getAppliedJobs = async (userDetail) => {
    const url = `/job/jobs/user/${userDetail?.userId}/applied`;
    let response = await get(url);
    if (response) {
      response = response.filter((itm) => itm.status === "ACTIVE");
      setAppliedJobs(response);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmitCandidateTimeSheet = async (data) => {
    const job = data?.job?.split("-");
    const date = new Date(data?.timesheetDate);
    const startTime = data?.timesheetstartTime?.split(":");
    const endTime = data?.timesheetEndTime?.split(":");
    let startTimeWithDate = date.setHours(startTime[0]);
    startTimeWithDate = date.setMinutes(startTime[1]);
    let endTimeWithDate = date.setHours(endTime[0]);
    endTimeWithDate = date.setMinutes(endTime[1]);
    setLoading(true);
    setTimeSheetApiMsg("");
    const payload = {
      userId: userDetail?.userId,
      companyId: job[1] || undefined,
      shiftId: job[0] || undefined,
      date: data?.timesheetDate,
      startDate: startTimeWithDate,
      endDate: endTimeWithDate,
      createdDate: new Date(),
      status: "PENDING_APPROVAL",
      isPaid: false,
      breakTime: data?.breakHour,
    };
    try {
      const url = `/meeting-timesheet/timesheet`;
      const response = await post(url, payload);
      if (
        response?.status === 201 ||
        response?.status === 200 ||
        response?.status === 202 ||
        response?.status === 204
      ) {
        setTimeSheetApiMsg("success");
        calendarInit();
        getSelectedDateTimeSheet(new Date(data?.timesheetDate));
      } else {
        setTimeSheetApiMsg("error");
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setTimeSheetApiMsg("error");
      setLoading(false);
    }
  };
  const [timeSheetList, setTimeSheetList] = useState([]);
  const getSelectedDateTimeSheet = async (selectedDate) => {
    console.log("selectedDate", selectedDate);
    setCareHomeSelectedDate(selectedDate);
    let url = "";
    setTimeSheetList([]);
    if (user?.role === ROLE.CARE_HOME) {
      url = `/meeting-timesheet/timesheet?date=${formatDate(
        selectedDate
      )}&care-home=true`;
    } else {
      url = `/meeting-timesheet/timesheet?date=${formatDate(
        selectedDate
      )}&care-home=false`;
    }
    const response = await get(url);
    if (response) {
      if (Array.isArray(response)) {
        setTimeSheetList(response);
      } else {
        setTimeSheetList([response]);
      }
    }
    console.log("response", response);
  };

  const msg = (date) => {
    console.log("date", date);
    setClickedDate(date?._def?.extendedProps?.getDate);
    getSelectedDateTimeSheet(date?._def?.extendedProps?.getDate);
  };
  function renderEventContent(eventInfo) {
    console.log("event", eventInfo);
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <p
          id="demo"
          style={{ color: "#ffffff" }}
          onClick={() => msg(eventInfo.event)}
        >
          {eventInfo.event.title} hour(s)
        </p>
      </>
    );
  }

  const handleDateClick = (e) => {
    console.log("clicked date", e);
    setClickedDate(e.dateStr);
    getSelectedDateTimeSheet(e.date);
  };

  const updateTimeSheetByCareHome = async (status) => {
    console.log("status", status);
    console.log("selectedTimeSheet", selectedTimeSheet);
    setLoading(true);
    setTimeSheetApiMsg("");
    try {
      const payload = {
        ...selectedTimeSheet,
        startDate: null,
        endDate: null,
        status: status === "PAID" ? "APPROVED" : status,
        isPaid: status === "PAID" ? true : false,
        paid: status === "PAID" ? true : false,
      };
      const url = `/meeting-timesheet/timesheet`;
      const response = await put(url, payload);
      if (
        response?.status === 201 ||
        response?.status === 200 ||
        response?.status === 202 ||
        response?.status === 204
      ) {
        setTimeSheetApiMsg("success");
        getSelectedDateTimeSheet(careHomeSelectedDate);
      } else {
        setTimeSheetApiMsg("error");
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setTimeSheetApiMsg("error");
      setLoading(false);
    }
  };

  const calendarInit = async (dates) => {
    let url = "";
    if (dates) {
      url = `/meeting-timesheet/timesheet/count?month=${dates?.monthNumber}&year=${dates?.year}`;
    } else {
      url = `/meeting-timesheet/timesheet/count?month=${calendarMonth?.monthNumber}&year=${calendarMonth?.year}`;
    }
    const response = await get(url);
    if (response?.count) {
      let res = [];
      for (const [key, value] of Object.entries(response?.count)) {
        console.log(key, value);
        res.push({
          title: String(value / 3600),
          date: key,
          getDate: key,
        });
      }
      console.log("===>", res);
      setCount(res);
    }
    console.log("calendarInit ===>", response);
    // console.log('todayDate', todayDate);
  };
  return (
    <div className="page-wrapper dashboard ">
      <Scripts />
      {/* <!-- Preloader --> */}
      {/* <div className="preloader"></div> */}

      {/* <!-- Header Span --> */}
      {/* <span className="header-span"></span> */}
      <Header />

      {user?.role === ROLE.CANDIDATE && <CandidateSidebar />}
      {user?.role === ROLE.CARE_HOME && <CareHomeSidebar />}
      <section className="user-dashboard">
        <div className="dashboard-outer">
          <div className="upper-title-box">
            <h3>Timesheet</h3>
          </div>

          <div className="row">
            <div className="col-lg-12">
              {/* <!-- Ls widget --> */}
              <div className="ls-widget">
                <div className="tabs-box">
                  <div className="widget-title"></div>

                  <div className="widget-content">
                    <div className="default-tabs style-two tabs-box">
                      {/* <!--Tabs Box--> */}
                      <ul className="tab-buttons clearfix">
                        {user?.role === ROLE.CANDIDATE && (
                          <li className="tab-btn active-btn" data-tab="#tab1">
                            <span className="la la-user-clock"></span> Update
                            Timesheet
                          </li>
                        )}
                        {user?.role === ROLE.CARE_HOME && (
                          <li className="tab-btn active-btn" data-tab="#tab2">
                            <span className="la la-clock"></span> Approve
                            Timesheet
                          </li>
                        )}
                      </ul>

                      <div className="tabs-content">
                        {/* <!--Submit Timesheet--> */}
                        {user?.role === ROLE.CANDIDATE && (
                          <div
                            className="tab active-tab animated fadeIn"
                            id="tab1"
                            style={{ display: "block" }}
                          >
                            <div className="row">
                              <div className="col-lg-8 col-md-12">
                                {/* <!-- Ls widget --> */}
                                <div className="ls-widget">
                                  <div>
                                    <div className="widget-content-1">
                                      <div className="table-outer">
                                        <main className="calendar-contain">
                                          <section className="calendar__days_full">
                                            <FullCalendar
                                              plugins={[
                                                dayGridPlugin,
                                                interactionPlugin,
                                              ]}
                                              initialView="dayGridMonth"
                                              datesSet={(e) => {
                                                const date = new Date(
                                                  e.startStr
                                                );
                                                let currentTime = new Date(
                                                  date
                                                ).getTime();
                                                let updatedTIme = new Date(
                                                  currentTime +
                                                    10 * 24 * 60 * 60 * 1000
                                                );
                                                const month =
                                                  Number(
                                                    updatedTIme?.getMonth()
                                                  ) + 1;
                                                const year =
                                                  updatedTIme.getFullYear();
                                                const payload = {
                                                  monthNumber: month,
                                                  year: year,
                                                };
                                                setCalendarMonthsDates(payload);
                                                calendarInit(payload);
                                              }}
                                              // weekends={false}
                                              dateClick={(e) =>
                                                handleDateClick(e)
                                              }
                                              events={count}
                                              eventContent={renderEventContent}
                                            />
                                          </section>
                                        </main>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-12">
                                <form
                                  onSubmit={handleSubmit(
                                    onSubmitCandidateTimeSheet
                                  )}
                                  className="default-form"
                                >
                                  <div className="row">
                                    {timeSheetApiMsg === "success" && (
                                      <Success
                                        msg={`Success: Timesheet saved.`}
                                      />
                                    )}
                                    {timeSheetApiMsg === "error" && (
                                      <Error
                                        msg={`Error: Something went wrong please
                                      correct the form and re submit`}
                                      />
                                    )}
                                    {timeSheetList?.map((timeSheet) => (
                                      <div>
                                        <ul
                                          className="job-other-info candidateTimesheetList"
                                          style={{ paddingLeft: "0px" }}
                                        >
                                          <>
                                            <li>
                                              <strong>
                                                <i class="la la-building h6"></i>{" "}
                                                {timeSheet?.jobName}
                                              </strong>
                                            </li>
                                            <li>
                                              <strong>
                                                <i class="la la-calendar-check h6"></i>{" "}
                                                {formatDateString(
                                                  timeSheet?.date
                                                )}
                                              </strong>
                                            </li>
                                            <li>
                                              {timeSheet?.isPaid ? (
                                                <span className="badge bg-success">
                                                  PAID
                                                </span>
                                              ) : (
                                                getTimeSheetStatusBadge(
                                                  timeSheet?.status
                                                )
                                              )}
                                            </li>
                                            <li>
                                              <strong>
                                                <i class="la la-clock h6"></i>{" "}
                                                Start Time:
                                              </strong>{" "}
                                              {timeSheet?.startDate
                                                ?.split("T")[1]
                                                ?.substr(0, 5)}
                                            </li>
                                            <li>
                                              <strong>
                                                <i class="la la-clock h6"></i>{" "}
                                                End Time:
                                              </strong>{" "}
                                              {timeSheet?.endDate
                                                ?.split("T")[1]
                                                ?.substr(0, 5)}
                                            </li>
                                          </>
                                        </ul>
                                      </div>
                                    ))}
                                    {/* <!-- Input --> */}

                                    <div className="form-group col-lg-12 col-md-12">
                                      <label>Active Job</label>
                                      <select
                                        className="chosen-select-active-job"
                                        {...register("job")}
                                      >
                                        <option selected value="">
                                          Select
                                        </option>
                                        {appliedJobs
                                          ?.filter(
                                            (item) =>
                                              item?.jobApplicationStatus ===
                                              "ACCEPTED"
                                          )
                                          .map((itm) => (
                                            <Fragment key={itm?.id}>
                                              <option
                                                key={itm?.id}
                                                value={`${itm?.id}-${itm?.companyId}`}
                                              >
                                                {itm?.title}
                                              </option>
                                            </Fragment>
                                          ))}
                                      </select>
                                    </div>

                                    <div className="form-group col-lg-12 col-md-12">
                                      <label>Date</label>
                                      <input
                                        type="date"
                                        name="date"
                                        required
                                        {...register("timesheetDate")}
                                      />
                                    </div>

                                    {/* <!-- Input --> */}
                                    <div className="form-group col-lg-6 col-md-12">
                                      <label>Start Time</label>
                                      <input
                                        {...register("timesheetstartTime")}
                                        type="time"
                                        name="timesheetstartTime"
                                        placeholder=""
                                        required
                                      />
                                    </div>

                                    {/* <!-- Input --> */}
                                    <div className="form-group col-lg-6 col-md-12">
                                      <label>End Time</label>
                                      <input
                                        {...register("timesheetEndTime")}
                                        type="time"
                                        name="timesheetEndTime"
                                        placeholder=""
                                        required
                                      />
                                    </div>

                                    <div className="form-group col-lg-6 col-md-12">
                                      <label>Break (hours)</label>
                                      <select
                                        className="chosen-select-break"
                                        {...register("breakHour")}
                                      >
                                        <option>0</option>
                                        <option selected>0.5</option>
                                        <option>1</option>
                                        <option>1.5</option>
                                        <option>2</option>
                                      </select>
                                    </div>

                                    {/* <!-- Input --> */}
                                    <div className="form-group col-lg-12 col-md-12 text-right">
                                      {isLoading && <Loading />}
                                      {!isLoading && (
                                        <button
                                          className="theme-btn btn-style-one"
                                          type="submit"
                                        >
                                          Submit for Approval
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* <!--Approve Timesheet--> */}
                        {user?.role === ROLE.CARE_HOME && (
                          <div
                            className="tab active-tab animated fadeIn"
                            id="tab2"
                            style={{ display: "block" }}
                          >
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="notification-widget ls-widget">
                                  <div className="tabs-box">
                                    <div className="widget-content">
                                      <div className="table-outer">
                                        <div className="column col-lg-4 col-md-12">
                                          <form className="default-form">
                                            {/* <!-- Input --> */}
                                            <div className="form-group">
                                              <label>Choose Date</label>
                                              <input
                                                type="date"
                                                name="timesheetdate"
                                                onChange={(e) => {
                                                  setTimeSheetList([]);
                                                  getSelectedDateTimeSheet(
                                                    e?.target?.value
                                                  );
                                                }}
                                              />
                                            </div>
                                          </form>
                                        </div>
                                        <div
                                          className="modal fade"
                                          id="exampleModal"
                                          tabindex="-1"
                                          role="dialog"
                                          aria-labelledby="exampleModalCenterTitle"
                                          aria-hidden="true"
                                          style={{ position: "absolute" }}
                                        >
                                          <div
                                            className="modal-dialog modal-dialog-centered"
                                            role="document"
                                            style={{ zIndex: "10000" }}
                                          >
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h5
                                                  className="modal-title"
                                                  id="exampleModalLongTitle"
                                                >
                                                  Approve
                                                </h5>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                >
                                                  <span aria-hidden="true">
                                                    &times;
                                                  </span>
                                                </button>
                                              </div>
                                              {/* <div className="modal-body">...</div> */}
                                              <div className="modal-footer">
                                                <button
                                                  type="button"
                                                  className="btn btn-secondary"
                                                  data-dismiss="modal"
                                                  onClick={() => {
                                                    setSelectedTimeSheet({});
                                                  }}
                                                >
                                                  Close
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn btn-primary"
                                                  data-dismiss="modal"
                                                  onClick={() => {
                                                    updateTimeSheetByCareHome(
                                                      "APPROVED"
                                                    );
                                                  }}
                                                >
                                                  Approve
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal fade"
                                          id="exampleModal2"
                                          tabindex="-1"
                                          role="dialog"
                                          aria-labelledby="exampleModalCenterTitle"
                                          aria-hidden="true"
                                          style={{ position: "absolute" }}
                                        >
                                          <div
                                            className="modal-dialog modal-dialog-centered"
                                            role="document"
                                            style={{ zIndex: "10000" }}
                                          >
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h5
                                                  className="modal-title"
                                                  id="exampleModalLongTitle"
                                                >
                                                  Reject
                                                </h5>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                >
                                                  <span aria-hidden="true">
                                                    &times;
                                                  </span>
                                                </button>
                                              </div>
                                              {/* <div className="modal-body">...</div> */}
                                              <div className="modal-footer">
                                                <button
                                                  type="button"
                                                  className="btn btn-secondary"
                                                  data-dismiss="modal"
                                                  onClick={() => {
                                                    setSelectedTimeSheet({});
                                                  }}
                                                >
                                                  Close
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn btn-danger"
                                                  data-dismiss="modal"
                                                  onClick={() => {
                                                    updateTimeSheetByCareHome(
                                                      "REJECTED"
                                                    );
                                                  }}
                                                >
                                                  Reject
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="modal fade"
                                          id="exampleModal1"
                                          tabindex="-1"
                                          role="dialog"
                                          aria-labelledby="exampleModalCenterTitle"
                                          aria-hidden="true"
                                          style={{ position: "absolute" }}
                                        >
                                          <div
                                            className="modal-dialog modal-dialog-centered"
                                            role="document"
                                            style={{ zIndex: "10000" }}
                                          >
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h5
                                                  className="modal-title"
                                                  id="exampleModalLongTitle"
                                                >
                                                  Mark as paid
                                                </h5>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                >
                                                  <span aria-hidden="true">
                                                    &times;
                                                  </span>
                                                </button>
                                              </div>
                                              {/* <div className="modal-body">...</div> */}
                                              <div className="modal-footer">
                                                <button
                                                  type="button"
                                                  className="btn btn-secondary"
                                                  data-dismiss="modal"
                                                  onClick={() => {
                                                    setSelectedTimeSheet({});
                                                  }}
                                                >
                                                  Close
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn btn-danger"
                                                  data-dismiss="modal"
                                                  onClick={() => {
                                                    updateTimeSheetByCareHome(
                                                      "PAID"
                                                    );
                                                  }}
                                                >
                                                  Mark as paid
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {timeSheetApiMsg === "success" && (
                                          <Success
                                            msg={`Success: Timesheet saved.`}
                                          />
                                        )}
                                        {timeSheetApiMsg === "error" && (
                                          <Error
                                            msg={`Error: Something went wrong please
                                          correct the form and re submit`}
                                          />
                                        )}
                                        <table className="default-table">
                                          <thead>
                                            <tr>
                                              <th>Action</th>
                                              <th>Candidate</th>
                                              <th>Job</th>
                                              <th>Site</th>
                                              <th>Status</th>
                                              <th>Work Hours</th>
                                              <th>Payable Hours</th>
                                            </tr>
                                          </thead>

                                          <tbody>
                                            {timeSheetList?.length === 0 && (
                                              <tr>
                                                <td>No Result Found</td>
                                              </tr>
                                            )}
                                            {timeSheetList?.map((timeSheet) => (
                                              <tr>
                                                <td
                                                  style={{
                                                    paddingLeft: "0",
                                                  }}
                                                >
                                                  <div className="option-box">
                                                    <ul className="option-list">
                                                      <li
                                                        style={{
                                                          display:
                                                            timeSheet?.status ===
                                                              "PENDING_APPROVAL" ||
                                                            timeSheet?.status ===
                                                              "REJECTED" ||
                                                            timeSheet?.isPaid
                                                              ? "none"
                                                              : "",
                                                        }}
                                                      >
                                                        <button
                                                          id="mybtn"
                                                          data-text="Mark as paid"
                                                          data-toggle="modal"
                                                          data-target="#exampleModal1"
                                                          onClick={() => {
                                                            setSelectedTimeSheet(
                                                              timeSheet
                                                            );
                                                          }}
                                                        >
                                                          <span className="la la-pound-sign"></span>
                                                        </button>
                                                      </li>
                                                      <li
                                                        style={{
                                                          display:
                                                            timeSheet?.status ===
                                                              "APPROVED" ||
                                                            timeSheet?.status ===
                                                              "REJECTED"
                                                              ? "none"
                                                              : "",
                                                        }}
                                                      >
                                                        <button
                                                          id="mybtn"
                                                          data-text="Approve"
                                                          data-toggle="modal"
                                                          data-target="#exampleModal"
                                                          onClick={() => {
                                                            setSelectedTimeSheet(
                                                              timeSheet
                                                            );
                                                          }}
                                                        >
                                                          <span className="la la-check"></span>
                                                        </button>
                                                      </li>
                                                      <li
                                                        style={{
                                                          display:
                                                            timeSheet?.status ===
                                                              "APPROVED" ||
                                                            timeSheet?.status ===
                                                              "REJECTED"
                                                              ? "none"
                                                              : "",
                                                        }}
                                                      >
                                                        <button
                                                          data-text="Reject"
                                                          data-toggle="modal"
                                                          data-target="#exampleModal2"
                                                          onClick={() => {
                                                            setSelectedTimeSheet(
                                                              timeSheet
                                                            );
                                                          }}
                                                        >
                                                          <span className="la la-times"></span>
                                                        </button>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </td>
                                                <td>{timeSheet?.userName}</td>
                                                <td>{timeSheet?.jobName}</td>
                                                <td>
                                                  {timeSheet?.jobSiteName}
                                                </td>
                                                <td>
                                                  {timeSheet?.isPaid
                                                    ? "PAID"
                                                    : timeSheet?.status
                                                        ?.split("_")
                                                        ?.join(" ")}
                                                </td>
                                                <td>
                                                  {getTimeFromDate(
                                                    timeSheet?.startDate
                                                  )}
                                                  -
                                                  {getTimeFromDate(
                                                    timeSheet?.endDate
                                                  )}
                                                </td>
                                                <td>
                                                  {getTimeBetweenDates(
                                                    new Date(
                                                      timeSheet?.startDate
                                                    ),
                                                    new Date(
                                                      timeSheet?.endDate
                                                    ),
                                                    timeSheet?.breakTime
                                                  )}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Timesheet;
