import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Scripts from "../../Common/Scripts/Scripts";
import CareHomeSidebar from "../Dashboard/CareHomeSideBar";
import Footer from "../Dashboard/Footer";
import Header from "../Dashboard/Header";
import { InputError } from "../../Common/FormControl/Input";
import { useState } from "react";
import Loading from "../../Common/Loading";
import { post, get } from "../../../api";
import { getSession } from "../../../utils/session";
import { EMPLOYR_LOGIN_SESSION } from "../../../constant/session";
import { AuthContext } from "../../../context/auth";
import { formatDateHyphen, isDateOld } from "../../../utils/date";
import { scrollTop } from "../../../utils/scroll";
import Success from "../../Common/Alert/Success";
import Error from "../../Common/Alert/Error";
import { weekOptions } from "../../../utils/weeks";
import { jobCategory } from "../../../utils/jobCategory";

const PostJob = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      name: "",
      jobDescription: "",
      roleType: "",
      hours: "",
      rateph: "",
      experience: "",
      qualification: "",
      nvqLevel: "",
      vacancy: "",
      stdate: "",
      enddate: "",
      site: "",
      shift: "",
      choosedays: "",
    },
  });
  const [isLoading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState();
  const [jobAddedMsg, setJobAddedMsg] = useState("");
  const [jobAddedErrorMsg, setJobAddedErrorMsg] = useState("");
  const [user, setUser] = useState();
  const { setFavourites } = useContext(AuthContext);
  const [userRoleTypes, setUserTypeRoles] = useState([]);
  const getUserDetail = async () => {
    const url = `/user/users/detail`;
    const response = await get(url);
    setUserDetail(response);
    console.log("profile response", response);
    if (response) {
      setFavourites(response?.favourites?.length);
    }
    setLoading(false);
  };
  const getUserRoleType = async () => {
    const url = `/user/users/type`;
    const response = await get(url);
    if (response) {
      setUserTypeRoles(response);
    }
  };
  useEffect(() => {
    const session = getSession(EMPLOYR_LOGIN_SESSION);
    setUser(session);
    getUserDetail();
    getUserRoleType();
  }, []);
  const submitPostJob = async (data) => {
    console.log("data", data);
    setLoading(true);
    setJobAddedMsg("");
    const payload = {
      companyId: Number(userDetail?.company?.companyId),
      title: data?.name,
      description: data?.jobDescription,
      siteId: Number(data?.site),
      startDate: data?.stdate,
      endDate: data?.enddate,
      nvqLevelRequired: Number(data?.nvqLevel) || 0,
      minHoursPerWeek: Number(data?.hours),
      minQualification: data?.qualification,
      vacancies: Number(data?.vacancy),
      minExperience: data?.experience,
      ratePerHour: Number(data?.rateph),
      jobTypeId: data?.roletype,
      jobCategory: data?.jobCategory,
      days: String(data.choosedays), //"M,T,W",
      shift: data?.Shift,
      status: "ACTIVE",
      applicants: 0,
    };
    try {
      const url = `/job/jobs`;
      const response = await post(url, payload);
      if (response?.status === 201) {
        console.log("resource updated", response);
        setJobAddedMsg("success");
        reset({
          name: "",
          jobDescription: "",
          roleType: "",
          hours: "",
          rateph: "",
          experience: "",
          qualification: "",
          nvqLevel: "",
          vacancy: "",
          stdate: "",
          enddate: "",
          site: "",
          shift: "",
          choosedays: "",
        });
      } else {
        setJobAddedMsg("error");
      }
      scrollTop();
    } catch (e) {
      console.log("e", e);
      if (e?.response?.data?.message?.includes('Subscription limit')) {
        setJobAddedErrorMsg("You have exhausted your job post limit for the subscription, please apply for enhanced subscription before posting a new job.");
      }
      setJobAddedMsg("error");
      scrollTop();
    }

    setLoading(false);
  };
  return (
    <>
      <div className="page-wrapper dashboard ">
        <Scripts />
        <Header />
        <CareHomeSidebar />

        {/* <!-- Dashboard --> */}
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="upper-title-box">
              <h3>Post a New Job!</h3>
            </div>

            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Ls widget --> */}
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-title"></div>

                    <div className="widget-content">
                      {/* <FormProvider {...methods}> */}
                      <form
                        className="default-form"
                        onSubmit={handleSubmit(submitPostJob)}
                      >
                        <div className="row">
                          {/* <!-- Input --> */}
                          {jobAddedMsg === "success" && (
                            <Success
                              msg={`Success: Your job has been posted. If you want
                            to edit your post, please go to Manage Jobs`}
                            />
                          )}
                          {jobAddedMsg === "error" && (
                            <Error
                              msg={
                                jobAddedErrorMsg
                                  ? jobAddedErrorMsg
                                  : `Error: The job could not be posted. Please check
                              input and re-submit.`
                              }
                            />
                          )}
                          {/* <div className="message-box error">
                                                        <p>Error: The job could not be posted. Please check input and re-submit.</p>
                                                        <button className="close-btn"><span className="close_icon"></span></button>
                                                    </div>
                                                    <div className="message-box success">
                                                        <p>Success: Your job has been posted. If you want to edit your post, please go to "Manage Jobs".</p>
                                                        <button className="close-btn"><span className="close_icon"></span></button>
                                                    </div> */}

                          <div className="form-group col-lg-12 col-md-12">
                            <label>Job Title</label>
                            <input
                              type="text"
                              name="name"
                              placeholder="Title"
                              {...register("name", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                            />
                            {errors?.name && (
                              <InputError
                                message={errors?.name?.message}
                                key={errors?.name?.message}
                              />
                            )}
                          </div>

                          {/* <!-- About Company --> */}
                          <div className="form-group col-lg-12 col-md-12">
                            <label>Job Description</label>
                            <textarea
                              {...register("jobDescription", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              placeholder="Add description"
                            ></textarea>
                            {errors?.jobDescription && (
                              <InputError
                                message={errors?.jobDescription?.message}
                                key={errors?.jobDescription?.message}
                              />
                            )}
                          </div>

                          {/* <!-- Input --> */}

                          <div className="form-group col-lg-6 col-md-12">
                            <label>Role Type</label>
                            <select
                              {...register("roletype", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              name="roletype"
                              className="chosen-select-role"
                            >
                              <option value={""} disabled selected>Select</option>
                              {userRoleTypes
                                ?.sort(function (a, b) {
                                  if (a.description < b.description) {
                                    return -1;
                                  }
                                  if (a.description > b.description) {
                                    return 1;
                                  }
                                  return 0;
                                })
                                .map((itm) => (
                                  <option value={itm?.userTypeId}>
                                    {itm?.description}
                                  </option>
                                ))}
                            </select>
                            {errors?.roletype && (
                              <InputError
                                message={errors?.roletype?.message}
                                key={errors?.roletype?.message}
                              />
                            )}
                          </div>

                          <div className="form-group col-lg-6 col-md-12">
                            <label>Job Category</label>
                            <select
                              {...register("jobCategory", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              name="jobCategory"
                              className="chosen-select-jobCategory"
                            >
                              <option value={""} disabled selected>Select</option>
                              {jobCategory?.map((itm) => (
                                <option value={itm?.value}>{itm?.label}</option>
                              ))}
                            </select>
                            {errors?.jobCategory && (
                              <InputError
                                message={errors?.jobCategory?.message}
                                key={errors?.jobCategory?.message}
                              />
                            )}
                          </div>

                          {/* <!-- Input --> */}
                          <div className="form-group col-lg-6 col-md-12">
                            <label>Min. hours per week</label>
                            <input
                              type="text"
                              name="hours"
                              placeholder="Hours per week"
                              {...register("hours", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                            />
                            {errors?.hours && (
                              <InputError
                                message={errors?.hours?.message}
                                key={errors?.hours?.message}
                              />
                            )}
                          </div>

                          {/* <!-- Input --> */}
                          <div className="form-group col-lg-6 col-md-12">
                            <label>Rate per hour</label>
                            <input
                              type="text"
                              {...register("rateph", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              name="rateph"
                              placeholder="Hourly Rate in GBP"
                            />
                            {errors?.rateph && (
                              <InputError
                                message={errors?.rateph?.message}
                                key={errors?.rateph?.message}
                              />
                            )}
                          </div>

                          <div className="form-group col-lg-6 col-md-12">
                            <label>Minimum Experience (years)</label>
                            <input
                              type="number"
                              min={0}
                              {...register("experience", {
                                required: {
                                  value: /^[0-9]*[1-9][0-9]*$/,
                                  message:
                                    "Input must be a number greater than 0.",
                                },
                              })}
                              name="experience"
                              placeholder="Years of Experience needed"
                            />
                            {errors?.experience && (
                              <InputError
                                message={errors?.experience?.message}
                                key={errors?.experience?.message}
                              />
                            )}
                          </div>

                          <div className="form-group col-lg-6 col-md-12">
                            <label>Minimum Qualification Needed</label>
                            <input
                              type="text"
                              {...register("qualification", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              name="qualification"
                              placeholder="Min. Qualification"
                            />
                            {errors?.qualification && (
                              <InputError
                                message={errors?.qualification?.message}
                                key={errors?.qualification?.message}
                              />
                            )}
                          </div>

                          <div className="form-group col-lg-6 col-md-12">
                            <label>Min. NVQ Level Required</label>
                            <select
                              {...register("nvqLevel", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              className="chosen-select-nvq"
                            >
                              <option value={""} disabled selected>Select</option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              <option>Not Applicable</option>
                            </select>
                            {errors?.nvqLevel && (
                              <InputError
                                message={errors?.nvqLevel?.message}
                                key={errors?.nvqLevel?.message}
                              />
                            )}
                          </div>

                          <div className="form-group col-lg-6 col-md-12">
                            <label>Vacancies</label>
                            <input
                              {...register("vacancy", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              type="text"
                              name="vacancy"
                              placeholder="Number of vacancies"
                            />
                            {errors?.vacancy && (
                              <InputError
                                message={errors?.vacancy?.message}
                                key={errors?.vacancy?.message}
                              />
                            )}
                          </div>

                          {/* <!-- Input --> */}
                          <div className="form-group col-lg-6 col-md-12">
                            <label>Vacancy Start Date</label>
                            <input
                              {...register("stdate", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                                validate: (value) => {
                                  if (!isDateOld(value, getValues().enddate)) {
                                    return "Vacancy start date should be in future or later than the application date.";
                                  }
                                },
                              })}
                              type="date"
                              name="stdate"
                              min={formatDateHyphen(new Date())}
                              placeholder=""
                            />
                            {errors?.stdate && (
                              <InputError
                                message={errors?.stdate?.message}
                                key={errors?.stdate?.message}
                              />
                            )}
                          </div>

                          {/* <!-- Input --> */}
                          <div className="form-group col-lg-6 col-md-12">
                            <label>Application Deadline Date</label>
                            <input
                              {...register("enddate", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              type="date"
                              name="enddate"
                              min={formatDateHyphen(new Date())}
                              placeholder=""
                            />
                            {errors?.enddate && (
                              <InputError
                                message={errors?.enddate?.message}
                                key={errors?.enddate?.message}
                              />
                            )}
                          </div>

                          {/* <!-- Input --> */}
                          <div className="form-group col-lg-6 col-md-12">
                            <label>Select Site</label>
                            <select
                              {...register("site", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              className="chosen-select-site"
                            >
                              <option value="" disabled selected>Select</option>
                              {userDetail?.company?.sites?.map(function (itm) {
                                return (
                                  <option value={itm?.id} key={itm?.id}>
                                    {itm?.name} - {itm?.city}
                                  </option>
                                );
                              })}
                            </select>
                            {errors?.site && (
                              <InputError
                                message={errors?.site?.message}
                                key={errors?.site?.message}
                              />
                            )}
                          </div>

                          {/* <!-- Input --> */}
                          <div className="form-group col-lg-6 col-md-12">
                            <label>Shift</label>
                            <select
                              {...register("Shift", {
                                required: {
                                  value: true,
                                  message: "required",
                                },
                              })}
                              className="chosen-select-shift"
                            >
                              <option value="" disabled selected>Select</option>
                              <option value="Morning">Morning</option>
                              <option value="Afternoon">Afternoon</option>
                              <option value="Evening">Evening</option>
                              <option value="Night">Night</option>
                              <option value="May vary">May vary</option>
                            </select>
                            {errors?.Shift && (
                              <InputError
                                message={errors?.Shift?.message}
                                key={errors?.Shift?.message}
                              />
                            )}
                          </div>

                          <div className="form-group col-lg-6 col-md-12">
                            <label>Applicable Days </label>
                            <ul className="checkboxes square">
                              {weekOptions?.map((itm) => (
                                <li key={itm?.value}>
                                  <input
                                    id={itm?.value}
                                    type="checkbox"
                                    name="choosedays"
                                    value={itm?.value}
                                    {...register("choosedays", {
                                      required: {
                                        value: true,
                                        message: "required",
                                      },
                                    })}
                                  />
                                  <label for={itm?.label}>{itm?.label}</label>
                                </li>
                              ))}
                            </ul>
                            {errors?.choosedays && (
                              <InputError
                                message={errors?.choosedays?.message}
                                key={errors?.choosedays?.message}
                              />
                            )}
                          </div>

                          {/* <!-- Input --> */}
                          <div className="form-group col-lg-12 col-md-12 text-right pt-4">
                            {isLoading && <Loading />}
                            {!isLoading && (
                              <button
                                type="submit"
                                className="theme-btn btn-style-one"
                              >
                                Post
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                      {/* </FormProvider> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Dashboard --> */}

        {/* <!-- Copyright --> */}
        <Footer />
      </div>
      {/* <!-- End Page Wrapper --> */}
    </>
  );
};
export default PostJob;
