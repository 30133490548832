import { createContext, useState } from "react";
export const AuthContext = createContext();
const constinitialState = {
  token: "",
  isAuthenticated: false,
  loginAttempt: 0,
  userName: "",
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(constinitialState);
  const [favourites, setFavourites] = useState(0);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [profilePic, setProfilePic] = useState("");

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        setFavourites,
        setProfilePic,
        favourites,
        profilePic,
        unreadMessages,
        setUnreadMessages,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
